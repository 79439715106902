import { GetUser, GetUserAvailability } from '../../api/Api';
import { SingleSelectOption, UserComponent } from '../../Interface';
import { useCallback, useContext, useEffect, useState } from 'react';

import SingleSelect from './SingleSelect';
import { UserSearchParamInfo } from '../../context/UserSearchParam';

export default function UserSearchMulti(props: UserComponent) {
    const { change, isMulti = true, defaultValue = "",Availability=false } = props
    const [options, setOptions] = useState<SingleSelectOption[]>([]);
    const [defaultValues,setDefaultValues]=useState<any>([]);
    const params=useContext(UserSearchParamInfo);
    const SearchUser = (value: string) => {
        let param: any = params;
        if(value!=="")
        param={ ...params, ...{display_name: value} };
        GetData(param);
    }

    const GetData = useCallback((param: any) => {
        if(!Availability){
            GetUser(param).then((response: any) => {
                let options: SingleSelectOption[] = [];
                options=response.data.map((d:any)=>{
    
                    return { value: d.id, label: d.display_name, selected:  false }
                })
                setOptions(options);
            })
        }else{
            GetUserAvailability(param).then((response: any) => {
                let options: SingleSelectOption[] = [];
                options=response.data.map((d:any)=>{
    
                    return { value: d.id, label: d.display_name, selected:  false }
                })
                setOptions(options);
            })
        }
    
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    useEffect(()=>{
        GetData({});
    // eslint-disable-next-line react-hooks/exhaustive-deps
    },[])

    useEffect(() => {

        let defaultValues = defaultValue.split(",");
        let values: any[] = [];
        defaultValues.forEach(d => {
            values.push(options.find((item: any) => item.value === d));
        });
        setDefaultValues(values)
    }, [defaultValue, options])

    // useEffect(() => {
    //     let param: any = params
    //     if (defaultValue) {
    //         param={ ...params, ...{idSearch: defaultValue} };
    //         setDefaultValues(defaultValue.split(","));
    //     }

    //    GetData(param);
    // }, [GetData, defaultValue, params])


    return <>
        <SingleSelect defaultValue={defaultValues} changedInput={(value) => { SearchUser(value) }} options={options} isMulti={isMulti} changed={(event: any) => { change(isMulti?event:event.value) }} />

    </>
}