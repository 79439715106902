/* eslint-disable react-hooks/exhaustive-deps */

import { FetchFileMulti, RemoveAttachApi } from '../../api/Api';
import { UserAllNotesActionGetData, UserAllNotesData } from '../../context/Notes';
import { useContext, useEffect, useState } from 'react';

import AttachList from '../Element/AttachList';
import { DataFromApi } from '../../Interface';
import LayoutStyle from '../../style/layout/Layout.module.css';
import UploadImg from '../Element/UploadImg';
import { getGalleryImageData } from '../booking/interviewBooking/CreateDataForEdit';
import { useParams } from 'react-router-dom';

export default function EditNotesAttachments(props: any) {
    const { id } = useParams();
    const GetUserAllNotes = useContext(UserAllNotesActionGetData)
    const userAllNotes = useContext(UserAllNotesData)

    const [load, setLoad] = useState(false);
    const [empty, setEmpty] = useState(false);
    const [gallery, setGallery] = useState<any[]>([]);
    const GetGallery = () => { GetUserAllNotes({ idSearch: id }); }


    const SendImage = (event: any) => {
        let file = (event.target as HTMLInputElement).files;
        setLoad(true);
        FetchFileMulti("UserNotes/addAttach", { img: file, idSearch: id }).then((response: DataFromApi | any) => {
            setEmpty(true);
            setLoad(false);
            if (response.status === 'true') {
                GetGallery();
            }
        });
    }




    useEffect(() => {

        if (userAllNotes !== undefined) {
            console.log(userAllNotes, '======userAllNotes')
            if (userAllNotes.data.length > 0) {
                let datas = userAllNotes.data[0];

                setGallery(getGalleryImageData(datas.attachments))
            }
        }

    }, [userAllNotes])

    return <>
        <div className={`${LayoutStyle.container}`}>
            <div className={`${LayoutStyle.innerContainer}`}>
            <div className={`${LayoutStyle.backBlackLight} ${LayoutStyle.cover} ${LayoutStyle.mt1}`}>
                <div className={LayoutStyle.flex}>
                    <h4>Attachments</h4>
                </div>
                <div>
                    <div>
                        <UploadImg loading={load} multiple={true} change={(event) => { SendImage(event) }} imageSrc={""} empty={empty} />
                    </div>
                    <div className={` ${LayoutStyle.mt1}`}>
                        <AttachList RemoveGallery={RemoveAttachApi} GetGallery={GetGallery} datas={gallery}/>
                    </div>
                </div>

            </div>
        </div>
    </div>
    </>
}

