import { GetMyPermissionApi, GetUserPermissionApi } from "../api/Api";
import { createContext, useState } from "react";

import { PropsChildren } from "../Interface";

export const UserPermissionContextData = createContext<any>(undefined);
export const UserOnlinePermissionContextData = createContext<any>(undefined);
export const UserPermissionContextAction = createContext<(param?: any) => any | undefined>(() => { });
export const UserOnlinePermissionContextAction = createContext<(param?: any) => any | undefined>(() => { });
export const UserPermissionContextActionData = createContext<(param?: any) => any | undefined>(() => { });

export default function UserPermissionContextProvider(props: PropsChildren) {

   const { children } = props
   const [permissionInfo, setPermissionInfo] = useState(undefined);
   const [myPermissionInfo, setMyPermissionInfo] = useState(undefined);

   const GetPermission = (param = {}) => {
      GetUserPermissionApi(param).then((response: any) => {
         setPermissionInfo(response.data);
      })
   }


   const GetMyPermission = (param = {}) => {
      GetMyPermissionApi(param).then((response: any) => {
         setMyPermissionInfo(response.data);
      })
   }

   return (
      <UserPermissionContextData.Provider value={permissionInfo}>
         <UserPermissionContextAction.Provider value={GetPermission}>
            <UserPermissionContextActionData.Provider value={setPermissionInfo}>
               <UserOnlinePermissionContextData.Provider value={myPermissionInfo}>
                  <UserOnlinePermissionContextAction.Provider value={GetMyPermission}>
                     {children}
                  </UserOnlinePermissionContextAction.Provider>
               </UserOnlinePermissionContextData.Provider>
            </UserPermissionContextActionData.Provider>
         </UserPermissionContextAction.Provider>
      </UserPermissionContextData.Provider>
   )

}

