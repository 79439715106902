import * as React from 'react';

import { FormControl, FormControlLabel, FormLabel, RadioGroup } from '@mui/material';

import DarkMode from './DarkMode';
import Radio from '@mui/material/Radio';
import { RadioProps } from '../../Interface';

export default function Radios(props:RadioProps) {
  const {Change,label,name,option=[],defaultValue=""}=props;

  const [value, setValue] = React.useState('female');

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setValue((event.target as HTMLInputElement).value);
    Change((event.target as HTMLInputElement).value)
  };

  React.useEffect(()=>{
    if(defaultValue!==undefined&&defaultValue!==""){
      setValue(defaultValue)
    }
  },[defaultValue])

  let item=option.map((data)=>{
    return <><FormControlLabel value={data.value} control={<Radio />} label={data.label} /></>
  })

  return (
    <DarkMode>
      <FormControl>
      <FormLabel id="demo-row-radio-buttons-group-label">{label}</FormLabel>
      <RadioGroup
        row
        aria-labelledby="demo-row-radio-buttons-group-label"
        name={name}
        value={value}
        
        onChange={handleChange}
      >
        {item}
      </RadioGroup>
    </FormControl>
       
     
    </DarkMode>
  );
}
