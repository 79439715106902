import { createContext, useState } from "react";

import { PropsChildren } from "../Interface";

export const LinkContextData = createContext<any>([]);
export const LinkContextAction = createContext<(param?:any) => any | undefined>(() => { });

export default function LinkContextProvider(props: PropsChildren) {

   const { children } = props
   const [linkInfo, setLinkInfo] = useState([]);





   return (
      <LinkContextData.Provider value={linkInfo}>
         <LinkContextAction.Provider value={setLinkInfo}>
            {children}
         </LinkContextAction.Provider>
      </LinkContextData.Provider>
   )

}

