import { createContext, useEffect, useState } from "react";

import { PropsChildren } from "../Interface";

export const DialogOpenData = createContext<{open:boolean,id:string}>({open:false,id:""});
export const DialogAction = createContext<(data: any) => any | undefined>((data: boolean) => { });

export default function DialogProvider(props: PropsChildren) {

   const { children } = props
   const [dialogOpenData, setDialogData] = useState({open:false,id:""});

   

   return (
      <DialogOpenData.Provider value={dialogOpenData}>
         <DialogAction.Provider value={setDialogData}>
            {children}
         </DialogAction.Provider>
      </DialogOpenData.Provider>
   )

}

