import LayoutStyle from '../../style/layout/Layout.module.css';
import { UserInfo } from '../../Interface';

const About = (props:UserInfo) => {
    const {data}=props
    return <>
        <div className={` ${LayoutStyle.cover} ${LayoutStyle.mt1}`}>
            <div>
                <h4>About</h4>
                <p className={`${LayoutStyle.spanName}`}>{data?.about}
                </p>
            </div>
        </div>
    </>
}

export default About;