import { createContext, useState } from "react";

import { PropsChildren } from "../Interface";

export const UserSearchParamInfo = createContext<{}>({});
export const UserSearchParamAction = createContext<(param:any)=>any>((param: any) => { });


export default function UserSearchParamProvider(props: PropsChildren) {
    const { children } = props;
    const [params, setParams] = useState({})
    return <>
        <UserSearchParamInfo.Provider value={params}>
            <UserSearchParamAction.Provider value={setParams}>
                {children}
            </UserSearchParamAction.Provider>
        </UserSearchParamInfo.Provider>
    </>

}