import { GetMyNotesApi, GetNoteApi, GetNotesUserEditApi } from "../api/Api";
import { createContext, useState } from "react";

import { PropsChildren } from "../Interface";

export const UserNote = createContext<any>(undefined);
export const UserAllNote = createContext<any>(undefined);
export const UserAllNotesData = createContext<any>(undefined);
export const UserNoteAction = createContext<(data: any) => any | undefined>((data: any) => { });
export const UserNoteActionGetData = createContext<(param: any) => any | undefined>(() => { });
export const UserAllNoteActionGetData = createContext<(param: any) => any | undefined>(() => { });
export const UserAllNotesActionGetData = createContext<(param: any) => any | undefined>((param: any) => { });

export const UserEditNotesData = createContext<any>(undefined);
export const UserEditNotesActionGetData = createContext<(param: any) => any | undefined>((param: any) => { });


export default function NotesProvider(props: PropsChildren) {

   const { children } = props
   const [userNote, setUserNote] = useState([]);
   const [userAllNote, setUserAllNote] = useState([]);
   const [userAllNotes, setUserAllNotes] = useState([]);
   const [userEditNotes, setUserEditNotes] = useState([]);

   const GetUserNote = (param = { start: 0, number: 25 }) => {
      GetNoteApi(param).then((response: any) => {
         setUserNote(response);
      })
   }

   const GetUserAllNote = (param = { start: 0, number: 25 }) => {
      GetNoteApi(param).then((response: any) => {
         setUserAllNote(response);
      })
   }


   const GetUserAllNotes = (param = { start: 0, number: 25 }) => {
      GetMyNotesApi(param).then((response: any) => {
         setUserAllNotes(response);
      })
   }

   const GetUserEditNote = (param = { start: 0, number: 25 }) => {
      GetNotesUserEditApi(param).then((response: any) => {
         setUserEditNotes(response);
      })
   }

   return (
      <UserNote.Provider value={userNote}>
         <UserAllNote.Provider value={userAllNote}>
            <UserAllNotesData.Provider value={userAllNotes}>
               <UserAllNoteActionGetData.Provider value={GetUserAllNote}>
                  <UserAllNotesActionGetData.Provider value={GetUserAllNotes}>
                     <UserNoteAction.Provider value={setUserNote}>
                        <UserNoteActionGetData.Provider value={GetUserNote}>
                           <UserEditNotesData.Provider value={userEditNotes}>
                              <UserEditNotesActionGetData.Provider value={ GetUserEditNote}>
                                 {children}
                              </UserEditNotesActionGetData.Provider>
                           </UserEditNotesData.Provider>

                        </UserNoteActionGetData.Provider>
                     </UserNoteAction.Provider>
                  </UserAllNotesActionGetData.Provider>
               </UserAllNoteActionGetData.Provider>
            </UserAllNotesData.Provider>
         </UserAllNote.Provider>
      </UserNote.Provider>
   )

}

