import React, { Fragment, useEffect, useState } from 'react';

import Select from 'react-select';
import { SingleSelects } from '../../Interface';
import { blue } from '@mui/material/colors';

export default function SingleSelect(props: SingleSelects) {

  const {
    isClearable = false,
    isDisabled = false,
    isLoading = false,
    isRtl = false,
    isSearchable = true,
    isMulti = false,
    defaultValue=[],
    options = [{ value: '', label: 'Choose one', selected: true }],
    changed,
    changedInput
  } = props

 
  const [value, setValue] = useState<any>(options[0]);

  const Change=(event:any)=>{
    setValue(event);
    changed(event);
   
  }
  const ChangeInput=(InputValue: string)=>{
    if(changedInput!==undefined)
    changedInput(InputValue);
   
  }
  
  useEffect(() => {
    if (options.length > 0) {
      let index = options.findIndex((data: any) => { return data.selected === true })
      if (index === undefined)
        index = 0;
      setValue(options[index]);
    }else{
      setValue("");
    }
  }, [options])
  
  useEffect(() => {
    
    if (defaultValue.length > 0) {
      
      if(defaultValue[0]!==undefined){
        
        setValue(defaultValue);
      }
     
    }
  }, [defaultValue])
 const CustomStyles = {
    control: (styles: any) => ({ ...styles, backgroundColor: '#1e2026', color: "white", padding: ".4em", border: "1px solid #dcdcdc78" }),
    option: (styles: any, { data, isDisabled, isFocused, isSelected }: any) => {
      return {
        ...styles,
        backgroundColor: isSelected ? '#2196f3' : blue,
        color: '#FFF',
        cursor: 'pointer',
        "&:hover": {
          color: "#83c1ad"
        }

      };
    },
    input: (styles: any) => ({ ...styles, color: "white" }),
    singleValue: (styles: any) => ({ ...styles, color: "white" }),
    menu: (styles: any) => ({ ...styles, backgroundColor: '#4f4f50',zIndex:"100", color: "white" }),

  };

  return (
    <Fragment>
      <Select
        className="basic-single"
        classNamePrefix="select"
        value={value}
        isDisabled={isDisabled}
        isLoading={isLoading}
        isMulti={isMulti}
        isClearable={isClearable}
        isRtl={isRtl}
        isSearchable={isSearchable}
        name="color"
        options={options}
        styles={CustomStyles}
        onChange={(event: any) => { Change(event) }}
        onInputChange={(InputValue)=>{ChangeInput(InputValue);}}
        
   
      />

    </Fragment>
  );

}


