import './index.css';

import App from './App';
import AvailabilityContextProvider from './context/AvailabilityContext';
import DialogProvider from './context/Dialog';
import FormProvider from './context/FormContext';
import InterViewBookingContextProvider from './context/InterViewBookingContext';
import LanguageContextProvider from './context/LanguageContext';
import LinkContextProvider from './context/LinkContext';
import NotesProvider from './context/Notes';
import ProgramContextProvider from './context/ProgramContext';
import ProofContextProvider from './context/ProofContext';
import React from 'react';
import ReactDOM from 'react-dom/client';
import RoleContextProvider from './context/RoleContext';
import SiteContextProvider from './context/SiteContext';
import StudioContextProvider from './context/StudioContext';
import TeamBookingContextProvider from './context/TeamBookingContext';
import { ToastContainer } from 'react-toastify';
import UserDocProvider from './context/UserDoc';
import UserGalleryProvider from './context/UserGallery';
import UserInfoProvider from './context/UserInfo';
import UserPermissionContextProvider from './context/UserPermissionContext';
import UserSearchParamProvider from './context/UserSearchParam';
import UsersProvider from './context/Users';
import WhiteProvider from './context/WhiteBoard';
import reportWebVitals from './reportWebVitals';

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);
root.render(
  <React.StrictMode>
    <UserInfoProvider>
      <DialogProvider>
        <UserGalleryProvider>
          <UserSearchParamProvider>
            <InterViewBookingContextProvider>
              <AvailabilityContextProvider>
                <TeamBookingContextProvider>
                  <UsersProvider>
                    <ProgramContextProvider>
                      <ProofContextProvider>
                        <SiteContextProvider>
                          <StudioContextProvider>
                            <UserPermissionContextProvider>
                              <NotesProvider>
                                <LanguageContextProvider>
                                  <WhiteProvider>
                                    <UserDocProvider>
                                      <FormProvider>
                                        <RoleContextProvider>
                                          <LinkContextProvider>
                                          <App />
                                          </LinkContextProvider>
                                        </RoleContextProvider>
                                      </FormProvider>
                                    </UserDocProvider>
                                  </WhiteProvider>
                                </LanguageContextProvider>
                              </NotesProvider>
                            </UserPermissionContextProvider>
                          </StudioContextProvider>
                        </SiteContextProvider>
                      </ProofContextProvider>
                    </ProgramContextProvider>
                  </UsersProvider>
                </TeamBookingContextProvider>
              </AvailabilityContextProvider>
            </InterViewBookingContextProvider>
          </UserSearchParamProvider>
        </UserGalleryProvider>
      </DialogProvider>
    </UserInfoProvider>

    <ToastContainer />

  </React.StrictMode >
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
