import { useEffect, useState } from 'react';

import LayoutStyle from '../../style/layout/Layout.module.css';
import Proof from './Proof';
import { RoleCrewDataItem } from '../../Interface';
import Sites from './Site';
import UserSearch from './UserSearch';

export default function ChooseRoleCrewOption(props: RoleCrewDataItem) {

    const { index, changed, isMulti = false, ItemDatas = { userId: "", role: "0" } } = props

    const [userId, setUserId] = useState("");
    const [role, setRole] = useState("0");
    const [item,setItem]=useState({userId:"",role:"0"})

    useEffect(() => {
        setUserId(ItemDatas.userId);
        setRole(ItemDatas.role);
    }, [ItemDatas])

    const changedItem = (value: any, types: string) => {
        
        const newItem:any=item;
        newItem[types]=value;
        setItem({...newItem});
        changed({ index, userId: newItem.userId, role:newItem.role });
       

    }


    return <>
        <div className={`${LayoutStyle.flexWarp}`} key={`divLink`}>
           
            <div className={`${LayoutStyle.flexGrow1}`}>
                <p>Role:</p>
                <Proof  type="role" defaultValue={role} isMulti={false} change={(value) => { changedItem(value, "role") }} />
            </div>
            <div className={`${LayoutStyle.flexGrow1}`}>
                <p>Choose User:</p>
                <UserSearch defaultValue={ItemDatas.userId} isMulti={false} change={(event: any) => { changedItem(event, "userId") }} />
            </div>


        </div>
    </>
}