import './App.css';
import 'react-toastify/dist/ReactToastify.css';

import { BrowserRouter, Route, Routes } from 'react-router-dom';

import AddAvailability from './components/booking/availability/AddAvailability';
import AddAvailabilityUser from './components/user/AddAvailabilityUser';
import AddBooking from './components/booking/interviewBooking/AddBooking';
import AddDocument from './components/user/AddDocument';
import AddForm from './components/form/AddForm';
import AddNote from './components/tools/AddNote';
import AddProgram from './components/program/AddProgram';
import AddProof from './components/setting/proof/AddProof';
import AddSite from './components/setting/site/AddSite';
import AddStudio from './components/setting/studio/AddStudio';
import AddUser from './components/user/AddUser';
import AddWhiteBoard from './components/tools/AddWhiteBoard';
import AllBooking from './components/booking/allBooking/AllBooking';
import Availability from './components/booking/availability/Availability';
import CheckListProgram from './components/form/CheckListProgram';
import Dashboard from './components/Dashboard/Dashboard';
import EditBooking from './components/booking/interviewBooking/EditBooking';
import EditDocsAttachments from './components/user/EditDocsAttachments';
import EditDocument from './components/user/EditDocument';
import EditForm from './components/form/EditForm';
import EditImageGallery from './components/program/EditImageGallery';
import EditImageGalleryUser from './components/user/EditImageGalleryUser';
import EditNotes from './components/tools/EditNotes';
import EditNotesAttachments from './components/tools/EditNotesAttachments';
import EditProfile from './components/profile/EditProfile';
import EditProgram from './components/program/EditProgram';
import EditProof from './components/setting/proof/EditProof';
import EditSite from './components/setting/site/EditSite';
import EditStudio from './components/setting/studio/EditStudio';
import EditUser from './components/user/EditUser';
import EditUserPermission from './components/user/EditUserPermission';
import EditWhiteBoard from './components/tools/EditWhiteBoard';
import EditWhiteBoardAttachments from './components/tools/EditWhiteBoardAttachments';
import ForgetPassword from './components/Auth/ForgetPassword';
import Forms from './components/form/Forms';
import InterviewBooking from './components/booking/interviewBooking/InterviewBooking';
import Languages from './components/language/Languages';
import Layout from './components/Layout/Layout';
import Login from './components/Auth/Login';
import MyDocuments from './components/tools/MyDocuments';
import MyForms from './components/tools/MyForms';
import MyNote from './components/tools/MyNote';
import MyNotification from './components/MyNotification';
import MyProgram from './components/program/MyProgram';
import NotesView from './components/tools/NotesView';
import PermissionForm from './components/form/PermissionForm';
import Profile from './components/profile/Profile';
import Program from './components/program/Program';
import Proofs from './components/setting/Proofs';
import Register from './components/Auth/Register';
import ResponseToForm from './components/tools/ResponseForm';
import Site from './components/setting/Site';
import SortForm from './components/form/SortForm';
import Studio from './components/setting/Studio';
import UserDocuments from './components/user/UserDocuments';
import UserFormResponse from './components/form/UserFormResponse';
import UserManager from './components/user/UserManager';
import ViewFormResponse from './components/form/ViewFormResponse';
import ViewTeamStatusList from './components/booking/interviewBooking/ViewTeamStatusList';
import WhiteBoard from './components/tools/WhiteBoard';
import WhiteBoardView from './components/tools/WhiteBoardView';

function App() {
  return (
    <>
      <BrowserRouter>
        <Routes>
          <Route path="/" element={<Login />} />
          <Route path="/Dashboard" element={<Dashboard />} />
          <Route path="/MyProfile" element={<Layout><Profile /></Layout>} />
          <Route path="/EditMyProfile" element={<Layout><EditProfile /></Layout>} />
          
          <Route path="/InterviewBooking" element={<Layout><InterviewBooking /></Layout>} />
          <Route path="/VewStatusList/:id" element={<Layout><ViewTeamStatusList /></Layout>} />
          <Route path="/EditUser/:id" element={<Layout><EditUser /></Layout>} />
          <Route path="/AddBooking" element={<Layout><AddBooking /></Layout>} />
          <Route path="/EditBooking/:id" element={<Layout><EditBooking /></Layout>} />
          <Route path="/AllBooking" element={<Layout><AllBooking /></Layout>} />
          <Route path="/Availability" element={<Layout><Availability /></Layout>} />
          <Route path="/AddAvailability" element={<Layout><AddAvailability /></Layout>} />
          <Route path="/UserManager" element={<Layout><UserManager /></Layout>} />
          <Route path="/UserDocuments" element={<Layout><UserDocuments /></Layout>} />
          <Route path="/AddDocument" element={<Layout><AddDocument /></Layout>} />
          
          <Route path="/EditUser/:id" element={<Layout><EditUser /></Layout>} />
          <Route path="/EditUserPermission/:id" element={<Layout><EditUserPermission /></Layout>} />
          <Route path="/AddUser" element={<Layout><AddUser /></Layout>} />
          <Route path="/EditImageGalleryUser/:id" element={<Layout><EditImageGalleryUser /></Layout>} />
          <Route path="/AddAvailabilityUser/:id" element={<Layout><AddAvailabilityUser /></Layout>} />
          <Route path="/AddBookingUser/:user_id" element={<Layout><AddBooking /></Layout>} />
          
          <Route path="/Programs" element={<Layout><Program /></Layout>} />
          <Route path="/MyPrograms" element={<Layout><MyProgram /></Layout>} />
          <Route path="/AddProgram" element={<Layout><AddProgram /></Layout>} />
          <Route path="/EditProgram/:id" element={<Layout><EditProgram /></Layout>} />
          <Route path="/EditProgramImageGallery/:id" element={<Layout><EditImageGallery /></Layout>} />
          <Route path="/Proofs" element={<Layout><Proofs/></Layout>} />
          <Route path="/AddProof" element={<Layout><AddProof/></Layout>} />
          <Route path="/EditProof/:id" element={<Layout><EditProof/></Layout>} />
          <Route path="/Site" element={<Layout><Site/></Layout>} />
          <Route path="/AddSite" element={<Layout><AddSite/></Layout>} />
          <Route path="/EditSite/:id" element={<Layout><EditSite/></Layout>} />
          <Route path="/Studio" element={<Layout><Studio/></Layout>} />
          <Route path="/AddStudio" element={<Layout><AddStudio/></Layout>} />
          <Route path="/EditStudio/:id" element={<Layout><EditStudio/></Layout>} />
          <Route path="/MyNotification" element={<Layout><MyNotification/></Layout>} />
          <Route path="/language" element={<Layout><Languages/></Layout>} />
          <Route path="/MyNote" element={<Layout><MyNote/></Layout>} />
          <Route path="/AddNote" element={<Layout><AddNote/></Layout>} />
          <Route path="/NotesView/:id" element={<Layout><NotesView/></Layout>} />
          <Route path="/EditNotes/:id" element={<Layout><EditNotes/></Layout>} />
          <Route path="/EditNotesAttachments/:id" element={<Layout><EditNotesAttachments/></Layout>} />
          <Route path="/WhiteBoard" element={<Layout><WhiteBoard/></Layout>} />
          <Route path="/AddWhiteBoard" element={<Layout><AddWhiteBoard/></Layout>} />
          <Route path="/EditWhiteBoard/:id" element={<Layout><EditWhiteBoard/></Layout>} />
          <Route path="/EditWhiteBoardAttachments/:id" element={<Layout><EditWhiteBoardAttachments/></Layout>} />
          <Route path="/WhiteBoardView/:id" element={<Layout><WhiteBoardView/></Layout>} />
          <Route path="/EditDocument/:id" element={<Layout><EditDocument/></Layout>} />
          <Route path="/EditDocsAttachments/:id" element={<Layout><EditDocsAttachments/></Layout>} />
          <Route path="/MyDocuments" element={<Layout><MyDocuments/></Layout>} />
          <Route path="/Forms" element={<Layout><Forms/></Layout>} />
          <Route path="/AddForm" element={<Layout><AddForm/></Layout>} />
          <Route path="/EditForm/:id" element={<Layout><EditForm/></Layout>} />
          <Route path="/PermissionForm/:id" element={<Layout><PermissionForm/></Layout>} />
          <Route path="/SortForm/:id" element={<Layout><SortForm/></Layout>} />
          
          <Route path="/MyForms" element={<Layout><MyForms/></Layout>} />
          <Route path="/ResponseToForm/:id" element={<Layout><ResponseToForm/></Layout>} />
          <Route path="/CheckListProgram/:id" element={<Layout><CheckListProgram/></Layout>} />
          <Route path="/ViewFormResponse" element={<Layout><ViewFormResponse/></Layout>} />
          <Route path="/UserFormResponse/:id" element={<Layout><UserFormResponse/></Layout>} />
          
          <Route path="/ResponseToFormProgram/:id/:program_id" element={<Layout><ResponseToForm/></Layout>} />

          
          
          <Route path="/Register" element={<Register />} />
          <Route path="/ForgetPassword" element={<ForgetPassword />} />
          
        </Routes>
      </BrowserRouter>
    </>

  );
}

export default App;
