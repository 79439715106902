import { GetDocApi, GetMyDocsApi } from "../api/Api";
import { createContext, useState } from "react";

import { PropsChildren } from "../Interface";

export const UserDoc = createContext<any>(undefined);
export const UserAllDoc = createContext<any>(undefined);
export const UserAllDocsData = createContext<any>(undefined);
export const UserDocAction = createContext<(data: any) => any | undefined>((data: any) => { });
export const UserDocActionGetData = createContext<(param: any) => any | undefined>(() => { });
export const UserAllDocActionGetData = createContext<(param: any) => any | undefined>(() => { });
export const UserAllDocsActionGetData = createContext<(param: any) => any | undefined>(() => { });

export default function UserDocProvider(props: PropsChildren) {

   const { children } = props
   const [userDoc, setUserDoc] = useState([]);
   const [userAllDoc, setUserAllDoc] = useState([]);
   const [userAllDocs, setUserAllDocs] = useState([]);

   const GetUserDoc = (param = { start: 0, number: 25 }) => {
      GetDocApi(param).then((response: any) => {
         setUserDoc(response);
      })
   }

   const GetUserAllDoc = (param = { start: 0, number: 25 }) => {
      GetDocApi(param).then((response: any) => {
         setUserAllDoc(response);
      })
   }


   const GetUserAllDocs = (param = { start: 0, number: 25 }) => {
      GetMyDocsApi(param).then((response: any) => {
         setUserAllDocs(response);
      })
   }

   return (
      <UserDoc.Provider value={userDoc}>
         <UserAllDoc.Provider value={userAllDoc}>
            <UserAllDocsData.Provider value={userAllDocs}>
               <UserAllDocActionGetData.Provider value={GetUserAllDoc}>
                  <UserAllDocsActionGetData.Provider value={GetUserAllDocs}>
                     <UserDocAction.Provider value={setUserDoc}>
                        <UserDocActionGetData.Provider value={GetUserDoc}>
                           {children}
                        </UserDocActionGetData.Provider>
                     </UserDocAction.Provider>
                  </UserAllDocsActionGetData.Provider>
               </UserAllDocActionGetData.Provider>
            </UserAllDocsData.Provider>
         </UserAllDoc.Provider>
      </UserDoc.Provider>
   )

}

