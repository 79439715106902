import * as React from 'react';

import Button from '@mui/material/Button';
import { DropDownBtnProps } from '../../Interface';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';

export default function DropDownBtn(props: DropDownBtnProps) {
    const { title, items } = props
    const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
    const open = Boolean(anchorEl);
    const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
        setAnchorEl(event.currentTarget);
    };
    const handleClose = () => {
        setAnchorEl(null);
    };
    let Menus = items.map((data: any) => (
        <MenuItem onClick={() => {
            data.click();
            if (data.close) {
                handleClose()
            }
        }}>{data.title}</MenuItem>
    ))

    return (
        <div>
            <Button
                id="basic-button"
                aria-controls={open ? 'basic-menu' : undefined}
                aria-haspopup="true"
                aria-expanded={open ? 'true' : undefined}
                onClick={handleClick}
            >
                {title}
            </Button>
            <Menu
                id="basic-menu"
                anchorEl={anchorEl}
                open={open}
                onClose={handleClose}
                MenuListProps={{
                    'aria-labelledby': 'basic-button',
                }}
            >

                {Menus}
            </Menu>
        </div>
    );
}
