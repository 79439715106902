/* eslint-disable jsx-a11y/anchor-is-valid */

import { Avatar, Paper } from '@mui/material';
import { WhiteCommentActionGetData, WhiteCommentPropsData, newWhiteCommentDataAction, newWhiteCommentDatas } from '../../context/WhiteBoard';
import { useCallback, useContext, useEffect, useState } from 'react';

import { AddWhiteCommentReplyApi } from '../../api/Api';
import { ApiUrl } from '../../config';
import ButtonStyle from '../../style/button/Button.module.css';
import Buttons from '../Element/ButtonLoader';
import { CheckValue } from '../../function/CheckEmpty';
import ConfirmRejectDialog from '../Element/AgreeDialog';
import { DialogAction } from '../../context/Dialog';
import LayoutStyle from '../../style/layout/Layout.module.css';
import TextArea from '../Element/TextArea';

export default function WhiteBoardComments(props: any) {
    const { id } = props
    const whiteCommentData = useContext(WhiteCommentPropsData)
    const newWhiteCommentData = useContext(newWhiteCommentDatas)
    const [comment, setComment] = useState("")
    const [data, setdata] = useState([])
    const [loading, setLoading] = useState(false)

    const [info, setInfo] = useState({
        on_white_board_comment_id: "",
        txt: "",
        user_white_board_id: "",
    })
    const [param, setParam] = useState<any>({ start: 0, number: 1000, idSearch: id });
    const GetWhiteCommentData = useContext(WhiteCommentActionGetData)
    const setDialogData = useContext(DialogAction);
    const setNewWhiteCommentData = useContext(newWhiteCommentDataAction)

    const SaveReply = () => {
        let checkedItem = [{ name: 'Reply', value: info.txt },]
        if (!CheckValue(checkedItem)) {
            return false;
        }
        setLoading(false);
        info["user_white_board_id"] = id
        AddWhiteCommentReplyApi(info).then((response: any) => {
            setLoading(false);
            if (response?.status === "true") {
                setDialogData({ open: false, id: "WhiteBoardCommentReply" })
                setNewWhiteCommentData("start")
                setTimeout(() => {
                    setNewWhiteCommentData("")
                }, 200);
            }
        })
    }

    const GetComment = useCallback(() => {
        console.log(param, '===param')
        GetWhiteCommentData(param);
    }, [GetWhiteCommentData, param])

    useEffect(() => {
        GetComment();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    useEffect(() => {
        if (newWhiteCommentData === "start") {
            let newParam = param
            newParam.start = 0;
            newParam.number = 1000;
            setParam({ ...newParam });
            GetComment()
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [newWhiteCommentData]);

    useEffect(() => {

        if (whiteCommentData !== undefined) {
            setdata(whiteCommentData.data)
        }
    }, [whiteCommentData])

    const Changed = (value: any, type: string) => {
        let newValue: any = info;
        newValue[type] = value;
        setInfo(newValue);
    }

    let items = [];

    items = data.map((data: any) => {
        return <>
            <div id={data.id}>
                <Paper elevation={3} className={`${LayoutStyle.mt1} ${LayoutStyle.p1}`}>
                    <div className={LayoutStyle.flex}>
                        <Avatar sx={{ width: "70px", height: "70px" }} src={`${ApiUrl}${data.file_url}/small/${data.file_name}`} />
                        <span className={LayoutStyle.mse1}>{`${data.fname} ${data.lname}`}</span>
                        <small className={` ${LayoutStyle.darkWhiteColor}`}>{`(${data.created_at})`}</small>

                        {data.on_white_board_comment_id!==null&&<a href={`#${data.on_white_board_comment_id}`}>
                            <small className={` ${LayoutStyle.darkWhiteColor} ${LayoutStyle.textSuccess}`}>
                            @{`(${data.u_fname} ${data.u_lname})`}
                        </small>
                        </a>}

                    </div>
                    <p className={LayoutStyle.darkWhiteColor}>{data.txt}</p>
                    <p>
                        <Buttons classes={`${ButtonStyle.btnBlueOuterLink}`}
                            loading={false}
                            clicked={() => {
                                setDialogData({ open: true, id: "WhiteBoardCommentReply" });
                                setComment(data.txt)
                                Changed(data.id, "on_white_board_comment_id");
                            }}
                            title="Reply"
                            size='small' />

                    </p>
                </Paper>
            </div>
        </>
    })

    const ElementReply = () => {
        return <>
            <p>{comment}</p>
            <TextArea
                id='text'
                name='text'
                value={""}
                cols={40}
                label={"Reply"}
                changed={(event: any) => { Changed(event.target.value, "txt"); }}
            />
        </>
    }

    return <>
        <div className={`${LayoutStyle.backBlackLight} ${LayoutStyle.cover} ${LayoutStyle.mt1}`}>
            {items}
        </div>

        <ConfirmRejectDialog
            title="Write your comment"
            id="WhiteBoardCommentReply"
            body={ElementReply()}
            confirmTitle='Save'
            rejectTitle='Cancel'
            confirmFunction={() => { SaveReply() }}
            rejectFunction={() => { setDialogData({ open: false, id: "WhiteBoardCommentReply" }) }}
            confirmClass={ButtonStyle.danger}
            rejectClass={ButtonStyle.rejectBtnDialog}
            loading={loading}
        />

    </>

}