import { UserAllDoc, UserAllDocActionGetData } from "../../context/UserDoc";
import { useContext, useEffect, useState } from "react";

import AttachFileIcon from '@mui/icons-material/AttachFile';
import ButtonStyle from '../../style/element/button/Button.module.css'
import ConfirmRejectDialog from "../Element/AgreeDialog";
import DeleteIcon from '@mui/icons-material/Delete';
import { DialogAction } from "../../context/Dialog";
import DropDownBtn from "../Element/DropDownBtn";
import EditIcon from '@mui/icons-material/Edit';
import GridServerSide from "../Element/GridServerSide";
import GridStyle from '../../style/element/grid/Grid.module.css'
import { RemoveDocsApi } from "../../api/Api";
import SettingsIcon from '@mui/icons-material/Settings';
import { useNavigate } from "react-router-dom";

export default function GridsDoc() {
    const navigate = useNavigate();
    const GetUserAllDoc = useContext(UserAllDocActionGetData)
    const columns = [
        { field: 'id', type: "string", hideable: false, width: 120, },
        {
            field: 'Title', type: "string", width: 250, renderCell: (params: any) => {
                return (<div className={`${GridStyle.textTd}`}>{params.value}</div>);
            }
        },
        {
            field: 'Keyword', type: "string", filterable: false, width: 270, renderCell: (params: any) => {
                return (<div className={`${GridStyle.textTd}`}>{params.value}</div>);
            }
        },
        {
            field: 'Description', type: "string", filterable: false, width: 270, renderCell: (params: any) => {
                return (<div className={`${GridStyle.textTd}`}>{params.value}</div>);
            }
        },
        {
            field: 'Action', type: "date", filterable: false, sortable: false, renderCell: (params: any) => {
                let items = [];
                let id=params.value;
                items.push({ click: () => { GoToEditPage(id) }, title: <><EditIcon/>Edit</>, close: true })
                items.push({ click: () => { GoToAttachmentPage(id) }, title: <><AttachFileIcon/>Attachments</>, close: true })
                items.push({ click: () => { Remove(id) }, title: <><DeleteIcon/>Remove</>, close: true })
                return (
                    <>
                        <DropDownBtn
                            title={<><strong><SettingsIcon /></strong></>}
                            items={items}
                        />
                    </>
                );
            },
        },
    ];
    const [rows, setRows] = useState([]);
    const userAllDoc = useContext(UserAllDoc)
    const [id, setId] = useState(0);
    const [loading, setLoading] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [start, setStart] = useState(0);
    const [number, setNumber] = useState(25);



    const setDialogData = useContext(DialogAction);
    const [rowCountState, setRowCountState] = useState(0);
    const Remove = (id: any) => { setId(id); setDialogData({ open: true, id: "RemoveDocUser" }) }
    const RemoveAvailability = () => {
        setLoading(true);
        RemoveDocsApi({ idSearch: id}).then(() => {
            setLoading(false);
            setDialogData({ open: false, id: "RemoveDocUser" });
            GetData();
        })
    }

    const GoToEditPage = (id: string | number) => {
        GetUserAllDoc({ idSearch: id });
        navigate(`/EditDocument/${id}`);
    }
    
    const GoToAttachmentPage = (id: string | number) => {
        GetUserAllDoc({ idSearch: id });
        navigate(`/EditDocsAttachments/${id}`);
    }
    
    const GetData = (starts: number=start,numbers: number=number) => { GetUserAllDoc({ start:starts, number:numbers }); }
   
    const ChangePageSize = (param: any) => {
        setNumber(param.pageSize);
        setStart(0);
        GetData(0,param.pageSize);
    }

    const ChangePageNumber = (param: any) => {
        setStart(number * param.page);
        GetData(number * param.page);
    }
    
    useEffect(()=>{
        GetUserAllDoc({})
    // eslint-disable-next-line react-hooks/exhaustive-deps
    },[])

    useEffect(() => {
        if (userAllDoc !== undefined) {
            setIsLoading(false)
            setRowCountState(userAllDoc?.total);
            if (userAllDoc.data) {
                let newRows = userAllDoc.data.map((item: any) => {
             
    
                    return {
                        id: item.id,
                        Title:`${item.title}`,
                        Keyword: item.keyword,
                        Description: item.description,
                        Action: `${item.id}`
                    }
                })

                setRows(newRows);
            }
        }
    }, [userAllDoc]);
    
    return <>
        <GridServerSide
            rowCounts={rowCountState}
            columns={columns}
            rows={rows}
            isLoading={isLoading}
            ChangePageNumber={ChangePageNumber}
            ChangePageSize={ChangePageSize}
        />
        <ConfirmRejectDialog
            title='Are you sure remove?'
            id="RemoveDocUser"
            body="All information related to the program will be deleted! "
            confirmTitle='Remove'
            rejectTitle='Cancel'
            confirmFunction={() => { RemoveAvailability() }}
            rejectFunction={() => { setDialogData({ open: false, id: "RemoveDocUser" }) }}
            confirmClass={ButtonStyle.danger}
            rejectClass={ButtonStyle.rejectBtnDialog}
            loading={loading}
        />
    </>
}