import { FormDatasResponseAction, FormItemDatasAction, MyFormDatasAction } from "../../../context/FormContext";
import { InterViewBookingContextAction, InterViewBookingContextData } from "../../../context/InterViewBookingContext";
import { Link, useNavigate } from "react-router-dom";
import { RemoveProgramApi, UpdateBookingTeamApi } from "../../../api/Api";
import { useContext, useEffect, useState } from "react";

import ButtonStyle from '../../../style/element/button/Button.module.css'
import ConfirmRejectDialog from "../../Element/AgreeDialog";
import { CreateParamSearchTable } from "../../../function/Common";
import { DialogAction } from "../../../context/Dialog";
import DriveFileRenameOutlineIcon from '@mui/icons-material/DriveFileRenameOutline';
import DropDownBtn from "../../Element/DropDownBtn";
import GridServerSide from "../../Element/GridServerSide";
import GridStyle from '../../../style/element/grid/Grid.module.css'
import LayoutStyle from '../../../style/layout/Layout.module.css'
import SettingsIcon from '@mui/icons-material/Settings';
import { UserInfoData } from "../../../context/UserInfo";
import { UserOnlinePermissionContextData } from "../../../context/UserPermissionContext";

export default function Grids() {
    const navigate = useNavigate()
    const [permission, setPermission] = useState({ list: [], menu: [], module: [] });
    const myPermissionInfo = useContext(UserOnlinePermissionContextData)
    const GetMyFormData = useContext(MyFormDatasAction)
    const GetFormDataResponse = useContext(FormDatasResponseAction)
    const GetFormItemData = useContext(FormItemDatasAction)
    const userInfoData = useContext(UserInfoData);


    const [rows, setRows] = useState([]);
    const interViewInfo = useContext(InterViewBookingContextData)
    const GetDataInterView = useContext(InterViewBookingContextAction)
    const [id, setId] = useState(0);
    const [loading, setLoading] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [start, setStart] = useState(0);
    const [number, setNumber] = useState(25);
    const [isAdmin, setIsAdmin] = useState(false);

    const columns = [
        {
            field: 'Action', type: "date", filterable: false, sortable: false, renderCell: (params: any) => {
                let items = [];

                let valueItem = params.value.split(",");
                let id = valueItem[0];
                let editable = valueItem[1];
                let teamId = valueItem[2];
                let confirm = valueItem[3];
                let form_id = valueItem[4];
                let user_online_edit = valueItem[5];

                if(user_online_edit!=='false' && user_online_edit!==false){
                    editable='1';
                }

                if (permission.module.findIndex((data: any) => data.action === 'remove' && data.menu === "interviewBooking") >= 0) {
                    items.push({ click: () => { Remove(id) }, title: "Remove", close: true })
                }
                if (permission.module.findIndex((data: any) => data.action === 'edit' && data.menu === "interviewBooking" && editable === '1') >= 0) {
                    items.push({ click: () => { GoToEditPage(id) }, title: "Edit", close: true })
                }
                if (confirm === '0') {
                    items.push({ click: () => { Confirm(teamId) }, title: "Confirm", close: true })
                    items.push({ click: () => { Reject(teamId) }, title: "Reject", close: true })
                } else {
                    if (form_id !== "null") {
                        items.push({ click: () => { GoToResponsePage(form_id, id) }, title: <><DriveFileRenameOutlineIcon />Response</>, close: true })
                    }
                }

                return (
                    <>
                        <DropDownBtn
                            title={<><strong><SettingsIcon /></strong></>}
                            items={items}
                        />
                    </>
                );
            },
        },
        { field: 'id', type: "string", hideable: false, width: 120, },
        {
            field: 'Title', type: "string", width: 250, renderCell: (params: any) => {
                return (<div className={`${GridStyle.textTd}`}>{params.value}</div>);
            }
        },
        {
            field: 'Team', type: "string", width: 250, renderCell: (params: any) => {
                return (<div className={`${GridStyle.textTd}`}>{params.value}</div>);
            }
        },
        {
            field: 'Date', type: "date", width: 150, renderCell: (params: any) => {
                let dates = params.value.split("\n");
                return (<div><div>{dates[0]}</div><div>{dates[1]}</div></div>);
            }
        },
        {
            field: "Confirm", type: "string", width: 150, renderCell: (params: any) => {
                
                let value=params.value.split(",");
                if(isAdmin){
                    return (<div className={`${GridStyle.textTd} `}>
                    <Link to={"/VewStatusList/"+value[1]}><strong style={{ color:"white" }}>Show List</strong></Link>
                </div>)  
                }
                return (<div className={`${GridStyle.textTd} ${value[0] === "1" ? LayoutStyle.textSuccess : value[0] === '-1' ? LayoutStyle.colorRed : ""}`}>
                    <strong>{value[0] === '1' ? "Confirm" : value[0] === '-1' ? "Reject" : "pending"}</strong>
                </div>)
            }
        },
        {
            field: 'Studio', type: "string", filterable: false, width: 200, renderCell: (params: any) => {
                return (<div className={`${GridStyle.textTd}`}>{params.value}</div>);
            }
        },
        {
            field: 'Creator', type: "string", filterable: false, width: 200, renderCell: (params: any) => {
                return (<div className={`${GridStyle.textTd}`}>{params.value}</div>);
            }
        },

    ];
    const setDialogData = useContext(DialogAction);
    const [rowCountState, setRowCountState] = useState(0);
    const Remove = (id: any) => { setId(id); setDialogData({ open: true, id: "InterviewBooking" }) }
    const RemoveAvailability = () => {
        setLoading(true);
        RemoveProgramApi({ idSearch: id }).then(() => {
            setLoading(false);
            setDialogData({ open: false, id: "InterviewBooking" });
            GetData();
        })
    }

    const GoToEditPage = (id: string | number) => {
        GetDataInterView({ idSearch: id });
        navigate(`/EditBooking/${id}`);
    }
    const GetData = (starts: number = start, numbers: number = number, param: any = {}) => {
        let params = {
            start: starts,
            number: numbers,
        };
        params = { ...param, ...params };
        GetDataInterView(params);
    }

    const ChangePageSize = (param: any) => {
        setNumber(param.pageSize);
        setStart(0);
        GetData(0, param.pageSize);
    }

    const ChangePageNumber = (param: any) => {
        setStart(number * param.page);
        GetData(number * param.page);
    }

    const GoToResponsePage = (form_id: string | number, program_id: string | number) => {

        GetMyFormData({ idSearch: form_id });
        GetFormDataResponse({ idSearch: form_id });
        GetFormItemData({ idSearch: form_id })

        navigate(`/ResponseToFormProgram/${form_id}/${program_id}`);
    }

    const Confirm = (id: any) => { setId(id); setDialogData({ open: true, id: "InterviewBookingConfirm" }) }
    const Reject = (id: any) => { setId(id); setDialogData({ open: true, id: "InterviewBookingReject" }) }

    const UpdateTeamBookConfirm = () => {
        setLoading(true);
        UpdateBookingTeamApi({ idSearch: id, confirm: "1" }).then(() => {
            setLoading(false);
            setDialogData({ open: false, id: "InterviewBookingConfirm" });
            GetData();
        })
    }
    const UpdateTeamBookReject = () => {
        setLoading(true);
        UpdateBookingTeamApi({ idSearch: id, confirm: "-1" }).then(() => {
            setLoading(false);
            setDialogData({ open: false, id: "InterviewBookingReject" });
            GetData();
        })
    }

    useEffect(() => {
        if (userInfoData.length > 0) {
            setIsAdmin(userInfoData[0].is_admin==='1'?true:false)
        }
    }, [userInfoData])
    
    useEffect(() => {
        if (myPermissionInfo !== undefined) {
            setPermission(myPermissionInfo)
        }
    }, [myPermissionInfo])

    useEffect(() => {
        setIsLoading(true)
        if (interViewInfo !== undefined) {
            setIsLoading(false)
            setRowCountState(interViewInfo?.total);
            if (interViewInfo.data) {
      
                let user_online=interViewInfo.user_online??0;
                let newRows = interViewInfo.data.map((item: any) => {
                    let programTeam=item.program_teams !== null ?item.program_teams.split(","):[];

                    let editable =  "0";
                    let teamId =  "0";
                    let confirm = "0";
                    let team:any=[];
                    programTeam.forEach((pr:any) => {
                        let teamItem=pr.split("::");
                        team.push(teamItem[2]);
                        if(teamItem[0]===user_online){
                            teamId=teamItem[3];
                            confirm=teamItem[4];
                            editable=teamItem[1];
                        }
                    });
   
    
                    return {
                        id: item.id,
                        Title: item.title,
                        Team:team.toString(),
                        Date: `${item.date_from} \n${item.date_to} `,
                        Confirm: `${confirm},${item.id} `,
                        //Date: `${item.date_from} ${item.time_from_timezone}\n${item.date_to} ${item.time_to_timezone}`,
                        Studio: item.studio_description,
                        Creator:`${item.fname} ${item.lanme??""}`,
                        Action: `${item.id},${editable},${teamId},${confirm},${item.form_id},${user_online===item.user_id}`
                    }
                })
                setRows(newRows);
            }
        }
    }, [interViewInfo])

    const ChangeFilter = (param: any) => {
        let params: any = CreateParamSearchTable(param)
        if (Object.entries(params).length > 0) {
            setIsLoading(true)
            GetData(start, number, params);
        }
    }

    return <>
        <GridServerSide
            rowCounts={rowCountState}
            columns={columns}
            rows={rows}
            isLoading={isLoading}
            ChangePageNumber={ChangePageNumber}
            ChangePageSize={ChangePageSize}
            ChangeFilter={ChangeFilter}
        />
        <ConfirmRejectDialog
            title='Are you sure remove?'
            id="InterviewBooking"
            body="All information related to the program will be deleted! "
            confirmTitle='Remove'
            rejectTitle='Cancel'
            confirmFunction={() => { RemoveAvailability() }}
            rejectFunction={() => { setDialogData({ open: false, id: "InterviewBooking" }) }}
            confirmClass={ButtonStyle.danger}
            rejectClass={ButtonStyle.rejectBtnDialog}
            loading={loading}
        />

        <ConfirmRejectDialog
            title='Are you sure Confirm?'
            id="InterviewBookingConfirm"
            body=" "
            confirmTitle='Confirm'
            rejectTitle='Cancel'
            confirmFunction={() => { UpdateTeamBookConfirm() }}
            rejectFunction={() => { setDialogData({ open: false, id: "InterviewBookingConfirm" }) }}
            confirmClass={ButtonStyle.danger}
            rejectClass={ButtonStyle.rejectBtnDialog}
            loading={loading}
        />

        <ConfirmRejectDialog
            title='Are you sure Reject?'
            id="InterviewBookingReject"
            body=""
            confirmTitle='Reject'
            rejectTitle='Cancel'
            confirmFunction={() => { UpdateTeamBookReject() }}
            rejectFunction={() => { setDialogData({ open: false, id: "InterviewBookingReject" }) }}
            confirmClass={ButtonStyle.danger}
            rejectClass={ButtonStyle.rejectBtnDialog}
            loading={loading}
        />
    </>
}