import { ChangeToStringFy, ConvertToArrayOneIndexValueArray, GetValueFromMultiSelect } from '../../function/Common';
import { GetUserOnlineInfo, UpdateMyProfile } from '../../api/Api';
import { UserInfoAction, UserInfoData } from '../../context/UserInfo';
import { useContext, useEffect, useState } from 'react';

import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import Buttons from '../Element/ButtonLoader';
import EditInfo from './edit/EditInfo';
import EditLink from './edit/EditLink';
import LayoutStyle from '../../style/layout/Layout.module.css';
import { Link } from 'react-router-dom';
import { LinkContextData } from '../../context/LinkContext';
import { LinkItemData } from '../../Interface';
import MoreFieldProfile from './MoreFieldProfile';
import { useNavigate } from "react-router-dom";

export default function EditProfile() {
    const userInfoData = useContext(UserInfoData);
    const setUserInfoData=useContext(UserInfoAction);
    const navigate = useNavigate();
    const [data, setData] = useState({
        fname: "",
        lname: "",
        display_name: "",
        email: "",
        phone: "",
        mobile: "",
        fax: "",
        about: "",
        birth_date:"",
        country:"",
        province:"",
        city:"",
        team_tag: "",
        timezone: "",
        file_url: "",
        gender:"",
        file_name: "",
        banner_file_url:"",
        banner_file_name:"",
    });
    const [loading, setLoading] = useState(false);
    const [links, setLinks] = useState<LinkItemData[]>([]);
    const [info, setInfo] = useState({} as any);
    const [programPart, setProgramPart] = useState<any>("");
    const [businessType, setBusinessType] = useState<any>("");
    const linkInfo=useContext(LinkContextData);
    const SendData = () => {
        //setLoading(true);
        let param: any = {};
        param.fname = info.fname ?? info.fName;
        param.lname = info.lname ?? info.lName;
        param.display_name = info.display_name ?? info.displayName;
        param.email = info.email;
        param.gender = info.gender;
        param.phone = info.phone;
        param.mobile = info.mobile;
        param.fax = info.fax;
        param.birth_date = info.birth_date;
        param.about = info.about;
        param.team_link = JSON.stringify(linkInfo);
        param.timezone = info.timezone;
        param.country = info.country;
        param.province = info.province;
        param.city = info.city;

        param.business_type = ChangeToStringFy(GetValueFromMultiSelect(businessType))
        param.program_part = ChangeToStringFy(GetValueFromMultiSelect(programPart))

        UpdateMyProfile(param).then((response: any) => {
            setLoading(false);

            if (response?.status === "true") {
                GetUserOnlineInfo().then((response)=>{ setUserInfoData(response);});
                navigate("/MyProfile");
            }
        })
    }

    useEffect(() => {
        if (userInfoData.length > 0) {
            setData(userInfoData[0]);
            setInfo(userInfoData[0]);

            if(userInfoData[0].program_parts!==null)
            setProgramPart(ConvertToArrayOneIndexValueArray(userInfoData[0].program_parts));
            
            if(userInfoData[0].business_types!==null)
            setBusinessType(ConvertToArrayOneIndexValueArray(userInfoData[0].business_types));
        }
    }, [userInfoData])


    return <>
        <div className={`${LayoutStyle.container}`}>
            <div className={`${LayoutStyle.innerContainer}`}>
                <div className={`${LayoutStyle.flex}`}>
                    <span><Link className={`${LayoutStyle.spanName}`} to={"/MyProfile"}>My profile</Link></span>
                    <span> <ArrowForwardIosIcon className={`${LayoutStyle.fontSm}`} /></span>
                    <span>Edit profile</span>
                </div>

                <EditInfo changed={(data) => setInfo(data)} data={data} />
                <MoreFieldProfile
            
                    programPart={programPart}
                    setProgramPart={setProgramPart}
                    businessType={businessType}
                    setBusinessType={setBusinessType}
              
                />
                <EditLink changed={(data: LinkItemData[]) => setLinks(data)} datas={data} />
                <div className={` ${LayoutStyle.cover} ${LayoutStyle.mt1} ${LayoutStyle.mb1}`}>
                    <Buttons clicked={() => { SendData() }} loading={loading} title="Save Data" size='large' />
                </div>
            </div>

        </div>

    </>
}