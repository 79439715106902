import { Link, useNavigate } from "react-router-dom";

import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import Buttons from '../Element/ButtonLoader';
import { FetchFileMulti } from "../../api/Api";
import LayoutStyle from '../../style/layout/Layout.module.css';
import NoteItem from "./NoteItem";
import { useState } from "react";

export default function AddNote(){
    const [loading, setLoading] = useState(false);
    const [info, setInfo] = useState({} as any);
    const navigate=useNavigate();

    const SendData=()=>{
       
        setLoading(true);

        // AddUserNoteApi(info).then((response:any)=>{
        //     setLoading(false);
            
        //     if (response?.status === "true") {
        //         //GetPrograms();
        //         navigate("/MyNote");
        //     }
        // })
        FetchFileMulti("UserNotes/add", info).then((response:  any) => {
            setLoading(false);
            if (response.status === 'true') {
                //GetPrograms();
                navigate("/MyNote");
            }
        });
    }


    return<>
         <div className={`${LayoutStyle.container}`}>
            <div className={`${LayoutStyle.innerContainer}`}>
                <div className={`${LayoutStyle.flex}`}>
                    <span><Link className={`${LayoutStyle.spanName}`} to={"/MyNote"}>My Note</Link></span>
                    <span> <ArrowForwardIosIcon className={`${LayoutStyle.fontSm}`} /></span>
                    <span>Add Note  </span>
                </div>
                <NoteItem  changed={(data:any)=>setInfo(data)}/>
                <div className={` ${LayoutStyle.cover} ${LayoutStyle.mt1} ${LayoutStyle.mb1}`}>
                    <Buttons clicked={() => { SendData() }} loading={loading} title="Save Data" size='large' />
                </div>
            </div>
        </div>
        </>;
    
}