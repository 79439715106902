import { useContext, useEffect, useState } from "react"
import { useNavigate, useParams } from "react-router-dom";

import BoredCamps from "../../Element/BoredCamp"
import Buttons from "../../Element/ButtonLoader"
import Items from "./Items";
import LayoutStyle from '../../../style/layout/Layout.module.css'
import { StudioData } from "../../../context/StudioContext";
import { UpdateStudioApi } from "../../../api/Api";

export default function EditStudio(){
    const [loading,setLoading]=useState(false);
    const [data,setData]=useState();
    const [info,setInfo]=useState();
    const { id }=useParams();
    const studioData=useContext(StudioData);
    const navigate=useNavigate();

    const SendData=()=>{
        setLoading(true);
        let params:any=info;
        params.idSearch=id;
        UpdateStudioApi(params).then((response:any)=>{
            setLoading(false);
            if (response?.status === "true") {
                navigate("/Studio");
            }
        })
    }

    useEffect(()=>{
        if(studioData!==undefined){
        
            if(studioData.data.length>0)
            {
                setInfo(studioData.data[0]);
                setData(studioData.data[0]);
            }

        }
    },[studioData])

    return <>
          <div className={`${LayoutStyle.container}`}>
            <div className={`${LayoutStyle.innerContainer}`}>

                <BoredCamps Title='Edit Studio' linkHref='/Studio' linkTitle='Studio' />
                <Items datas={data} Changed={setInfo}/>

                <div className={` ${LayoutStyle.cover} ${LayoutStyle.mt1} ${LayoutStyle.mb1}`}>
                    <Buttons clicked={() => { SendData() }} loading={loading} title="Save Data" size='large' />
                </div>
            </div>

        </div>
    </>
}