import { useContext, useState } from 'react';

import { ApiUrl } from '../../config';
import ButtonStyle from '../../style/button/Button.module.css';
import ConfirmRejectDialog from '../Element/AgreeDialog';
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';
import { DialogAction } from '../../context/Dialog';
import LayoutStyle from '../../style/layout/Layout.module.css';
import ProfileStyle from '../../style/profile/Profile.module.css';
import { red } from '@mui/material/colors';

const GalleryList = (props:any) => {

    const {GetGallery,datas,RemoveGallery}=props
   
    const [removeId, setRemoveId] = useState('')
    const setDialogData=useContext(DialogAction);
    const [loading,setLoading]=useState(false);

    const RemoveImage=()=>{
        setLoading(true);
        RemoveGallery({img_id:removeId}).then(()=>{
            setLoading(false);
            setDialogData({open:false,id:"removeGalleryImage"});
            GetGallery();
        })
    }


    const Item = datas.map((data:any) => {
        return <>
            <div className={`${ProfileStyle.galleryDivImg}`}>
                <img src={`${ApiUrl}${data.file_url}/small/${data.file_name}`} alt="" />
                <button onClick={() => { setRemoveId(data.id); setDialogData({open:true,id:"removeGalleryImage"}) }} className={`${ButtonStyle.btnNone} `}><DeleteOutlineIcon sx={{ color: red[500] }} /></button>

            </div>
        </>
    })
    return <>
        <div className={LayoutStyle.flexWarp}>{Item}</div>
        
        <ConfirmRejectDialog
            title='Are you sure remove image?'
            body=""
            id='removeGalleryImage'
            confirmTitle='Remove'
            rejectTitle='Cancel'
            confirmFunction={()=>{RemoveImage()}}
            rejectFunction={()=>{setDialogData({open:false,id:"removeGalleryImage"})}}
            confirmClass={ButtonStyle.danger}
            rejectClass={ButtonStyle.rejectBtnDialog}
            loading={loading}
        />
    </>
}


export default GalleryList;