import {
    GetFormDataApi,
    GetFormDataItemApi,
    GetFormDependsApi,
    GetFormProgramDatasResponseApi,
    GetFormResponseDataApi,
    GetFormUserDatasResponseApi,
    GetMyFormDataApi
} from "../api/Api";
import { createContext, useState } from "react"

import { PropsChildren } from "../Interface";

export const FormDatas = createContext<any>([]);
export const FormDatasAction = createContext<(param: any) => any>(() => { });

export const FormItemDatas = createContext<any>([]);
export const FormItemDatasAction = createContext<(param: any) => any>(() => { });

export const FormDependsDatas = createContext<any>([]);
export const FormDependsDatasAction = createContext<(param: any) => any>(() => { });

export const MyFormDatas = createContext<any>([]);
export const MyFormDatasAction = createContext<(param: any) => any>(() => { });

export const FormDatasResponse = createContext<any>([]);
export const FormDatasResponseAction = createContext<(param: any) => any>(() => { });


export const FormProgramDatasResponse = createContext<any>([]);
export const FormProgramDatasResponseAction = createContext<(param: any) => any>(() => { });

export const FormUserDatasResponse = createContext<any>([]);
export const FormUserDatasResponseAction = createContext<(param: any) => any>(() => { });

export const FormEmptyDatasAction = createContext<(param: any) => any>(() => { });


export default function FormProvider(props: PropsChildren) {
    const { children } = props

    const [datas, setDatas] = useState();
    const [myDatas, setMyDatas] = useState();
    const [datasItem, setDatasItem] = useState();
    const [datasDepends, setDatasDepends] = useState();
    const [datasResponse, setDatasResponse] = useState();
    const [datasResponseProgram, setDatasResponseProgram] = useState();
    const [datasResponseUser, setDatasResponseUser] = useState();

    const GetFormData = (param = { start: 0, number: 25 }) => {
        GetFormDataApi(param).then((response: any) => {
            setDatas(response);
        })
    }
    const GetFormItemData = (param = { start: 0, number: 25 }) => {
        GetFormDataItemApi(param).then((response: any) => {
            setDatasItem(response);
        })
    }

    const GetFormDependsData = (param = { start: 0, number: 25 }) => {
        GetFormDependsApi(param).then((response: any) => {
            setDatasDepends(response);
        })
    }

    const GetMyFormData = (param = { start: 0, number: 25 }) => {
        GetMyFormDataApi(param).then((response: any) => {
            setMyDatas(response);
        })
    }

    const GetFormDataResponse = (param = { start: 0, number: 25 }) => {
        GetFormResponseDataApi(param).then((response: any) => {
            setDatasResponse(response);
        })
    }

    const GetFormDataProgramResponse = (param = { start: 0, number: 25 }) => {
        GetFormProgramDatasResponseApi(param).then((response: any) => {
            setDatasResponseProgram(response);
        })
    }

    const GetFormDataUserResponse = (param = { start: 0, number: 25 }) => {
        GetFormUserDatasResponseApi(param).then((response: any) => {
            setDatasResponseUser(response);
        })
    }

    const EmptyValues=(data:any[])=>{
        if(data.includes("datas")){
            setDatas(undefined);
        }
        if(data.includes("myDatas")){
            setMyDatas(undefined);
        }
        if(data.includes("datasItem")){
            setDatasItem(undefined);
        }
        if(data.includes("datasDepends")){
            setDatasDepends(undefined);
        }
        if(data.includes("datasResponse")){
            setDatasResponse(undefined);
        }
        if(data.includes("datasResponseProgram")){
            setDatasResponseProgram(undefined);
        }
        if(data.includes("datasResponseUser")){
            setDatasResponseUser(undefined);
        }
    }

    return <>
        <FormDatas.Provider value={datas}>
            <FormItemDatas.Provider value={datasItem}>
                <FormDatasAction.Provider value={GetFormData}>
                    <FormItemDatasAction.Provider value={GetFormItemData}>
                        <FormDependsDatas.Provider value={datasDepends}>
                            <FormDependsDatasAction.Provider value={GetFormDependsData}>
                                <MyFormDatas.Provider value={myDatas}>
                                    <MyFormDatasAction.Provider value={GetMyFormData}>
                                        <FormDatasResponse.Provider value={datasResponse}>
                                            <FormDatasResponseAction.Provider value={GetFormDataResponse}>
                                                <FormProgramDatasResponse.Provider value={datasResponseProgram}>
                                                    <FormProgramDatasResponseAction.Provider value={GetFormDataProgramResponse}>
                                                        <FormUserDatasResponse.Provider value={datasResponseUser}>
                                                            <FormUserDatasResponseAction.Provider value={GetFormDataUserResponse}>
                                                                <FormEmptyDatasAction.Provider value={EmptyValues}>
                                                                {children}
                                                                </FormEmptyDatasAction.Provider>
                                                            </FormUserDatasResponseAction.Provider>
                                                        </FormUserDatasResponse.Provider>
                                                    </FormProgramDatasResponseAction.Provider>
                                                </FormProgramDatasResponse.Provider>
                                            </FormDatasResponseAction.Provider>
                                        </FormDatasResponse.Provider>
                                    </MyFormDatasAction.Provider>
                                </MyFormDatas.Provider>
                            </FormDependsDatasAction.Provider>
                        </FormDependsDatas.Provider>
                    </FormItemDatasAction.Provider>
                </FormDatasAction.Provider>
            </FormItemDatas.Provider>
        </FormDatas.Provider>

    </>
}