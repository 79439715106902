import { GetWhiteApi, GetWhiteCommentApi } from "../api/Api";
import { createContext, useState } from "react";

import { PropsChildren } from "../Interface";

export const WhitePropsData = createContext<any>([]);
export const WhiteCommentPropsData = createContext<any>([]);
export const WhiteAction = createContext<(data: any) => any | undefined>((data: any) => { });
export const WhiteActionGetData = createContext<(param: any) => any | undefined>(() => { });
export const WhiteCommentActionGetData = createContext<(param: any) => any | undefined>(() => { });
export const newWhiteCommentDatas = createContext<any>([]);
export const newWhiteCommentDataAction = createContext<(param: any) => any | undefined>(() => { });

export default function WhiteProvider(props: PropsChildren) {

   const { children } = props
   const [whiteData, setWhiteData] = useState([]);
   const [whiteCommentData, setWhiteCommentData] = useState(undefined);
   const [newWhiteCommentData, setNewWhiteCommentData] = useState<any>("");

   const GetWhiteData = (param = { start: 0, number: 25 }) => {
      GetWhiteApi(param).then((response: any) => {
         setWhiteData(response);
      })
   }


   const GetWhiteCommentData = (param = { start: 0, number: 25 }) => {
      GetWhiteCommentApi(param).then((response: any) => {
         setWhiteCommentData(response);
      })
   }


   return (
      <WhitePropsData.Provider value={whiteData}>
         <WhiteCommentPropsData.Provider value={whiteCommentData}>
            <WhiteAction.Provider value={setWhiteData}>
               <WhiteActionGetData.Provider value={GetWhiteData}>
                  <WhiteCommentActionGetData.Provider value={GetWhiteCommentData}>
                     <newWhiteCommentDatas.Provider value={newWhiteCommentData}>
                        <newWhiteCommentDataAction.Provider value={setNewWhiteCommentData}>
                           {children}
                        </newWhiteCommentDataAction.Provider>
                     </newWhiteCommentDatas.Provider>
                  </WhiteCommentActionGetData.Provider>
               </WhiteActionGetData.Provider>
            </WhiteAction.Provider>
         </WhiteCommentPropsData.Provider>
      </WhitePropsData.Provider>
   )

}

