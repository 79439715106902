import { useEffect, useState } from 'react';

import { ChooseTeamProps } from '../../Interface';
import LayoutStyle from '../../style/layout/Layout.module.css';
import ProgramSearchMulti from '../Element/ProgramSearchMulti';

export default function ChooseProgramCombo(props: ChooseTeamProps) {

    const { changed, datas } = props
    const [data, setData] = useState<any>("");
    const [programs,setPrograms]=useState([]);

    useEffect(() => {
        if (datas !== null&&datas !== undefined&&datas!=='') {
            console.log(datas)
            setData(datas!);
        }
    }, [datas])


    return <>

        <div className={`${LayoutStyle.backBlackLight} ${LayoutStyle.cover} `}>
        <div className={`${LayoutStyle.flexGrow1}`}>
                <p>Choose Program:</p>
                <ProgramSearchMulti defaultValue={data} isMulti={true} change={(data: any) => { setPrograms(data);changed(data) }} />
            </div>
      
        </div>
    </>
}