import { useContext, useEffect, useState } from "react";

import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import Gallery from "./Gallery";
import Image from "./Image";
import LayoutStyle from '../../style/layout/Layout.module.css';
import { Link } from "react-router-dom";
import { UsersData } from "../../context/Users";

export default function EditImageGalleryUser(){
    const usersData=useContext(UsersData);
    const [data, setData] = useState([]);
    const [title,setTitle]=useState("");

    useEffect(() => {
        if(usersData.data !==undefined){
            if (usersData.data.length > 0) {
                let datas=usersData.data[0];
                setData(datas);
                setTitle(datas.display_name);
            }
        }
        
    }, [usersData])

    
    return <>
        <div className={`${LayoutStyle.container}`}>
            <div className={`${LayoutStyle.innerContainer}`}>
                <div className={`${LayoutStyle.flex}`}>
                    <span><Link className={`${LayoutStyle.spanName}`} to={"/UserManager"}>User Manager</Link></span>
                    <span> <ArrowForwardIosIcon className={`${LayoutStyle.fontSm}`} /></span>
                    <span>Edit User Image && Gallery ({title})</span>
                </div>
                <Image data={data}/>
                <Gallery data={data}/>

     
            </div>
        </div>
        </>
}