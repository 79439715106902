import { AvailabilityContextAction, AvailabilityContextData } from "../../../context/AvailabilityContext";
import { useContext, useEffect, useState } from "react";

import ButtonStyle from '../../../style/element/button/Button.module.css'
import ConfirmRejectDialog from "../../Element/AgreeDialog";
import { DialogAction } from "../../../context/Dialog";
import DropDownBtn from "../../Element/DropDownBtn";
import GridServerSide from "../../Element/GridServerSide";
import GridStyle from '../../../style/element/grid/Grid.module.css'
import { RemoveAvailabilityApi } from "../../../api/Api";
import SettingsIcon from '@mui/icons-material/Settings';

export default function Grids() {
    const setDialogData = useContext(DialogAction);
    const availabilityInfo = useContext(AvailabilityContextData)
    const GetDataAvailability = useContext(AvailabilityContextAction);

    const columns = [
        {
            field: 'Action', type: "date", filterable: false, sortable: false, renderCell: (params: any) => {

                return (
                    <>
                        <DropDownBtn
                            title={<><strong><SettingsIcon /></strong></>}
                            items={[{ click: () => { Remove(params.value) }, title: "Remove", close: true }]}
                        />
                    </>
                );
            },
        },
        { field: 'id', type: "string", hideable: false, width: 120, },
        {
            field: 'Start Date', type: "date", width: 150, renderCell: (params: any) => {
                return (<div className={`${GridStyle.textTd}`}>{params.value}</div>);
            }
        },
        {
            field: 'Start time', type: "string", filterable: false, width: 250
        },
        {
            field: 'End Date', type: "date", width: 150, renderCell: (params: any) => {
                return (<div className={`${GridStyle.textTd}`}>{params.value}</div>);
            }
        },
        {
            field: 'End time', type: "string", filterable: false, width: 250
        },
    
    ];
    const [rows, setRows] = useState([]);
    const [rowCountState, setRowCountState] = useState(0);
    const [id, setId] = useState(0);
    const [loading, setLoading] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [start, setStart] = useState(0);
    const [number, setNumber] = useState(25);


    const Remove = (id: any) => { setId(id); setDialogData({ open: true, id: "removeAvailability" }) }
    const RemoveAvailability = () => {
        setLoading(true);
        RemoveAvailabilityApi({ idSearch: id }).then(() => {
            setLoading(false);
            setDialogData({ open: false, id: "removeAvailability" });
            GetData();
        })
    }
    const GetData = (starts: number=start,numbers: number=number) => { GetDataAvailability({ start:starts, number:numbers }); }

    const ChangePageSize = (param: any) => {
        setNumber(param.pageSize);
        setStart(0);
        GetData(0,param.pageSize);
    }

    const ChangePageNumber = (param: any) => {
        setStart(number * param.page);
        GetData(number * param.page);
    }

    ///
    useEffect(() => {
        setIsLoading(true)
        if (availabilityInfo !== undefined) {
            setIsLoading(false)
            setRowCountState(availabilityInfo?.total);
            if (availabilityInfo.data) {
                let newRows = availabilityInfo.data.map((item: any) => {
                    return {
                        id: item.id,
                        'Start Date': item.start_date,
                        'Start time': item.start_time,
                        'End Date': item.end_date,
                        'End time': item.end_time,
                        

                        Action: item.id
                    }
                })

                setRows(newRows);
            }
        }
    }, [availabilityInfo])

    


    return <>
        <GridServerSide
            rowCounts={rowCountState}
            columns={columns}
            rows={rows}
            isLoading={isLoading}
            ChangePageNumber={ChangePageNumber}
            ChangePageSize={ChangePageSize}
        />

        <ConfirmRejectDialog
            title='Are you sure remove?'
            id="removeAvailability"
            body=""
            confirmTitle='Remove'
            rejectTitle='Cancel'
            confirmFunction={() => { RemoveAvailability() }}
            rejectFunction={() => { setDialogData({ open: false, id: "removeAvailability" }) }}
            confirmClass={ButtonStyle.danger}
            rejectClass={ButtonStyle.rejectBtnDialog}
            loading={loading}
        />
    </>
}