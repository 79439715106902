import { CheckedFormItem } from "../Interface";
import { toast } from "react-toastify";

export const CheckValue=(data:CheckedFormItem[])=>{
    let returns=true;
    data.forEach(item => {
        if(item.value===""){
            toast.error(`${item.name} is empty`);
            returns=false;
        }
    });

    return returns;
}

export const CheckObjEmpty=(data:any)=>{
   return Object.keys(data).length !== 0 && data.constructor === Object
}

export const CheckNullData=(data:string,string:string)=>{
    return data !=='null'?data:string
}