import { useCallback, useContext, useEffect, useState } from 'react'

import Buttons from '../../Element/ButtonLoader'
import { Card } from './Card'
import type { FC } from 'react'
import { FormItemDatas } from '../../../context/FormContext'
import LayoutStyle from '../../../style/layout/Layout.module.css';
import { UpdateFormItemSortApi } from '../../../api/Api'
import update from 'immutability-helper'

const style = {
  width: 'auto',
}

export interface Item {
  id: number
  text: string
}

export interface ContainerState {
  cards: Item[]
}

const SortItem: FC = (props: any) => {

  
  const [loading,setLoading]=useState(false);
  const datasItem: any = useContext(FormItemDatas);

  const [cards, setCards] = useState([
    {
      id: 1,
      text: 'Write a cool JS library',
    },
  ])

  const SendData=()=>{
    setLoading(true)
    UpdateFormItemSortApi({items:JSON.stringify(cards)}).then((response)=>{
      setLoading(false);
    })  
   
  }

  useEffect(() => {
    if (datasItem !== undefined) {
      if (datasItem.data !== undefined) {
        let newCard = datasItem.data.map((data: any) => {
          return {
            id: data.id,
            text: data.title,
          }
        });
        setCards(newCard);
      }
      console.log(datasItem, '=====datasItem');
    }
  }, [datasItem])

  const moveCard = useCallback((dragIndex: number, hoverIndex: number) => {

    setCards((prevCards: Item[]) =>
      update(prevCards, {
        $splice: [
          [dragIndex, 1],
          [hoverIndex, 0, prevCards[dragIndex] as Item],
        ],
      }),

    )

  }, [])



  const renderCard = useCallback(
    (card: { id: number; text: string }, index: number) => {
      return (
        <Card
          key={card.id}
          index={index}
          id={card.id}
          text={card.text}
          moveCard={moveCard}
        />
      )
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [],
  )

  return (
    <>
      <div style={style}>{cards.map((card, i) => renderCard(card, i))}</div>

      <div className={` ${LayoutStyle.cover} ${LayoutStyle.mt1} ${LayoutStyle.mb1}`}>
        <Buttons clicked={() => { SendData() }} loading={loading} title="Save Data" size='large' />
      </div>

    </>
  )

}

export default SortItem