import { ReactElement, ReactHTMLElement, useContext, useEffect, useState } from "react";
import { UserAllDocsActionGetData, UserAllDocsData } from "../../context/UserDoc";

import { ApiUrl } from "../../config";
import GridServerSide from "../Element/GridServerSide";
import GridStyle from '../../style/element/grid/Grid.module.css'
import LayoutStyle from '../../style/layout/Layout.module.css';

export default function MyDocuments() {

    const GetUserAllDocs = useContext(UserAllDocsActionGetData)
    const columns = [
        { field: 'id', type: "string", hideable: false, width: 120, },
        {
            field: 'Title', type: "string", width: 250, renderCell: (params: any) => {
                return (<div className={`${GridStyle.textTd}`}>{params.value}</div>);
            }
        },
        {
            field: 'Type', type: "string", width: 130, renderCell: (params: any) => {
                return (<div className={`${GridStyle.textTd}`}>{params.value}</div>);
            }
        },
        {
            field: 'Description', type: "string", filterable: false, width: 270, renderCell: (params: any) => {
                return (<div className={`${GridStyle.textTd}`}>{params.value}</div>);
            }
        },
        {
            field: 'File', type: "string", filterable: false, width: 270, renderCell: (params: any) => {
                let files=params.value;
                let item:ReactElement[]=[];
                if(files!==null && files!==''){
                    let fileItems=files.split(",");
                    fileItems.forEach((file:any) => {
                        let fileValue=file.split("::");
                        item.push(<p>
                            <a   className={`${LayoutStyle.spanName}`} href={`${ApiUrl}${fileValue[1]}/${fileValue[2]}`} download>{fileValue[2]}</a>
                            </p>)
                    });
                }
                return (<div className={`${GridStyle.textTd}`}>{item}</div>);
            }
        },
    ];
    const [rows, setRows] = useState([]);
    const userAllDocs = useContext(UserAllDocsData)
    const [isLoading, setIsLoading] = useState(true);
    const [start, setStart] = useState(0);
    const [number, setNumber] = useState(25);



    const [rowCountState, setRowCountState] = useState(0);


    const GetData = (starts: number = start, numbers: number = number) => { GetUserAllDocs({ start: starts, number: numbers }); }

    const ChangePageSize = (param: any) => {
        setNumber(param.pageSize);
        setStart(0);
        GetData(0, param.pageSize);
    }

    const ChangePageNumber = (param: any) => {
        setStart(number * param.page);
        GetData(number * param.page);
    }

    useEffect(() => {
       
        GetUserAllDocs({})
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    useEffect(() => {
     
        if (userAllDocs !== undefined) {
        
            setRowCountState(userAllDocs?.total);
            if (userAllDocs.data) {
                setIsLoading(false)
                let newRows = userAllDocs.data.map((item: any) => {
                    return {
                        id: item.id,
                        Title: `${item.title}`,
                        Type: `${item.type_name}`,
                        Description: item.description,
                        File:item.attachments
                    }
                })

                setRows(newRows);
            }
        }
    }, [userAllDocs]);

    return <>
        <div className={`${LayoutStyle.container}`}>
            <div className={`${LayoutStyle.innerContainer}`}>
                <div className={`${LayoutStyle.backBlackLight} ${LayoutStyle.cover}`}>
                    <GridServerSide
                        rowCounts={rowCountState}
                        columns={columns}
                        rows={rows}
                        isLoading={isLoading}
                        ChangePageNumber={ChangePageNumber}
                        ChangePageSize={ChangePageSize}
                    />
                </div>
            </div>
        </div>
    </>
}
