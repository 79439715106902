import { useEffect, useState } from "react";

import City from "./City";
import { CityItemsProps } from "../../Interface";
import Country from "./Country";
import LayoutStyle from "../../style/layout/Layout.module.css";
import Province from "./Province";

export default function CityItems(props: CityItemsProps) {

    const { Change,data } = props
    const [values, setValues] = useState({ country_id: "", province_id: "", city_id: "" })

    const Changed = (value: string, type: string) => {

        let newValue: any = values;
        newValue[type] = value;
        setValues({ ...newValue });
        Change(newValue);
    }

    useEffect(()=>{
        if(data!==undefined){
            setValues(data)
        }
    },[data])

    return <>
        <div className={LayoutStyle.flexWarp}>
            <div className={LayoutStyle.flexGrow1}>
                <p>Country / Regional:</p>
                <Country countryId={values.country_id} changed={(value) => Changed(value, "country_id")} />
            </div>
            <div className={LayoutStyle.flexGrow1}>
                <p>State / ‌Province:</p>
                <Province provinceId={values.province_id} countryId={values.country_id} changed={(value) => Changed(value, "province_id")} />
            </div>
            <div className={LayoutStyle.flexGrow1}>
                <p>City:</p>
                <City cityId={values.city_id} ProvinceId={values.province_id} changed={(value) => Changed(value, "city_id")} />
            </div>


        </div>
    </>
}