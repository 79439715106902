import { useEffect, useState } from 'react';

import LayoutStyle from '../../style/layout/Layout.module.css';
import SingleSelect from './SingleSelect';
import UserSearch from './UserSearch';
import { UserTeamDataItem } from '../../Interface';

export default function ChooseTeamsOption(props: UserTeamDataItem) {

    const { index, changed, ItemDatas = { userId: "", edit: "0" } } = props

    const [userId, setUserId] = useState("");
    const [edit, setEdit] = useState("0");

    useEffect(() => {

        if(ItemDatas.userId !=='' && ItemDatas.userId !=='0' ){
            setUserId(ItemDatas.userId);
            setEdit(ItemDatas.edit);
        }

    }, [ItemDatas])

    const changedItem = (value: string, types: string) => {

        if (types === "userId") {
            setUserId(value);
            changed({ index, userId: value, edit });
        }
        if (types === "edit") {
            changed({ index, userId, edit: value });
            setEdit(value)
        }

    }


    return <>
        <div className={`${LayoutStyle.flexWarp}`} key={`divLink`}>
            <div className={`${LayoutStyle.flexGrow1}`}>
                <p>Choose User:</p>
                <UserSearch Availability={true} defaultValue={ItemDatas.userId} isMulti={false} change={(event: any) => { changedItem(event, "userId") }} />
            </div>
            <div className={`${LayoutStyle.flexGrow1}`}>
                <p>Can edit?:</p>
                <SingleSelect changed={(event) => { changedItem(event.value, "edit"); }} isSearchable={false}
                    options={[{ value: '', label: 'Choose one..' }, { value: '0', label: 'No',selected:ItemDatas.edit==='0'?true:false }, { value: '1', label: 'Yes',selected:ItemDatas.edit==='1'?true:false }]} />
            </div>

        </div>
    </>
}