import * as React from 'react';

import { Link, useNavigate } from "react-router-dom";
import { LoginApi, RegisterApi } from '../../api/Api';
import { SetCooke, getCookie } from '../../function/Fetching';

import AuthStyle from '../../style/auth/Auth.module.css';
import Buttons from '../Element/ButtonLoader';
import { CheckValue } from '../../function/CheckEmpty';
import Inputs from '../Element/Input';
import LayoutStyle from '../../style/layout/Layout.module.css';
import Password from '../Element/Password';
import { toast } from 'react-toastify';

export default function Register() {
    const [loading, setLoading] = React.useState(false)

    const navigate = useNavigate();

    const [info, setInfo] = React.useState({
        fname: "",
        lname: "",
        email: "",
        password: ""
    });


    const Changed = (value: string, type: string, JsonString = false) => {

        let newValue: any = info;
        if (JsonString)
            newValue[type] = JSON.stringify(value);
        else
            newValue[type] = value;

        setInfo({ ...newValue });
    }


    const handleClick = () => {
        setLoading(true);
        let checkedItem = [
        { name: "fname", value: info.fname },
        { name: "lname", value: info.lname },
        { name: 'email', value: info.email }, 
        { name: "password", value: info.password }
    ]
        if (!CheckValue(checkedItem)) {
            setLoading(false);
            return false;
        }

        RegisterApi(info).then((data: any) => {
            setLoading(false);
            if (data.status !== undefined) {
                if (data.status === 'false') {
                    toast.error(data.err);
                } else {
                    SetCooke(data.data);
                    toast.success('success');
                    navigate("/");
                }
            }


        })

    }

    React.useEffect(() => {
        if (getCookie() !== "") {
            navigate("/MyProfile");
        }
    })


    return <>
        <div className={`${AuthStyle.container}`}>
            <div className={`${AuthStyle.login}`}>
                <div>
                    <h2>Register </h2>
                </div>

                <Inputs
                    id='fname'
                    name='fname'
                    value={info.fname}
                    label="Name"
                    changed={(event) => { Changed(event.target.value, "fname") }}
                />

                <Inputs
                    id='lname'
                    name='lname'
                    value={info.lname}
                    label="Family"
                    changed={(event) => { Changed(event.target.value, "lname") }}
                />

                <Inputs
                    id='email'
                    name='email'
                    value={info.email}
                    label="Email"
                    changed={(event) => { Changed(event.target.value, "email") }}
                />
                <Password
                    id='password'
                    name='password'
                    value={info.password}
                    label="Password"
                    changed={(event) => { Changed(event.target.value, "password") }}
                />
                <div>
                    <Buttons
                        title='Register'
                        size='large'
                        loading={loading}
                        clicked={() => { handleClick() }}
                    />
                </div>

                <div>
                    <Link className={`${LayoutStyle.spanName}`} to={"/"}>Login </Link>

                </div>

            </div>
        </div>
    </>
}


