import { FormDatasResponse, MyFormDatas } from "../../context/FormContext";
import { useContext, useEffect, useState } from "react";

import { GetMyFormItemDataArray } from "../booking/interviewBooking/CreateDataForEdit";
import LayoutStyle from '../../style/layout/Layout.module.css'

export default function ViewFormResponse() {

    const [data, setData] = useState<any>([]);
    const [response, setResponse] = useState<any>([]);

    const myDatas = useContext(MyFormDatas);
    const datasResponse = useContext(FormDatasResponse);


    useEffect(() => {
        if (myDatas !== undefined) {
            
            if (myDatas.data.length > 0) {
                let data = myDatas.data[0];
                let item:any=[];
                
                if(data.items!==null){
                    item= GetMyFormItemDataArray(data.items).data;
                }
                setData(item);
            }
        }

    }, [myDatas])

    useEffect(() => {
        if (datasResponse !== undefined) {
            
            if (datasResponse.data !== undefined) {
                if (datasResponse.data.length >0) {
                    let item: any = {}
                    datasResponse.data.forEach((data: any) => {
                        item[data.form_item_id] = data.response
                    });
                    setResponse(item);
                }
            }
        }

    }, [datasResponse])

    let items = [];
    
    items = data.map((item: any) => {
        
        let values = response[item.id] ?? "";

        return <>
            <div className={`${LayoutStyle.flexGrow1}`}>
                <p>
                    <strong>{item.title} : </strong>
                    <span>{values}</span>
                </p>
            </div>
        </>
    })

    return <>
        <div className={`${LayoutStyle.container}`}>
            <div className={`${LayoutStyle.innerContainer}`}>
                <div className={`${LayoutStyle.backBlackLight} ${LayoutStyle.cover} ${LayoutStyle.mt1}`}>
                    {items}
                </div>
            </div>
        </div>
    </>

}