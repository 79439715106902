import { createContext, useState } from "react";

import { GetSiteApi } from "../api/Api";
import { PropsChildren } from "../Interface";

export const SitesData = createContext<any>(undefined);
export const SitesAction = createContext<(data: any) => any | undefined>((data: any) => { });
export const SitesActionGetData = createContext<(param:any) => any | undefined>(() => { });

export default function SiteContextProvider(props: PropsChildren) {

   const { children } = props
   const [siteData, setSiteData] = useState([]);

   const GetSiteData = (param={start:0,number:25}) => {
      GetSiteApi(param).then((response: any) => {
    
         setSiteData(response.data);
      })
   }

   return (
      <SitesData.Provider value={siteData}>
         <SitesAction.Provider value={setSiteData}>
            <SitesActionGetData.Provider value={GetSiteData}>
               {children}
            </SitesActionGetData.Provider>
         </SitesAction.Provider>
      </SitesData.Provider>
   )

}

