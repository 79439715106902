import { useEffect, useState } from "react"

import { GetProofTypeDataApi } from "../../../api/Api"
import SingleSelect from "../../Element/SingleSelect"

export default function ProofTypes(props: any) {

    const { value, changed } = props
    const [datas, setDatas] = useState([]);
    const [defaultValue, setDefaultValue] = useState<any>([]);

    useEffect(() => {

        GetProofTypeDataApi({}).then((response: any) => {
            if (response.status === "true") {
                if (response.data.length > 0) {
                    setDatas(response.data)
                }
            }
        })
    }, [])

    let options: any[] = []
    if (datas.length > 0) {
        options = datas.map((data: any) => {
            return { value: data.type, label: data.type, selected: defaultValue.includes(data.type)?true:false }
        })
    }

    useEffect(()=>{
        if(value!==undefined){
            if(value!=="")
            setDefaultValue(value)
        }
    },[value])

    return <>
        <SingleSelect
            changed={(data) => { changed(data.value) }}
            options={options}
            defaultValue={defaultValue}
            
        />
    </>
}