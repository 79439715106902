/* eslint-disable react-hooks/exhaustive-deps */

import { GetDayMonthCustom, GetFirstCustomAddMonth, GetFirstCustomDayMonthAgo, GetFirstCustomMonthLate, GetFirstNowDayMonth, GetFirstNowDayMonthLate } from '../../../function/Date';
import { ProgramContextActionAll, ProgramContextData } from '../../../context/ProgramContext';
import { TeamBookingContextAction, TeamBookingContextData } from '../../../context/TeamBookingContext';
import { useContext, useEffect, useRef, useState } from 'react';

import ButtonStyle from '../../../style/element/button/Button.module.css'
import { DialogAction } from '../../../context/Dialog';
import FullCalendar from '@fullcalendar/react' // must go before plugins
import InfoDialog from '../../Element/InfoDialog';
import LayoutStyle from '../../../style/layout/Layout.module.css';
import Loader from '../../Element/Loader';
import { UserInfoData } from '../../../context/UserInfo';
import dayGridPlugin from '@fullcalendar/daygrid' // a plugin!
import interactionPlugin from "@fullcalendar/interaction" // needed for dayClick
import listPlugin from '@fullcalendar/list';
import timeGridPlugin from "@fullcalendar/timegrid";

export default function AllBooking() {

  const [events, setEvents] = useState([]);
  const [timezone, setTimezone] = useState("");
  const [loading, setLoading] = useState(false);
  const FullCalender: any = useRef(null)
  const userInfoData = useContext(UserInfoData);

  const [datasInfo, setDatasInfo] = useState<any>(undefined)

  const setDialogData = useContext(DialogAction);
  const GetPrograms = useContext(ProgramContextActionAll)
  const GetDataTeamBooking = useContext(TeamBookingContextAction);
  const teamBookingInfo = useContext(TeamBookingContextData);
  const programInfo = useContext(ProgramContextData);

  const GetData = (date_from: string, date_to: string) => {
    let param = { date_from, date_to };
    GetDataTeamBooking(param);
    setLoading(true)
  }

  const ShowDetailDataModal = (event: any) => {
    setLoading(true)
    GetPrograms({ idSearch: event.id });
  }

  useEffect(() => {
    setLoading(false);
    if (programInfo !== undefined) {
      if (programInfo.data.length > 0) {
        let datas = programInfo.data[0];
        setDatasInfo(datas)
        setDialogData({ open: true, id: "InterviewBookingDetail" });
      }
    }

  }, [programInfo])

  useEffect(() => {
    let date_from = GetFirstNowDayMonth();
    let date_to = GetFirstNowDayMonthLate();
    GetData(date_from, date_to);
  }, []);


  useEffect(() => {
    setLoading(false);
    if (teamBookingInfo !== undefined) {
      if (teamBookingInfo.data !== undefined) {
        let event = teamBookingInfo.data.map((data: any) => {
          return {
            title: data.title,
            start: data.date_from + "T" + data.time_from,
            end: data.date_to + "T" + data.time_to,
            url: "",
            id: data.id
          }
        })
        setEvents(event);
      }
    }

  }, [teamBookingInfo])

  useEffect(() => {
    document.getElementsByClassName("fc-prev-button")[0].addEventListener('click', function (e) {
      if (FullCalender.current) {
        let date_from = GetFirstCustomDayMonthAgo(FullCalender.current.getApi().getDate())
        let date_to = GetDayMonthCustom(FullCalender.current.getApi().getDate())
        GetData(date_from, date_to);
      }
    });
    document.getElementsByClassName("fc-next-button")[0].addEventListener('click', function () {
      if (FullCalender.current) {
        let date_from = GetFirstCustomMonthLate(FullCalender.current.getApi().getDate());
        let date_to = GetFirstCustomAddMonth(FullCalender.current.getApi().getDate(), 2)
        GetData(date_from, date_to);
      }
    });
  }, [])

  useEffect(() => {
    if (userInfoData.length > 0) {
      setTimezone(userInfoData[0]?.timezone!);
    }
}, [userInfoData])

  let teams:any="";
  teams=datasInfo?.program_teams??""
  teams=teams.split(",")
  return (
    <div className={`${LayoutStyle.container}`}>
      <div className={`${LayoutStyle.innerContainer}`}>
        <div className={`${LayoutStyle.backBlackLight} ${LayoutStyle.cover} ${LayoutStyle.relative}`}>
          {loading && <div className={`${LayoutStyle.CenterLoader} ${LayoutStyle.centerWithAlign}`}>
            <Loader borderTop="2px" width="40px" height='40px' key={"viewBookingLoader"} />
          </div>}
          <FullCalendar
            plugins={[
              dayGridPlugin,
              timeGridPlugin,
              listPlugin,
              interactionPlugin
            ]}
            initialView="dayGridMonth"

            headerToolbar={
              {
                left: "prev,next today",
                center: "title",
                right: "dayGridMonth,timeGridWeek,dayGridDay,listWeek",
              }
            }
            events={events}
            selectable={true}
            navLinks={true} // can click day/week names to navigate views
            selectMirror={true}
            nowIndicator={true}
            ref={FullCalender}
            eventClick={(arg) => { ShowDetailDataModal(arg.event); }}

          />

          <InfoDialog
            title='Details'
            id="InterviewBookingDetail"
            body={<>
              <div className={`${LayoutStyle.mt1}`}><strong className={`${LayoutStyle.LightText} `}>Title : </strong><span>{`${datasInfo?.title} `}</span></div>
              <div className={`${LayoutStyle.mt1}`}><strong className={`${LayoutStyle.LightText} `}>Studio : </strong><span>{`${datasInfo?.studio_title??""} `}</span></div>
              <div className={`${LayoutStyle.mt1}`}><strong className={`${LayoutStyle.LightText} `}>Date : </strong><span>{`${datasInfo?.date_from} - ${datasInfo?.date_to}`}</span></div>
              <div className={`${LayoutStyle.mt1}`}><strong className={`${LayoutStyle.LightText} `}>Description : </strong><span>{`${datasInfo?.description??""} `}</span></div>
              <div className={`${LayoutStyle.mt1}`}><strong className={`${LayoutStyle.LightText} `}>Team : </strong></div>
          
              {
                teams.map((data:any)=>{
                  let item=data.split("::")
                 return <div><span>{`${item[2]??""} `}</span></div>
                })
              }

            </>}
            rejectTitle='Hide'
            rejectFunction={() => { setDialogData({ open: false, id: "InterviewBookingDetail" }); }}
            rejectClass={ButtonStyle.rejectBtnDialog}
            loading={loading}

          />
        </div>
      </div>
    </div>
  )

}

