import AddIcon from '@mui/icons-material/Add';
import ButtonStyle from '../../style/button/Button.module.css';
import GridsDoc from './GridsDoc';
import LayoutStyle from '../../style/layout/Layout.module.css';
import { Link } from 'react-router-dom';

export default function UserDocuments() {

    return <>
        <div className={`${LayoutStyle.container}`}>
            <div className={`${LayoutStyle.innerContainer}`}>
                <div className={`${LayoutStyle.backBlackLight} ${LayoutStyle.cover}`}>
                    <Link className={`${ButtonStyle.btnBlueOuterLink} `} to={"/AddDocument"}>Add Document <AddIcon className={LayoutStyle.fontSm} /></Link>
                    <GridsDoc/>
                </div>
            </div>
        </div>
    </>
}