/* eslint-disable react-hooks/exhaustive-deps */

import { useContext, useEffect } from 'react';

import AddIcon from '@mui/icons-material/Add';
import { AvailabilityContextAction } from '../../../context/AvailabilityContext';
import ButtonStyle from '../../../style/button/Button.module.css';
import Grids from './Grids';
import LayoutStyle from '../../../style/layout/Layout.module.css';
import { Link } from "react-router-dom";

export default function Availability () {

 

    const GetDataAvailability=useContext(AvailabilityContextAction);
    useEffect(()=>{
        GetDataAvailability();
    },[])

    return <>
        <div className={`${LayoutStyle.container}`}>
            <div className={`${LayoutStyle.innerContainer}`}>
                <div className={`${LayoutStyle.backBlackLight} ${LayoutStyle.cover}`}>

                    <Link className={`${ButtonStyle.btnBlueOuterLink}`} to={"/AddAvailability"}>Add Availability <AddIcon className={LayoutStyle.fontSm} /></Link>
                    
                    <Grids/>
                </div>
            </div>
        </div>
    </>
  
}