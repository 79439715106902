import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import { BoredCamp } from '../../Interface';
import LayoutStyle from '../../style/layout/Layout.module.css';
import { Link } from 'react-router-dom';

export default function BoredCamps(props:BoredCamp) {

    const {linkHref,linkTitle,Title}=props
    return <>

                <div className={`${LayoutStyle.flex}`}>
                    <span><Link  className={`${LayoutStyle.spanName}`} to={linkHref}>{linkTitle}</Link></span>
                    <span><ArrowForwardIosIcon className={`${LayoutStyle.fontSm}`}/></span>
                    <span>{Title}</span>
                </div>



    </>
}