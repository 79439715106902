import { createContext, useState } from "react";

import { PropsChildren } from "../Interface";

export const RoleContextData = createContext<any>([]);
export const RoleContextAction = createContext<(param?:any) => any | undefined>(() => { });

export default function RoleContextProvider(props: PropsChildren) {

   const { children } = props
   const [RoleInfo, setRoleInfo] = useState([]);





   return (
      <RoleContextData.Provider value={RoleInfo}>
         <RoleContextAction.Provider value={setRoleInfo}>
            {children}
         </RoleContextAction.Provider>
      </RoleContextData.Provider>
   )

}

