import * as React from 'react';

import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import DarkMode from '../Element/DarkMode';
import { GetUserOnlineInfo } from '../../api/Api';
import IconButton from '@mui/material/IconButton';
import MenuIcon from '@mui/icons-material/Menu';
import SideBar from './SideBar';
import Toolbar from '@mui/material/Toolbar';
import { UserInfoAction } from '../../context/UserInfo';
import { UserOnlinePermissionContextAction } from '../../context/UserPermissionContext';

const drawerWidth = 240;

interface Props {
  /**
   * Injected by the documentation to work in an iframe.
   * You won't need it on your project.
   */
  window?: () => Window;
  children: React.ReactNode
}

export default function Layout(props: Props) {

  const setUserInfoData = React.useContext(UserInfoAction);
  const GetMyPermission = React.useContext(UserOnlinePermissionContextAction)

  const [mobileOpen, setMobileOpen] = React.useState(false);

  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };
  React.useEffect(() => {
    GetUserOnlineInfo().then((data: any) => {
      setUserInfoData(data);
    });
    GetMyPermission()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])





  return (
    <DarkMode>
      <Box sx={{ display: 'flex' }}>
        <AppBar
          position="fixed"
          sx={{
            width: { sm: `calc(100% - ${drawerWidth}px)` },
            ml: { sm: `${drawerWidth}px` },
          }}
        >
          <Toolbar>
            <IconButton
              color="inherit"
              aria-label="open drawer"
              edge="start"
              onClick={handleDrawerToggle}
              sx={{ mr: 2, display: { sm: 'none' } }}
            >
              <MenuIcon />
            </IconButton>

          </Toolbar>
        </AppBar>

        {/* The implementation can be swapped with js to avoid SEO duplication of links. */}
        <SideBar
          drawerWidth={drawerWidth}
          mobileOpen={mobileOpen}
          handleDrawerToggle={() => { handleDrawerToggle() }}

        />


        {props.children}
      </Box>
    </DarkMode>
  );
}
