import LayoutStyle from '../../style/layout/Layout.module.css'
import Proof from '../Element/Proof'
import SingleSelect from "../Element/SingleSelect"
import Sites from '../Element/Site'

export default function MoreField(props: any) {

    const { isAdmin,admin_confirm, setIsAdmin, programPart, setProgramPart, businessType, setBusinessType, sites, setSites,ChangedInfo } = props

    return <>

        <div className={`${LayoutStyle.backBlackLight} ${LayoutStyle.cover}`}>
            <p>Is Admin?</p>
            <SingleSelect
                changed={(data) => { setIsAdmin(data.value) }}
                options={[
                    { value: '0', label: 'No', selected: isAdmin === '0' ? true : false },
                    { value: '1', label: 'Yes', selected: isAdmin === '1' ? true : false },
                ]
                }
            />
            <div className={`${LayoutStyle.flexGrow1}`}>
                <p>Business type:</p>
                <Proof  change={(value) => { setBusinessType(value); }} type="businessType" defaultValue={(businessType)} />
            </div>
            <div className={`${LayoutStyle.flexGrow1}`}>
                <p>program part:</p>
                <Proof change={(value) => { setProgramPart(value); }} type="programPart" defaultValue={(programPart)} />
            </div>
            <div className={`${LayoutStyle.flexGrow1}`}>
                <p>Site:</p>
                <Sites isMulti={true} change={(value) => { setSites(value); }} defaultValue={sites} />
            </div>
            
            <p>Published?</p>
            <SingleSelect
                changed={(data) => {debugger; ChangedInfo(data.value,"admin_confirm") }}
                options={[
                    { value: '0', label: 'No', selected: admin_confirm === '0' ? true : false },
                    { value: '1', label: 'Yes', selected: admin_confirm === '1' ? true : false },
                ]
                }
            />
        </div>
    </>
}