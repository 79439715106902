import { useEffect, useState } from 'react';

import ButtonStyle from '../../../style/button/Button.module.css';
import DatePick from '../../Element/DatePick';
import LayoutStyle from '../../../style/layout/Layout.module.css';
import TimePick from '../../Element/TimePick';
import TimeZone from '../../Element/TimeZone';

export default function MultiDateTime(props: any) {

    const { changed, datas,dataUser } = props
    const [numbers, setNumbers] = useState([1]);
    const [data, setData] = useState<any[]>([]);
    const [minTime, setMinTime] = useState("");
    const NowDate = Date.now();  

    useEffect(() => {
        // if(datas!==undefined){
        //     if(datas.team_tag !==null && datas.team_tag!==""){
        //         let links=datas.team_tag.split(",");
        //         let number=[];
        //         let linkData=[];
        //         for(let i=0;i<(links.length);i++){
        //             number.push(i);
        //             let link=links[i].split("::");
        //             linkData.push({index:i,type:link[3],title:link[1],link:link[2]})
        //         }
        //         setNumbers(number)
        //         setData(linkData)
        //     }

        // }
    }, [datas])

    const changedItem = (dataChang: any, index: any) => {
        
        let AddIndexToDataChang:any = { index: index,start_date:"", start_time: "",end_time:"",end_date:"",timezone:dataUser?.timezone!??"" }
        AddIndexToDataChang[dataChang.name]=dataChang.value;
        let newData:any = data;
        if (!CheckExistDataInState(AddIndexToDataChang)) {
            newData.push(AddIndexToDataChang);
            setData(newData);
        } else {
            
            let index = GetIndex(AddIndexToDataChang);
            newData[index][dataChang.name]=dataChang.value;

        //     AddIndexToDataChang.start_date=newData[index].start_date
        //     AddIndexToDataChang.end_date=newData[index].end_date
        //     if(dataChang.name==="start_time"){
        //         AddIndexToDataChang.end_time=newData[index].end_time??"" 
 
        //     }
        //    if(dataChang.name==="end_time"){
         
        //         AddIndexToDataChang.start_time=newData[index].start_time??""   
        //     }
        //     newData[index] = AddIndexToDataChang;
            setData(newData);
        }
        console.log(AddIndexToDataChang,data)
        changed(data);
    }


    const CheckExistDataInState = (dataChang: any) => {

        if (data.length <= 0) {
            return false;
        }
        let indexes = GetIndex(dataChang);
        if (indexes < 0) {
            return false;
        }
        return true;
    }

    const GetIndex = (dataChang: any) => {
        return data.findIndex((item:any) => item.index === dataChang.index)
    }

    const AddMoreItem = () => {
        let newNumbers = [];
        for (let i = 1; i <= numbers.length + 1; i++)
            newNumbers.push(i);

        setNumbers(newNumbers)
    }
    let Items = numbers.map((item, index) => {
        return (<>
            <div className={`${LayoutStyle.flexWarp}`}>
                <div className={`${LayoutStyle.flexGrow1}`}>
                    <p>Day From:</p>
                    <DatePick DefaultValue={""} label="Choose date and time " minDate={NowDate} Changed={(data) => {changedItem({name:"start_date",value:data}, item)}} />

                </div>
                <div className={`${LayoutStyle.flexGrow1}`}>
                    <p>From:</p>
                    <TimePick DefaultValue={""} label="Choose date and time" minTime={""} Changed={(data,timeValue) => {setMinTime(timeValue);changedItem({name:"start_time",value:data}, item)}} />

                </div>
                <div className={`${LayoutStyle.flexGrow1}`}>
                    <p>Day To:</p>
                    <DatePick DefaultValue={""} label="Choose date and time " minDate={NowDate} Changed={(data) => {changedItem({name:"end_date",value:data}, item)}} />

                </div>
                <div className={`${LayoutStyle.flexGrow1}`}>
                    <p>To:</p>
                    <TimePick DefaultValue={""} label="Choose date and time" minTime={""} Changed={(data) => changedItem({name:"end_time",value:data}, item)} />
                </div>

                <div className={`${LayoutStyle.flexGrow1}`}>
                    <p>Timezone:</p>
                    <TimeZone changed={(data) => changedItem({name:"timezone",value:data}, item)} dataUser={dataUser} />
                </div>
            </div>
        </>)
    })

    return <>
        <div className={`${LayoutStyle.backBlackLight} ${LayoutStyle.cover} ${LayoutStyle.mt1}`}>
            {Items}
            <div className={`${LayoutStyle.mt1}`}>
                <button onClick={() => { AddMoreItem() }} className={`${ButtonStyle.btnBlueOuter}`} >Add More </button>
            </div>
        </div>
    </>
}