import { useContext, useEffect, useState } from 'react';

import { AddAvailabilityApi } from '../../../api/Api';
import { AvailabilityContextAction } from '../../../context/AvailabilityContext';
import BoredCamps from '../../Element/BoredCamp';
import Buttons from '../../Element/ButtonLoader';
import LayoutStyle from '../../../style/layout/Layout.module.css';
import MultiDateTime from './MultiDateTime';
import { UserInfoData } from '../../../context/UserInfo';
import { useNavigate } from 'react-router-dom';

export default function AddAvailability() {
    const [info, setInfo] = useState({} as any);
    const [loading, setLoading] = useState(false);
    const [dataUser, setDataUser] = useState("");

    const navigate = useNavigate();
    const GetDataAvailability=useContext( AvailabilityContextAction);
    const userInfoData = useContext(UserInfoData);

    const Changed=(date:any[])=>{
        setInfo(JSON.stringify(date));
    }
    const Submit=()=>{
        setLoading(true)
        AddAvailabilityApi({items:info}).then((response:any)=>{
            setLoading(false);
            if (response?.status === "true") {
                GetDataAvailability();
                navigate("/Availability");
            }
        })
    }

    useEffect(() => {
        if (userInfoData.length > 0) {
            setDataUser(userInfoData[0]!);
        }
    }, [userInfoData])
    
    return <>
        <div className={`${LayoutStyle.container}`}>

            <div className={`${LayoutStyle.innerContainer}`}>
                <div className={`${LayoutStyle.backBlackLight} ${LayoutStyle.cover}`}>
                    <BoredCamps Title='Add Availability' linkTitle='Availability' linkHref='/Availability' />
                   
                   <MultiDateTime changed={(data:any)=>{Changed(data)}} dataUser={dataUser}/>
                    <div className={`${LayoutStyle.center}`}>
                        <Buttons title='Save Data' clicked={()=>{Submit();}} loading={false} size='large'/>
                    </div>
                </div>
            </div>
        </div>
    </>
}