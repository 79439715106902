import { useCallback, useContext, useEffect, useMemo, useState } from "react";

import { AddBookingItemInterface } from "../../../Interface";
import Checkboxes from "../../Element/Checkboxes";
import ChooseGuest from "./ChooseGuest";
import ChooseTeam from "./ChooseTeam";
import DatePick from "../../Element/DatePick";
import { GetValueFromMultiSelect } from "../../../function/Common";
import Inputs from "../../Element/Input";
import LayoutStyle from '../../../style/layout/Layout.module.css';
import MultiDateTime from "./MultiDateTime";
import Proof from "../../Element/Proof";
import Sites from "../../Element/Site";
import TextArea from "../../Element/TextArea";
import TimePick from "../../Element/TimePick";
import TimeZone from "../../Element/TimeZone";
import { UserSearchParamAction } from "../../../context/UserSearchParam";

export default function AddBookingItem(props: AddBookingItemInterface) {
    const { changed, data } = props;

    const NowDate = Date.now();
    const [date_from, setDate_from] = useState("");
    const [date_fromMin, setDate_fromMin] = useState<any>(NowDate);
    const [time_fromDef, setTime_fromDef] = useState<any>("");
    const [date_to, setDate_to] = useState("");
    const [time_toDef, setTime_toDef] = useState<any>("");
    const [book_team_data, setBook_team_data] = useState<any>("");
    const [minDateTo, setMinDateTo] = useState<any>(NowDate);
    const [minTimeTo, setMinTimeTo] = useState<any>("");
    const [book_guest_data, setGuest_team_data] = useState<any>("");
    const [notification_data, setNotification_data] = useState<any>("");
    const [time_zone, setTime_zone] = useState("");

    const [info, setInfo] = useState({
        site: "",
        title: "",
        date_from: "",
        time_from: "",
        date_to: "",
        time_to: "",
        time_zone: "",
        business_type: "",
        program_part: "",
        book_team: "",
        book_guest: "",
        notification: "",
        description: "",
        my_studio: "0",
        category_id: "",
        form_type: "",
        direct_booking: "0",

    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
    const paramForGetUser = ["site", "date_from", "time_from", "date_to", "time_to", "business_type", "program_part", "time_zone", "direct_booking"];
    const setParams = useContext(UserSearchParamAction);

    const ChangeData = useCallback((value: any, type: string, JsonString: boolean = false) => {

        let newValue: any = info;
        if (JsonString)
            newValue[type] = JSON.stringify(value);
        else
            newValue[type] = value;

        setInfo({ ...newValue});
        changed(newValue)

        let typeDirect = type === "direct_booking" ? value : info.direct_booking
        if (paramForGetUser.includes(type) && typeDirect === '0') {
            setParams(
                {
                    is_admin: '0',
                    start_date: type === "date_from" ? value : info.date_from,
                    end_date: type === "date_to" ? value : info.date_to,
                    start_time: type === "time_from" ? value : info.time_from,
                    end_time: type === "time_to" ? value : info.time_to,
                    business_type: type === "business_type" ? value !== false ? GetValueFromMultiSelect(value).toString() : "" : info.business_type !== '' ? JSON.parse(info.business_type).toString() : "",
                    program_part: type === "program_part" ? value !== false ? GetValueFromMultiSelect(value).toString() : "" : info.program_part !== '' ? JSON.parse(info.program_part).toString() : "",
                    site_id: type === "site" ? value : info.site,
                    time_zone: type === "timezone" ? value : info.time_zone,
                    direct_booking: typeDirect,
                    checkTeam: "1",
                }
            );
        }
        if (typeDirect === '1') {
            setParams(
                {
                    direct_booking: typeDirect,
                    checkTeam: "1",

                })
        }

    },[changed, info, paramForGetUser, setParams])




    useEffect(() => {
        setParams({ idSearch: "0", direct_booking: "0" })
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    useEffect(() => {
        if (data !== undefined && Object.keys(data).length !== 0 && data.constructor === Object) {
            let newValue = info;

            newValue.title = (data.title)
            info.category_id = data.category_id;
            info.form_type = data.form_type;
            let sitesArray: any = [];
            if (data.program_site !== null) {

                let sites = data?.program_site.split(",") ?? [];
                sites.forEach((data: any) => {
                    let datas = data.split("::");
                    sitesArray.push(datas[1]);
                });
            }
            newValue.site = sitesArray.toString();
            newValue.my_studio = data.my_studio;
            newValue.direct_booking = data.direct_booking;
            newValue.date_from = data.date_from;
            newValue.time_from = data.time_from;
            newValue.date_to = data.date_to;
            newValue.time_to = data.time_to;
            newValue.time_zone = data.time_zone;
            setTime_zone(data.time_zone)
            setDate_from(data.date_from)
            setDate_to(data.date_to)


            setDate_fromMin(new Date(data.date_from));
            setMinDateTo(new Date(data.date_from));

            setTime_fromDef(new Date(`${data.date_from} ${data.time_from}`));
            setMinTimeTo(new Date(`${data.date_from} ${data.time_from}`));

            setTime_toDef(new Date(`${data.date_to} ${data.time_to}`));
            setMinTimeTo(new Date(`${data.date_to} ${data.time_to}`));


            let businessArray: any = [];
            if (data.program_business_type !== null) {
                let datas = data?.program_business_type.split(",") ?? [];
                datas.forEach((data: any) => {
                    let datas = data.split("::");
                    businessArray.push(datas[1]);
                });
                newValue.business_type = businessArray.toString();
            }

            let programPartArray: any = [];
            if (data.program_program_part !== null) {
                let datas = data?.program_program_part.split(",") ?? [];
                datas.forEach((data: any) => {
                    let datas = data.split("::");

                    programPartArray.push(datas[1]);
                });

                newValue.program_part = (programPartArray.toString());
            }

            info.description = (data.description);

            newValue.book_team = (data.program_teams);

            setBook_team_data(data.program_teams);

            newValue.book_guest = (data.program_guest);

            setGuest_team_data(data.program_guest);

            newValue.notification = (data.program_notification);

            setNotification_data(data.program_notification);


            setInfo({ ...newValue })
            changed(newValue)
            if (data.direct_booking === '1') {
                ChangeData("1", "direct_booking")
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [data])

    let GuestElement = useMemo(() => {
        return <><ChooseGuest datas={book_guest_data} changed={(value) => { ChangeData(value, "book_guest", true); }} /></>
       
    }, [ChangeData, book_guest_data])

    let TeamElement = useMemo(() => {
        return <> <ChooseTeam datas={book_team_data} changed={(value) => { ChangeData(value, 'book_team', true); }} /></>
    
    }, [ChangeData, book_team_data])


    let TimezoneElement = useMemo(() => {
        return <> <TimeZone dataUser={{ timezone: time_zone }} changed={(value) => { ChangeData(value,"time_zone"); }} /></>
    
    }, [ChangeData, time_zone])





    return <>
        <div className={`${LayoutStyle.flexWarp} ${LayoutStyle.mt2}`}>
            <div className={`${LayoutStyle.flexGrow1}`}>
                <Sites isMulti={false} change={(value) => { ChangeData(value.value, "site"); }} defaultValue={info.site} />
            </div>
            <div className={`${LayoutStyle.flexGrow1}`}>
            <Inputs
            id='title'
            name='title'
            value={info.title}
            label="Title"
            changed={(event) => { ChangeData(event.target.value, "title"); }}
        />
            </div>
        </div>
        <div className={`${LayoutStyle.flexGrow1} ${LayoutStyle.mt2}`}>
            <Checkboxes
                check={info.direct_booking === '1' ? true : false}
                Change={(data: any) => {
                    ChangeData(data.checked ? "1" : "0", "direct_booking")
                }}
                value="1"
                label="Direct Booking" />
        </div>
        <div className={`${LayoutStyle.flexWarp} ${LayoutStyle.mt2}`}>
            <div className={`${LayoutStyle.flexGrow1}`}>
                <DatePick label="Date From" Changed={(value, newValue) => { setMinDateTo(newValue); ChangeData(value, "date_from") }} DefaultValue={date_from} minDate={date_fromMin} />
            </div>
            <div className={`${LayoutStyle.flexGrow1}`}>
                <TimePick id="time_from" label="Time From" Changed={(value, newValue) => { ChangeData(value, "time_from") }} DefaultValue={time_fromDef} />
            </div>
        </div>

        <div className={`${LayoutStyle.flexWarp} ${LayoutStyle.mt2}`}>
            <div className={`${LayoutStyle.flexGrow1}`}>
                <DatePick label="Date To" Changed={(value) => { ChangeData(value, "date_to") }} DefaultValue={date_to} minDate={minDateTo} />
            </div>

            <div className={`${LayoutStyle.flexGrow1}`}>
                <TimePick id="time_to" label="Time To" Changed={(value) => { ChangeData(value, "time_to") }} DefaultValue={time_toDef} />
            </div>
        </div>

        <div className={`${LayoutStyle.mt2}`}>
            <p>TimeZone:</p>
            {TimezoneElement}
        </div>
        <div className={`${LayoutStyle.flexWarp} ${LayoutStyle.mt2}`}>

            <div className={`${LayoutStyle.flexGrow1}`}>
                <p>Business type:</p>
                <Proof   change={(value) => { ChangeData(GetValueFromMultiSelect(value), 'business_type', true); }} type="businessType" defaultValue={info.business_type} />
            </div>
            <div className={`${LayoutStyle.flexGrow1}`}>
                <p>program part:</p>
                <Proof  change={(value) => { ChangeData(GetValueFromMultiSelect(value), 'program_part', true); }} type="programPart" defaultValue={info.program_part} />
            </div>
        </div>
        <div className={``}>
            {TeamElement}
        </div>

        <div className={``}>
            {GuestElement}
        </div>
        <div className={``}>
            {/* {TimeDateElement} */}
            <MultiDateTime datas={notification_data} changed={(value: any) => { ChangeData(value, "notification", true); }} />
        </div>
        <div className={`${LayoutStyle.mt2}`}>
            <TextArea value={info.description} changed={(event) => { ChangeData(event.currentTarget.value, "description") }} label="description" id='description' name='description' />
        </div>
        <div className={`${LayoutStyle.mt2}`}>
            <p>Category:</p>
            <Proof  type="program" defaultValue={info.category_id} isMulti={false} change={(event) => { ChangeData(event, "category_id") }} />
        </div>
        <div className={`${LayoutStyle.mt2}`}>
            <p>Form Type:</p>
            <Proof  type="programForm" defaultValue={info.form_type} isMulti={false} change={(event) => { ChangeData(event, "form_type") }} />
        </div>
        <div className={`${LayoutStyle.mt2}`}>
            <Checkboxes
                check={info.my_studio === '1' ? true : false}
                Change={(data: any) => {
                    ChangeData(data.checked ? "1" : "0", "my_studio")
                }}
                value="1"
                label="My Studio" />
        </div>
    </>

}