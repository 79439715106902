import { useContext, useEffect, useState } from 'react';

import GroupIcon from '@mui/icons-material/Group';
import ListAltIcon from '@mui/icons-material/ListAlt';
import SettingsIcon from '@mui/icons-material/Settings';
import { SidebarAdminProps } from '../../Interface';
import { SubMenu } from "./SubMenu";
import { UserOnlinePermissionContextData } from '../../context/UserPermissionContext';
import { blue } from "@mui/material/colors";

// import VideoLibraryIcon from '@mui/icons-material/VideoLibrary';


export default function SidebarAdmin(props: SidebarAdminProps) {
    const [permission, setPermission] = useState({ list: [], menu: [], module: [] });
    const myPermissionInfo = useContext(UserOnlinePermissionContextData)

    const { fontSize } = props;

    useEffect(() => {
        if (myPermissionInfo !== undefined) {
            setPermission(myPermissionInfo)
        }
    }, [myPermissionInfo])

    return <>

        {/* 
        {permission.list.findIndex((data: any) => data.list === "program")>=0 && <SubMenu
            title="Program"
            id="7"
            icon={<VideoLibraryIcon sx={{ color: blue[500], fontSize: fontSize }} />}
            items={[
                {
                    title: "Programs",
                    menu: "programs",
                    icon: <></>,
                    href: "/Programs",
                    selected: false,
                    id: "8"
                },

            ]}
        />} */}

        {permission.list.findIndex((data: any) => data.list === "user") >= 0 && <SubMenu
            title="Users"
            id="5"
            icon={<GroupIcon sx={{ color: blue[500], fontSize: fontSize }} />}
            items={[
                {
                    title: "User Manager",
                    menu: "userManager",
                    icon: <></>,
                    href: "/UserManager",
                    selected: false,
                    id: "6"
                },
                {
                    title: "User Documents",
                    menu: "userDocuments",
                    icon: <></>,
                    href: "/UserDocuments",
                    selected: false,
                    id: "7"
                },
            ]}
        />}

        {permission.list.findIndex((data: any) => data.list === "forms") >= 0 && <SubMenu
            title="Check lists"
            id="9"
            icon={<ListAltIcon sx={{ color: blue[500], fontSize: fontSize }} />}
            items={[
                {
                    title: "Check list",
                    menu: "form",
                    icon: <></>,
                    href: "/Forms",
                    selected: false,
                    id: "11"
                },


            ]}
        />
        }

        {permission.list.findIndex((data: any) => data.list === "setting") >= 0 && <SubMenu
            title="Setting"
            id="9"
            icon={<SettingsIcon sx={{ color: blue[500], fontSize: fontSize }} />}
            items={[
                {
                    title: "Proofs",
                    menu: "proof",
                    icon: <></>,
                    href: "/Proofs",
                    selected: false,
                    id: "10"
                },

                {
                    title: "Site",
                    icon: <></>,
                    href: "/Site",
                    menu: "site",
                    selected: false,
                    id: "11"
                },
                {
                    title: "Studio",
                    icon: <></>,
                    href: "/Studio",
                    menu: "studio",
                    selected: false,
                    id: "12"
                },
            ]}
        />
        }
    </>
}