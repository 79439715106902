import {
  Collapse,
  List,
  ListItemButton,
  ListItemIcon,
  ListItemText,
} from "@mui/material";
import { blue, pink } from "@mui/material/colors";

import ArrowCircleDownIcon from "@mui/icons-material/ArrowCircleDown";
import ArrowCircleUpIcon from "@mui/icons-material/ArrowCircleUp";
import FiberManualRecordIcon from '@mui/icons-material/FiberManualRecord';
import LayoutStyle from "../../style/layout/Layout.module.css";
import { Link } from "react-router-dom";
import React from "react";
import { UserOnlinePermissionContextData } from "../../context/UserPermissionContext";

export const SubMenu = (props: any) => {
  const [open, setOpen] = React.useState(false);
  const [Href, setHref] = React.useState('');
  const [permission, setPermission] = React.useState({ list: [], menu: [], module: [] });
  const myPermissionInfo = React.useContext(UserOnlinePermissionContextData)

  const handleClick = () => {
    setOpen(!open);
  };

  React.useEffect(() => {
    let href = window.location.href;
    props.items.forEach((e: any) => {
      let position = href.search(e.href);
      if (position > 0) {
        setOpen(true);
        setHref(e.href);
      }
    });

  });

  React.useEffect(() => {
    if (myPermissionInfo !== undefined) {
      setPermission(myPermissionInfo)
    }
  }, [myPermissionInfo])

  let Item = <></>;
  Item = props.items.map((data: any) => {
    
    if(data.menu!==undefined){
      if(permission.menu.findIndex((items:any)=>items.menu===data.menu)<0)
      return false;
    }
      return (
        <>
  
          <ListItemButton selected={Href === data.href ? true : false} sx={{ pl: 4 }}>
            <ListItemIcon><small>{data.icon && <FiberManualRecordIcon sx={{ color: "white", fontSize: 12 }} />}</small></ListItemIcon>
            <ListItemText >
              <Link className={`${LayoutStyle.link} ${LayoutStyle.fontSm} ${LayoutStyle.darkWhiteColor}`} to={data.href}>{data.title}</Link>
            </ListItemText>
          </ListItemButton>
  
        </>
      );
    
   
  });
  return (
    <>
      <div className="mb-3" >
        <ListItemButton onClick={handleClick} >
          <ListItemIcon ><small>{props.icon}</small></ListItemIcon>
          <ListItemText ><span className="ms-3 page-normal-font">{props.title}</span></ListItemText>
          {open ? (
            <ArrowCircleUpIcon sx={{ color: pink[500] }} />
          ) : (
            <ArrowCircleDownIcon sx={{ color: blue[500] }} />
          )}
        </ListItemButton>
        <Collapse in={open} timeout="auto" unmountOnExit>
          <List component="div" disablePadding key={props.id}>
            {Item}
          </List>
        </Collapse>
      </div>
    </>
  );
};
