import { ChooseGuestProps, GuestItemDatas } from '../../../Interface';
import { useEffect, useState } from 'react';

import ButtonStyle from '../../../style/button/Button.module.css';
import { GetGuestData } from './CreateDataForEdit';
import GuestItems from '../../Element/GuestItems';
import LayoutStyle from '../../../style/layout/Layout.module.css';

export default function ChooseGuest(props: ChooseGuestProps) {

    const { changed, datas } = props
    const [numbers, setNumbers] = useState([1]);
    const [data, setData] = useState<GuestItemDatas[]>([]);

    useEffect(() => {
        
        if (datas !== null&&datas !== undefined) {
            let itemData = GetGuestData(datas)
            setNumbers(itemData?.number!);
            setData(itemData?.data!);

        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    const changedItem = (dataChang: GuestItemDatas) => {
        let newData = data;
        if (!CheckExistDataInState(dataChang)) {
            newData.push(dataChang);
            setData(newData);
        } else {
            let index = GetIndex(dataChang);
            newData[index] = dataChang;
            setData(newData);
        }
        changed(data);
    }

    const CheckExistDataInState = (dataChang: GuestItemDatas) => {

        if (data.length <= 0) {
            return false;
        }
        let indexes = GetIndex(dataChang);
        if (indexes < 0) {
            return false;
        }
        return true;
    }

    const GetIndex = (dataChang: GuestItemDatas) => {
        return data.findIndex((item) => item.index === dataChang.index)
    }


    const AddMoreItem = () => {
        let newNumbers = [];
        for (let i = 1; i <= numbers.length + 1; i++)
            newNumbers.push(i);

        setNumbers(newNumbers)
    }
    let Items = numbers.map((item, index) => {
        return (<>
            <GuestItems GuestItemDatas={data[index] ?? { name: "", email: "" }} index={item} changed={changedItem} />
        </>)
    })

    return <>
        <div className={`${LayoutStyle.backBlackLight} ${LayoutStyle.cover} ${LayoutStyle.mt1}`}>
            <p>Choose Guest:</p>
            {Items}
            <div className={`${LayoutStyle.mt1}`}>
                <button onClick={() => { AddMoreItem() }} className={`${ButtonStyle.btnBlueOuter}`} >Add More </button>
            </div>
        </div>
    </>
}