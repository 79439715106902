export const GetValueFromMultiSelect = (value: string[]) => {
    
    if(value.length<=0){
        return false
    }
    let newValue: string[] = [];
    value.forEach((v: any) => {
        if(v.value!==undefined)
        newValue.push(v.value)
    });
    if(newValue.length>0)
    return newValue;

    return value;
}

export const ChangeToStringFy=(value:any)=>{
    return JSON.stringify(value);
}

export const ConvertToArrayOneIndexValueArray=(value:any)=>{
    let newValues:any=[]
    let items=value.split(",");
    items.forEach((d:any) => {
        let itemData=d.split("::");
        newValues.push(itemData[1])
    });
    return newValues;
}


export const ConvertToFormData=(item:any,multi:boolean=false)=>{
    let formData = new FormData();
    let keys = Object.keys(item);
    keys.forEach(key => {
        if(key!=='img')
        {
            formData.append(key, item[key]);
        }
        else{
            if(multi){
                let files=item.img;
                for (let i = 0; i < files?.length; i++) {
                    formData.append("img"+i, files[i]);
                }
            }else{
                formData.append("img", item.img);
            }
        }
    });
    return formData;
}

export const downloadURI=(uri:string, name:string)=>{
    var link = document.createElement("a");
    // If you don't know the name or want to use
    // the webserver default set name = ''
    link.setAttribute('download', name);
    link.href = uri;
    document.body.appendChild(link);
    link.click();
    link.remove();
}

export const CreateParamSearchTable=(param:any)=>{
    let params:any={}
    param.forEach((p:any) => {
        
       if(p.value!==undefined){
            params[p.columnField.toLowerCase()]=p.value
       }
    });

    return params
}