import LayoutStyle from '../../style/layout/Layout.module.css'
import Proof from '../Element/Proof'

export default function MoreFieldProfile(props: any) {

    const {  programPart, setProgramPart, businessType, setBusinessType, site='1' } = props
    return <>

        <div className={`${LayoutStyle.backBlackLight} ${LayoutStyle.cover}`}>
         
            <div className={`${LayoutStyle.flexGrow1}`}>
                <p>Business type:</p>
                <Proof  change={(value) => { setBusinessType(value); }} type="businessType" defaultValue={(businessType)} />
            </div>
            <div className={`${LayoutStyle.flexGrow1}`}>
                <p>program part:</p>
                <Proof  change={(value) => { setProgramPart(value); }} type="programPart" defaultValue={(programPart)} />
            </div>

        </div>
    </>
}