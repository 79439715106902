import { FormDatas, FormDatasAction, FormDependsDatasAction, FormEmptyDatasAction, FormItemDatasAction } from "../../context/FormContext";
import { useContext, useEffect, useState } from "react";

import ButtonStyle from '../../style/element/button/Button.module.css'
import ConfirmRejectDialog from "../Element/AgreeDialog";
import DeleteIcon from '@mui/icons-material/Delete';
import { DialogAction } from "../../context/Dialog";
import DropDownBtn from "../Element/DropDownBtn";
import EditIcon from '@mui/icons-material/Edit';
import GridServerSide from "../Element/GridServerSide";
import GridStyle from '../../style/element/grid/Grid.module.css'
import LockOpenIcon from '@mui/icons-material/LockOpen';
import { RemoveFormApi } from "../../api/Api";
import SettingsIcon from '@mui/icons-material/Settings';
import SortIcon from '@mui/icons-material/Sort';
import { UserOnlinePermissionContextData } from "../../context/UserPermissionContext";
import { useNavigate } from "react-router-dom";

export default function Grids() {
    const navigate = useNavigate();
    const [permission, setPermission] = useState({ list: [], menu: [], module: [] });
    const myPermissionInfo = useContext(UserOnlinePermissionContextData)
    const columns = [
        {
            field: 'Action', type: "date", filterable: false, sortable: false, renderCell: (params: any) => {
                let items = [];
                if (permission.module.findIndex((data: any) => data.action === 'remove' && data.menu === "form") >= 0) {
                    items.push({ click: () => { Remove(params.value) }, title: <><DeleteIcon/>Remove</>, close: true })
                }
                if (permission.module.findIndex((data: any) => data.action === 'edit' && data.menu === "form") >= 0) {
                    items.push({ click: () => { GoToEditPage(params.value) }, title: <><EditIcon/>Edit</>, close: true })
                }
                if (permission.module.findIndex((data: any) => data.action === 'permission' && data.menu === "form") >= 0) {
                    items.push({ click: () => { GoToPermissionPage(params.value) }, title: <><LockOpenIcon/>Permission</>, close: true })
                }
                
                if (permission.module.findIndex((data: any) => data.action === 'sort' && data.menu === "form") >= 0) {
                    items.push({ click: () => { GoToSortPage(params.value) }, title: <><SortIcon/>Sort</>, close: true })
                }
                return (
                    <>
                        <DropDownBtn
                            title={<><strong><SettingsIcon /></strong></>}
                            items={items}
                        />
                    </>
                );
            },
        },
        { field: 'id', type: "string", hideable: false, width: 120, },
        {
            field: 'Title', type: "string", width: 250, renderCell: (params: any) => {
                return (<div className={`${GridStyle.textTd}`}>{params.value}</div>);
     
            }
        },
 
        {
            field: 'Description', type: "string", filterable: false, width: 200, renderCell: (params: any) => {
                return (<div className={`${GridStyle.textTd}`}>{params.value}</div>);
            }
        },
  
    
    ];
    const [rows, setRows] = useState([]);
    const formInfo = useContext(FormDatas)
    const GetFormData = useContext(FormDatasAction)
    const GetFormDependsData = useContext(FormDependsDatasAction)
    const GetFormItemData=useContext(FormItemDatasAction)
    const EmptyValues=useContext(FormEmptyDatasAction)
    
    const [id, setId] = useState(0);
    const [loading, setLoading] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [start, setStart] = useState(0);
    const [number, setNumber] = useState(25);

    const setDialogData = useContext(DialogAction);
    const [rowCountState, setRowCountState] = useState(0);
    const Remove = (id: any) => { setId(id); setDialogData({ open: true, id: "InterviewBooking" }) }
    const RemoveData = () => {
        setLoading(true);
        RemoveFormApi({ idSearch: id}).then(() => {
            setLoading(false);
            setDialogData({ open: false, id: "InterviewBooking" });
            GetData();
        })
    }

    const GoToEditPage = (id: string | number) => {
        EmptyValues(["datas","datasItem"]);
        GetFormData({ idSearch: id });
        GetFormItemData({idSearch:id})
        navigate(`/EditForm/${id}`);
    }
    
    const GoToPermissionPage = (id: string | number) => {
        GetFormData({ idSearch: id });
        GetFormDependsData({idSearch:id});
        navigate(`/PermissionForm/${id}`);
    }
    
    const GoToSortPage = (id: string | number) => {
        EmptyValues(["datas","datasItem"]);
        GetFormData({ idSearch: id });
        GetFormItemData({idSearch:id});
        navigate(`/SortForm/${id}`);
    }
    
    const GetData = (starts: number=start,numbers: number=number) => { GetFormData({ start:starts, number:numbers,confirm_team:1 }); }
   
    const ChangePageSize = (param: any) => {
        setNumber(param.pageSize);
        setStart(0);
        GetData(0,param.pageSize);
    }

    const ChangePageNumber = (param: any) => {
        setStart(number * param.page);
        GetData(number * param.page);
    }
    
    useEffect(() => {
        if (myPermissionInfo !== undefined) {
            setPermission(myPermissionInfo)
        }
    }, [myPermissionInfo])

    useEffect(() => {
        if (formInfo !== undefined) {
            setIsLoading(false)
            setRowCountState(formInfo?.total);
            if (formInfo.data) {
                let newRows = formInfo.data.map((item: any) => {
                    return {
                        id: item.id,
                        Title:`${item.title}`,
                        Description: item.description,
                        Action: item.id,
                    }
                })
                setRows(newRows);
            }
        }
    }, [formInfo])
    useEffect(()=>{
        GetData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
    },[])
    return <>
        <GridServerSide
            rowCounts={rowCountState}
            columns={columns}
            rows={rows}
            isLoading={isLoading}
            ChangePageNumber={ChangePageNumber}
            ChangePageSize={ChangePageSize}
        />
        <ConfirmRejectDialog
            title='Are you sure remove?'
            id="InterviewBooking"
            body="All information related to the program will be deleted! "
            confirmTitle='Remove'
            rejectTitle='Cancel'
            confirmFunction={() => { RemoveData() }}
            rejectFunction={() => { setDialogData({ open: false, id: "InterviewBooking" }) }}
            confirmClass={ButtonStyle.danger}
            rejectClass={ButtonStyle.rejectBtnDialog}
            loading={loading}
        />
    </>
}