import { GetBookingTeamApi, GetJustBookingTeamApi } from "../api/Api";
import { createContext, useState } from "react";

import { PropsChildren } from "../Interface";

export const TeamBookingContextData = createContext<any>(undefined);
export const TeamBookingContextAction = createContext<(param?: any) => any | undefined>(() => { });

export const JustTeamBookingContextData = createContext<any>(undefined);
export const JustTeamBookingContextAction = createContext<(param?: any) => any | undefined>(() => { });


export default function TeamBookingContextProvider(props: PropsChildren) {

   const { children } = props
   const [teamBookingInfo, setTeamBookingInfo] = useState(undefined);
   const [justTeamBookingInfo, setJustTeamBookingInfo] = useState(undefined);

   const GetDataTeamBooking = (param = {}) => {
      GetBookingTeamApi(param).then((response: any) => {
         setTeamBookingInfo(response);
      })
   }

   const GetDataJustTeamBooking = (param = {}) => {
      GetJustBookingTeamApi(param).then((response: any) => {
         setJustTeamBookingInfo(response);
      })
   }



   return (
      <TeamBookingContextData.Provider value={teamBookingInfo}>
         <TeamBookingContextAction.Provider value={GetDataTeamBooking}>
            <JustTeamBookingContextData.Provider value={justTeamBookingInfo}>
               <JustTeamBookingContextAction.Provider value={GetDataJustTeamBooking}>
                  {children}
               </JustTeamBookingContextAction.Provider>
            </JustTeamBookingContextData.Provider>
         </TeamBookingContextAction.Provider>
      </TeamBookingContextData.Provider>
   )

}

