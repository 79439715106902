import { Link, useNavigate } from 'react-router-dom';
import { useContext, useState } from 'react';

import { AddProgramApi } from '../../api/Api';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import Buttons from '../Element/ButtonLoader';
import EditInfo from './edit/EditInfo';
import LayoutStyle from '../../style/layout/Layout.module.css';
import { LinkContextData } from '../../context/LinkContext';
import { ProgramContextAction } from '../../context/ProgramContext';
import { RoleContextData } from '../../context/RoleContext';

export default function AddProgram() {
    const [data] = useState([]);
    const [loading, setLoading] = useState(false);
    const [info, setInfo] = useState({} as any);

    const navigate = useNavigate();
    const GetPrograms = useContext(ProgramContextAction);
    const RoleInfo=useContext(RoleContextData);
    const linkInfo=useContext(LinkContextData);
    
    const SendData = () => {
        info["program_role"]=JSON.stringify(RoleInfo);
        info['program_link']=JSON.stringify(linkInfo);
        console.log(info);
     
        setLoading(true);


        AddProgramApi(info).then((response: any) => {
            setLoading(false);

            if (response?.status === "true") {
                GetPrograms();
                navigate("/Programs");
            }
        })
    }


    return <>
        <div className={`${LayoutStyle.container}`}>
            <div className={`${LayoutStyle.innerContainer}`}>
                <div className={`${LayoutStyle.flex}`}>
                    <span><Link className={`${LayoutStyle.spanName}`} to={"/Programs"}>Programs</Link></span>
                    <span> <ArrowForwardIosIcon className={`${LayoutStyle.fontSm}`} /></span>
                    <span>Add Program  </span>
                </div>
                <EditInfo
                    data={data} 
                    book_guest_data_insert=""
                    role_data_insert=""
                    changed={(data: any) => setInfo(data)}
                />
                
                <div className={` ${LayoutStyle.cover} ${LayoutStyle.mt1} ${LayoutStyle.mb1}`}>
                    <Buttons clicked={() => { SendData() }} loading={loading} title="Save Data" size='large' />
                </div>
            </div>
        </div>
    </>
}