import { ChooseTeamProps, UserTeamData } from '../../../Interface';
import { useEffect, useState } from 'react';

import ButtonStyle from '../../../style/button/Button.module.css';
import ChooseTeamsOption from '../../Element/ChoosetemsOption';
import { GetTeamData } from './CreateDataForEdit';
import LayoutStyle from '../../../style/layout/Layout.module.css';

export default function ChooseTeam(props: ChooseTeamProps) {

    const { changed, datas } = props
    const [numbers, setNumbers] = useState([1]);
    const [data, setData] = useState<any[]>([]);

    useEffect(() => {
        if (datas !== null&&datas !== undefined) {
            let itemData = GetTeamData(datas)
            setNumbers(itemData?.number!);
            setData(itemData?.data!);

        }
    }, [datas])

    const changedItem = (dataChang: UserTeamData) => {
        let newData = data;
        if (!CheckExistDataInState(dataChang)) {
            newData.push(dataChang);
            setData(newData);
        } else {
            let index = GetIndex(dataChang);
            newData[index] = dataChang;
            setData(newData);
        }
        changed(data);
    }

    const CheckExistDataInState = (dataChang: UserTeamData) => {

        if (data.length <= 0) {
            return false;
        }
        let indexes = GetIndex(dataChang);
        if (indexes < 0) {
            return false;
        }
        return true;
    }

    const GetIndex = (dataChang: UserTeamData) => {
        return data.findIndex((item) => item.index === dataChang.index)
    }


    const AddMoreItem = () => {
        let newNumbers = [];
        for (let i = 1; i <= numbers.length + 1; i++)
            newNumbers.push(i);

        setNumbers(newNumbers)
    }
    let Items = numbers.map((item, index) => {
        return (<>
            <ChooseTeamsOption ItemDatas={data[index] ?? { userId: "", edit: "" }} index={item} changed={changedItem} />
        </>)
    })

    return <>

        <div className={`${LayoutStyle.backBlackLight} ${LayoutStyle.cover} ${LayoutStyle.mt1}`}>
            {Items}
            <div className={`${LayoutStyle.mt1}`}>
                <button onClick={() => { AddMoreItem() }} className={`${ButtonStyle.btnBlueOuter}`} >Add More </button>
            </div>
        </div>
    </>
}