import { useContext, useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';

import BoredCamps from '../Element/BoredCamp'
import Buttons from '../Element/ButtonLoader';
import { FormDatas } from '../../context/FormContext';
import FormItem from './FormItem';
import Inputs from '../Element/Input';
import LayoutStyle from '../../style/layout/Layout.module.css'
import Proof from '../Element/Proof';
import TextArea from '../Element/TextArea';
import { UpdateFormApi } from '../../api/Api';

export default function EditForm() {
    const { id } = useParams();
    const [loading, setLoading] = useState(false);
    const [info, setInfo] = useState<any>({ title: "", description: "", item: "", form_type: "0" })
    const [data, setData] = useState<any>([]);
    const [title, setTitle] = useState("");
    const navigate = useNavigate();
    const formInfo = useContext(FormDatas)

    const changedItem = (value: any, types: string, json: boolean = false) => {

        let newValue: any = info;
        newValue[types] = json ? JSON.stringify(value) : value;
        setInfo({ ...newValue });
    }

    useEffect(() => {
        if (formInfo !== undefined) {
            if (formInfo.data.length > 0) {
                let datas = formInfo.data[0];
                setData(datas);
                setInfo(datas);
                setTitle(datas.title);
            }
        }

    }, [formInfo])

    const SendData = () => {
        info["idSearch"] = id


        setLoading(true);
        UpdateFormApi(info).then((response: any) => {
            setLoading(false);

            if (response?.status === "true") {
                navigate("/Forms");
            }
        })

    }
    return <>
        <div className={`${LayoutStyle.container}`}>
            <div className={`${LayoutStyle.innerContainer}`}>
                <div className={`${LayoutStyle.backBlackLight} ${LayoutStyle.cover} ${LayoutStyle.mt1}`}>
                    <BoredCamps Title={`Edit ${title}`} linkHref='/Forms' linkTitle='Forms' />

              
                    <div className={`${LayoutStyle.flexGrow1}`}>
                        <Inputs value={data.title} id={`title`} name='title' label='Title' changed={(value) => { changedItem(value.target.value, "title") }} />

                    </div>
                    <div className={`${LayoutStyle.flexGrow1}${LayoutStyle.mt1}`}>
                        <TextArea value={data.description} id={`description`} name='description' label='Description' changed={(value) => { changedItem(value.target.value, "description") }} />
                    </div>
                    <div className={`${LayoutStyle.mt1}`}>
                        <p>Program Form</p>
                        <Proof  type="programForm" defaultValue={data.form_type} isMulti={false} change={(event) => { changedItem(event, "form_type") }} />
                    </div>

                    <FormItem id={id} changed={(value: any) => { changedItem(value, "item", true) }} />

                    <div className={` ${LayoutStyle.cover} ${LayoutStyle.mt1} ${LayoutStyle.mb1}`}>
                        <Buttons clicked={() => { SendData() }} loading={loading} title="Save Data" size='large' />
                    </div>
                </div>
            </div>
        </div>
    </>
}