import { createContext, useState } from "react";

import { GetAvailabilityApi } from "../api/Api";
import { PropsChildren } from "../Interface";

export const AvailabilityContextData = createContext<any>(undefined);
export const AvailabilityContextAction = createContext<(param?: any) => any | undefined>(() => { });

export default function AvailabilityContextProvider(props: PropsChildren) {

   const { children } = props
   const [availabilityInfo, setAvailabilityInfo] = useState(undefined);

   const GetDataAvailability = (param = { start: 0, number: 25 }) => {
      GetAvailabilityApi(param).then((response: any) => {
         setAvailabilityInfo(response);
      })
   }
   return (
      <AvailabilityContextData.Provider value={availabilityInfo}>
         <AvailabilityContextAction.Provider value={GetDataAvailability}>
            {children}
         </AvailabilityContextAction.Provider>
      </AvailabilityContextData.Provider>
   )

}

