import { useContext, useEffect, useState } from "react";

import ButtonStyle from '../../style/button/Button.module.css';
import { FormItemDatas } from "../../context/FormContext";
import FormItems from "./FormItems";
import { GetFormItemDataArray } from "../booking/interviewBooking/CreateDataForEdit";
import LayoutStyle from '../../style/layout/Layout.module.css';

export default function FormItem(props:any){

    const { changed } = props
    const [numbers, setNumbers] = useState([1]);
    const [data, setData] = useState<any[]>([]);
    const [info, setInfo] = useState<any[]>([]);
    const [LoadData, setLoadData] = useState<boolean>(false);
    const datasItem:any=useContext(FormItemDatas);

    

    const changedItem = (dataChang: any) => {
        let newData = info;
        if (!CheckExistDataInState(dataChang)) {
            newData.push(dataChang);
            setInfo(newData);
        } else {
            let index = GetIndex(dataChang);
            newData[index] = dataChang;
            setInfo(newData);
        }
        changed(newData);
      
    }

    const CheckExistDataInState = (dataChang: any) => {
        if (data.length <= 0) {
            return false;
        }
        let indexes = GetIndex(dataChang);
        if (indexes < 0) {
            return false;
        }
        return true;
    }

    const GetIndex = (dataChang: any) => {
    
        return data.findIndex((item) => item.index === dataChang.index)
    }


    useEffect(()=>{
        if(datasItem!==undefined){
            setLoadData(true)
            let data=GetFormItemDataArray(datasItem.data)
            setNumbers(data?.number!);
            setData(data?.data!);
            setInfo(data?.data!);
            
        }
    },[datasItem]);



    const AddMoreItem = () => {
        let newNumbers = [];
        for (let i = 1; i <= numbers.length + 1; i++)
            newNumbers.push(i);

        setNumbers(newNumbers)
    }
    
    let Items:any=[]
    if(LoadData)
    {
        Items= numbers.map((item, index) => {
            
        return (<>
            <FormItems  ItemDatas={data!==undefined?data[index]:undefined ?? {index:item, title: "", type: "",value:"",orders:"",action:"insert" }} index={item} changed={changedItem} />
        </>)
    })
}

    return <>

        <div className={`${LayoutStyle.backBlackLight} ${LayoutStyle.cover} ${LayoutStyle.mt1}`}>
            {Items}
            <div className={`${LayoutStyle.mt1}`}>
                <button onClick={() => { AddMoreItem() }} className={`${ButtonStyle.btnBlueOuter}`} >Add More </button>
            </div>
        </div>
    </>

} 