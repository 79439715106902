import * as React from 'react';

import LogoutIcon from '@mui/icons-material/Logout';
import { RemoveCooke } from '../../function/Fetching';
import { blue } from '@mui/material/colors';
import styleButton from '../../style/button/Button.module.css';
import { useNavigate } from 'react-router-dom';

export default function LogoutBtn() {
    const fontSize = 30;
    const navigate = useNavigate();

    return <>
        <button className={`${styleButton.btnNone} ${styleButton.btnLogout}`} onClick={() => {
            RemoveCooke()
            navigate("/");
        }}><LogoutIcon sx={{ color: blue[500], fontSize: fontSize }} /> Logout</button>
    </>
}