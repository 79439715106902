import Buttons from "../Element/ButtonLoader";
import Inputs from "../Element/Input";
import LayoutStyle from '../../style/layout/Layout.module.css'
import SingleSelect from "../Element/SingleSelect";
import Sites from "../Element/Site";
import { useState } from "react";

interface ComponentProps {
    ChangeForSearch: Function
}
interface ParamProps {
    idSearch: string | "",
    published: string | "",
    site: string | "",
    title: string | ""
}
export default function SearchProgram(props: ComponentProps) {

    const { ChangeForSearch } = props;
    const [param, setParam] = useState<ParamProps>({
        idSearch: "",
        published: "",
        site: "",
        title: "",


    });

    const Change = (value: string, name: string) => {
        let newParams: any = param;
        newParams[name] = value;
        setParam({ ...newParams });
    }

    const Cancel = () => {
        let newParams: any = {
            idSearch: "",
            published: "",
            site: "",
            title: "",
        }
        setParam({ ...newParams });
        ChangeForSearch({})
    }

    return <>
        <div className={`${LayoutStyle.backBlackLight} ${LayoutStyle.cover} ${LayoutStyle.flexWarp}`}>

            <div className={`${LayoutStyle.flexGrow1}`}>
                <Inputs
                    label='Id'
                    name="id"
                    id="id"
                    value={param.idSearch}
                    changed={(event) => Change(event.target.value, "idSearch")}
                />
            </div>
            <div className={`${LayoutStyle.flexGrow1}`}>
                <Inputs
                    label='Site Name'
                    name="site"
                    id="site"
                    value={param.site}
                    changed={(event) => Change(event.target.value, "site")}
                />
            </div>
            <div className={`${LayoutStyle.flexGrow1}`}>
                <Inputs
                    label='Title'
                    name="title"
                    id="title"
                    value={param.title}
                    changed={(event) => Change(event.target.value, "title")}
                />
            </div>

        </div>
        <div className={`${LayoutStyle.backBlackLight} ${LayoutStyle.cover} ${LayoutStyle.flexWarp}`}>
            <div className={`${LayoutStyle.flexGrow1}`}>
                <div className={`${LayoutStyle.mt1}`}>
                    <p>Published?</p>
                    <SingleSelect
                        changed={(data) => { Change(data.value, "published") }}
                        options={[
                            { value: '', label: '', selected: param.published === '' ? true : false },
                            { value: '0', label: 'No', selected: param.published === '0' ? true : false },
                            { value: '1', label: 'Yes', selected: param.published === '1' ? true : false },
                        ]
                        }
                    />
                </div>
            </div>
        </div>
        <div className={`${LayoutStyle.backBlackLight} ${LayoutStyle.cover} ${LayoutStyle.flexWarp}`}>
            <Buttons loading={false} clicked={() => { ChangeForSearch(param) }} title="Search" size='small' />
            <Buttons loading={false} clicked={() => Cancel()} color="secondary" title="Cancel" size='small' />
        </div>
    </>
}