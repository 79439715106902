import { createContext, useState } from "react";

import { GetUser } from "../api/Api";
import { PropsChildren } from "../Interface";

export const UsersData = createContext<any>([]);
export const UsersAction = createContext<(data: any) => any | undefined>((data: any) => { });
export const UsersActionGetData = createContext<(param:any) => any | undefined>(() => { });

export default function UsersProvider(props: PropsChildren) {

   const { children } = props
   const [usersData, setUsersData] = useState([]);

   const GetUsersData = (param={start:0,number:25}) => {
      GetUser(param).then((response: any) => {
         setUsersData(response);
      })
   }

   return (
      <UsersData.Provider value={usersData}>
         <UsersAction.Provider value={setUsersData}>
            <UsersActionGetData.Provider value={GetUsersData}>
               {children}
            </UsersActionGetData.Provider>
         </UsersAction.Provider>
      </UsersData.Provider>
   )

}

