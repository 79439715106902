import { FetchFileMulti, UpdateDocsApi } from "../../api/Api";
import { Link, useNavigate, useParams } from "react-router-dom";
import { useContext, useEffect, useState } from "react";

import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import Buttons from '../Element/ButtonLoader';
import DocItem from "./DocItem";
import LayoutStyle from '../../style/layout/Layout.module.css';
import { UserAllDoc } from "../../context/UserDoc";

export default function EditDocument(){
    const [loading, setLoading] = useState(false);
    const [info, setInfo] = useState({} as any);
    const [data, setData] = useState([]);
    const [title,setTitle]=useState("");
    const { id } = useParams();

    const navigate=useNavigate();
    const userAllDoc = useContext(UserAllDoc)

    const SendData=()=>{
       info["idSearch"]=id

       setLoading(true);

        UpdateDocsApi(info).then((response:any)=>{
            setLoading(false);
            
            if (response?.status === "true") {
                navigate("/UserDocuments");
            }
        })
 
    }

    useEffect(() => {
        if(userAllDoc !==undefined){
            if (userAllDoc.data.length > 0) {
                let datas=userAllDoc.data[0];
                setData(datas);
                setInfo(datas);
                setTitle(datas.title);
            }
        }
        
    }, [userAllDoc])
    return<>
         <div className={`${LayoutStyle.container}`}>
            <div className={`${LayoutStyle.innerContainer}`}>
                <div className={`${LayoutStyle.flex}`}>
                    <span><Link className={`${LayoutStyle.spanName}`} to={"/UserDocuments"}>User Documents</Link></span>
                    <span> <ArrowForwardIosIcon className={`${LayoutStyle.fontSm}`} /></span>
                    <span>Edit {title}  </span>
                </div>
                <DocItem data={data} Upload={false} changed={(data:any)=>setInfo(data)}/>
                <div className={` ${LayoutStyle.cover} ${LayoutStyle.mt1} ${LayoutStyle.mb1}`}>
                    <Buttons clicked={() => { SendData() }} loading={loading} title="Save Data" size='large' />
                </div>
            </div>
        </div>
        </>;
    
}