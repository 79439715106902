import { UserAllNotesActionGetData, UserAllNotesData } from "../../context/Notes";
import { useContext, useEffect, useState } from "react";

import AttachFileIcon from '@mui/icons-material/AttachFile';
import ButtonStyle from '../../style/element/button/Button.module.css'
import ConfirmRejectDialog from "../Element/AgreeDialog";
import { DialogAction } from "../../context/Dialog";
import DropDownBtn from "../Element/DropDownBtn";
import EditIcon from '@mui/icons-material/Edit';
import GridServerSide from "../Element/GridServerSide";
import GridStyle from '../../style/element/grid/Grid.module.css'
import PreviewIcon from '@mui/icons-material/Preview';
import { RemoveProgramApi } from "../../api/Api";
import SettingsIcon from '@mui/icons-material/Settings';
import { useNavigate } from "react-router-dom";

export default function GridsNote() {
    const navigate = useNavigate();
    const GetUserAllNotes = useContext(UserAllNotesActionGetData)
    const columns = [
        {
            field: 'Action', type: "date", filterable: false, sortable: false, renderCell: (params: any) => {
                let items = [];
                let values = params.value.split(",");
                let id = values[0];
                let edit = values[1];
                if (edit === 'true') {
                    items.push({ click: () => { GoToEditPage(id) }, title: <><EditIcon />Edit</>, close: true })
                }
                items.push({ click: () => { GoToAttachmentPage(id) }, title: <><AttachFileIcon />Attachments</>, close: true })
                items.push({ click: () => { GoToView(id) }, title: <><PreviewIcon />View</>, close: true })
                return (
                    <>
                        <DropDownBtn
                            title={<><strong><SettingsIcon /></strong></>}
                            items={items}
                        />
                    </>
                );
            },
        },
        { field: 'id', type: "string", hideable: false, width: 120, },
        {
            field: 'Title', type: "string", width: 250, renderCell: (params: any) => {
                return (<div className={`${GridStyle.textTd}`}>{params.value}</div>);
            }
        },
        {
            field: 'Description', type: "string", filterable: false, width: 270, renderCell: (params: any) => {
                return (<div className={`${GridStyle.textTd}`}>{params.value}</div>);
            }
        },
        {
            field: 'CreatorName', type: "date", filterable: false, width: 170, renderCell: (params: any) => {
                return (<div className={`${GridStyle.textTd}`}>{params.value}</div>);
            }
        },

        {
            field: 'Created', type: "date", filterable: false, width: 170, renderCell: (params: any) => {
                return (<div className={`${GridStyle.textTd}`}>{params.value}</div>);
            }
        },

    ];
    const [rows, setRows] = useState([]);
    const userAllNotes = useContext(UserAllNotesData)
    const [id] = useState(0);
    const [loading, setLoading] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [start, setStart] = useState(0);
    const [number, setNumber] = useState(25);



    const setDialogData = useContext(DialogAction);
    const [rowCountState, setRowCountState] = useState(0);
    const RemoveAvailability = () => {
        setLoading(true);
        RemoveProgramApi({ idSearch: id }).then(() => {
            setLoading(false);
            setDialogData({ open: false, id: "InterviewBooking" });
            GetData();
        })
    }

    const GoToEditPage = (id: string | number) => {
        GetUserAllNotes({ idSearch: id });
        navigate(`/EditNotes/${id}`);
    }

    const GoToAttachmentPage = (id: string | number) => {
        GetUserAllNotes({ idSearch: id });
        navigate(`/EditNotesAttachments/${id}`);
    }

    const GoToView = (id: string | number) => {
        GetUserAllNotes({ idSearch: id });
        navigate(`/NotesView/${id}`);
    }
    const GetData = (starts: number = start, numbers: number = number) => { GetUserAllNotes({ start: starts, number: numbers }); }

    const ChangePageSize = (param: any) => {
        setNumber(param.pageSize);
        setStart(0);
        GetData(0, param.pageSize);
    }

    const ChangePageNumber = (param: any) => {
        setStart(number * param.page);
        GetData(number * param.page);
    }

    useEffect(() => {
        GetUserAllNotes({})
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    useEffect(() => {
        setIsLoading(true)
        if (userAllNotes !== undefined) {
            setIsLoading(false)
            setRowCountState(userAllNotes?.total);
            if (userAllNotes.data) {
                let newRows = userAllNotes.data.map((item: any) => {
                    let user_notes_user = item.user_notes_user !== null ? item.user_notes_user.split(",") : [];
                    let edit = false;

                    if (item.creator_id === userAllNotes.userOnlineId) {
                        edit = true
                    } else
                        user_notes_user.forEach((itemUserNote: any) => {

                            let itemData = itemUserNote.split("::");

                            if (itemData[2] === userAllNotes.userOnlineId && item.creator_id !== userAllNotes.userOnlineId) {

                                edit = itemData[3] === '1' ? true : false
                            }
                        });


                    return {
                        id: item.id,
                        Title: `${item.title}`,
                        Description: item.description,
                        CreatorName: `${item.fname} ${item.lname}`,
                        Created: item.created_at,
                        Action: `${item.id},${edit}`
                    }
                })

                setRows(newRows);
            }
        }
    }, [userAllNotes]);

    return <>
        <GridServerSide
            rowCounts={rowCountState}
            columns={columns}
            rows={rows}
            isLoading={isLoading}
            ChangePageNumber={ChangePageNumber}
            ChangePageSize={ChangePageSize}
        />
        <ConfirmRejectDialog
            title='Are you sure remove?'
            id="InterviewBooking"
            body="All information related to the program will be deleted! "
            confirmTitle='Remove'
            rejectTitle='Cancel'
            confirmFunction={() => { RemoveAvailability() }}
            rejectFunction={() => { setDialogData({ open: false, id: "InterviewBooking" }) }}
            confirmClass={ButtonStyle.danger}
            rejectClass={ButtonStyle.rejectBtnDialog}
            loading={loading}
        />
    </>
}