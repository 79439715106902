import * as React from 'react';

import { DataGrid, GridFilterModel, GridToolbar } from '@mui/x-data-grid';

import DarkMode from './DarkMode';
import { GridTable } from '../../Interface';

export default function GridServerSide(props: GridTable) {
    const { columns, rows,rowCounts,isLoading,ChangePageSize,ChangePageNumber,width="100%",ChangeFilter=()=>{} } = props
    const [queryOptions, setQueryOptions] = React.useState({});

    const onFilterChange = React.useCallback((filterModel: GridFilterModel) => {
        
        let params={ filterModel: { ...filterModel } }
        //setQueryOptions(params);
        ChangeFilter(params.filterModel.items)
        //console.log(queryOptions,'queryOptions');
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const [pageSize, setPageSize] = React.useState(25);
    const [page, setPage] = React.useState(0);

    const PageSizeChange=(newPageSize:number)=>{
        ChangePageSize({pageSize:newPageSize,page});
        setPageSize(newPageSize)
    }

    const PageChange=(newPage:number)=>{
        ChangePageNumber({pageSize,page:newPage});
        setPage(newPage)
    }

    return (
        <DarkMode>
            <div style={{ height: '80vh', width:width}}>
                <DataGrid
                    rows={rows}
                    rowCount={rowCounts}
                    columns={columns}
                    filterMode="server"
                    onFilterModelChange={onFilterChange}
                    loading={isLoading}
                    paginationMode="server"
                    onPageChange={(newPage) => PageChange(newPage)}
                    onPageSizeChange={(newPageSize) => PageSizeChange(newPageSize)}
                    pagination
                    pageSize={pageSize}
                    components={{
                        Toolbar: GridToolbar,
                    }}
                    initialState={{
                        filter: {
                            filterModel: {
                                items: [
                                    {
                                        id: 1,
                                        columnField: 'age',
                                        value: '3.5',
                                        operatorValue: 'above',
                                    },
                                ],
                            },
                        },
                    }}
                    getRowHeight={() => 'auto'} 
                />
            </div>
        </DarkMode>
    );
}
