import * as React from 'react';

import { JustTeamBookingContextAction, JustTeamBookingContextData } from '../../../context/TeamBookingContext';

import BoredCamps from '../../Element/BoredCamp';
import LayoutStyle from '../../../style/layout/Layout.module.css';
import Paper from '@mui/material/Paper';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import { useParams } from 'react-router-dom';

function createData(
    name: string,
    calories: number,
    fat: number,
    carbs: number,
    protein: number,
) {
    return { name, calories, fat, carbs, protein };
}

const rows = [
    createData('Frozen yoghurt', 159, 6.0, 24, 4.0),
    createData('Ice cream sandwich', 237, 9.0, 37, 4.3),
    createData('Eclair', 262, 16.0, 24, 6.0),
    createData('Cupcake', 305, 3.7, 67, 4.3),
    createData('Gingerbread', 356, 16.0, 49, 3.9),
];

export default function ViewTeamStatusList() {
    const { id } = useParams();
    const GetDataJustTeamBooking = React.useContext(JustTeamBookingContextAction);
    const justTeamBookingInfo = React.useContext(JustTeamBookingContextData);
    const [data, setData] = React.useState([])

    React.useEffect(() => {
        GetDataJustTeamBooking({ idSearch: id });
    }, [])

    React.useEffect(() => {
        if (justTeamBookingInfo !== undefined) {

            if (justTeamBookingInfo.data !== undefined) {
                setData(justTeamBookingInfo.data);
            }
        }

    }, [justTeamBookingInfo])

    return (
        <div className={`${LayoutStyle.container}`}>
            <div className={`${LayoutStyle.innerContainer}`}>
                <div className={`${LayoutStyle.backBlackLight} ${LayoutStyle.cover}`}>
                <BoredCamps Title='Team List' linkTitle='InterviewBooking' linkHref='/InterviewBooking' />
                    <TableContainer component={Paper}>
                        <Table sx={{ minWidth: 650 }} aria-label="simple table">
                            <TableHead>
                                <TableRow>
                                    <TableCell>Id</TableCell>
                                    <TableCell>Name</TableCell>
                                    <TableCell >Status</TableCell>

                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {data.map((row: any) => (
                                    <TableRow
                                        key={row.id}
                                        sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                                    >
                                        <TableCell  >{row.id} </TableCell>
                                        <TableCell  > {row.display_name} </TableCell>
                                        <TableCell >
                                            <div className={`  ${row.confirm === "1" ? LayoutStyle.textSuccess : row.confirm === '-1' ? LayoutStyle.colorRed : ""}`}>
                                                <strong>{row.confirm === '1' ? "Confirm" : row.confirm === '-1' ? "Reject" : "pending"}</strong>
                                            </div>
                                        </TableCell>


                                    </TableRow>
                                ))}
                            </TableBody>
                        </Table>
                    </TableContainer>
                </div>
            </div>
        </div>
    );
}