import { useNavigate, useParams } from 'react-router-dom';

import { AddAvailabilityApi } from "../../api/Api";
import BoredCamps from "../Element/BoredCamp";
import Buttons from "../Element/ButtonLoader";
import LayoutStyle from '../../style/layout/Layout.module.css'
import MultiDateTime from "../booking/availability/MultiDateTime";
import { useState } from "react";

export default function AddAvailabilityUser() {
    const { id } = useParams();
    const [info, setInfo] = useState({} as any);
    const [loading, setLoading] = useState(false);
    const navigate = useNavigate();

    const Changed=(date:any[])=>{
        setInfo(JSON.stringify(date));
    }

    const Submit=()=>{
     
        setLoading(true)
        AddAvailabilityApi({items:info,for_user:"1",user_id:id}).then((response:any)=>{
            setLoading(false);
            if (response?.status === "true") {
         
                navigate("/UserManager");
            }
        })
    }

    return <>
        <div className={`${LayoutStyle.container}`}>

            <div className={`${LayoutStyle.innerContainer}`}>
                <div className={`${LayoutStyle.backBlackLight} ${LayoutStyle.cover}`}>
                    <BoredCamps Title='Add Availability' linkTitle='User Manager' linkHref='/UserManager' />

                    <MultiDateTime changed={(data: any) => { Changed(data) }} />
                    <div className={`${LayoutStyle.center}`}>
                        <Buttons title='Save Data' clicked={() => { Submit(); }} loading={loading} size='large' />
                    </div>
                </div>
            </div>
        </div>
    </>
}