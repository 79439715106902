import 'primeicons/primeicons.css';
import 'primereact/resources/themes/lara-light-indigo/theme.css';
import 'primereact/resources/primereact.css';
import 'primeflex/primeflex.css';
import '../../index.css';

import { useRef, useState } from 'react';

import { Button } from 'primereact/button';
import { FileUpload } from 'primereact/fileupload';
import { Tag } from 'primereact/tag';
import { UploadFileProps } from '../../Interface';

export const UploadFile = (props:UploadFileProps) => {
    const{multiple,accept="image/*,video/*,audio/*,.doc, .docx,.ppt, .pptx,.txt,.pdf,.xlsx,.xls",maxFileSize=10000000,title,changed}=props
    const [totalSize, setTotalSize] = useState(0);
    const fileUploadRef = useRef<any>(null);
    const [files, setFiles] = useState<any[]>([]);


    const onTemplateSelect = (e: any) => {

        let exist: any = [];
        for (let i = 0; i < e.files.length; i++) {
            if (files.findIndex((item) => item.name === e.files[i].name) >= 0) {
                exist.push(e.files[i].name)
            }
        }
        let newFiles = [...files, ...e.files];
        newFiles = newFiles.filter((item) => !exist.includes(item.name))
        newFiles=[...newFiles,...e.files]
        changed(newFiles);
        setFiles(newFiles);

    }

    const onTemplateUpload = (e: any) => {

        let _totalSize = 0;
        e.files.forEach((file: any) => {
            _totalSize += (file.size || 0);
        });

        setTotalSize(_totalSize);
        console.log({ severity: 'info', summary: 'Success', detail: 'File Uploaded' });
    }

    const onTemplateRemove = (file: any, callback: any) => {

        let newFiles = files.filter((item) => item.name !== file.name);
        setFiles(newFiles);
        changed(newFiles);
        setTotalSize(totalSize - file.size);
        callback();
    }

    const onTemplateClear = () => {
        setTotalSize(0);
        setFiles([]);
        changed([]);
    }


    const headerTemplate = (options: any) => {
        const { className, chooseButton, uploadButton, cancelButton } = options;
        const value = totalSize / 10000;
        const formatedValue: any = fileUploadRef && fileUploadRef.current ? fileUploadRef.current?.formatSize(totalSize)! : '0 B';

        return (
            <div className={className} style={{ backgroundColor: 'transparent', display: 'flex', alignItems: 'center', background: "#1a2027" }}>
                {chooseButton}
                {/* {uploadButton} */}
                {cancelButton}
                {/* <ProgressBar value={value} displayValueTemplate={() => `${formatedValue} / 1 MB`} style={{width: '300px', height: '20px', marginLeft: 'auto',display:"none"}}></ProgressBar> */}
            </div>
        );
    }

    const itemTemplate = (file: any, props: any) => {
        return (
            <div className="flex align-items-center flex-wrap">
                <div className="flex align-items-center" style={{ width: '40%' }} >
                    <img alt={file.name} role="presentation" src={file.objectURL} width={100} />
                    <span className="flex flex-column text-left ml-3" >
                        {file.name}
                        <small>{new Date().toLocaleDateString()}</small>
                    </span>
                </div>
                <Tag value={props.formatSize} severity="warning" className="px-3 py-2" />
                <Button type="button" icon="pi pi-times" className="p-button-outlined p-button-rounded p-button-danger ml-auto" onClick={() => onTemplateRemove(file, props.onRemove)} />
            </div>
        )
    }

    const emptyTemplate = () => {
        return (
            <div className="flex align-items-center flex-column" style={{ background: "#1a2027" }}>
                <i className="pi pi-image mt-3 p-5" style={{ 'fontSize': '5em', borderRadius: '50%', backgroundColor: '#1a2027', color: 'white' }}></i>
                <span style={{ 'fontSize': '1.2em', color: 'var(--text-color-secondary)' }} className="my-5">Drag and Drop Image Here</span>
            </div>
        )
    }



    const chooseOptions = { icon: 'pi pi-fw pi-images', iconOnly: true, className: 'custom-choose-btn p-button-rounded p-button-outlined' };
    const uploadOptions = { icon: '', iconOnly: true, className: '' };
    //const uploadOptions = {icon: 'pi pi-fw pi-cloud-upload', iconOnly: true, className: 'custom-upload-btn p-button-success p-button-rounded p-button-outlined'};
    const cancelOptions = { icon: 'pi pi-fw pi-times', iconOnly: true, className: 'custom-cancel-btn p-button-danger p-button-rounded p-button-outlined' };

    return (
        <div >


            <h5>{title}</h5>
            <FileUpload ref={fileUploadRef} name="demo[]" url=""
                multiple={multiple}
                accept={accept}
                maxFileSize={maxFileSize}

                onUpload={onTemplateUpload}
                onSelect={onTemplateSelect}
                onError={onTemplateClear}
                onClear={onTemplateClear}
                headerTemplate={headerTemplate}
                itemTemplate={itemTemplate}
                emptyTemplate={emptyTemplate}
                chooseOptions={chooseOptions}
                uploadOptions={uploadOptions}
                cancelOptions={cancelOptions} />



        </div>
    )
}
