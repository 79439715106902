import { EditLinkProps, LinkItemData } from '../../../Interface';
import { useContext, useEffect, useState } from 'react';

import ButtonStyle from '../../../style/button/Button.module.css';
import LayoutStyle from '../../../style/layout/Layout.module.css';
import { LinkContextAction } from '../../../context/LinkContext';
import LinkItems from '../../Element/LinkItems';

export default function EditLink(props: EditLinkProps) {

    const setLinkInfo=useContext(LinkContextAction);
    const { datas,linkType="userLink" } = props
    const [numbers, setNumbers] = useState([1]);
    const [data, setData] = useState<LinkItemData[]>([]);

    useEffect(()=>{
        
        if(datas!==undefined){
            if(datas !==''){
                
                if(datas.team_tag !==null && datas.team_tag!=="" && datas.team_tag!==undefined){
                    let links=datas.team_tag.split(",");
                    let number=[];
                    let linkData=[];
                    for(let i=0;i<(links.length);i++){
                        number.push(i);
                        let link=links[i].split("::");
                        
                        linkData.push({index:i,type:link[3],title:link[1],link:link[2],site:link[5]})
                    }
                    setNumbers(number)
                    setData(linkData)
                }
            }
         

        }
    },[ datas])

    const changedItem = (dataChang: LinkItemData) => {
        let newData = data;
        if (!CheckExistDataInState(dataChang)) {
            newData.push(dataChang);
            setData(newData);
        } else {
            let index = GetIndex(dataChang);
            newData[index] = dataChang;
            setData(newData);
        }
        setLinkInfo(data);
       // changed(data);
    }

    const CheckExistDataInState = (dataChang: LinkItemData) => {

        if (data.length <= 0) {
            return false;
        }
        let indexes = GetIndex(dataChang);
        if (indexes < 0) {
            return false;
        }
        return true;
    }

    const GetIndex = (dataChang: LinkItemData) => {
        return data.findIndex((item) => item.index === dataChang.index)
    }


    const AddMoreItem = () => {
        let newNumbers = [];
        for (let i = 1; i <= numbers.length + 1; i++)
            newNumbers.push(i);

        setNumbers(newNumbers)
    }
    let Items = numbers.map((item,index) => {
        return (<>
            <LinkItems linkType={linkType} LinkItemDatas={data[index]??{title:"",link:"",type:"",site:""}} index={item} changed={changedItem} />
        </>)
    })

    return <>

        <div className={`${LayoutStyle.backBlackLight} ${LayoutStyle.cover} ${LayoutStyle.mt1}`}>
            {Items}
            <div className={`${LayoutStyle.mt1}`}>
                <button onClick={() => { AddMoreItem() }} className={`${ButtonStyle.btnBlueOuter}`} >Add More </button>
            </div>
        </div>
    </>
}