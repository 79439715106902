import { FormDatas, FormDependsDatas } from '../../context/FormContext';
import { useContext, useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";

import BoredCamps from "../Element/BoredCamp"
import Buttons from "../Element/ButtonLoader";
import ChooseDocUser from "../user/ChooseDocUser";
import ChooseProgramCombo from "../user/ChooseProgramCombo";
import LayoutStyle from '../../style/layout/Layout.module.css'
import { UpdateFormPermissionApi } from "../../api/Api";

export default function PermissionForm() {
    const { id } = useParams();
    const [loading, setLoading] = useState(false);
    const [info, setInfo] = useState<any>({
        form_users:"",
        form_user:"",
        form_programs:"",
        form_program:"",
       
    })
    const [title, setTitle] = useState("");
    const navigate = useNavigate();
    const formInfo = useContext(FormDatas)
    const datasDepends = useContext<any>(FormDependsDatas)


    const Changed = (value: string, type: string, JsonString = false) => {
        let newValue: any = info;
        if (JsonString)
            newValue[type] = JSON.stringify(value);
        else
            newValue[type] = value;

        setInfo({ ...newValue });

    }

    const SendData=()=>{
        info["idSearch"]=id
   
        setLoading(true);

        UpdateFormPermissionApi(info).then((response:  any) => {
            setLoading(false);
            if (response.status === 'true') {
                navigate("/Forms");
            }
        });
    }

    useEffect(() => {
        if (formInfo !== undefined) {
            if (formInfo.data.length > 0) {
                let datas = formInfo.data[0];
                setInfo(datas);
                setTitle(datas.title);
            }
        }

    }, [formInfo])
    
    useEffect(() => {
        if (datasDepends !== undefined) {
            if (datasDepends.status ==="true") {
               let user: any[]=[];
               let program: any[]=[];
               
               datasDepends.user.forEach((d:any) => {
                user.push(d.user_id);
               });
               datasDepends.program.forEach((d:any) => {
                program.push(d.program_id);
               });
               let newValue=info;
               newValue.form_users=user.toString();
               newValue.form_programs=program.toString()
               setInfo({...newValue})
            }
        }

    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [datasDepends])
    
    return <>
        <div className={`${LayoutStyle.container}`}>
            <div className={`${LayoutStyle.innerContainer}`}>
                <div className={`${LayoutStyle.backBlackLight} ${LayoutStyle.cover} ${LayoutStyle.mt1}`}>
                    <BoredCamps Title={`Permission ${title}`} linkHref='/Forms' linkTitle='Forms' />

                    <div className={`${LayoutStyle.mt1}`}>
                        <ChooseDocUser datas={info.form_users} changed={(data: any) => { Changed(data, "form_user", true) }} />
                    </div>
                    
                    <div className={`${LayoutStyle.mt1}`}>
                        <ChooseProgramCombo datas={info.form_programs} changed={(data: any) => { Changed(data, "form_program", true) }} />
                    </div>

                    <div className={` ${LayoutStyle.cover} ${LayoutStyle.mt1} ${LayoutStyle.mb1}`}>
                        <Buttons clicked={() => { SendData() }} loading={loading} title="Save Data" size='large' />
                    </div>
                </div>
            </div>
        </div>
    </>
}