import { UserAllNote, UserAllNoteActionGetData } from "../context/Notes";
import { useContext, useEffect, useState } from "react";

import GridServerSide from "./Element/GridServerSide";
import GridStyle from '../style/element/grid/Grid.module.css'
import LayoutStyle from '../style/layout/Layout.module.css'

export default function MyNotification() {

    const columns = [
        { field: 'id', type: "string", hideable: false, width: 120, },
        
        {
            field: 'Text', type: "string", filterable: false, width:550 , renderCell: (params: any) => {
                return (<div className={`${GridStyle.textTd}`}>{params.value}</div>);
            }
        },
      
     
    ];
    const [rows, setRows] = useState([]);
    const userAllNote:any = useContext(UserAllNote)
    const GetUserAllNote = useContext(UserAllNoteActionGetData)
  
    const [isLoading, setIsLoading] = useState(false);
    const [start, setStart] = useState(0);
    const [number, setNumber] = useState(25);

    const [rowCountState, setRowCountState] = useState(0);

    const GetData = (starts: number = start, numbers: number = number) => { GetUserAllNote({ start: starts, number: numbers }); }

    const ChangePageSize = (param: any) => {
        setNumber(param.pageSize);
        setStart(0);
        GetData(0, param.pageSize);
    }

    const ChangePageNumber = (param: any) => {
        setStart(number * param.page);
        GetData(number * param.page);
    }

    useEffect(() => {
        if (userAllNote !== undefined) {
            setIsLoading(false)
            setRowCountState(userAllNote?.total);
            if (userAllNote.data) {
                let newRows = userAllNote.data.map((item: any) => {
                    return {
                        id: item.id,
                        Text: item.text,
                    }
                })
                setRows(newRows);
            }
        }
    }, [userAllNote])

    useEffect(()=>{
        GetData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
    },[])
 
    return <>
        <div className={`${LayoutStyle.container}`}>
            <div className={`${LayoutStyle.innerContainer}`}>

                <div className={`${LayoutStyle.backBlackLight} ${LayoutStyle.cover}`}>

                    <GridServerSide
                        rowCounts={rowCountState}
                        columns={columns}
                        rows={rows}
                        isLoading={isLoading}
                        ChangePageNumber={ChangePageNumber}
                        ChangePageSize={ChangePageSize}
                    />
                
                </div>
            </div>
        </div>
    </>
}