import { FormDatasResponseAction, FormUserDatasResponse, FormUserDatasResponseAction, MyFormDatasAction } from "../../context/FormContext";
import { useContext, useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";

import { ApiUrl } from "../../config";
import { Avatar } from "@mui/material";
import BoredCamps from "../Element/BoredCamp";
import DropDownBtn from "../Element/DropDownBtn";
import GridServerSide from "../Element/GridServerSide";
import LayoutStyle from '../../style/layout/Layout.module.css';
import RemoveRedEyeIcon from '@mui/icons-material/RemoveRedEye';
import SettingsIcon from '@mui/icons-material/Settings';

export default function  UserFormResponse(){

    const param=useParams();
    const userId=param.id;

    const GetMyFormData = useContext(MyFormDatasAction)
    const GetFormDataResponse = useContext(FormDatasResponseAction)

    const navigate = useNavigate();
    const columns = [
        {
            field: 'Action', type: "date", filterable: false, sortable: false, renderCell: (params: any) => {
                let items = [];
                items.push({ click: () => { GoToViewPage(params.value) }, title: <><RemoveRedEyeIcon/>View</>, close: true })
                return (
                    <>
                        <DropDownBtn
                            title={<><strong><SettingsIcon /></strong></>}
                            items={items}
                        />
                    </>
                );
            },
        },
        { field: 'id', type: "string", hideable: false, width: 120, },
        {
            field: 'Name', type: "string", width: 250, renderCell: (params: any) => {
                let data=params.value.split(",");
                return (<div className={`${LayoutStyle.flexWarp}`}>
                    <div><Avatar src={`${ApiUrl}${data[1]}/small/${data[2]}`}/></div> <div> {data[0]}</div>
                </div>);
     
            }
        },  
    
    ];
    const [rows, setRows] = useState([]);
    const datasResponseUser = useContext(FormUserDatasResponse)
    const GetFormDataUserResponse = useContext(FormUserDatasResponseAction)
    
    const [isLoading, setIsLoading] = useState(false);
    const [start, setStart] = useState(0);
    const [number, setNumber] = useState(25);
    const [rowCountState, setRowCountState] = useState(0);


    const GoToViewPage = (id: string | number) => {
        GetMyFormData({ idSearch: id });
        GetFormDataResponse({ idSearch: id });
        navigate(`/ViewFormResponse`);
    }
    


    const GetData = (starts: number=start,numbers: number=number) => { GetFormDataUserResponse({ start:starts, number:numbers,idSearch:userId }); }
   
    const ChangePageSize = (param: any) => {
        setNumber(param.pageSize);
        setStart(0);
        GetData(0,param.pageSize);
    }

    const ChangePageNumber = (param: any) => {
        setStart(number * param.page);
        GetData(number * param.page);
    }
    

    useEffect(() => {
        setIsLoading(true)
        if (datasResponseUser !== undefined) {
            setIsLoading(false)
            setRowCountState(datasResponseUser?.total);
            if (datasResponseUser.data) {
                let newRows = datasResponseUser.data.map((item: any) => {
                    return {
                        id: item.id,
                        Name:`${item.fname} ${item.lname},${item.file_url},${item.file_name}`,
                        Action: item.form_id,
                    }
                })
                setRows(newRows);
            }
        }
    }, [datasResponseUser])
    useEffect(()=>{
        GetData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
    },[])
    return <>
      <div className={`${LayoutStyle.container}`}>
            <div className={`${LayoutStyle.innerContainer}`}>
                <div className={`${LayoutStyle.backBlackLight} ${LayoutStyle.cover}`}>
                <BoredCamps Title={`User Forms (userId:${userId})`} linkHref='/UserManager' linkTitle='User Manager' />

        <GridServerSide
            rowCounts={rowCountState}
            columns={columns}
            rows={rows}
            isLoading={isLoading}
            ChangePageNumber={ChangePageNumber}
            ChangePageSize={ChangePageSize}
        />
        </div>
        </div>
        </div>
 
    </>
}