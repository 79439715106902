import { useContext, useEffect, useState } from 'react';

import CityItems from '../../Element/CityItems';
import DatePick from '../../Element/DatePick';
import { EditInfoProps } from '../../../Interface';
import Inputs from '../../Element/Input';
import LayoutStyle from '../../../style/layout/Layout.module.css';
import Password from '../../Element/Password';
import Proof from '../../Element/Proof';
import TextArea from '../../Element/TextArea';
import TimeZone from '../../Element/TimeZone';
import { UsersData } from '../../../context/Users';

export default function EditInfo(props: EditInfoProps) {
    const { changed, data } = props
    const [fName, setFName] = useState("");
    const [lName, setLName] = useState("");
    const [displayName, setDisplayName] = useState("");
    const [email, setEmail] = useState("");
    const [phone, setPhone] = useState("");
    const [mobile, setMobile] = useState("");
    const [fax, setFax] = useState("");
    const [gender, setGender] = useState("");
    const [birth_date, setBirth_date] = useState("");
    const [country, setCountry] = useState("");
    const [province, setProvince] = useState("");
    const [city, setCity] = useState("");
    const [password, setPassword] = useState("");
    const [about, setAbout] = useState("");
    const [timezone, setTimezone] = useState("");
    const usersData = useContext(UsersData)
    const [datas, setDatas] = useState<any>({
        about: "",
        display_name: "",
        gender: "",
        phone: "",
        mobile: "",
        fax: "",
        email: "",
        file_name: "",
        file_url: "",
        birth_date: "",
        country: "",
        province: "",
        city: "",
        fname: "",
        lname: "",
        team_tag: "",
        timezone: "",
        site:"",
    })

    useEffect(() => {
        setFName(data.fname);
        setLName(data.lname);
        setEmail(data.email);
        setGender(data.gender);
        setPhone(data.phone);
        setMobile(data.mobile);
        setFax(data.fax);
        setBirth_date(data.birth_date);
        setDisplayName(data.display_name);
        setAbout(data.about);
        setCountry(data.country);
        setProvince(data.province);
        setCity(data.city);
        setTimezone(data.timezone ?? "");

        setDatas(data);
    }, [data])

    useEffect(() => {

        if (usersData !== undefined) {
            if (usersData.data) {

                if (usersData.data.length > 0) {
                    let data = usersData.data[0];
                    setFName(data.fname);
                    setLName(data.lname);
                    setEmail(data.email);
                    setGender(data.gender);
                    setBirth_date(data.birth_date);
                    setCountry(data.country);
                    setProvince(data.province);
                    setCity(data.city);
                    setPhone(data.phone);
                    setMobile(data.mobile);
                    setFax(data.fax);
                    setDisplayName(data.display_name);
                    setAbout(data.about);
                    setTimezone(data.timezone !== 'null' ? data.timezone : "");
                    setDatas(data);
                }

            }
        }
    }, [usersData])

    const ChangeData = (value: string, type: string) => {

     
        if (type === "fName") {
            setFName(value);
            changed({ fName: value, lName, displayName, email, about, timezone, password, gender, phone, mobile, fax, country, province, city,birth_date });
        }
        if (type === "lName") {
            setLName(value)
            changed({ fName, lName: value, displayName, email, about, timezone, password, gender, phone, mobile, fax, country, province, city,birth_date });
        }
        if (type === "displayName") {
            setDisplayName(value);
            changed({ fName, lName, displayName: value, email, about, timezone, password, gender, phone, mobile, fax, country, province, city,birth_date });
        }
        if (type === "email") {
            setEmail(value);
            changed({ fName, lName, displayName, email: value, about, timezone, password, gender, phone, mobile, fax, country, province, city,birth_date });
        }
        if (type === "gender") {
            setGender(value);
            changed({ fName, lName, displayName, email, about, timezone, password, gender: value, phone, mobile, fax, country, province, city,birth_date });
        }
        if (type === "about") {
            setAbout(value)
            changed({ fName, lName, displayName, email, about: value, timezone, password, gender, phone, mobile, fax, country, province, city,birth_date });
        }
        if (type === "timezone") {
            setTimezone(value)
            changed({ fName, lName, displayName, email, about, timezone: value, password, gender, phone, mobile, fax, country, province, city,birth_date });
        }

        if (type === "password") {
            setPassword(value)
            changed({ fName, lName, displayName, email, about, timezone, password: value, gender, phone, mobile, fax, country, province, city,birth_date });
        }
        if (type === "phone") {
            setPhone(value);
            changed({ fName, lName, displayName, email, about, timezone, password, gender, phone: value, mobile, fax, country, province, city,birth_date });
        }
        if (type === "mobile") {
            setMobile(value);
            changed({ fName, lName, displayName, email, about, timezone, password, gender, phone, mobile: value, fax, country, province, city,birth_date });
        }
        if (type === "fax") {
            setFax(value);
            changed({ fName, lName, displayName, email, about, timezone, password, gender, phone, mobile, fax: value,country, province, city,birth_date });
        }
        if (type === "country") {
            setCountry(value);
            changed({ fName, lName, displayName, email, about, timezone, password, gender, phone, mobile, fax, country: value, province, city,birth_date });
        }
        if (type === "province") {
            setProvince(value);
            changed({ fName, lName, displayName, email, about, timezone, password, gender, phone, mobile, fax, country, province: value, city,birth_date });
        }
        if (type === "city") {
            setCity(value);
            changed({ fName, lName, displayName, email, about, timezone, password, gender, phone, mobile, fax, country, province, city: value,birth_date });
        }
        
        if (type === "birth_date") {
            setBirth_date(value);
            changed({ fName, lName, displayName, email, about, timezone, password, gender, phone, mobile, fax, country, province, city,birth_date:value });
        }
    }

    return <>

        <div className={`${LayoutStyle.backBlackLight} ${LayoutStyle.cover} ${LayoutStyle.mt1}`}>
            <div className={`${LayoutStyle.mt2}`}>
                <Inputs
                    id='fName'
                    name='fName'
                    value={fName}
                    label="First name"
                    changed={(event) => { ChangeData(event.target.value, "fName"); }}
                />
            </div>
            <div className={`${LayoutStyle.mt2}`}>
                <Inputs
                    id='lName'
                    name='lName'
                    value={lName}
                    label="last name"
                    changed={(event) => { ChangeData(event.target.value, "lName"); }}
                />
            </div>
            <div className={`${LayoutStyle.mt2}`}>
                <Inputs
                    id='displayName'
                    name='displayName'
                    value={displayName}
                    label="Display name"
                    changed={(event) => { ChangeData(event.target.value, "displayName"); }}
                />
            </div>
            <div className={`${LayoutStyle.mt2}`}>
                <Inputs
                    id='email'
                    name='email'
                    value={email}
                    label="Email"
                    changed={(event) => { ChangeData(event.target.value, "email"); }}
                />
            </div>

            <div className={`${LayoutStyle.mt2}`}>
                <TextArea
                    id='about'
                    name='about'
                    value={about??""}
                    label="About"
                    changed={(event) => { ChangeData(event.target.value, "about"); }}
                />
            </div>

            <div className={`${LayoutStyle.mt2}`}>
                <p>Timezone:</p>
                <TimeZone dataUser={datas} changed={(value) => { ChangeData(value, "timezone"); }} />
            </div>
            <div className={`${LayoutStyle.mt2}`}>
                <p>Gender:</p>
                <Proof  type='gender' isMulti={false} defaultValue={data.gender} change={(value) => { ChangeData(value, "gender"); }} />
            </div>
            <div className={`${LayoutStyle.mt2}`}>
                <Inputs
                    id='phone'
                    name='phone'
                    value={phone??""}
                    label="Phone"
                    changed={(event) => { ChangeData(event.target.value, "phone"); }}
                />
            </div>
            <div className={`${LayoutStyle.mt2}`}>
                <Inputs
                    id='mobile'
                    name='mobile'
                    value={mobile??""}
                    label="Mobile"
                    changed={(event) => { ChangeData(event.target.value, "mobile"); }}
                />
            </div>
            <div className={`${LayoutStyle.mt2}`}>
                <Inputs
                    id='fax'
                    name='fax'
                    value={fax??""}
                    label="Fax"
                    changed={(event) => { ChangeData(event.target.value, "fax"); }}
                />
            </div>
            <div className={`${LayoutStyle.mt2}`}>
                <DatePick label="Birth Date" Changed={(value, newValue) => { ChangeData(value, "birth_date") }} DefaultValue={birth_date} />
            </div>
            <div className={`${LayoutStyle.mt2}`}>
                <CityItems Change={(data: any) => {

                    ChangeData(data.country_id, "country");
                    ChangeData(data.province_id, "province");
                    ChangeData(data.city_id, "city");

                }
                } data={{ country_id: country, province_id: province, city_id: city }} />
            </div>
            <div className={`${LayoutStyle.mt2}`}>
                <Password
                    id='passwords'
                    name='passwords'
                    value={password}
                    label="Password"
                    changed={(event) => { ChangeData(event.target.value, "password"); }}
                />
            </div>

        </div>

    </>
}