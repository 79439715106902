import { LangDataAction, LangDefaultDataAction, ParamAction } from '../../context/LanguageContext';
import { Link, useNavigate } from 'react-router-dom';

import ButtonStyle from '../../style/button/Button.module.css';
import EditIcon from '@mui/icons-material/Edit';
import LayoutStyle from '../../style/layout/Layout.module.css';
import ProfileBanner from './ProfileBanner';
import ProfileImage from './ProfileImage';
import { UserInfo } from '../../Interface';
import { useContext } from 'react';

const Info = (props: UserInfo) => {
    const { data } = props;
    const navigate = useNavigate();
    const SetParams=useContext(ParamAction);
    const GetDataLangInfo=useContext(LangDataAction);
    const setLangDefaultData=useContext(LangDefaultDataAction);

    const GoToLanguage = () => {
        SetParams([
            {table_name:"user",table_id:data?.id!,field_name:'fname'},
            {table_name:"user",table_id:data?.id!,field_name:'lname'},
            {table_name:"user",table_id:data?.id!,field_name:'about'},
        ])
        setLangDefaultData(data)
        GetDataLangInfo({table_name:"user",table_id:data?.id!});
        navigate(`/language/`);
    }

    return <>
        <div className={` ${LayoutStyle.cover}`}>
            <div className={`${LayoutStyle.flexWarp}`}>
                <Link className={`${ButtonStyle.btnBlue}`} to={"/EditMyProfile"}>Edit your profile <EditIcon className={LayoutStyle.fontSm} /></Link>
                <button className={`${ButtonStyle.btnBlue}`} onClick={()=>GoToLanguage()} >Set profile with other language <EditIcon className={LayoutStyle.fontSm} /></button>
            </div>
            <ProfileImage data={data} />
            <ProfileBanner data={data} />
            <div>
                <span className={`${LayoutStyle.spanName}`}>Name : </span><strong>{`${data?.fname} ${data?.lname}`}</strong>
            </div>
            <div>
                <span className={`${LayoutStyle.spanName}`}>Display name : </span><strong>{data?.display_name}</strong>
            </div>
            <div>
                <span className={`${LayoutStyle.spanName}`}>Email : </span><strong>{data?.email}</strong>
            </div>
          

        </div>
    </>
}


export default Info;