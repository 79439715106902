/* eslint-disable react-hooks/exhaustive-deps */

import { UsersAction, UsersActionGetData } from '../../context/Users';
import { useContext, useEffect, useState } from 'react';

import AddIcon from '@mui/icons-material/Add';
import ButtonStyle from '../../style/button/Button.module.css';
import Grids from './Grids';
import LayoutStyle from '../../style/layout/Layout.module.css';
import { UserOnlinePermissionContextData } from '../../context/UserPermissionContext';
import { useNavigate } from "react-router-dom";

export default function UserManager() {
    const GetUsersData = useContext(UsersActionGetData)
    const setUsersData=useContext(UsersAction);
    const [permission, setPermission] = useState({ list: [], menu: [], module: [] });
    const myPermissionInfo = useContext(UserOnlinePermissionContextData)
    const navigate=useNavigate();
    useEffect(()=>{
        GetUsersData({start:0,number:25});
    },[])

    const GoToAddUser=()=>{
        setUsersData([]);
        navigate(`/AddUser`);
    }
    
    useEffect(() => {
        if (myPermissionInfo !== undefined) {
            setPermission(myPermissionInfo)
        }
    }, [myPermissionInfo])

    return <>
        <div className={`${LayoutStyle.container}`}>
            <div className={`${LayoutStyle.innerContainer}`}>
                <div className={`${LayoutStyle.backBlackLight} ${LayoutStyle.cover}`}>

                    {permission.module.findIndex((data: any) => data.action === 'add' && data.menu === "userManager") >= 0 &&<button className={`${ButtonStyle.btnBlueOuterLink} `} onClick={()=>{GoToAddUser()}}>Add User <AddIcon className={LayoutStyle.fontSm} /></button>}

                    <Grids/>
                  
                </div>
            </div>
        </div>
    </>
}