import InputStyle from '../../style/element/input/Inputs.module.css';
import { InputType } from '../../Interface';

const Password = (props: InputType) => {
    const { id, name, value = "", placeholder = "&nbsp;", label, changed } = props

    return <>
        <div className={`${InputStyle.inputsDiv}`}>
            <label htmlFor={`${InputStyle.inputsDiv}`} >
                <input
                    type="password"
                    id={id}
                    name={name}
                    className={`${InputStyle.inputs}`}
                    placeholder={placeholder}
                    value={value}
                    onChange={(event)=>{changed(event)}}
             
                     />
                <span className={`${InputStyle.label}`}>{label}</span>
            </label>
        </div>
    </>
}

export default Password;