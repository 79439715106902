import { useEffect, useState } from 'react'

import Inputs from '../../Element/Input'
import LayoutStyle from '../../../style/layout/Layout.module.css'
import TextArea from '../../Element/TextArea'

export default function Items(props:any) {

    const {Changed,datas}=props

    const [params,setParams]=useState({title:"",link:"",description:""})

    const Change=(value="",type="")=>{
        let newParam:any=params;
        newParam[type]=value;
        setParams({...newParam});
        Changed(newParam);
    }

    useEffect(()=>{
        if(datas !==undefined)
        setParams(datas);
    },[datas])

    return <>
        <div className={`${LayoutStyle.backBlackLight} ${LayoutStyle.cover}`}>
            <div className={`${LayoutStyle.flexGrow1}`}>
                <Inputs
                    label='Title'
                    name="title"
                    id="title"
                    value={params.title}
                    changed={(event) => Change(event.target.value,"title")}
                />
            </div>
           
            <div className={`${LayoutStyle.flexGrow1}`}>
                <Inputs
                    label='Link'
                    name="link"
                    id="link"
                    value={params.link}
                    changed={(event) => Change(event.target.value,"link")}
                />
            </div>

            <div className={`${LayoutStyle.flexGrow1}`}>
                <TextArea
                    label='Description'
                    name="description"
                    id="description"
                    value={params.description}
                    changed={(event) => Change(event.target.value,"description")}
                />
            </div>
          
        </div>
    </>
}