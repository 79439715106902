/* eslint-disable react-hooks/exhaustive-deps */

import { useContext, useEffect, useState } from 'react';

import ChooseNoteUser from './ChooseNoteUser';
import { GetNotesData } from '../booking/interviewBooking/CreateDataForEdit';
import Inputs from '../Element/Input';
import LayoutStyle from '../../style/layout/Layout.module.css';
import TextArea from '../Element/TextArea';
import { UploadFile } from '../Element/UploadFile';
import { UserSearchParamAction } from '../../context/UserSearchParam';

export default function NoteItem(props:any) {

    const {changed,Upload=true,data=undefined}=props
    const setParams = useContext(UserSearchParamAction);

    const [info, setInfo] = useState({
        title: "",
        description: "",
        note_user: "",
        user_notes_user:"",
        img:[],
    })

    const Changed = (value: string, type: string, JsonString = false) => {

        let newValue: any = info;
        if (JsonString)
            newValue[type] = JSON.stringify(value);
        else
            newValue[type] = value;

        setInfo({ ...newValue });
         changed(newValue)
    }

    useEffect(()=>{
        if(data!==undefined){
            let itemData:any = GetNotesData(data.user_notes_user);
            data.note_user=JSON.stringify(itemData.data??"");
            setInfo(data)
        }

    },[data])

    useEffect(()=>{
        setParams({ })
    },[])

    return <>
        <div className={`${LayoutStyle.backBlackLight} ${LayoutStyle.cover} ${LayoutStyle.mt1}`}>

            <div className={`${LayoutStyle.mt1}`}>
                <Inputs
                    id='title'
                    name='title'
                    value={info.title}
                    label="Title"
                    changed={(event) => { Changed(event.target.value, "title"); }}
                />
            </div>

            <div className={`${LayoutStyle.mt1}`}>
                <TextArea
                    id='description'
                    name='description'
                    value={info.description}
                    label="Details"
                    changed={(event) => { Changed(event.target.value, "description"); }}
                />
            </div>
            <div className={`${LayoutStyle.mt1}`}>
                <ChooseNoteUser datas={info.user_notes_user} changed={(data:any)=>{Changed(data,"note_user",true)}}/>
            </div>
           {Upload && <div className={`${LayoutStyle.mt1}`}>
                <UploadFile multiple={true} title="Add Files" changed={(files)=>Changed(files,'img')}/>
            </div>}
        </div>
    </>
}