import { FormDatasResponseAction, FormItemDatasAction, MyFormDatas, MyFormDatasAction } from "../../context/FormContext";
import { useContext, useEffect, useState } from "react";

import DriveFileRenameOutlineIcon from '@mui/icons-material/DriveFileRenameOutline';
import DropDownBtn from "../Element/DropDownBtn";
import GridServerSide from "../Element/GridServerSide";
import GridStyle from '../../style/element/grid/Grid.module.css'
import LayoutStyle from '../../style/layout/Layout.module.css';
import SettingsIcon from '@mui/icons-material/Settings';
import { useNavigate } from "react-router-dom";

export default function MyForms() {
    const navigate = useNavigate();
    const GetMyFormData = useContext(MyFormDatasAction)
    const GetFormDataResponse = useContext(FormDatasResponseAction)
    const GetFormItemData=useContext(FormItemDatasAction)
    const columns = [
        {
            field: 'Action', type: "date", filterable: false, sortable: false, renderCell: (params: any) => {
                let items = [];
              
                items.push({ click: () => { GoToResponsePage(params.value) }, title: <><DriveFileRenameOutlineIcon/>Response</>, close: true })
                
                return (
                    <>
                        <DropDownBtn
                            title={<><strong><SettingsIcon /></strong></>}
                            items={items}
                        />
                    </>
                );
            },
        },
        { field: 'id', type: "string", hideable: false, width: 120, },
        {
            field: 'Title', type: "string", width: 250, renderCell: (params: any) => {
                return (<div className={`${GridStyle.textTd}`}>{params.value}</div>);
     
            }
        },
 
        {
            field: 'Description', type: "string", filterable: false, width: 200, renderCell: (params: any) => {
                return (<div className={`${GridStyle.textTd}`}>{params.value}</div>);
            }
        },
  
    
    ];
    const [rows, setRows] = useState([]);
    const myDatas = useContext(MyFormDatas);
    const [isLoading, setIsLoading] = useState(false);
    const [start, setStart] = useState(0);
    const [number, setNumber] = useState(25);



    const [rowCountState, setRowCountState] = useState(0);

    const GoToResponsePage=(id: string | number) => {
        GetMyFormData({ idSearch: id });
        GetFormDataResponse({ idSearch: id });
        GetFormItemData({idSearch:id})

        navigate(`/ResponseToForm/${id}`);
    }

    const GetData = (starts: number = start, numbers: number = number) => { GetMyFormData({ start: starts, number: numbers }); }

    const ChangePageSize = (param: any) => {
        setNumber(param.pageSize);
        setStart(0);
        GetData(0, param.pageSize);
    }

    const ChangePageNumber = (param: any) => {
        setStart(number * param.page);
        GetData(number * param.page);
    }

    useEffect(() => {
        GetMyFormData({})
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    useEffect(() => {
        if (myDatas !== undefined) {
            setIsLoading(false)
            setRowCountState(myDatas?.total);
            if (myDatas.data) {
                let newRows = myDatas.data.map((item: any) => {
                    return {
                        id: item.id,
                        Title:`${item.title}`,
                        Description: item.description,
                        Action: item.id,
                    }
                })

                setRows(newRows);
            }
        }
    }, [myDatas]);

    return <>
        <div className={`${LayoutStyle.container}`}>
            <div className={`${LayoutStyle.innerContainer}`}>
                <div className={`${LayoutStyle.backBlackLight} ${LayoutStyle.cover}`}>
                    <GridServerSide
                        rowCounts={rowCountState}
                        columns={columns}
                        rows={rows}
                        isLoading={isLoading}
                        ChangePageNumber={ChangePageNumber}
                        ChangePageSize={ChangePageSize}
                    />
                </div>
            </div>
        </div>
    </>
}
