import { Link, useNavigate, useParams } from "react-router-dom";
import { useContext, useEffect, useState } from "react";

import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import Buttons from '../Element/ButtonLoader';
import LayoutStyle from '../../style/layout/Layout.module.css';
import { UpdateWhiteApi } from "../../api/Api";
import WhiteItem from "./WhiteItem";
import { WhitePropsData } from "../../context/WhiteBoard";

export default function EditWhiteBoard() {
    const [loading, setLoading] = useState(false);
    const [info, setInfo] = useState({} as any);
    const [data, setData] = useState([]);
    const [title, setTitle] = useState("");
    const { id } = useParams();

    const navigate = useNavigate();
    const whiteData = useContext(WhitePropsData)


    useEffect(() => {
        if (whiteData.data !== undefined) {

            if (whiteData.data.length > 0) {
                let datas = whiteData.data[0];
                setData(datas);
                setInfo(datas);
                setTitle(datas.title);
            }
        }

    }, [whiteData])

    const SendData = () => {
        info["idSearch"] = id

        setLoading(true);
        UpdateWhiteApi(info).then((response: any) => {
            setLoading(false);
            if (response?.status === "true") {
                navigate("/WhiteBoard");
            }
        })

    }

    return <>
        <div className={`${LayoutStyle.container}`}>
            <div className={`${LayoutStyle.innerContainer}`}>
                <div className={`${LayoutStyle.flex}`}>
                    <span><Link className={`${LayoutStyle.spanName}`} to={"/WhiteBoard"}>My WhiteBoard</Link></span>
                    <span> <ArrowForwardIosIcon className={`${LayoutStyle.fontSm}`} /></span>
                    <span>Edit {title}  </span>
                </div>
                <WhiteItem data={data} Upload={false} changed={(data: any) => setInfo(data)} />
                <div className={` ${LayoutStyle.cover} ${LayoutStyle.mt1} ${LayoutStyle.mb1}`}>
                    <Buttons clicked={() => { SendData() }} loading={loading} title="Save Data" size='large' />
                </div>
            </div>
        </div>
    </>;

}