import ButtonStyle from '../../../style/button/Button.module.css'
import Buttons from "../../Element/ButtonLoader";
import Inputs from "../../Element/Input";
import LayoutStyle from '../../../style/layout/Layout.module.css'
import ProofTypes from "./ProofTypes";
import Sites from "../../Element/Site";
import { useState } from "react";

interface ComponentProps {
    ChangeForSearch: Function
}
interface ParamProps {
    idSearch: string | "",
    type: string | "",
    site: string | "",
    title: string | ""
}
export default function SearchProof(props: ComponentProps) {

    const { ChangeForSearch } = props;
    const [param, setParam] = useState<ParamProps>({
        idSearch: "",
        type: "",
        site: "",
        title: "",


    });

    const Change = (value: string, name: string) => {
        let newParams: any = param;
        newParams[name] = value;
        setParam({ ...newParams });
    }

    const Cancel = () => {
        let newParams: any = {
            idSearch: "",
            type: "",
            site: "",
            title: "",
        }
        setParam({ ...newParams });
        ChangeForSearch({})
    }

    return <>
        <div className={`${LayoutStyle.backBlackLight} ${LayoutStyle.cover} ${LayoutStyle.flexWarp}`}>

            <div className={`${LayoutStyle.flexGrow1}`}>
                <Inputs
                    label='Id'
                    name="id"
                    id="id"
                    value={param.idSearch}
                    changed={(event) => Change(event.target.value, "idSearch")}
                />
            </div>
            <div className={`${LayoutStyle.flexGrow1}`}>
                <Sites isMulti={false} change={(value) => { Change(value.value, "site"); }} defaultValue={param.site} />

            </div>
            <div className={`${LayoutStyle.flexGrow1}`}>
                <Inputs
                    label='Title'
                    name="title"
                    id="title"
                    value={param.title}
                    changed={(event) => Change(event.target.value, "title")}
                />
            </div>
            <div className={`${LayoutStyle.flexGrow1}`}>
                <ProofTypes value={param.type} changed={(value: any) => { Change(value, "type") }} />
            </div>
        </div>

        <div className={`${LayoutStyle.backBlackLight} ${LayoutStyle.cover} ${LayoutStyle.flexWarp}`}>
            <Buttons loading={false} clicked={() => { ChangeForSearch(param) }} title="Search" size='small' />
            <Buttons loading={false} clicked={ ()=>Cancel() } color="secondary" title="Cancel" size='small' />
        </div>
    </>
}