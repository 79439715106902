import { useContext, useEffect, useState } from "react";

import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import Buttons from "../Element/ButtonLoader";
import Gallery from "./Gallery";
import Image from "./Image";
import LayoutStyle from '../../style/layout/Layout.module.css';
import { Link } from "react-router-dom";
import { ProgramContextData } from "../../context/ProgramContext";

export default function EditImageGallery(){
    const programInfo=useContext(ProgramContextData);
    const [data, setData] = useState([]);
    const [title,setTitle]=useState("");
    const [loading, setLoading] = useState(false);

    useEffect(() => {
        if(programInfo !==undefined){
            if (programInfo.data.length > 0) {
                let datas=programInfo.data[0];
                setData(datas);
                setTitle(datas.title);
            }
        }
        
    }, [programInfo])

    const SendData=()=>{

    }
    
    return <>
        <div className={`${LayoutStyle.container}`}>
            <div className={`${LayoutStyle.innerContainer}`}>
                <div className={`${LayoutStyle.flex}`}>
                    <span><Link className={`${LayoutStyle.spanName}`} to={"/Programs"}>Programs</Link></span>
                    <span> <ArrowForwardIosIcon className={`${LayoutStyle.fontSm}`} /></span>
                    <span>Edit Program Image && Gallery ({title})</span>
                </div>
                <Image data={data}/>
                <Gallery data={data}/>

                <div className={` ${LayoutStyle.cover} ${LayoutStyle.mt1} ${LayoutStyle.mb1}`}>
                    <Buttons clicked={() => { SendData() }} loading={loading} title="Save Data" size='large' />
                </div>
            </div>
        </div>
        </>
}