import { LangDataAction, LangDefaultDataAction, ParamAction } from "../../context/LanguageContext";
import { Link, useNavigate } from "react-router-dom";
import { ProofContextAction, ProofContextData } from "../../context/ProofContext";
import { useContext, useEffect, useState } from "react";

import AddIcon from "@mui/icons-material/Add";
import ButtonStyle from '../../style/button/Button.module.css'
import ClosedCaptionOffIcon from '@mui/icons-material/ClosedCaptionOff';
import ConfirmRejectDialog from "../Element/AgreeDialog";
import { CreateParamSearchTable } from "../../function/Common";
import DeleteIcon from '@mui/icons-material/Delete';
import { DialogAction } from "../../context/Dialog";
import DropDownBtn from "../Element/DropDownBtn";
import EditIcon from '@mui/icons-material/Edit';
import GridServerSide from "../Element/GridServerSide";
import GridStyle from '../../style/element/grid/Grid.module.css'
import LayoutStyle from '../../style/layout/Layout.module.css'
import { RemoveProofApi } from "../../api/Api";
import SearchProof from "./proof/SearchProof";
import SettingsIcon from '@mui/icons-material/Settings';
import { UserOnlinePermissionContextData } from "../../context/UserPermissionContext";

export default function Proofs() {
    const [permission, setPermission] = useState({ list: [], menu: [], module: [] });
    const myPermissionInfo = useContext(UserOnlinePermissionContextData);
    const setLangDefaultData = useContext(LangDefaultDataAction);
    const GetDataLangInfo = useContext(LangDataAction);
    const SetParams = useContext(ParamAction);

    const navigate = useNavigate()
    const columns = [
        {
            field: 'Action', type: "date", filterable: false, sortable: false, renderCell: (params: any) => {
                let items = [];
                if (permission.module.findIndex((data: any) => data.action === 'remove' && data.menu === "proof") >= 0) {
                    items.push({ click: () => { Remove(params.value.id) }, title: <><DeleteIcon />Remove</>, close: true })
                }
                if (permission.module.findIndex((data: any) => data.action === 'edit' && data.menu === "proof") >= 0) {
                    items.push({ click: () => { GoToEditPage(params.value.id) }, title: <><EditIcon />Edit</>, close: true })
                }
                items.push({ click: () => { GoToLanguage(params.value) }, title: <><ClosedCaptionOffIcon />Set Language</>, close: true })

                return (
                    <>
                        <DropDownBtn
                            title={<><strong><SettingsIcon /></strong></>}
                            items={items}
                        />
                    </>
                );
            },
        },

        { field: 'id', type: "string", hideable: false, width: 120, },

        {
            field: 'Title', type: "string", filterable: true, width: 200, renderCell: (params: any) => {
                return (<div className={`${GridStyle.textTd}`}>{params.value}</div>);
            }
        },
        {
            field: 'Type', type: "string", filterable: true, width: 150, renderCell: (params: any) => {
                return (<div className={`${GridStyle.textTd}`}>{params.value}</div>);
            }
        },
        {
            field: 'Site', type: "string", filterable: true, width: 150, renderCell: (params: any) => {
                return (<div className={`${GridStyle.textTd}`}>{params.value}</div>);
            }
        },
        {
            field: 'Order', type: "string", filterable: false, width: 100, renderCell: (params: any) => {
                return (<div className={`${GridStyle.textTd}`}>{params.value}</div>);
            }
        },

    ];
    const [rows, setRows] = useState([]);
    const proofInfo: any = useContext(ProofContextData)
    const GetProofDataApi = useContext(ProofContextAction)
    const [id, setId] = useState(0);
    const [loading, setLoading] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [start, setStart] = useState(0);
    const [number, setNumber] = useState(25);


    const setDialogData = useContext(DialogAction);
    const [rowCountState, setRowCountState] = useState(0);
    const Remove = (id: any) => { setId(id); setDialogData({ open: true, id: "InterviewBooking" }) }
    const RemoveAvailability = () => {
        setLoading(true);
        RemoveProofApi({ idSearch: id }).then(() => {
            setLoading(false);
            setDialogData({ open: false, id: "InterviewBooking" });
            GetData();
        })
    }

    const GoToEditPage = (id: string | number) => {
        GetProofDataApi({ idSearch: id });
        navigate(`/EditProof/${id}`);
    }

    const GoToLanguage = (item: any) => {
        SetParams([
            { table_name: "proof", table_id: item.id, field_name: 'title' },

        ])
        setLangDefaultData(item)
        GetDataLangInfo({ table_name: "proof", table_id: id });
        navigate(`/language/`);
    }
    
    const GetData = (starts: number = start, numbers: number = number,param={}) => {
        let params={
            start: starts,
            number: numbers,
            order: "proof.id",
            order_type: "desc",
        };
        params={...param,...params};
        GetProofDataApi(params);
    }

    const ChangePageSize = (param: any) => {
        setNumber(param.pageSize);
        setStart(0);
        GetData(0, param.pageSize);
    }

    const ChangePageNumber = (param: any) => {
        setStart(number * param.page);
        GetData(number * param.page);
    }
    const ChangeFilter = (param: any) => {
        
        let params:any=CreateParamSearchTable(param)
        if( Object.entries(params).length > 0 ){
            var key = 'site'
            params['site_name'] = params[key]??""
            delete params[key];
            setIsLoading(true)
            GetData(start,number, params);
        }
    }

    const ChangeForSearch=(params:any)=>{
        setIsLoading(true)
        GetData(start,number, params);
    }

    useEffect(() => {
        setIsLoading(true)
        if (proofInfo !== undefined) {
            setIsLoading(false)
            setRowCountState(proofInfo?.total);
            if (proofInfo.data) {
                let newRows = proofInfo.data.map((item: any) => {
                    return {
                        id: item.id,
                        Title: `${item.title}`,
                        Type: item.type,
                        Order: item.order,
                        Site: item.name,
                        Action: item
                    }
                })

                setRows(newRows);
            }
        }
    }, [proofInfo])

    useEffect(() => {
        GetData();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])
    useEffect(() => {
        if (myPermissionInfo !== undefined) {
            setPermission(myPermissionInfo)
        }
    }, [myPermissionInfo])
    return <>
        <div className={`${LayoutStyle.container}`}>
            <div className={`${LayoutStyle.innerContainer}`}>

                <div className={`${LayoutStyle.backBlackLight} ${LayoutStyle.cover}`}>
                    {permission.module.findIndex((data: any) => data.action === 'add' && data.menu === "proof") >= 0 && <Link className={`${ButtonStyle.btnBlueOuterLink}`} to={"/AddProof"}>Add Proof <AddIcon className={LayoutStyle.fontSm} /></Link>}

                    <SearchProof ChangeForSearch={ChangeForSearch}/>
                    <GridServerSide
                        rowCounts={rowCountState}
                        columns={columns}
                        rows={rows}
                        isLoading={isLoading}
                        ChangePageNumber={ChangePageNumber}
                        ChangePageSize={ChangePageSize}
                        ChangeFilter={ChangeFilter}
                    />
                    <ConfirmRejectDialog
                        title='Are you sure remove?'
                        id="InterviewBooking"
                        body="All information related to the program will be deleted! "
                        confirmTitle='Remove'
                        rejectTitle='Cancel'
                        confirmFunction={() => { RemoveAvailability() }}
                        rejectFunction={() => { setDialogData({ open: false, id: "InterviewBooking" }) }}
                        confirmClass={ButtonStyle.danger}
                        rejectClass={ButtonStyle.rejectBtnDialog}
                        loading={loading}
                    />
                </div>
            </div>
        </div>
    </>
}