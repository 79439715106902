import { useContext, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';

import { AddBookingApi } from '../../../api/Api';
import AddBookingItem from './AddBookingItem';
import BoredCamps from '../../Element/BoredCamp';
import Buttons from '../../Element/ButtonLoader';
import { InterViewBookingContextAction } from '../../../context/InterViewBookingContext';
import LayoutStyle from '../../../style/layout/Layout.module.css';

export default function AddBooking() {
    const [info, setInfo] = useState({} as any);
    const [loading, setLoading] = useState(false);
    const navigate = useNavigate();
    const GetDataInterView=useContext(InterViewBookingContextAction);
    const { user_id } = useParams();
    const SubmitBooking=()=>{
   
        if(user_id!==undefined){
            info['for_user']=true;
            info['user_id']=user_id;
        }
       
        setLoading(true)
        AddBookingApi(info).then((response:any)=>{
            setLoading(false);
            if (response?.status === "true") {
                GetDataInterView();
                navigate("/InterviewBooking");
            }
        })
    }

    return <>
        <div className={`${LayoutStyle.container}`}>

            <div className={`${LayoutStyle.innerContainer}`}>
                <div className={`${LayoutStyle.backBlackLight} ${LayoutStyle.cover}`}>
                    <BoredCamps Title='AddBooking' linkTitle='InterviewBooking' linkHref='/InterviewBooking' />
                    <AddBookingItem changed={(data)=>setInfo(data)}/>
                    <div className={`${LayoutStyle.center}`}>
                        <Buttons title='Save Data' clicked={()=>{SubmitBooking();}} loading={loading} size='large'/>
                    </div>
                </div>
            </div>
        </div>
    </>
}