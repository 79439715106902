import { createContext, useState } from "react";

import { GetStudioApi } from "../api/Api";
import { PropsChildren } from "../Interface";

export const StudioData = createContext<any>(undefined);
export const StudioAction = createContext<(data: any) => any | undefined>((data: any) => { });
export const StudioActionGetData = createContext<(param:any) => any | undefined>(() => { });

export default function StudioContextProvider(props: PropsChildren) {

   const { children } = props
   const [studioData, setStudioData] = useState(undefined);

   const GetStudioData = (param={start:0,number:25}) => {
      GetStudioApi(param).then((response: any) => {
    
         setStudioData(response);
      })
   }

   return (
      <StudioData.Provider value={studioData}>
         <StudioAction.Provider value={setStudioData}>
            <StudioActionGetData.Provider value={GetStudioData}>
               {children}
            </StudioActionGetData.Provider>
         </StudioAction.Provider>
      </StudioData.Provider>
   )

}

