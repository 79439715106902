/* eslint-disable react-hooks/exhaustive-deps */

import { useContext, useEffect, useState } from 'react';

import AddIcon from '@mui/icons-material/Add';
import ButtonStyle from '../../../style/button/Button.module.css';
import Grids from './Grids';
import { InterViewBookingContextAction } from '../../../context/InterViewBookingContext';
import LayoutStyle from '../../../style/layout/Layout.module.css';
import { Link } from "react-router-dom";
import { UserOnlinePermissionContextData } from '../../../context/UserPermissionContext';

export default function InterviewBooking() {
    const [permission, setPermission] = useState({ list: [], menu: [], module: [] });
    const myPermissionInfo = useContext(UserOnlinePermissionContextData)
    const GetDataInterView=useContext(InterViewBookingContextAction);
    useEffect(()=>{
        GetDataInterView();
    },[])

    useEffect(() => {
        if (myPermissionInfo !== undefined) {
            setPermission(myPermissionInfo)
        }
    }, [myPermissionInfo])
    return <>
        <div className={`${LayoutStyle.container}`}>
            <div className={`${LayoutStyle.innerContainer}`}>
                <div className={`${LayoutStyle.backBlackLight} ${LayoutStyle.cover}`}>

                    { permission.module.findIndex((data: any) => data.action === 'add' && data.menu === "interviewBooking") >= 0 &&<Link className={`${ButtonStyle.btnBlueOuterLink}`} to={"/AddBooking"}>Add Booking <AddIcon className={LayoutStyle.fontSm} /></Link>}
                    
                    <Grids/>
                </div>
            </div>
        </div>
    </>
}