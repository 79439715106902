import { CityProps, SingleSelectOption } from '../../Interface';
import { useEffect, useState } from 'react';

import { CityApi } from '../../api/Api';
import SingleSelect from './SingleSelect';

export default function City(props:CityProps) {
    const { ProvinceId, changed,cityId } = props
    const [options, setOptions] = useState<SingleSelectOption[]>([]);
    const [city,setCity]=useState("");
    const Change=(value:any)=>{
        setCity(value);
        changed(value)
    }

    useEffect(() => {
        
        if(ProvinceId!==''){
            CityApi({"province_id":ProvinceId}).then((response: any) => {

                const optionsItem = response.data.map((data: any, index: number) => {
                    return { value: data.id, label: data.name, selected: index === 0 ? true : false }
                })
                if (cityId !== undefined) {
    
                    if (cityId !== "" && cityId !== null )
                        {
                            let newIndex = optionsItem.findIndex((item:any)=>item.value===(cityId??"0"));
                            if(newIndex>=0){
                                optionsItem[0].selected=false;
                                optionsItem[newIndex].selected=true;
                            }
                        }
                }
                setOptions(optionsItem);
            })
        }
     
    }, [ProvinceId,cityId])
    return <>
        <SingleSelect options={options} changed={(event: any) => { Change(event.value) }} />
    </>
}