import { FetchFileImg, RemoveProgramImageApi } from '../../api/Api';
import { ProgramContextAction, ProgramContextData } from '../../context/ProgramContext';
import { useContext, useEffect, useState } from 'react';

import { ApiUrl } from '../../config';
import ButtonStyle from '../../style/button/Button.module.css';
import ConfirmRejectDialog from '../Element/AgreeDialog';
import { DataFromApi } from '../../Interface';
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';
import { DialogAction } from '../../context/Dialog';
import LayoutStyle from '../../style/layout/Layout.module.css';
import UploadImg from '../Element/UploadImg';
import { red } from '@mui/material/colors';
import { useParams } from 'react-router-dom';

const Image = (props: any) => {
    const { data } = props
    const setDialogData=useContext(DialogAction);
    const [imgSrc,setImgSrc]=useState("");
    const [loading,setLoading]=useState(false);
    const [empty,setEmpty]=useState(false);

    const { id } = useParams();
    const GetPrograms=useContext(ProgramContextAction);
    const programInfo=useContext(ProgramContextData);

    const SendImage = (event: Event) => {
        setLoading(true);
        let file = (event.target as HTMLInputElement).files;
        FetchFileImg("program/updateImageProgram",{img:file?.[0],idSearch:id}).then((response:DataFromApi|any)=>{
            setEmpty(true);
            setLoading(false);
            if(response.status==='true'){
                GetPrograms({idSearch:id});
            }
        });
    }

    const RemoveImageProfile=()=>{
        
        setLoading(true);
        RemoveProgramImageApi({idSearch:id}).then((result)=>{
            setLoading(false);
            setDialogData({open:false,id:"ConfirmRemoveProgramImage"});
            setEmpty(true);
            GetPrograms({idSearch:id});

        })
    }

    useEffect(()=>{
        if(data.file_url!==undefined){
            if(data.file_url!==null||data.file_url!==""){
                setImgSrc(ApiUrl+data.file_url+"/small/"+data.file_name);
            }
        }
    },[data]);

    return <>
        <div className={`${LayoutStyle.center} ${LayoutStyle.width100} ${LayoutStyle.backBlackLight} ${LayoutStyle.mt1} `}>
            <UploadImg loading={loading} change={(event) => { SendImage(event) }} imageSrc={imgSrc} empty={empty}/>
            <button onClick={()=>{setDialogData({open:true,id:"ConfirmRemoveProgramImage"})}} className={`${ButtonStyle.btnNone} `}><DeleteOutlineIcon sx={{ color: red[500] }} /></button>
        </div>

        <ConfirmRejectDialog
            title='Are you sure remove image?'
            body=""
            id="ConfirmRemoveProgramImage"
            confirmTitle='Remove'
            rejectTitle='Cancel'
            confirmFunction={()=>{RemoveImageProfile()}}
            rejectFunction={()=>{setDialogData({open:false,id:"ConfirmRemoveProgramImage"})}}
            confirmClass={ButtonStyle.danger}
            rejectClass={ButtonStyle.rejectBtnDialog}
            loading={loading}
        />

    </>
}


export default Image;