import { FormDatasAction, FormItemDatasAction } from '../../context/FormContext';
import { useContext, useEffect, useState } from 'react';

import AddIcon from '@mui/icons-material/Add';
import ButtonStyle from '../../style/button/Button.module.css';
import Grids from './Grids';
import LayoutStyle from '../../style/layout/Layout.module.css';
import { UserOnlinePermissionContextData } from '../../context/UserPermissionContext';
import { useNavigate } from 'react-router-dom';

export default function Forms(){

    const [permission, setPermission] = useState({ list: [], menu: [], module: [] });
    const myPermissionInfo = useContext(UserOnlinePermissionContextData)
    const navigate=useNavigate();
    const GetFormData = useContext(FormDatasAction)
    const GetFormItemData=useContext(FormItemDatasAction)


    const GoToAdd=()=>{
        GetFormData({ idSearch: '-100' });
        GetFormItemData({ idSearch: '-100' });
        navigate(`/AddForm`);
    }
    
    useEffect(() => {
        if (myPermissionInfo !== undefined) {
            setPermission(myPermissionInfo)
        }
    }, [myPermissionInfo])

    return <>
        <div className={`${LayoutStyle.container}`}>
            <div className={`${LayoutStyle.innerContainer}`}>
                <div className={`${LayoutStyle.backBlackLight} ${LayoutStyle.cover}`}>

                    {permission.module.findIndex((data: any) => data.action === 'add' && data.menu === "form") >= 0 &&<button className={`${ButtonStyle.btnBlueOuterLink} `} onClick={()=>{GoToAdd()}}>Add Form <AddIcon className={LayoutStyle.fontSm} /></button>}

                    <Grids/>
                  
                </div>
            </div>
        </div>
    </>
}