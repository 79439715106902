import { GetAllProgramApi, GetProgramApi } from "../api/Api";
import { createContext, useState } from "react";

import { PropsChildren } from "../Interface";

export const ProgramContextData = createContext<any>(undefined);
export const ProgramContextAction = createContext<(param?: any) => any | undefined>(() => { });
export const ProgramContextActionAll = createContext<(param?: any) => any | undefined>(() => { });

export default function ProgramContextProvider(props: PropsChildren) {

   const { children } = props
   const [programInfo, setProgramInfo] = useState(undefined);

   const GetPrograms = (param = { start: 0, number: 25, confirm_team: 1 }) => {
      GetProgramApi(param).then((response: any) => {
         setProgramInfo(response);
      })
   }
   
   const GetAllPrograms = (param = { start: 0, number: 25, confirm_team: 1 }) => {
      GetAllProgramApi(param).then((response: any) => {
         setProgramInfo(response);
      })
   }

   return (
      <ProgramContextData.Provider value={programInfo}>
         <ProgramContextAction.Provider value={GetPrograms}>
            <ProgramContextActionAll.Provider value={GetAllPrograms}>
               {children}
            </ProgramContextActionAll.Provider>
         </ProgramContextAction.Provider>
      </ProgramContextData.Provider>
   )

}

