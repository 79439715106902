import { UserInfoAction, UserInfoData } from '../../context/UserInfo';
import { useContext, useEffect, useState } from 'react';

import About from './About';
import Gallery from './Gallery';
import Info from './Info';
import LayoutStyle from '../../style/layout/Layout.module.css';
import Link from './Link';

// import ProgramGrid from './ProgramGrid';

const infoDefault = {
    fname: "",
    lname: "",
    about: "",
    gender: "",
    phone: "",
    mobile: "",
    fax: "",
    birth_date: "",
    country: "",
    province: "",
    city: "",
    display_name: "",
    email: "",
    team_tag: null,
    file_url: null,
    file_name: null,
    banner_file_url:null,
    banner_file_name:null,
}

export default function Profile() {

    const userInfoData = useContext(UserInfoData);
    const setUserInfoData = useContext(UserInfoAction);
    const [data, setData] = useState(infoDefault);

    useEffect(() => {
        if (userInfoData.length > 0) {
            setData(userInfoData[0]);
        }
    }, [userInfoData])

    return <>

        <div className={`${LayoutStyle.container}`}>
            <div className={`${LayoutStyle.innerContainer}`}>
                <div className={`${LayoutStyle.backBlackLight}`}>
                    <Info data={{ ...data, ...{ setUserInfoData } }} />
                    <About data={data} />
                    <Link data={data} />
                </div>
                <Gallery data={data} />
                {/* <div className={`${LayoutStyle.backBlackLight}`}>
                    <ProgramGrid />
                </div> */}

            </div>
        </div>
    </>
}