import { EditLangProps, LangItemDatas } from '../../Interface';
import { useEffect, useState } from 'react';

import ButtonStyle from '../../style/button/Button.module.css';
import LanguageItems from '../Element/LanguageItems';
import LayoutStyle from '../../style/layout/Layout.module.css';

export default function LangItem(props: EditLangProps) {

    const { changed, datas, field_name } = props
    const [numbers, setNumbers] = useState([1]);
    const [data, setData] = useState<LangItemDatas[]>([]);

    useEffect(() => {

        if (datas !== undefined) {  
            let number = [];
            let langData = [];
            for (let i = 0; i < (datas.length); i++) {
                number.push(i);
                langData.push({ index: i, value: datas[i].value, proof_id: datas[i].proof_id, lang: datas[i].lang, field_name: datas[i].field_name })
            }
            setNumbers(number)
            setData(langData)
            changed(langData);
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [datas])

    const changedItem = (dataChang: LangItemDatas) => {
        let newData = data;
        if (!CheckExistDataInState(dataChang)) {
            newData.push(dataChang);
            setData(newData);
        } else {
            let index = GetIndex(dataChang);
            newData[index] = dataChang;
            setData(newData);
        }
        changed(data);
    }

    const CheckExistDataInState = (dataChang: LangItemDatas) => {

        if (data.length <= 0) {
            return false;
        }
        let indexes = GetIndex(dataChang);
        if (indexes < 0) {
            return false;
        }
        return true;
    }

    const GetIndex = (dataChang: LangItemDatas) => {
        return data.findIndex((item) => item.index === dataChang.index && item.field_name === field_name)
    }


    const AddMoreItem = () => {
        let newNumbers = [];
        for (let i = 1; i <= numbers.length + 1; i++)
            newNumbers.push(i);
        setNumbers(newNumbers)
    }
    let Items = numbers.map((item, index) => {
        return (<>
            <LanguageItems 
            field_name={field_name} 
            LangItemDatas={data[index] ?? { value: "", proof_id: "", lang: "", index: item, field_name: field_name }} 
            index={item} changed={changedItem}
             />
        </>)
    })

    return <>

        <div className={`${LayoutStyle.backBlackLight} ${LayoutStyle.cover} ${LayoutStyle.mt1}`}>
            {Items}
            <div className={`${LayoutStyle.mt1}`}>
                <button onClick={() => { AddMoreItem() }} className={`${ButtonStyle.btnBlueOuter}`} >Add More </button>
            </div>
        </div>
    </>
}