import * as React from 'react';

import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import DarkMode from './DarkMode';
import { DatePickInterface } from '../../Interface';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import TextField from '@mui/material/TextField';

export default function DatePick(props: DatePickInterface) {
    const { DefaultValue, label, Changed, minDate } = props
    const [value, setValue] = React.useState<(data: any) => any>();
    const [valueDef, setValueDef] = React.useState<(data: any) => any>();
    const change = (newValue: any) => {
        setValue(newValue);
        if (Changed !== undefined) {
            let date:any = new Date(newValue);
            let year = date.getFullYear();
            let Month = date.getMonth() + 1;
            Month = '' + Month;
            if (Month.length < 2) {
                Month = '0' + Month;
            }
            let Day = '' + date.getDate();
            if (Day.length < 2) {
                Day = '0' + Day;
            }
            date = year + '-' + Month + '-' + Day;
            Changed(date,newValue)
      
        }
    }

  React.useEffect(()=>{
    if(DefaultValue!==undefined){
        setValueDef(DefaultValue);
    }
  },[DefaultValue])

    return <>
        <DarkMode>

            <LocalizationProvider dateAdapter={AdapterDateFns} >
                <DatePicker
                    mask="____/__/__"
                    value={value??valueDef}
                    minDate={minDate}
                    label={label}
                    onChange={(newValue: any) => {
                        change(newValue);
                   
                    }}
                    renderInput={(params) => <TextField  sx={{ width: "100%" }} {...params} />}
                />
            </LocalizationProvider>
        </DarkMode>
    </>;
}
