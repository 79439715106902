import * as React from 'react';

import AccountBoxIcon from "@mui/icons-material/AccountBox";
import { ApiUrl } from '../../config';
import { Avatar } from '@mui/material';
import ConstructionIcon from '@mui/icons-material/Construction';
import Drawer from '@mui/material/Drawer';
import List from '@mui/material/List';
import LocalLibraryIcon from '@mui/icons-material/LocalLibrary';
import LogoutBtn from '../Element/LogoutBtn';
import Note from './Note';
import NotificationsIcon from '@mui/icons-material/Notifications';
import { OneSubMenu } from './OneSubMenu';
import SidebarAdmin from './SidebarAdmin';
import { SubMenu } from './SubMenu';
import Toolbar from '@mui/material/Toolbar';
import { UserInfoData } from '../../context/UserInfo';
import { UserOnlinePermissionContextData } from '../../context/UserPermissionContext';
import VideoLibraryIcon from '@mui/icons-material/VideoLibrary';
import { blue } from '@mui/material/colors';
import style from '../../style/layout/Layout.module.css';

interface Props {
    mobileOpen: boolean;
    window?: () => Window;
    handleDrawerToggle: () => any
    drawerWidth: string | number

}
export default function SideBar(props: Props) {
    const { window, drawerWidth, mobileOpen, handleDrawerToggle } = props;
    const container = window !== undefined ? () => window().document.body : undefined;
    const fontSize = 30;
    const userInfoData = React.useContext(UserInfoData);
    const [imgSrc, setImgSrc] = React.useState("");
    const [name, setName] = React.useState("");
    const [isAdmin, setIsAdmin] = React.useState(false);

    const [permission, setPermission] = React.useState({ list: [], menu: [], module: [] });
    const myPermissionInfo = React.useContext(UserOnlinePermissionContextData)

    React.useEffect(() => {
        if (userInfoData !== undefined) {
            if (userInfoData.length > 0) {
                let data = userInfoData[0];
                if (data.file_url !== undefined) {
                    if (data.file_url !== null || data.file_url !== "") {
                        setImgSrc(ApiUrl + data.file_url + "/small/" + data.file_name);
                    }
                }
                setName(data.display_name);
                if (data.is_admin !== undefined) {
                    setIsAdmin(data.is_admin === "0" ? false : true)
                }
            }

        }

    }, [userInfoData]);


    React.useEffect(() => {
        if (myPermissionInfo !== undefined) {
            setPermission(myPermissionInfo)
        }
    }, [myPermissionInfo])

    const drawer = (
        <div >
            <Toolbar >
                <div className={`${style.center} ${style.width100} ${style.flexWarp}`}>
                    <div><Avatar sx={{ width: 56, height: 56 }} src={imgSrc} /></div>

                    <Note />
                    <div><small>{name}</small></div>
                </div>
            </Toolbar>
            <hr className={style.sidebarHr} />
            <List>
                {/* <OneSubMenu
                    title="Dashboard"
                    icon={<DashboardIcon sx={{ color: blue[500], fontSize: fontSize }} />}
                    href="/Dashboard"
                    selected={false}
                /> */}
                <OneSubMenu
                    title="My Profile"
                    icon={<AccountBoxIcon sx={{ color: blue[500], fontSize: fontSize }} />}
                    href="/MyProfile"
                    selected={false}
                />


                <SubMenu
                    title="Booking"
                    id="1"
                    icon={<LocalLibraryIcon sx={{ color: blue[500], fontSize: fontSize }} />}
                    items={[
                        {
                            title: "Interview Booking",
                            menu: "interviewBooking",
                            icon: <></>,
                            href: "/InterviewBooking",
                            selected: false,
                            id: "2"
                        },
                        {
                            title: "All Booking",
                            icon: <></>,
                            href: "/AllBooking",
                            selected: false,
                            id: "3"
                        },
                        {
                            title: "Schedule Availability",
                            icon: <></>,
                            href: "/Availability",
                            selected: false,
                            id: "4"
                        },
                    ]}
                />
                {isAdmin && <SidebarAdmin fontSize={fontSize} />}

                {/* {permission.list.findIndex((data: any) => data.list === "program") >= 0 && <SubMenu */}
                <SubMenu
                    title="Program"
                    id="7"
                    icon={<VideoLibraryIcon sx={{ color: blue[500], fontSize: fontSize }} />}
                    items={[
                        {
                            title: "Programs",
                            menu: "programs",
                            icon: <></>,
                            href: "/Programs",
                            selected: false,
                            id: "8"
                        },

                    ]} />
                {/* />} */}


                <SubMenu
                    title="Tools"
                    id="9"
                    icon={<ConstructionIcon sx={{ color: blue[500], fontSize: fontSize }} />}
                    items={[
                        {
                            title: "My Note",

                            icon: <></>,
                            href: "/MyNote",
                            selected: false,
                            id: "10"
                        },
                        {
                            title: "White Board",

                            icon: <></>,
                            href: "/WhiteBoard",
                            selected: false,
                            id: "11"
                        },
                        {
                            title: "My Documents",

                            icon: <></>,
                            href: "/MyDocuments",
                            selected: false,
                            id: "12"
                        },
                        // {
                        //     title: "My Forms",

                        //     icon: <></>,
                        //     href: "/MyForms",
                        //     selected: false,
                        //     id: "13"
                        // },
                    ]}
                />
                {/* <OneSubMenu
                    title="My Programs"
                    icon={<VideoLibraryIcon sx={{ color: blue[500], fontSize: fontSize }} />}
                    href="/MyPrograms"
                    selected={false}
                /> */}

                <OneSubMenu
                    title="My Notification"
                    icon={<NotificationsIcon sx={{ color: blue[500], fontSize: fontSize }} />}
                    href="/MyNotification"
                    selected={false}
                />
                <LogoutBtn />
            </List>

        </div>
    );

    return <>
        <div    >
            <Drawer
                container={container}
                variant="temporary"
                open={mobileOpen}
                onClose={handleDrawerToggle}

                ModalProps={{
                    keepMounted: true, // Better open performance on mobile.
                }}
                sx={{
                    display: { xs: 'block', sm: 'none' },
                    '& .MuiDrawer-paper': { boxSizing: 'border-box', width: 300 },
                }}
            >
                {drawer}
            </Drawer>
            <Drawer
                className={`${style.sidebar}`}
                variant="permanent"
                sx={{

                    display: { xs: 'none', sm: 'block', },
                    '& .MuiDrawer-paper': { boxSizing: 'border-box', width: drawerWidth },
                }}
                open
            >
                {drawer}
            </Drawer>

        </div>

    </>
}