/* eslint-disable react-hooks/exhaustive-deps */

import { FetchFileMulti, RemoveMyImageGallery } from '../../api/Api';
import { UsersActionGetData, UsersData } from '../../context/Users';
import { useContext, useEffect, useState } from 'react';

import { DataFromApi } from '../../Interface';
import GalleryList from '../Element/GalleryList';
import LayoutStyle from '../../style/layout/Layout.module.css';
import UploadImg from '../Element/UploadImg';
import { getGalleryImageData } from '../booking/interviewBooking/CreateDataForEdit';
import { useParams } from 'react-router-dom';

const Gallery = (props: any) => {
    const { id } = useParams();
    const GetUsersData=useContext(UsersActionGetData);
    const usersData=useContext(UsersData);

    const [load, setLoad] = useState(false);
    const [empty, setEmpty] = useState(false);
    const [gallery,setGallery]=useState<any[]>([]);
    const GetGallery=()=>{GetUsersData({idSearch:id});}


    const SendImage = (event: any) => {
        let file = (event.target as HTMLInputElement).files;
        setLoad(true);
        FetchFileMulti("user/addImageGalleryUser", { img: file,idSearch:id }).then((response: DataFromApi | any) => {
            setEmpty(true);
            setLoad(false);
            if (response.status === 'true') {
                GetGallery();
            }
        });
    }

    useEffect(()=>{
        GetGallery();
    },[])

    
    useEffect(() => {
        if(usersData.data !==undefined){
            if (usersData.data.length > 0) {
                let datas=usersData.data[0];
               
                setGallery(getGalleryImageData(datas.user_gallery))
             
            }
        }
        
    }, [usersData])

    return <>
        <div className={`${LayoutStyle.backBlackLight} ${LayoutStyle.cover} ${LayoutStyle.mt1}`}>
            <div className={LayoutStyle.flex}>
                <h4>Gallery</h4>
            </div>
            <div>
                <div>
                    <UploadImg loading={load} multiple={true} change={(event) => { SendImage(event) }} imageSrc={""} empty={empty} />

                </div>
                <div className={` ${LayoutStyle.mt1}`}>
                    <GalleryList RemoveGallery={RemoveMyImageGallery} GetGallery={GetGallery} datas={gallery}/>
                </div>
            </div>
        </div>
    </>
}

export default Gallery;