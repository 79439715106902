import BtnStyle from '../../style/element/button/Button.module.css'
import { Button } from '@mui/material';
import Loader from "./Loader";

export default function Buttons(props: {
    size: "large" | "small" | "medium" | undefined,
    clicked: any,
    title: string,
    loading: boolean
    widthLoader?: string
    heightLoader?: string
    classes?: string
    color?: "inherit" | "primary" | "secondary" | "success" | "error" | "info" | "warning"
}) {

    const { title, size = "large", clicked, loading, widthLoader = "15px", heightLoader = "15px", classes = "", color = "" } = props

    return <>
        {color!==''?<Button
            className={`${loading ? BtnStyle.disabledLoadingButton : classes}`}
            size={size}
            onClick={clicked}
            variant="contained"
            disabled={loading}
            color={color}
           
        >
            {loading ? <Loader width={widthLoader} height={heightLoader} /> : title}
        </Button>:<Button
            className={`${loading ? BtnStyle.disabledLoadingButton : classes}`}
            size={size}
            onClick={clicked}
            variant="contained"
            disabled={loading}
            
        >
            {loading ? <Loader width={widthLoader} height={heightLoader} /> : title}
        </Button>}
    </>
}