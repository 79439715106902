export const GetTeamData=(datas:any)=>{
    if (datas !== null) {
        let itemData = datas.split(",");
        let number = [];
        let teamData = [];
        for (let i = 0; i < (itemData.length); i++) {
            number.push(i);
            let item = itemData[i].split("::");
            teamData.push({ index: i, userId: item[0], edit: item[1] })
        }
        return ({data:teamData,number});

    }
}

export const GetNotesData=(datas:any)=>{
    if (datas !== null) {
        let itemData = datas.split(",");
        let number = [];
        let teamData = [];
        for (let i = 0; i < (itemData.length); i++) {
            number.push(i);
            let item = itemData[i].split("::");
            teamData.push({ index: i, userId: item[2], edit: item[3] })
        }
        return ({data:teamData,number});

    }
}



export const GetFormItemDataArray=(datas:any)=>{
    if (datas !== null) {
        let number = [];
        let itemData = [];
        for (let i = 0; i < (datas.length); i++) {
            number.push(i);
            itemData.push({ index: i, title: datas[i].title, value: datas[i].value,type:datas[i].type,orders:datas[i].orders,action:"update" })
        }
        return ({data:itemData,number});

    }
}

export const GetFormItemDataArrayResponse=(datas:any)=>{
    if (datas !== null) {
        let number = [];
        let itemData = [];
        for (let i = 0; i < (datas.length); i++) {
            number.push(i);
            itemData.push({ index: i, title: datas[i].title, value: datas[i].value,type:datas[i].type,orders:datas[i].orders,id:datas[i].id })
        }
        return ({data:itemData,number});

    }
}

export const GetMyFormItemDataArray=(datas:any)=>{
    if (datas !== null) {
        
        let itemData = datas.split(";");
        let number = [];
        let data = [];
        for (let i = 0; i < (itemData.length); i++) {
            number.push(i);
            let item = itemData[i].split("::");
            data.push({ index: i, id: item[0], title: item[1],type: item[2],value: item[3] })
        }
        return ({data,number});

    }
    return ({data:[],number:0});
}

export const GetRoleData=(datas:any)=>{
    console.log(datas,datas !== null && datas !== "" && datas!==undefined);
    if (datas !== null && datas !== "" && datas!==undefined ) {
        if(datas.includes("::")){
            let itemData = datas.split(",");
            let number = [];
            let teamData = [];
            for (let i = 0; i < (itemData.length); i++) {
                number.push(i);
                let item = itemData[i].split("::");
                teamData.push({ index: i,role: item[0], userId: item[1] })
            }
            return ({data:teamData,number});
        }
   

    }
    return undefined;
}

export const GetGuestData=(datas:any)=>{
    if (datas !== null) {
        let itemData = datas.split(",");
        let number = [];
        let guestData = [];
        for (let i = 0; i < (itemData.length); i++) {
            number.push(i);
            let item = itemData[i].split("::");
            guestData.push({ index: i, name: item[0], email: item[1],site:item[2],url_link:item[3] })
        }
        return ({data:guestData,number});

    }
}

export const GetNotificationData=(datas:any)=>{
    if (datas !== null) {
            
        let itemData = datas.split(",");
        let number = [];
        let notificationData = [];
        for (let i = 0; i < (itemData.length); i++) {
            number.push(i);
            let item = itemData[i].split("::");
            notificationData.push({ index: i, time: new Date(item[1]) })
        }
        return({data:notificationData,number});

    }
}

export const getGalleryImageData=(datas:any)=>{
    if (datas !== null) {
        let itemData = datas.split(",");
        let number = [];
        let Gallery = [];
        for (let i = 0; i < (itemData.length); i++) {
            number.push(i);
            let item = itemData[i].split("::");
            Gallery.push({ index: i,id:item[0], file_url: item[1], file_name: item[2] })
        }
        return (Gallery);

    }
    return [];
}