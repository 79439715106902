import { GetGuestData, GetRoleData } from '../../booking/interviewBooking/CreateDataForEdit';
import { useCallback, useEffect, useMemo, useState } from 'react';

import ChooseGuest from '../../booking/interviewBooking/ChooseGuest';
import CityItems from '../../Element/CityItems';
import DatePick from '../../Element/DatePick';
import EditLink from '../../profile/edit/EditLink';
import Inputs from '../../Element/Input';
import LayoutStyle from '../../../style/layout/Layout.module.css';
import Proof from '../../Element/Proof';
import Roles from '../../profile/edit/Roles';
import SingleSelect from '../../Element/SingleSelect';
import Sites from '../../Element/Site';
import TextArea from '../../Element/TextArea';
import TimePick from '../../Element/TimePick';

export default function EditInfo(props: any) {
    const { changed, data, book_guest_data_insert = undefined,role_data_insert=undefined } = props
    const [roles, setRoles] = useState<any>(undefined);
    const [book_guest_data, setGuest_team_data] = useState<any>(undefined);
    
    const [info, setInfo] = useState({
        title: "",
        country_id: "-1",
        province_id: "0",
        city_id: "",
        category_id: "",
        type: "",
        video_link: "",
        tags: "",
        description: "",
        program_link: "",
        book_guest: "",
        program_role: "",
        published: "0",
        siteDefault: "",
        date_from: "",
        time_from:"",
        date_to: "",
        time_to:"",
        site:"",
        photographer_name:"",
    })

    const ChangeCity = (data: any) => {

        let newInfo = info;
        newInfo.country_id = data.country_id;
        newInfo.province_id = data.province_id;
        newInfo.city_id = data.city_id;

        setInfo({ ...newInfo });
        changed(newInfo)
    }

    const Changed = useCallback((value: string, type: string, JsonString = false) => {
        let newValue: any = info;
        if (JsonString)
            newValue[type] = JSON.stringify(value);
        else
            newValue[type] = value;
            
        setInfo({ ...newValue });
        changed(newValue)
    }, [changed, info])
    const[time_from_data,setTime_from_data]=useState<string>();
    const[time_to_data,setTime_to_data]=useState<string>();

    useEffect(() => {

        if (GetRoleData(data.program_role) !== undefined) {
            setRoles(GetRoleData(data.program_role));
        }
        let programRole = JSON.stringify(GetRoleData(data.program_role) !== undefined ? GetRoleData(data.program_role)?.data : "");

        setGuest_team_data(data.program_guest);

        const newInfo: any = {
            title: data.title,
            country_id: data.country_id,
            province_id: data.province_id,
            city_id: data.city_id,
            category_id: data.program_category,
            type: data.type,
            video_link: data.video_link,
            tags: data.tags,
            description: data.description,
            program_link: { team_tag: data.program_tag },
            program_role: programRole,
            published: data.published,
            date_from:data.date_from,
            time_from:data.time_from,
            date_to: data.date_to,
            time_to:data.time_to,
            photographer_name:data.photographer_name,


        }

        setTime_from_data(`${data.date_from} ${data.time_from}`);
        setTime_to_data(`${data.date_to} ${data.time_to}`);
        let sitesArray: any = [];
        if (data.program_site !== null && data.program_site !== undefined) {

            let sites = data?.program_site.split(",") ?? [];
            sites.forEach((data: any) => {
                let datas = data.split("::");
                sitesArray.push(datas[1]);
            });
            newInfo['siteDefault'] = sitesArray.toString();
        }

        if (data.program_guest !== null && data.program_guest !== undefined) {
            newInfo['book_guest'] = JSON.stringify(GetGuestData(data.program_guest)?.data);
        }
        setInfo({ ...newInfo })
        changed({ ...newInfo })
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [data])

    let GuestElement = useMemo(() => {
        if (book_guest_data !== undefined || book_guest_data_insert !== undefined)
            return <><ChooseGuest datas={book_guest_data} changed={(value: any) => { Changed(value, "book_guest", true); }} /></>

    }, [Changed, book_guest_data, book_guest_data_insert])

    // let RoleElement = useMemo(() => {
    //     if (roles !== undefined || role_data_insert !== undefined)
    //         return <><Roles datas={roles} changed={(value: any) => { Changed(value, "program_role", true) }} /></>
    // }, [Changed, roles,role_data_insert])

    
    return <>
        <div className={`${LayoutStyle.backBlackLight} ${LayoutStyle.cover} ${LayoutStyle.mt1}`}>
            <div className={`${LayoutStyle.mt1}`}>
                <Sites isMulti={true} change={(value) => { Changed(value, "site",true); }} defaultValue={info.siteDefault} />
            </div>
            <div className={`${LayoutStyle.mt1}`}>
                <CityItems Change={ChangeCity} data={{ country_id: info.country_id, province_id: info.province_id, city_id: info.city_id }} />
            </div>
            <div className={`${LayoutStyle.mt1}`}>
                <Inputs
                    id='title'
                    name='title'
                    value={info.title}
                    label="Title"
                    changed={(event) => { Changed(event.target.value, "title"); }}
                />
            </div>
            <div className={`${LayoutStyle.flexWarp} ${LayoutStyle.mt2}`}>
                <div className={`${LayoutStyle.flexGrow1}`}>
                    <DatePick label="Date From" Changed={(value, newValue) => { Changed(value, "date_from") }} DefaultValue={info.date_from} />
                </div>
                <div className={`${LayoutStyle.flexGrow1}`}>
                    <TimePick DefaultValue={time_from_data} id="time_from" label="Time From" Changed={(value, newValue) => { Changed(value, "time_from") }} />
                </div>
            </div>

            <div className={`${LayoutStyle.flexWarp} ${LayoutStyle.mt2}`}>
                <div className={`${LayoutStyle.flexGrow1}`}>
                    <DatePick label="Date To" Changed={(value) => { Changed(value, "date_to") }} DefaultValue={info.date_to} />
                </div>

                <div className={`${LayoutStyle.flexGrow1}`}>
                    <TimePick DefaultValue={time_to_data} id="time_to" label="Time To" Changed={(value) => { Changed(value, "time_to") }} />
                </div>
            </div>
            <div className={`${LayoutStyle.mt1}`}>
                <p>Category</p>
                <Proof  type="program" defaultValue={info.category_id} isMulti={false} change={(event) => { Changed(event, "category_id") }} />
            </div>

            <div className={`${LayoutStyle.mt1}`}>
                <p>Type</p>
                <Proof  type="programType" defaultValue={info.type} isMulti={false} change={(event) => { Changed(event, "type") }} />

            </div>
            <div className={`${LayoutStyle.mt1}`}>
                <Inputs
                    id='video'
                    name='video'
                    value={info.video_link !== "undefined" ? info.video_link : ""}
                    label="Video Link"
                    changed={(event) => { Changed(event.target.value, "video_link"); }}
                />
            </div>
            <div className={`${LayoutStyle.mt1}`}>
            <EditLink datas={info.program_link} linkType={"ProgramLinks"}  />
                {/* <EditLink datas={info.program_link} linkType={"ProgramLinks"} changed={(value: any) => { Changed(value, "program_link", true) }} /> */}
            </div>
            <div className={`${LayoutStyle.mt1}`}>
            <Roles datas={roles} />
                {/* {RoleElement} */}
            </div>
            <div className={``}>
                {GuestElement}
            </div>
     
            <div className={`${LayoutStyle.mt1}`}>

                <TextArea
                    id='tags'
                    name='tags'
                    value={info.tags !== 'undefined' ? info.tags : ""}
                    label="Tags"
                    changed={(event) => { Changed(event.target.value ?? "", "tags"); }}
                />
            </div>
            <div className={`${LayoutStyle.mt1}`}>
                <TextArea
                    id='description'
                    name='description'
                    value={info.description}
                    label="Description"
                    changed={(event) => { Changed(event.target.value, "description"); }}
                />
            </div>

            <div className={`${LayoutStyle.mt1}`}>
                <Inputs
                    id='photographer_name'
                    name='photographer_name'
                    value={info.photographer_name}
                    label="Photographer Name"
                    changed={(event) => { Changed(event.target.value, "photographer_name"); }}
                />
            </div>

            <div className={`${LayoutStyle.mt1}`}>
                <p>Published?</p>
                <SingleSelect
                    changed={(data) => { Changed(data.value, "published") }}
                    options={[
                        { value: '0', label: 'No', selected: info.published === '0' ? true : false },
                        { value: '1', label: 'Yes', selected: info.published === '1' ? true : false },
                    ]
                    }
                />
            </div>


        </div>

    </>
}