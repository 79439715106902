import * as React from 'react';

import { AgreeDialog } from '../../Interface';
import Button from '@mui/material/Button';
import Buttons from './ButtonLoader';
import DarkMode from './DarkMode';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import { DialogOpenData } from '../../context/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import LayoutStyle from '../../style/layout/Layout.module.css'
import useMediaQuery from '@mui/material/useMediaQuery';
import { useTheme } from '@mui/material/styles';

export default function ConfirmRejectDialog(props: AgreeDialog) {
    const { title, body, confirmTitle, rejectTitle, confirmClass, rejectClass, confirmFunction, rejectFunction, loading,id } = props
    const [open, setOpen] = React.useState(false);
    const dialogOpenData = React.useContext(DialogOpenData);
    const theme = useTheme();
    const fullScreen = useMediaQuery(theme.breakpoints.down('md'));

    React.useEffect(() => {
        
        if(dialogOpenData.id===id)
        setOpen(dialogOpenData.open);
    }, [dialogOpenData, id])

    return (
        <div >
       
            <Dialog
                //fullScreen
                open={open}
                aria-labelledby={id}
                disableEscapeKeyDown={true}
                itemID={id}
            >
                <div className={LayoutStyle.backBlackLightContainer}>
                    <DialogTitle id={id}>
                        {title}
                    </DialogTitle>
                    <DialogContent>
                        <DialogContentText sx={{ color:"#ffffff9e" }}>
                            {body}
                        </DialogContentText>
                    </DialogContent>
                    <DialogActions>
                        <Buttons title={confirmTitle} clicked={confirmFunction} loading={loading} size="medium" classes={confirmClass} />
                        <Button onClick={rejectFunction} autoFocus className={rejectClass}>
                            {rejectTitle}
                        </Button>
                    </DialogActions>
                </div>
            </Dialog>
    
        </div>
    );
}
