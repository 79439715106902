import { useEffect, useState } from 'react';

import Loader from './Loader';
import { ReactComponent as Plus } from '../svg/plus.svg';
import { UploadImgType } from '../../Interface';
import UploadStyle from '../../style/element/uploadImg/Upload.module.css';

export default function UploadImg(props: UploadImgType) {

    const { change,
        imageSrc,
        width = "80px",
        height = "80px",
        borderRadius = "50%",
        empty = false,
        multiple = false,
        loading = false
    } = props
    const [defaultValue, setDefaultValue] = useState("");

    useEffect(() => {
        if (empty) {
            setDefaultValue("");
        }
    }, [empty])

    return <>

        {loading ? <Loader width={width} height={height} /> : <div style={
            {
                backgroundImage: "url(" + imageSrc + ")",
                backgroundSize: 'cover',
                borderRadius: borderRadius,
                backgroundPosition: 'center',
                width: width,
                height: height,

            }
        } className={UploadStyle.container} >
            <div className={UploadStyle.input}><input
                style={{
                    width: width,
                    height: height,
                    borderRadius: borderRadius,
                }}
                onChange={(event) => change(event)} accept="image/*" value={defaultValue} multiple={multiple} type="file" className={UploadStyle.customFileInput} />
            </div>
            <div className={UploadStyle.icon}><Plus /></div>
        </div>}
    </>
}