import { Link, useParams } from "react-router-dom";
import { WhiteActionGetData, WhitePropsData, newWhiteCommentDataAction } from "../../context/WhiteBoard";
import { useContext, useEffect, useState } from "react";

import { AddWhiteCommentApi } from "../../api/Api";
import { ApiUrl } from "../../config";
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import AttachFileIcon from '@mui/icons-material/AttachFile';
import Buttons from '../Element/ButtonLoader';
import LayoutStyle from '../../style/layout/Layout.module.css';
import TextArea from "../Element/TextArea";
import WhiteBoardComments from "./WhiteBoardComments";

export default function WhiteBoardView() {
    const { id } = useParams();
    const GetWhiteData = useContext(WhiteActionGetData)
    const [loading, setLoading] = useState(false);
    const [info, setInfo] = useState({ idSearch: id, txt: "" } as any);
    const [data, setData] = useState<any>("");

    const setNewWhiteCommentData = useContext(newWhiteCommentDataAction)
    
    const whiteData = useContext(WhitePropsData)

    const Changed = (value: any, type: string) => {
        let newValue = info;
        newValue[type] = value;
        setInfo(newValue);
    }

    useEffect(()=>{
        GetWhiteData({ idSearch: id });
    // eslint-disable-next-line react-hooks/exhaustive-deps
    },[])

    useEffect(() => {
        if (whiteData.data !== undefined) {

            if (whiteData.data.length > 0) {
                let datas = whiteData.data[0];
                setData(datas);
            }
        }

    }, [whiteData])

    const SendData = () => {
        info["user_white_board_id"] = id
        setLoading(true);
        AddWhiteCommentApi(info).then((response: any) => {
            setLoading(false);
            if (response?.status === "true") {
                setInfo({ idSearch: id, txt: "" })
                setNewWhiteCommentData("start")
                setTimeout(() => {
                    setNewWhiteCommentData("")
                }, 200);
            }
        })

    }

    const CreateFileLink=(props:any)=>{
        
        let {data}=props
        if(data!==undefined){
            let files=data.split(",");
            let items=files.map((item:any)=>{
                let itemData=item.split("::");
                return <><a className={`${LayoutStyle.spanName} me-2`} target="blank" href={`${ApiUrl}${itemData[1]}/${itemData[2]}`} download><AttachFileIcon/> {itemData[2]}</a></>
            })
            return <>{items}</>
        }else{
            return <></>
        }
       
    }

    return <>
        <div className={`${LayoutStyle.container}`}>
            <div className={`${LayoutStyle.innerContainer}`}>
                <div className={`${LayoutStyle.flex}`}>
                    <span><Link className={`${LayoutStyle.spanName}`} to={"/WhiteBoard"}>WhiteBoard List</Link></span>
                    <span> <ArrowForwardIosIcon className={`${LayoutStyle.fontSm}`} /></span>
                    <span> Comments about the {data.title ?? ""}  </span>
                </div>
                <div className={`${LayoutStyle.backBlackLight} ${LayoutStyle.cover} ${LayoutStyle.mt1}`}>

                    <div className={`${LayoutStyle.mt1}`}>
                        <h4>{data.title ?? ""}</h4>
                        <p className={LayoutStyle.darkWhiteColor}>{data.description ?? ""}</p>
                    </div>
                    <div className={`${LayoutStyle.mt1}`}>
                        {data.attachments!==null?<CreateFileLink data={data.attachments}/>:""}
                    </div>
                </div>
                <div className={`${LayoutStyle.backBlackLight} ${LayoutStyle.cover} ${LayoutStyle.mt1}`}>
                    <p>Write Your Comment</p>
                    <div className={`${LayoutStyle.mt1}`}>
                        <TextArea
                            id='description'
                            name='description'
                            value={info.txt}
                            label={"Comment"}
                            changed={(event) => { Changed(event.target.value, "txt"); }}
                        />
                    </div>

                    <Buttons clicked={() => { SendData() }} loading={loading} title="Save Data" size='large' />
                </div>
                <WhiteBoardComments id={id} />
            </div>
        </div>
    </>;

}