import { useContext, useEffect, useState } from "react"
import { useNavigate, useParams } from "react-router-dom";

import BoredCamps from "../../Element/BoredCamp"
import Buttons from "../../Element/ButtonLoader"
import Items from "./Items";
import LayoutStyle from '../../../style/layout/Layout.module.css'
import { ProofContextData } from "../../../context/ProofContext";
import { UpdateProofApi } from "../../../api/Api";

export default function EditProof(){
    const [loading,setLoading]=useState(false);
    const [data,setData]=useState();
    const [info,setInfo]=useState();
    const { id }=useParams();
    const proofInfo=useContext(ProofContextData);
    const navigate=useNavigate();

    const SendData=()=>{
        setLoading(true);
        let params:any=info;
        params.idSearch=id;
        UpdateProofApi(params).then((response:any)=>{
            setLoading(false);
            if (response?.status === "true") {
                navigate("/Proofs");
            }
        })
    }

    useEffect(()=>{
        if(proofInfo!==undefined){
            if(proofInfo.data.length>0)
            {
                setInfo(proofInfo.data[0]);
                setData(proofInfo.data[0]);
            }

        }
    },[proofInfo])

    return <>
          <div className={`${LayoutStyle.container}`}>
            <div className={`${LayoutStyle.innerContainer}`}>

                <BoredCamps Title='Edit Proof' linkHref='/Proofs' linkTitle='Proofs' />
                <Items insert={false} datas={data} Changed={setInfo}/>

                <div className={` ${LayoutStyle.cover} ${LayoutStyle.mt1} ${LayoutStyle.mb1}`}>
                    <Buttons clicked={() => { SendData() }} loading={loading} title="Save Data" size='large' />
                </div>
            </div>

        </div>
    </>
}