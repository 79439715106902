import { Link, useNavigate } from 'react-router-dom';
import { UserNote, UserNoteActionGetData } from '../../context/Notes';
import { useContext, useEffect, useState } from 'react';

import { Badge } from '@mui/material';
import ButtonStyle from '../../style/button/Button.module.css'
import CommentIcon from '@mui/icons-material/Comment';
import DropDownBtn from '../Element/DropDownBtn';
import GridStyle from '../../style/element/grid/Grid.module.css'
import LayoutStyle from '../../style/layout/Layout.module.css';
import NotificationsIcon from '@mui/icons-material/Notifications';
import { UpdateNoteApi } from '../../api/Api';

export default function Note() {

    const GetUserNote = useContext(UserNoteActionGetData);
    const userNote = useContext(UserNote);
    const [notes, setNotes] = useState([]);
    const [total, setTotal] = useState(0);
    const [showStar, setShowStar] = useState(false);
    const navigate = useNavigate();


    useEffect(() => {
        if (userNote !== undefined) {
            if (userNote.data !== undefined) {

                setNotes(userNote.data.length>5?userNote.data.slice(0,5):userNote.data);
                if (userNote.data.length > 0) {
                    setShowStar(true);
                    setTotal(userNote.total ?? 0)
                } else {
                    setShowStar(false);
                }
            }

        }
    }, [userNote])

    useEffect(() => {
        GetUserNote({ start: '0', number: "5", is_read: "0" });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    const GoToNotification = (id: string) => {
        UpdateNoteApi({ idSearch: id, is_read: "1" });
        navigate("/MyNotification");
    }

    let items = notes.map((data: any) => {
        return {
            click: () => { GoToNotification(data.id) },
            title: <div className={`${LayoutStyle.flex} `}>
                <CommentIcon sx={{ color: "orange" }} />
                <div className={`${GridStyle.textNote} ${LayoutStyle.width200} ${LayoutStyle.txtOverflow} ${LayoutStyle.p1} `}>{data.text}</div>
            </div>,
            close: true
        }
    })
    items.push({
        click: () => { },
        title: <div className={`${LayoutStyle.center} ${LayoutStyle.width100}`}><Link className={`${LayoutStyle.link} `} to={"/MyNotification"}>Show All</Link></div>,
        close: true
    });
    return <>
        <div>

            <DropDownBtn
                title={<>
                    <div className={`${LayoutStyle.relative} `} >
                        <Badge badgeContent={total} color="error">
                        <NotificationsIcon />
                        </Badge>
                        {/* <div ><NotificationsIcon /></div>
                        <div style={{ display: showStar ? "" : "none" }} className={`${LayoutStyle.noteStar}`}>
                            *
                        </div> */}
                    </div>
                </>}
                items={items}
            />
        </div>

    </>
}