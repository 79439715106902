import { LangData, ParamData, langGetDefaultData } from "../../context/LanguageContext";
import { useContext, useEffect, useState } from "react";

import { AddLangDataApi } from "../../api/Api";
import Buttons from "../Element/ButtonLoader";
import LangItem from "./LangItem";
import LayoutStyle from '../../style/layout/Layout.module.css';
import { useNavigate } from "react-router-dom";

export default function Languages() {
    const param = useContext(ParamData) ?? []
    const navigate = useNavigate();
    const [loading, setLoading] = useState(false);
    const [dataLang, setDataLang] = useState<any[]>([]);
    const [dataLangServer, setDataLangServer] = useState<any[]>([]);
    const [dataLangDefaults, setDataLangDefaults] = useState<any>(undefined);

    const langDatas = useContext(LangData);
    const langDefaultData = useContext(langGetDefaultData);
    

    useEffect(() => {
        if (param !== undefined) {
            let fieldName: any = {};
            param.forEach(data => {
                fieldName[data.field_name] = [];
            });
        }
    })
    
    useEffect(()=>{
        if(langDefaultData!==undefined){
            setDataLangDefaults(langDefaultData)
        }

    },[langDefaultData])
    useEffect(() => {

        if (langDatas !== undefined) {

            let langs = langDatas;
            let langData: any[] = [];
            console.log(langs, '=====langs');
            for (let i = 0; i < (langs.length); i++) {

                let obj: any = {};
                obj[langs[i].field_name] = []
                let exist = false;
                for (let j = 0; j < langData.length; j++) {
                    if (langData[j][langs[i].field_name] !== undefined) {
                        exist = true;
                    }
                }
                if (!exist)
                    langData.push(obj);
            }

            for (let i = 0; i < (langs.length); i++) {

                for (let j = 0; j < langData.length; j++) {
                    if (langData[j][langs[i].field_name] !== undefined) {
                        
                        langData[j][langs[i].field_name].push({
                             index: i,
                             value: langs[i].value,
                              proof_id: langs[i].proof_id,
                               lang: langs[i].lang,
                             field_name: langs[i].field_name })
                    }
                }
            }
            setDataLangServer(langData)

        }
    }, [langDatas])

    const Change = (data: any) => {

        let newValue = dataLang;
        data.forEach((d: any) => {

            let index = dataLang.findIndex((item) => item.index === d.index && item.field_name === d.field_name);
            if (index < 0) {
                newValue.push(d);
            } else {
                newValue[index] = d;
            }
        });

        setDataLang(newValue);
    }
    const SendData = () => {
        let parameter = {
            lang: JSON.stringify(dataLang),
            table_id: param[0].table_id,
            table_name: param[0].table_name,
        }

        setLoading(true);
        AddLangDataApi(parameter).then((response: any) => {

            setLoading(false);
            if (response?.status === "true") {
                navigate("/MyProfile");
            }
        })
       
    }

    const GetDataFromServer = (field_name: string) => {
        if (dataLangServer.length <= 0) {
            return undefined;
        }
        
        let returns = undefined;
        for (let j = 0; j < dataLangServer.length; j++) {
            if (dataLangServer[j][field_name] !== undefined) {
                returns = dataLangServer[j][field_name];
            }
    
        }
        return returns;
    }

    const items = param.map((data) => {
        return <>
            <div><strong>{data.field_name}</strong>
                {dataLangDefaults&& <span>({dataLangDefaults[data.field_name]??""}):</span>}

                
            </div>
            <div className={`${LayoutStyle.mt1}`}>  <LangItem datas={GetDataFromServer(data.field_name)} field_name={data.field_name} changed={(response) => Change(response)} /></div>
        </>
    })
    return <>
        <div className={`${LayoutStyle.container}`}>
            <div className={`${LayoutStyle.innerContainer}`}>
                <div className={`${LayoutStyle.backBlackLight} ${LayoutStyle.p1}`}>
                    {items}
                </div>
                <div className={` ${LayoutStyle.cover} ${LayoutStyle.mt1} ${LayoutStyle.mb1}`}>
                    <Buttons clicked={() => { SendData() }} loading={loading} title="Save Data" size='large' />
                </div>
            </div>
        </div>
    </>
}