import * as React from 'react';

import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import DarkMode from './DarkMode';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import TextField from '@mui/material/TextField';
import { TimePickInterface } from '../../Interface';
import { TimePicker } from '@mui/x-date-pickers';

export default function TimePick(props: TimePickInterface) {
    const { DefaultValue, label, Changed, minTime="" } = props
    const [value, setValue] = React.useState<(data: any) => any>();
    const [valueDef, setValueDef] = React.useState();

    const change = (newValue: any) => {
        setValue(newValue);
        if (Changed !== undefined) {
            let date:any = new Date(newValue);
            let Hours = date.getHours();
            let Minutes = date.getMinutes();
            let Seconds = date.getSeconds();
            let time = Hours + ':' + Minutes + ':' + Seconds;
            Changed(time,newValue)
      
        }
    }

    React.useEffect(()=>{
        if(DefaultValue!==undefined){
            setValueDef(DefaultValue);
        }
      },[DefaultValue])

      
    return <>

        <DarkMode>
            <LocalizationProvider dateAdapter={AdapterDateFns}>
                <TimePicker
                    label={label}
                    value={value??valueDef}
             
                    minTime={minTime}
                    onChange={(newValue:any) => {
                        change(newValue)
                    }}
                    renderInput={(params) => <TextField sx={{ width: "100%" }} {...params} />}
                />
            </LocalizationProvider>

        </DarkMode>
    </>;
}
