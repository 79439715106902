import { useEffect, useState } from 'react'

import InputNumber from '../../Element/InputNumber'
import Inputs from '../../Element/Input'
import LayoutStyle from '../../../style/layout/Layout.module.css'
import ProofTypes from './ProofTypes'
import Sites from '../../Element/Site'
import TextArea from '../../Element/TextArea'

export default function Items(props: any) {

    const { Changed, datas, insert = true } = props

    const [params, setParams] = useState({ title: "", orders: "", type: "", site: "", siteDefault: "" })

    const Change = (value = "", type = "") => {
        let newParam: any = params;
        newParam[type] = value;
        setParams({ ...newParam });
        Changed(newParam);
    }

    useEffect(() => {
        if (datas !== undefined)
            setParams(datas);

    }, [datas])

    return <>
        <div className={`${LayoutStyle.backBlackLight} ${LayoutStyle.cover}`}>
            <div className={`${LayoutStyle.flexGrow1}`}>
                {insert ? <>
                    <p>separator with , </p>
                    <TextArea
                        label='Title'
                        name="title"
                        id="title"
                        value={params.title}
                        changed={(event) => Change(event.target.value, "title")}
                    />
                </>
                    : <Inputs
                        label='Title'
                        name="title"
                        id="title"
                        value={params.title}
                        changed={(event) => Change(event.target.value, "title")}
                    />}
            </div>
            <div className={`${LayoutStyle.flexGrow1}`}>
                <p>Type:</p>
                <ProofTypes value={params.type} changed={(value: any) => { Change(value, "type") }} />
            </div>
            <div className={`${LayoutStyle.mt1}`}>
                <Sites isMulti={false} change={(value) => { Change(value.value, "site"); }} defaultValue={params.site} />
            </div>
            {!insert&&     <div className={`${LayoutStyle.flexGrow1} ${LayoutStyle.mt2}`}>
                <InputNumber
                    label='Order'
                    name="orders"
                    id="orders"
                    value={params.orders}
                    changed={(event) => Change(event.target.value, 'orders')}
                />
            </div>}
       


        </div>
    </>
}