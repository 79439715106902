import CssBaseline from '@mui/material/CssBaseline';
import { PropsChildren } from '../../Interface';
import { ThemeProvider } from '@mui/material/styles';
import { createTheme } from '@mui/material/styles';

export default function DarkMode(props: PropsChildren) {
    const darkTheme = createTheme({
        palette: {
            mode: 'dark',
        },
    });
    return <>
        <ThemeProvider theme={darkTheme}>
            <CssBaseline />
            {props.children}
        </ThemeProvider>
    </>
}