import { DataFromApi, UserInfo } from '../../Interface';
import { FetchFileMulti, GetMyGallery } from '../../api/Api';
import { useContext, useEffect, useState } from 'react';

import AttachmentFile from '../Element/AttachmentFile';
import GalleryList from './GalleryList';
import LayoutStyle from '../../style/layout/Layout.module.css';
import UploadImg from '../Element/UploadImg';
import { UserGalleryAction } from '../../context/UserGallery';

const Gallery = (props: UserInfo) => {
    const { data } = props
    const setUserGalleryData=useContext(UserGalleryAction);
    const [load, setLoad] = useState(false);
    const [empty, setEmpty] = useState(false);
    const GetGallery=()=>{GetMyGallery({}).then((response) => { setUserGalleryData(response); });}

    const SendImage = (event: any) => {
        let file = (event.target as HTMLInputElement).files;
        setLoad(true);
        FetchFileMulti("user/addImageMyGallery", { img: file }).then((response: DataFromApi | any) => {
            setEmpty(true);
            setLoad(false);
            if (response.status === 'true') {
                GetGallery();
            }
        });
    }

    useEffect(()=>{
        GetGallery();
    })

    return <>
        <div className={`${LayoutStyle.backBlackLight} ${LayoutStyle.cover} ${LayoutStyle.mt1}`}>
            <div className={LayoutStyle.flex}>
                <h4>Gallery</h4>
            </div>
            <div>
                <div>
                    {/* <AttachmentFile/> */}
                    <UploadImg loading={load} multiple={true} change={(event) => { SendImage(event) }} imageSrc={""} empty={empty} />

                </div>
                <div className={` ${LayoutStyle.mt1}`}>
                    <GalleryList GetGallery={GetGallery}/>
                </div>
            </div>
        </div>
    </>
}

export default Gallery;