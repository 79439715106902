import { SingleSelectOption, siteComponent } from '../../Interface';
import { useEffect, useState } from 'react';

import { GetSite } from '../../api/Api';
import SingleSelect from './SingleSelect';

export default function Sites(props: siteComponent) {
    const { change, isMulti = true, defaultValue = "" } = props
    const [options, setOptions] = useState<SingleSelectOption[]>([]);
    const [defaultValues, setDefaultValues] = useState<any[]>([]);

    useEffect(() => {
        GetSite({}).then((response: any) => {
            let options: SingleSelectOption[] = [];
            response.data.forEach((d: any) => {
                options.push({ value: d.id, label: d.name, selected: false });
            });
            setOptions(options);
        })
    }, [])

    
    useEffect(() => {

        let defaultValues = defaultValue.toString().split(",");
        let values: any[] = [];
        defaultValues.forEach(d => {
            values.push(options.find((item: any) => item.value === d));
        });
        setDefaultValues(values)
    }, [defaultValue, options])

    return <>
        <SingleSelect  defaultValue={defaultValues} options={options} isMulti={isMulti} changed={(event: any) => { change(event) }} />




    </>
}