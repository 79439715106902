import { LanguageProps, PropsChildren } from "../Interface";
import { createContext, useState } from "react";

import { GetDataLangInfoApi } from "../api/Api";

export const ParamAction = createContext<(data: LanguageProps[]) => any>(data => { });
export const LangDataAction = createContext<(data: any) => any>(data => { });
export const ParamData = createContext<LanguageProps[]>([]);
export const LangData = createContext<any[]>([]);
export const langGetDefaultData = createContext<any>(undefined);
export const LangDefaultDataAction = createContext<(data: any) => any>(data => { });


export default function LanguageContextProvider(props: PropsChildren) {

   const { children } = props;

   const [param, setParam] = useState<LanguageProps[]>([]);
   const [langDatas, setLangDatas] = useState<any[]>([]);
   const [langDefaultData, setLangDefaultData] = useState<any>(undefined);

   const SetParams = (data: LanguageProps[] | []) => { setParam(data) }

   const GetDataLangInfo = (param: any) => {
      GetDataLangInfoApi(param).then((response: any) => {
         
         if(response.status==='true'){
            setLangDatas(response.data);
         }
      
      })
   }

   return (
      <ParamAction.Provider value={SetParams}>
         <LangDataAction.Provider value={GetDataLangInfo}>
         <LangDefaultDataAction.Provider value={setLangDefaultData}>
            <LangData.Provider value={langDatas}>
               <ParamData.Provider value={param}>
               <langGetDefaultData.Provider value={langDefaultData}>
                  {children}
                  </langGetDefaultData.Provider>
               </ParamData.Provider>
            </LangData.Provider>
            </LangDefaultDataAction.Provider>
         </LangDataAction.Provider>
      </ParamAction.Provider >
   )
}