import { GetUser, GetUserAvailability } from '../../api/Api';
import { SingleSelectOption, UserComponent } from '../../Interface';
import { useCallback, useContext, useEffect, useState } from 'react';

import SingleSelect from './SingleSelect';
import { UserSearchParamInfo } from '../../context/UserSearchParam';

export default function UserSearch(props: UserComponent) {
    const { change, isMulti = true, defaultValue = "", Availability = false } = props
    const [options, setOptions] = useState<SingleSelectOption[]>([]);
    const params = useContext(UserSearchParamInfo);
    const [defaultV, setDefaultV] = useState<any>([])
    const [settingDef, SetSettingDef] = useState(false);
    const SearchUser = (value: string) => {
        let param: any = params;
        if (value !== "")
            param = { ...params, ...{ display_name: value } };
        GetData(param);
    }

    const GetData = useCallback((param: any) => {
        if (!Availability) {
            GetUser(param).then((response: any) => {
                let options: SingleSelectOption[] = [];
                options = response.data.map((d: any) => {

                    return { value: d.id, label: d.display_name, selected: false }
                })
                let optionsItem = [...[{ value: "-1", label: "...", selected: false }], ...options]
                setOptions(optionsItem);
                // setOptions(options);
            })
        } else {
            GetUserAvailability(param).then((response: any) => {
                let options: SingleSelectOption[] = [];
                options = response.data.map((d: any) => {

                    return { value: d.id, label: d.display_name, selected: false }
                })
                let optionsItem = [...[{ value: "-1", label: "...", selected: false }], ...options]
                setOptions(optionsItem);
            })
        }


        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])


    useEffect(() => {

        let param: any = params
        if (defaultValue) {
            param = { ...params, ...{ idSearch: defaultValue } };
        }

        GetData(param);
    }, [GetData, defaultValue, params])

    useEffect(() => {
        if (defaultValue) {
            let data = options.find((data) => data.value === defaultValue);
           
                setDefaultV([data])
                SetSettingDef(true)
            
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [defaultValue, options])


    return <>
        <SingleSelect defaultValue={defaultV} changedInput={(value) => { SearchUser(value) }} options={options} isMulti={isMulti} changed={(event: any) => { change(isMulti ? event : event.value) }} />

    </>
}