import { LangDataAction, LangDefaultDataAction, ParamAction } from "../../context/LanguageContext";
import { UserOnlinePermissionContextData, UserPermissionContextAction, UserPermissionContextActionData } from "../../context/UserPermissionContext";
import { UsersAction, UsersActionGetData, UsersData } from "../../context/Users";
import { useContext, useEffect, useState } from "react";

import { ApiUrl } from "../../config";
import { Avatar } from "@mui/material";
import BrokenImageIcon from '@mui/icons-material/BrokenImage';
import ButtonStyle from '../../style/element/button/Button.module.css'
import { CheckNullData } from "../../function/CheckEmpty";
import ClosedCaptionOffIcon from '@mui/icons-material/ClosedCaptionOff';
import ConfirmRejectDialog from "../Element/AgreeDialog";
import { CreateParamSearchTable } from "../../function/Common";
import { DialogAction } from "../../context/Dialog";
import DropDownBtn from "../Element/DropDownBtn";
import EditIcon from '@mui/icons-material/Edit';
import EventAvailableIcon from '@mui/icons-material/EventAvailable';
import { FormUserDatasResponseAction } from "../../context/FormContext";
import GridServerSide from "../Element/GridServerSide";
import GridStyle from '../../style/element/grid/Grid.module.css'
import LayoutStyle from '../../style/layout/Layout.module.css'
import ListAltIcon from '@mui/icons-material/ListAlt';
import LockIcon from '@mui/icons-material/Lock';
import { RemoveProgramApi } from "../../api/Api";
import SearchUsers from "./SearchUsers";
import SettingsIcon from '@mui/icons-material/Settings';
import { useNavigate } from "react-router-dom";

export default function Grids() {
    const navigate = useNavigate();
    const [permission, setPermission] = useState({ list: [], menu: [], module: [] });
    const myPermissionInfo = useContext(UserOnlinePermissionContextData)
    const GetFormDataUserResponse=useContext(FormUserDatasResponseAction);

    
    const setLangDefaultData = useContext(LangDefaultDataAction);
    const GetDataLangInfo = useContext(LangDataAction);
    const SetParams = useContext(ParamAction);

    const columns = [
        {
            field: 'Action', type: "date", filterable: false, sortable: false, renderCell: (params: any) => {
       
                let items = [];
                if (permission.module.findIndex((data: any) => data.action === 'edit' && data.menu === "userManager") >= 0) {
                    items.push({ click: () => { GoToEditPage(params.value.id) }, title: <><EditIcon />Edit</>, close: true })
                }
                if (permission.module.findIndex((data: any) => data.action === 'permission' && data.menu === "userManager") >= 0) {
                    items.push({ click: () => { GoToEditPermission(params.value.id) }, title: <><LockIcon />Permission</>, close: true })
                }
                if (permission.module.findIndex((data: any) => data.action === 'gallery' && data.menu === "userManager") >= 0) {
                    items.push({ click: () => { GoToEditImageGallery(params.value.id) }, title: <><BrokenImageIcon />Change Image&Gallery</>, close: true })
                }
                if (permission.module.findIndex((data: any) => data.action === 'addAvailability' && data.menu === "userManager") >= 0) {
                    items.push({ click: () => { GoToAvailability(params.value.id) }, title: <><EventAvailableIcon />AddAvailability</>, close: true })
                }

                if (permission.module.findIndex((data: any) => data.action === 'addBooking' && data.menu === "userManager") >= 0) {
                    items.push({ click: () => { GoToBooking(params.value.id) }, title: <><EventAvailableIcon />AddBooking</>, close: true })
                }

                items.push({ click: () => { GoToUserForms(params.value.id) }, title: <><ListAltIcon />Forms</>, close: true })

                items.push({ click: () => { GoToLanguage(params.value) }, title: <><ClosedCaptionOffIcon />Set Language</>, close: true })

                return (
                    <>
                        <DropDownBtn
                            title={<><strong><SettingsIcon /></strong></>}
                            items={items}
                        />
                    </>
                );
            },
        },
        { field: 'id', type: "string", hideable: false, width: 100, },
        {
            field: 'Name', type: "string",filterable: false, width: 370, renderCell: (params: any) => {
                let data = params.value.split(",");
                return (<div className={`${LayoutStyle.flexWarp}`}>
                    <div><Avatar src={`${ApiUrl}${data[1]}/small/${data[2]}`} /></div> <div> {data[0]}</div>
                </div>);
            }
        },
        
        {
            field: 'display_name', type: "string",filterable: true, width: 370, renderCell: (params: any) => {
                return (<div className={`${GridStyle.textTd}`}>{CheckNullData(params.value, "...")}</div>);
            }
        },
        {
            field: 'Site', type: "string", width: 150, renderCell: (params: any) => {
                return (<div className={`${GridStyle.textTd}`}>{CheckNullData(params.value, "...")}</div>);
            }
        },
        {
            field: 'Bussiness Type', type: "string", filterable: false, width: 100, renderCell: (params: any) => {
                return (<div className={`${GridStyle.textTd}`}>{CheckNullData(params.value, "...")}</div>);
            }
        },
        {
            field: 'Program Part', type: "string", filterable: false, width: 200, renderCell: (params: any) => {
                return (<div className={`${GridStyle.textTd}`}>{CheckNullData(params.value, "...")}</div>);
            }
        },
        {
            field: 'IsAdmin', type: "boolean", filterable: false, width: 200, renderCell: (params: any) => {
                return (<div className={`${GridStyle.textTd} ${params.value === "1" ? LayoutStyle.textSuccess : ""}`}><strong>{params.value === '0' ? "No" : "Yes"}</strong></div>);
            }
        },
      
    ];
    const [rows, setRows] = useState([]);
    const usersData = useContext(UsersData)
    const GetUsersData = useContext(UsersActionGetData);
    const setPermissionInfo=useContext(UserPermissionContextActionData);
    const setUsersData = useContext(UsersAction);
    const GetPermission = useContext(UserPermissionContextAction)
    const [id] = useState(0);
    const [loading, setLoading] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [start, setStart] = useState(0);
    const [number, setNumber] = useState(25);


    const setDialogData = useContext(DialogAction);
    const [rowCountState, setRowCountState] = useState(0);

    const RemoveAvailability = () => {
        setLoading(true);
        RemoveProgramApi({ idSearch: id }).then(() => {
            setLoading(false);
            setDialogData({ open: false, id: "InterviewBooking" });
            GetData();
        })
    }
    const GoToEditImageGallery = (id: string | number) => {
        ResetAndGetUserDataByID(id)
        navigate(`/EditImageGalleryUser/${id}`);
    }
    const GoToAvailability=(id:string|number)=>{
        navigate(`/AddAvailabilityUser/${id}`);
    }
    
    const GoToBooking=(id:string|number)=>{
        navigate(`/AddBookingUser/${id}`);
    }
    const GoToUserForms=(id:string|number)=>{
        GetFormDataUserResponse({idSearch:id});
        navigate(`/UserFormResponse/${id}`);
    }

    const GoToLanguage = (item: any) => {
        SetParams([
            { table_name: "user", table_id: item.id, field_name: 'fname' },
            { table_name: "user", table_id: item.id, field_name: 'lname' },
            { table_name: "user", table_id: item.id, field_name: 'about' },

        ])
        setLangDefaultData(item)
        GetDataLangInfo({ table_name: "user", table_id: id });
        navigate(`/language/`);
    }

    const GoToEditPage = (id: string | number) => {
        ResetAndGetUserDataByID(id)
        
        navigate(`/EditUser/${id}`);
    }
    const GoToEditPermission = (id: string | number) => {
        
        ResetAndGetUserDataByID(id);
        setPermissionInfo(undefined);
        GetPermission({ idSearch: id });
        navigate(`/EditUserPermission/${id}`);
    }
    const ResetAndGetUserDataByID = (id: string | number) => {
        setUsersData([]);
        GetUsersData({ idSearch: id });
    }
    const GetData = (starts: number = start, numbers: number = number,param={}) => { 
        let params={
            start: starts,
            number: numbers,
        };
        params={...param,...params};
        GetUsersData(params);
     }

    const ChangePageSize = (param: any) => {
        setNumber(param.pageSize);
        setStart(0);
        GetData(0, param.pageSize);
    }

    const ChangePageNumber = (param: any) => {
        setStart(number * param.page);
        GetData(number * param.page);
    }
    
    const ChangeFilter = (param: any) => {
        let params:any=CreateParamSearchTable(param)
        if( Object.entries(params).length > 0 ){
            setIsLoading(true)
            GetData(start,number, params);
        }
    }

    const ChangeForSearch = (params: any) => {
        setIsLoading(true)
        GetData(start, number, params);
    }

    useEffect(() => {
        if (myPermissionInfo !== undefined) {
            setPermission(myPermissionInfo)
        }
    }, [myPermissionInfo])

    useEffect(() => {
        setIsLoading(true)
        if (usersData !== undefined) {
            setIsLoading(false)
            setRowCountState(usersData?.total);
            if (usersData.data) {
                let newRows = usersData.data.map((item: any) => {
                    return {
                        id: item.id,
                        Name: `${item.display_name},${item.file_url},${item.file_name}`,
                        display_name: `${item.display_name}`,
                        Site: `${item.site_name}`,
                        'Bussiness Type': item.business_types_name,
                        'Program Part': item.program_parts_name,
                        IsAdmin: item.is_admin,
                        Action: item
                    }
                })

                setRows(newRows);
            }
        }
    }, [usersData])

    return <>
            <SearchUsers ChangeForSearch={ChangeForSearch} />

        <GridServerSide
            rowCounts={rowCountState}
            columns={columns}
            rows={rows}
            isLoading={isLoading}
            ChangePageNumber={ChangePageNumber}
            ChangePageSize={ChangePageSize}
            ChangeFilter={ChangeFilter}
        />
        <ConfirmRejectDialog
            title='Are you sure remove?'
            id="InterviewBooking"
            body="All information related to the program will be deleted! "
            confirmTitle='Remove'
            rejectTitle='Cancel'
            confirmFunction={() => { RemoveAvailability() }}
            rejectFunction={() => { setDialogData({ open: false, id: "InterviewBooking" }) }}
            confirmClass={ButtonStyle.danger}
            rejectClass={ButtonStyle.rejectBtnDialog}
            loading={loading}
        />
    </>
}