import { AddStudioApi } from "../../../api/Api";
import BoredCamps from "../../Element/BoredCamp"
import Buttons from "../../Element/ButtonLoader"
import Items from "./Items";
import LayoutStyle from '../../../style/layout/Layout.module.css'
import { useNavigate } from "react-router-dom";
import { useState } from "react"

export default function AddStudio() {
    const [loading, setLoading] = useState(false);
    const [info, setInfo] = useState();
    const navigate = useNavigate();

    const SendData = () => {
        setLoading(true);
        AddStudioApi(info).then((response: any) => {
            setLoading(false);
            if (response?.status === "true") {
                navigate("/Studio");
            }
        })
    }

    return <>
        <div className={`${LayoutStyle.container}`}>
            <div className={`${LayoutStyle.innerContainer}`}>

                <BoredCamps Title='Add New Studio' linkHref='/Studio' linkTitle='Studio' />
                <Items Changed={setInfo} />

                <div className={` ${LayoutStyle.cover} ${LayoutStyle.mt1} ${LayoutStyle.mb1}`}>
                    <Buttons clicked={() => { SendData() }} loading={loading} title="Save Data" size='large' />
                </div>
            </div>

        </div>
    </>
}