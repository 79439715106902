import { useEffect, useState } from 'react';

import Inputs from './Input';
import { LangItem } from '../../Interface';
import LayoutStyle from '../../style/layout/Layout.module.css';
import Proof from './Proof';
import Sites from './Site';

export default function LanguageItems(props: LangItem) {

    const { index, changed,LangItemDatas={ lang:"",proof_id:"0",value:"",index,field_name:""} } = props
    const [paramData,setParamData]=useState({
        lang:"",
        proof_id:"",
        value:"",
        index
    })
  

    useEffect(()=>{
        setParamData(LangItemDatas);
    },[LangItemDatas])

    const changedItem = (value: any, types: string) => {
        
        let newValue:any=paramData;
        if(types==="proof_id"){
            newValue.proof_id=value?.value??"";
            newValue.lang=value.label;

        }else
        newValue[types]=value
        setParamData({...newValue});
        changed(newValue);
    }


    return <>
        <div className={`${LayoutStyle.flexWarp}`} key={`divLang`}>
      
        

            <div className={`${LayoutStyle.flexGrow1}`}>
                <Proof type='language' isMulti={false} defaultValue={paramData.proof_id} sendEventInChange={true} change={(data)=>changedItem(data,"proof_id")}/>
            </div>
            <div className={`${LayoutStyle.flexGrow1}`}>
                <Inputs
                    id={`value${index}`}
                    name='value'
                    value={paramData.value}
                    label="Value"
                    changed={(event: any) => { changedItem(event.target.value, "value") }}
                />
            </div>
        </div>
    </>
}