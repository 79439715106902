import { Link, useNavigate, useParams } from 'react-router-dom';
import { ProgramContextAction, ProgramContextData } from '../../context/ProgramContext';
import { useContext, useEffect, useState } from 'react';

import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import Buttons from '../Element/ButtonLoader';
import EditInfo from './edit/EditInfo';
import LayoutStyle from '../../style/layout/Layout.module.css';
import { LinkContextData } from '../../context/LinkContext';
import { RoleContextData } from '../../context/RoleContext';
import { UpdateProgramApi } from '../../api/Api';

export default function EditProgram() {
    const [data, setData] = useState([]);
    const [loading, setLoading] = useState(false);
    const programInfo=useContext(ProgramContextData);
    const [info, setInfo] = useState({} as any);
    const [title,setTitle]=useState("");
    const { id } = useParams();
    const navigate=useNavigate();
    const GetPrograms=useContext(ProgramContextAction);
    const RoleInfo=useContext(RoleContextData);
    const linkInfo=useContext(LinkContextData);

    const SendData=()=>{
        info["idSearch"]=id
        info["program_role"]=JSON.stringify(RoleInfo);
        info['program_link']=JSON.stringify(linkInfo);

        setLoading(true);
        UpdateProgramApi(info).then((response:any)=>{
            setLoading(false);
            if (response?.status === "true") {
                GetPrograms();
                navigate("/Programs");
            }
        })
    }

    useEffect(() => {
        if(programInfo !==undefined){
            if (programInfo.data.length > 0) {
                let datas=programInfo.data[0];
                setData(datas);
                setInfo(datas);
                setTitle(datas.title);
            }
        }
    }, [programInfo])

    return <>
        <div className={`${LayoutStyle.container}`}>
            <div className={`${LayoutStyle.innerContainer}`}>
                <div className={`${LayoutStyle.flex}`}>
                    <span><Link className={`${LayoutStyle.spanName}`} to={"/Programs"}>Programs</Link></span>
                    <span> <ArrowForwardIosIcon className={`${LayoutStyle.fontSm}`} /></span>
                    <span>Edit Program  ({title})</span>
                </div>
                <EditInfo data={data} changed={(data:any)=>{setInfo(data);}}/>
                <div className={` ${LayoutStyle.cover} ${LayoutStyle.mt1} ${LayoutStyle.mb1}`}>
                    <Buttons clicked={() => { SendData() }} loading={loading} title="Save Data" size='large' />
                </div>
            </div>
        </div>
    </>
}