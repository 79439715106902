import { UserEditNotesActionGetData, UserEditNotesData } from "../../context/Notes"
import { useContext, useEffect, useState } from "react"

import { ApiUrl } from "../../config";
import { Avatar } from "@mui/material";
import EditIcon from '@mui/icons-material/Edit';
import LayoutStyle from '../../style/layout/Layout.module.css';
import { useParams } from "react-router-dom"

export default function NotesUserEdit() {

    const { id } = useParams();
    const GetUserEditNote = useContext(UserEditNotesActionGetData)
    const userEditNotes = useContext(UserEditNotesData)

    const [datas, setDatas] = useState([]);

    useEffect(() => {
        if (userEditNotes !== undefined) {
            console.log(userEditNotes, '========userEditNotes')
            if (userEditNotes.data !== undefined) {
                let data = userEditNotes.data;
                setDatas(data);
            }
        }

    }, [userEditNotes])

    useEffect(() => {
        GetUserEditNote({ idSearch: id });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    let items = datas.map((data: any) => {

        return <>
            <div className={`${LayoutStyle.mt2}`}>
                <div className={`${LayoutStyle.flexWarp}`}>
                    <div><EditIcon className={`${LayoutStyle.spanName} ${LayoutStyle.fontSm}`}/></div>
                    <div><Avatar src={`${ApiUrl}${data.file_url}/small/${data.file_name}`} /></div>
                    <div> {` ${data.fname} ${data.lname}`}</div>
                    <div ><small className={`${LayoutStyle.spanName}`}>{data.created_at}</small></div>
                </div>
               
            </div>
        </>
    })
    return <>
        {items}
    </>

}