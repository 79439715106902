import { useEffect, useState } from 'react';

import Inputs from '../Element/Input';
import LayoutStyle from '../../style/layout/Layout.module.css';
import SingleSelect from '../Element/SingleSelect';

export default function FormItems(props: any) {
    const { index, changed, ItemDatas = {index:index, type: "", value: "" ,title:"",orders:"",action:"insert"} } = props
    const option=[
        { value: '', label: 'Choose Type', selected: false },
        { value: 'p', label: 'Paragraph', selected: false },
        { value: 'text', label: 'Text', selected: false },
        { value: 'textarea', label: 'Textarea', selected: false },
        { value: 'select', label: 'ComboBox', selected: false },
        { value: 'checkbox', label: 'Checkbox', selected: false },
        { value: 'radio', label: 'Radio', selected: false },
    ];
    const optionAction=[
        { value: 'insert', label: 'New record', selected: false },
        { value: 'update', label: 'Old record', selected: false },
        { value: 'remove', label: 'Remove', selected: false },
    ];
    const[itemValue,setItemValue]=useState({
        type:"",
        value:"",
        title:"",
        orders:"0",
        action:"update",
        index:index,
    })

    useEffect(() => {
        if(ItemDatas!==undefined){
            setItemValue({...ItemDatas})
        }
  
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    const changedItem = (value: any, types: string) => {

        let newValue:any=itemValue;
        newValue[types]=value;
        setItemValue({...newValue});
        changed(newValue)
    }

    return <>
        <div className={`${LayoutStyle.flexWarp} ${LayoutStyle.mt1}`} key={`divFormItem${index}`}>
            <div className={`${LayoutStyle.flexGrow1}`}>
      
                <SingleSelect
                   defaultValue={[option.find((data:any)=>data.value===itemValue.type)]}
                    options={option}
                    isMulti={false}
                    changed={(event: any) => {changedItem(event.value,"type")}} />
            </div>
            <div className={`${LayoutStyle.flexGrow1}`}>
               <Inputs value={itemValue.title} id={`title${index}`} name='title' label='Title' changed={(value)=>{changedItem(value.target.value,"title")}}/>
              
            </div>
            <div className={`${LayoutStyle.flexGrow1}`}>
               <Inputs value={itemValue.value} id={`value${index}`} name='value' label='Option' changed={(value)=>{changedItem(value.target.value,"value")}}/>
              
            </div>

            <div className={`${LayoutStyle.flexGrow1}`}>
               <Inputs value={itemValue.orders} id={`orders${index}`} name='orders' label='Sort' changed={(value)=>{changedItem(value.target.value,"orders")}}/>
              
            </div>
            <div className={`${LayoutStyle.flexGrow1}`}>
            <SingleSelect
                   defaultValue={[optionAction.find((data:any)=>data.value===itemValue.action)]}
                    options={optionAction}
                    isMulti={false}
                    changed={(event: any) => {changedItem(event.value,"action")}} />              
            </div>
        </div>
    </>
}