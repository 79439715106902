import { useEffect, useState } from 'react';

import { GuestItem } from '../../Interface';
import Inputs from './Input';
import LayoutStyle from '../../style/layout/Layout.module.css';
import Sites from './Site';

export default function GuestItems(props: GuestItem) {

    const { index, changed, GuestItemDatas = {
        name: "",
        email: "",
        url_link: "",
        site: ""
    }
    } = props



    const [data, setData] = useState<any>({
        name: "",
        email: "",
        url_link: "",
        site: "",
        index,
    })

    useEffect(() => {
        setData({...GuestItemDatas});
       
    }, [GuestItemDatas])

    const changedItem = (value: string, type: string,JsonString=false) => {
        let newValue: any = data;
        if (JsonString)
            newValue[type] = JSON.stringify(value);
        else
            newValue[type] = value;
        
        setData({ ...newValue });
    
        changed(newValue);


    }


    return <>
        <div className={`${LayoutStyle.flexWarp}`} key={`divGuest`}>

            <div className={`${LayoutStyle.flexGrow1}`}>
                <Inputs
                    id={`name${index}`}
                    name='name'
                    value={GuestItemDatas.name}
                    label="Name"
                    changed={(event: any) => { changedItem(event.target.value, "name") }}
                />
            </div>
            <div className={`${LayoutStyle.flexGrow1}`}>
                <Inputs
                    id={`email${index}`}
                    name='email'
                    value={GuestItemDatas.email}
                    label="Email"
                    changed={(event: any) => { changedItem(event.target.value, "email") }}
                />
            </div>
            <div className={`${LayoutStyle.flexGrow1}`}>
                <Inputs
                    id={`url_link${index}`}
                    name='url_link'
                    value={GuestItemDatas.url_link}
                    label="Url Link"
                    changed={(event: any) => { changedItem(event.target.value, "url_link") }}
                />
            </div>
            <div className={`${LayoutStyle.flexGrow1}`}>
                <Sites isMulti={false} change={(value) => { changedItem(value.value, "site"); }} defaultValue={GuestItemDatas.site} />
            </div>
        </div>
    </>
}