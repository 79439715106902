import { DataFromApi, UserInfo } from '../../Interface';
import { FetchFileImg, GetUserOnlineInfo, RemoveUserImage } from '../../api/Api';
import { useContext, useEffect, useState } from 'react';

import { ApiUrl } from '../../config';
import ButtonStyle from '../../style/button/Button.module.css';
import ConfirmRejectDialog from '../Element/AgreeDialog';
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';
import { DialogAction } from '../../context/Dialog';
import LayoutStyle from '../../style/layout/Layout.module.css';
import UploadImg from '../Element/UploadImg';
import { UserInfoActionGetData } from '../../context/UserInfo';
import { red } from '@mui/material/colors';

const ProfileImage = (props: UserInfo) => {
    const { data } = props
    const setDialogData=useContext(DialogAction);
    const [imgSrc,setImgSrc]=useState("");
    const [loading,setLoading]=useState(false);
    const [empty,setEmpty]=useState(false);
    const GetDataUserInfo=useContext(UserInfoActionGetData)
    
    const SendImage = (event: Event) => {
        let file = (event.target as HTMLInputElement).files;
        FetchFileImg("user/updateMyImage",{img:file?.[0]}).then((response:DataFromApi|any)=>{
            setEmpty(true);
            if(response.status==='true'){
                GetUserOnlineInfo().then((response)=>{ data.setUserInfoData(response);});
            }
        });
    }

    const RemoveImageProfile=()=>{
        
        setLoading(true);
        RemoveUserImage().then((result)=>{
            
            setLoading(false);
            setDialogData({open:false,id:"ConfirmRemoveProfileImage"});
            setEmpty(true);
            GetUserOnlineInfo().then((response)=>{ data.setUserInfoData(response);});
            GetDataUserInfo();
        })
    }

    useEffect(()=>{
        if(data.file_url!==undefined){
            if(data.file_url!==null||data.file_url!==""){
                setImgSrc(ApiUrl+data.file_url+"/small/"+data.file_name);
            }
        }
    },[data]);

    return <>
        <div className={`${LayoutStyle.center} ${LayoutStyle.width100}`}>
            <UploadImg  change={(event) => { SendImage(event) }} imageSrc={imgSrc} empty={empty}/>
            <button onClick={()=>{setDialogData({open:true,id:"ConfirmRemoveProfileImage"})}} className={`${ButtonStyle.btnNone} `}><DeleteOutlineIcon sx={{ color: red[500] }} /></button>
        </div>

        <ConfirmRejectDialog
            title='Are you sure remove image?'
            body=""
            id="ConfirmRemoveProfileImage"
            confirmTitle='Remove'
            rejectTitle='Cancel'
            confirmFunction={()=>{RemoveImageProfile()}}
            rejectFunction={()=>{setDialogData({open:false,id:"ConfirmRemoveProfileImage"})}}
            confirmClass={ButtonStyle.danger}
            rejectClass={ButtonStyle.rejectBtnDialog}
            loading={loading}

        />

    </>
}


export default ProfileImage;