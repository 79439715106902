import { FetchingFile, FetchingToken } from '../function/Fetching';

import { ConvertToFormData } from '../function/Common';
import { toast } from 'react-toastify';

export const GetUserOnlineInfo = () => {
    return RunFetchingFormData("user/getUserOnlineInfo", ConvertToFormData({}), false, false);
}
export const FetchFileImg = (url: string, body: any) => {
    return new Promise((resolve) => {
        
        let formData = ConvertToFormData(body,false);
        FetchingFile(url, formData).then((data) => {
            if (data.status === 'true') {
                toast.success('success');
            } else {
                toast.error(data.err);
            }
            resolve(data);
        })
    })
}
export const FetchFileMulti = (url: string, body: any) => {
    return new Promise((resolve) => {
        let formData = ConvertToFormData(body,true);
        
        FetchingFile(url, formData).then((data) => {
            if (data.status === 'true') {
                toast.success('success');
            } else {
                toast.error(data.err);
            }
            resolve(data);
        })
    })
}
export const RemoveUserImage = () => {
    return RunFetchingFormData("user/removeMyImage", ConvertToFormData({}), true);
}

export const RemoveUserBanner = () => {
    return RunFetchingFormData("user/removeMyBanner", ConvertToFormData({}), true);
}
export const LoginApi = (body:any) => {
    return RunFetchingFormData("user/login", ConvertToFormData(body), true,true);
}

export const ForgetPasswordApi = (body:any) => {
    return RunFetchingFormData("user/ForgotPassword", ConvertToFormData(body), true,true);
}

export const RegisterApi = (body:any) => {
    return RunFetchingFormData("user/register", ConvertToFormData(body), true,true);
}

export const RemoveUserImageProfile = (body:any) => {
    return RunFetchingFormData("user/removeUserImage", ConvertToFormData(body), true);
}
export const GetTimeZone = () => {
    return RunFetchingFormData("Fetch/GetAllTimeZone", ConvertToFormData({}), false, false);
}
export const CountryApi = () => {
    return RunFetching("country/get", {number:1000}, false, false);
}
export const ProvinceApi = (param:any) => {
    return RunFetching("province/get", param, false, false);
}
export const CityApi = (param:any) => {
    return RunFetching("city/get", param, false, false);
}
export const GetProof = (body: any) => {
    return RunFetchingFormData("proof/get", ConvertToFormData(body), false, false);
}
export const GetProofDataApi = (body: any) => {
    return RunFetchingFormData("proof/get", ConvertToFormData(body), false, true);
}
export const GetProofTypeDataApi = (body: any) => {
    return RunFetchingFormData("proof/getType", ConvertToFormData(body), false, true);
}
export const AddProofApi = (body: any) => {
    return RunFetchingFormData("proof/addNewProof", ConvertToFormData(body), true, true);
}
export const UpdateProofApi = (body: any) => {
    return RunFetchingFormData("proof/updateProf", ConvertToFormData(body), true, true);
}
export const RemoveProofApi = (body: any) => {
    return RunFetchingFormData("proof/removeProf", ConvertToFormData(body), true, true);
}
export const GetUser = (body: any) => {
    return RunFetchingFormData("user/get", ConvertToFormData(body), false, false);
}
export const GetUserAvailability = (body: any) => {
    return RunFetchingFormData("user/getAvailability", ConvertToFormData(body), false, false);   
}
export const UpdateMyProfile = (body: any) => {
    return RunFetchingFormData("user/updateMyInfo", ConvertToFormData(body), true);
}
export const GetMyGallery = (body: any) => {
    return RunFetchingFormData("user/getMyGalleryImage", ConvertToFormData(body), false,false);
}
export const RemoveMyImageGallery = (body: any) => {
    return RunFetchingFormData("user/removeImageGallery", ConvertToFormData(body),true);
}
export const GetStudioApi = (body: any) => {
    return RunFetchingFormData("studio/get", ConvertToFormData(body), false, true);
}
export const AddStudioApi = (body: any) => {
    return RunFetchingFormData("Studio/add", ConvertToFormData(body), true, true);
}
export const UpdateStudioApi = (body: any) => {
    return RunFetchingFormData("Studio/update", ConvertToFormData(body), true, true);
}
export const RemoveStudioApi = (body: any) => {
    return RunFetchingFormData("studio/Remove", ConvertToFormData(body), true, true);
}
export const GetSite = (body: any) => {
    return RunFetchingFormData("Fetch/GetAllSite", ConvertToFormData(body), false, false);
}
export const AddSiteApi = (body: any) => {
    return RunFetchingFormData("Fetch/AddSite", ConvertToFormData(body), true, true);
}
export const RemoveSiteApi = (body: any) => {
    return RunFetchingFormData("Fetch/RemoveSite", ConvertToFormData(body), true, true);
}
export const UpdateSiteApi = (body: any) => {
    return RunFetchingFormData("Fetch/updateSite", ConvertToFormData(body), true, true);
}
export const GetSiteApi = (body: any) => {
    return RunFetchingFormData("Fetch/GetAllSite", ConvertToFormData(body), false, true);
}
export const AddBookingApi = (body: any) => {
    return RunFetchingFormData("booking/add", ConvertToFormData(body,false), true, true);
}

export const UpdateBookingApi = (body: any) => {
    return RunFetchingFormData("booking/update", ConvertToFormData(body,false), true, true);
}
export const GetInterViewApi = (body: any) => {
    return RunFetchingFormData("booking/getMyBook", ConvertToFormData(body,false), false, true);
}
export const GetAvailabilityApi = (body: any) => {
    return RunFetching("teamAvailability/getMyAvailability", body, false, true);
}
export const AddAvailabilityApi = (body: any) => {
    return RunFetching("teamAvailability/AddAvailability", body, true, true);
}
export const GetBookingTeamApi = (body: any) => {
    return RunFetchingFormData("booking/get", ConvertToFormData(body,false), false, true);
}
export const GetJustBookingTeamApi = (body: any) => {
    return RunFetchingFormData("booking/GetTeam", ConvertToFormData(body,false), false, true);
}
export const RemoveAvailabilityApi = (body: any) => {
    return RunFetching("teamAvailability/Remove", body, true, false);
}
export const GetProgramApi = (body: any) => {
    return RunFetchingFormData("program/getMyProgram", ConvertToFormData(body,false), false, true);
}
export const GetAllProgramApi = (body: any) => {
    return RunFetchingFormData("program/get", ConvertToFormData(body,false), false, true);
}
export const RemoveProgramApi = (body: any) => {
    return RunFetchingFormData("program/removeProgram", ConvertToFormData(body,false), true, false);
}
export const RemoveProgramImageApi = (body: any) => {
    return RunFetchingFormData("program/removeImageProgram", ConvertToFormData(body,false), true, false);
}
export const RemoveImageGalleryApi = (body: any) => {
    return RunFetchingFormData("program/removeImageGallery", ConvertToFormData(body,false), true, false);
}
export const UpdateUserApi = (body: any) => {
    return RunFetchingFormData("user/updateUser", ConvertToFormData(body), true, true);
}
export const AddUserApi = (body: any) => {
    return RunFetchingFormData("user/addUser", ConvertToFormData(body), true, true);
}
export const UpdateProgramApi = (body: any) => {
    return RunFetchingFormData("program/updateProgram", ConvertToFormData(body,false), true, true);
}
export const AddProgramApi = (body: any) => {
    return RunFetchingFormData("program/addProgram", ConvertToFormData(body,false), true, true);
}

export const UpdateBookingTeamApi = (body: any) => {
    return RunFetchingFormData("booking/ConfirmOrReject", ConvertToFormData(body,false), true, true);
}
export  const SetUserPermission=(body:any)=>{
    return RunFetchingFormData("UserPermission/SetPermission",ConvertToFormData(body),true,true);
}
export  const GetUserPermissionApi=(body:any)=>{
    return RunFetchingFormData("UserPermission/getPermission",ConvertToFormData(body),false,true);
}
export  const GetMyPermissionApi=(body:any)=>{
    return RunFetchingFormData("UserPermission/getMyPermissionAdmin",ConvertToFormData(body),false,true);
}
export  const GetNoteApi=(body:any)=>{
    return RunFetchingFormData("fetch/GetNote",ConvertToFormData(body),false,true);
}
export const UpdateNoteApi = (body: any) => {
    return RunFetchingFormData("fetch/UpdateNote", ConvertToFormData(body,false), false, true);
}

export const AddLangDataApi = (body: any) => {
    return RunFetchingFormData("Lang/add", ConvertToFormData(body,false), true, true);
}

export const GetDataLangInfoApi = (body: any) => {
    return RunFetchingFormData("Lang/get", ConvertToFormData(body,false), false, true);
}

export const AddUserNoteApi = (body: any) => {
    return RunFetchingFormData("UserNotes/add", ConvertToFormData(body,false), true, true);
}

export  const GetMyNotesApi=(body:any)=>{
    return RunFetchingFormData("UserNotes/getMyNote",ConvertToFormData(body),false,true);
} 

export  const GetNotesUserEditApi=(body:any)=>{
    return RunFetchingFormData("UserNotes/getEditNoteUser",ConvertToFormData(body),false,true);
} 


export const UpdateNotesApi = (body: any) => {
    return RunFetchingFormData("UserNotes/Update", ConvertToFormData(body,false), false, true);
}

export const RemoveAttachApi = (body: any) => {
    return RunFetchingFormData("UserNotes/removeAttach", ConvertToFormData(body,false), true, false);
}
export const GetWhiteApi = (body: any) => {
    return RunFetchingFormData("UserWhiteBoard/get", ConvertToFormData(body), false, true);
}

export const AddWhiteApi = (body: any) => {
    return RunFetchingFormData("UserWhiteBoard/add", ConvertToFormData(body,false), true, true);
}

export const RemoveWhiteApi = (body: any) => {
    return RunFetchingFormData("UserWhiteBoard/remove", ConvertToFormData(body,false), true, false);
}
export const UpdateWhiteApi = (body: any) => {
    return RunFetchingFormData("UserWhiteBoard/Update", ConvertToFormData(body,false), false, true);
}

export const AddWhiteCommentApi = (body: any) => {
    return RunFetchingFormData("UserWhiteBoardComment/add", ConvertToFormData(body,false), true, true);
}

export const AddWhiteCommentReplyApi = (body: any) => {
    return RunFetchingFormData("UserWhiteBoardComment/addNewReplyComment", ConvertToFormData(body,false), true, true);
}

export const GetWhiteCommentApi = (body: any) => {
    return RunFetchingFormData("UserWhiteBoardComment/get", ConvertToFormData(body), false, true);
}
export  const GetDocApi=(body:any)=>{
    return RunFetchingFormData("UserDocument/get",ConvertToFormData(body),false,true);
} 

export  const GetMyDocsApi=(body:any)=>{
    return RunFetchingFormData("UserDocument/getMyDoc",ConvertToFormData(body),false,true);
}

export const RemoveDocsApi = (body: any) => {
    return RunFetchingFormData("UserDocument/remove", ConvertToFormData(body,false), true, false);
}

export const UpdateDocsApi = (body: any) => {
    return RunFetchingFormData("UserDocument/Update", ConvertToFormData(body,false), false, true);
}

export const RemoveAttachDocumentApi = (body: any) => {
    return RunFetchingFormData("UserDocument/removeAttach", ConvertToFormData(body,false), true, false);
}

export const AddFormApi = (body: any) => {
    return RunFetchingFormData("form/addNew", ConvertToFormData(body,false), true, true);
}

export const GetFormDataApi = (body: any) => {
    return RunFetchingFormData("form/get", ConvertToFormData(body,false), false, true);
}

export const GetFormDataItemApi = (body: any) => {
    return RunFetchingFormData("form/getItem", ConvertToFormData(body,false), false, true);
}

export const UpdateFormApi = (body: any) => {
    return RunFetchingFormData("form/update", ConvertToFormData(body,false), true, true);
}

export const UpdateFormItemSortApi = (body: any) => {
    return RunFetchingFormData("form/updateItemSort", ConvertToFormData(body,false), true, true);
}

export const UpdateFormPermissionApi = (body: any) => {
    return RunFetchingFormData("form/updateFormPermission", ConvertToFormData(body,false), true, true);
}

export const GetFormDependsApi = (body: any) => {
    return RunFetchingFormData("form/getFormDepends", ConvertToFormData(body,false), false, true);
}

export const RemoveFormApi = (body: any) => {
    return RunFetchingFormData("form/remove", ConvertToFormData(body,false), true, false);
}

export const GetMyFormDataApi = (body: any) => {
    return RunFetchingFormData("form/getMyForm", ConvertToFormData(body,false), false, true);
}

export const AddFormResponseApi = (body: any) => {
    return RunFetchingFormData("form/SetFormResponse", ConvertToFormData(body,false), true, true);
}

export const GetFormResponseDataApi = (body: any) => {
    return RunFetchingFormData("form/getFormResponse", ConvertToFormData(body,false), false, true);
}

export const GetFormProgramDatasResponseApi = (body: any) => {
    return RunFetchingFormData("form/GetProgramFormResponse", ConvertToFormData(body,false), false, true);
}

export const GetFormUserDatasResponseApi = (body: any) => {
    return RunFetchingFormData("form/GetUserFormResponse", ConvertToFormData(body,false), false, true);
}

const RunFetching = (url: string, body: any, successMessage = false, returnAllResponse = true) => {
    return new Promise((resolve) => {
        FetchingToken(url, body).then((data) => {
            if (data.status === 'true') {
                if (returnAllResponse)
                    resolve(data);
                else {
                    resolve(data.data);
                }
                if (successMessage) {
                    toast.success("success");
                }
            } else {
                if (data.err !== "expire token")
                    toast.error(data.err);
                resolve([]);
            }
        })
    })
}

const RunFetchingFormData = (url: string, body: any, successMessage = false, returnAllResponse = true) => {
    return new Promise((resolve) => {
        FetchingFile(url, body).then((data) => {
            if (data.status === 'true') {
                if (returnAllResponse)
                    resolve(data);
                else {
                    resolve(data.data);
                }
                if (successMessage) {
                    toast.success("success");
                }
            } else {
                if (data.err !== "expire token")
                    toast.error(data.err);
                resolve([]);
            }
        })
    })
}
