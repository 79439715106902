import BoredCamps from '../Element/BoredCamp';
import { DndProvider } from 'react-dnd'
import { HTML5Backend } from 'react-dnd-html5-backend'
import LayoutStyle from '../../style/layout/Layout.module.css';
import SortItem from './Sort/SortItem'

export default function SortForm() {


    return <>
        <div className={`${LayoutStyle.container}`}>
            <div className={`${LayoutStyle.innerContainer}`}>
                <div className={`${LayoutStyle.backBlackLight} ${LayoutStyle.cover}`}>
                    <BoredCamps Title={`Sort Items`} linkHref='/Forms' linkTitle='Forms' />
                    <DndProvider  backend={HTML5Backend}>
                        <SortItem  />
                    </DndProvider>
                </div>
            </div>
        </div>

    </>
}