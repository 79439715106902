import { ChangeToStringFy, ConvertToArrayOneIndexValueArray, GetValueFromMultiSelect } from '../../function/Common';
import { useContext, useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';

import BoredCamps from '../Element/BoredCamp'
import Buttons from '../Element/ButtonLoader';
import EditInfo from '../profile/edit/EditInfo'
import LayoutStyle from '../../style/layout/Layout.module.css'
import MoreField from './MoreField';
import { UpdateUserApi } from '../../api/Api';
import { UsersData } from '../../context/Users';

export default function EditUser() {
    const [info, setInfo] = useState({
        fname: "",
        lname: "",
        display_name: "",
        email: "",
        gender:"",
        phone: "",
        mobile: "",
        fax: "",
        birth_date:"",
        country:"",
        province:"",
        city:"",
        about: "",
        team_tag: "",
        timezone: "",
        file_url: "",
        file_name: "",
        admin_confirm:0,
    } as any);
    const navigate = useNavigate();
    const usersData = useContext(UsersData);
    const [programPart, setProgramPart] = useState<any>("");
    const [businessType, setBusinessType] = useState<any>("");
    const [sites, setSites] = useState<any>("");
    const { id } = useParams();

    const [loading, setLoading] = useState(false);
    const [isAdmin, setIsAdmin] = useState("");
    const [adminConfirm, setIsAdminConfirm] = useState(0);
    const SendData = () => {
        setLoading(true);
        let param: any = info;
        param.fname = info.fname ?? info.fName;
        param.lname = info.lname ?? info.lName;
        param.gender = info.gender;
        param.phone = info.phone;
        param.mobile = info.mobile;
        param.fax = info.fax;
        param.birth_date = info.birth_date;
        param.display_name = info.display_name ?? info.displayName;
        param.is_admin = isAdmin
        param.admin_confirm=info.admin_confirm
        param.site = ChangeToStringFy(GetValueFromMultiSelect(sites));
        param.business_type = ChangeToStringFy(GetValueFromMultiSelect(businessType))
        param.program_part = ChangeToStringFy(GetValueFromMultiSelect(programPart))
        param.idSearch = id
     
        UpdateUserApi(param).then((response: any) => {
            setLoading(false);

            if (response?.status === "true") {
                navigate("/UserManager");
            }
        })
    }

    useEffect(() => {

        if (usersData !== undefined) {
            if (usersData.data) {
                let data = usersData.data[0];
               // setData(data);
                setInfo(data);
                setIsAdmin(data.is_admin);
                setIsAdminConfirm(data.admin_confirm)
                if(data.sites!==null)
                setSites(ConvertToArrayOneIndexValueArray(data.sites));
                
                if(data.program_parts!==null)
                setProgramPart(ConvertToArrayOneIndexValueArray(data.program_parts));
                
                if(data.business_types!==null)
                setBusinessType(ConvertToArrayOneIndexValueArray(data.business_types));
            }
        }
    }, [usersData])

    const ChangedInfo=(data:string,name:string)=>{
        let newInfo=info;
        newInfo[name]=data;
        setInfo({...info})
    }

    return <>
        <div className={`${LayoutStyle.container}`}>
            <div className={`${LayoutStyle.innerContainer}`}>

                <BoredCamps Title='Edit User  Info' linkHref='/UserManager' linkTitle='User Manager' />

                <EditInfo changed={(data) => setInfo(data)} data={info} />
                <MoreField
                    isAdmin={isAdmin}
                    setIsAdmin={setIsAdmin}
                    programPart={programPart}
                    setProgramPart={setProgramPart}
                    businessType={businessType}
                    setBusinessType={setBusinessType}
                    sites={sites}
                    setSites={setSites}
                    admin_confirm={adminConfirm}
                    ChangedInfo={ChangedInfo}
                />

                <div className={` ${LayoutStyle.cover} ${LayoutStyle.mt1} ${LayoutStyle.mb1}`}>
                    <Buttons clicked={() => { SendData() }} loading={loading} title="Save Data" size='large' />
                </div>
            </div>

        </div>
    </>
}