import * as React from 'react';

import Checkbox from '@mui/material/Checkbox';
import { CheckboxProps } from '../../Interface';
import DarkMode from './DarkMode';
import { FormControlLabel } from '@mui/material';

export default function Checkboxes(props:CheckboxProps) {
  const {Change,label,value,check}=props;

  const [checked, setChecked] = React.useState(false);

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setChecked(event.target.checked);
    if(Change!==undefined){
      Change(event.target);
    }
  };

  React.useEffect(()=>{
    if(check!==undefined){

      setChecked(check);
    }
  },[check])
  return (
    <DarkMode>
        <FormControlLabel
          label={label}
          control={<Checkbox checked={checked} value={value} onChange={handleChange} />}
        />
     
    </DarkMode>
  );
}
