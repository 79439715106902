import { Link, useNavigate, useParams } from "react-router-dom";
import { useContext, useEffect, useState } from 'react';

import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import Buttons from "../Element/ButtonLoader";
import LayoutStyle from '../../style/layout/Layout.module.css';
import PermissionItems from './PermissionItems';
import { SetUserPermission } from '../../api/Api';
import { UserPermissionContextData } from "../../context/UserPermissionContext";
import { UsersData } from "../../context/Users";

export default function EditUserPermission() {
    const usersData = useContext(UsersData);
    const permissionInfo=useContext(UserPermissionContextData);
    const [title, setTitle] = useState("");
    const [loading,setLoading] = useState(false);
    const { id }=useParams();
    const navigate=useNavigate();
    const [param,setParam]=useState({
        list:[],
        menu:[],
        module:[],
    } as any)

    const [data,setData]=useState({
        list:[],
        menu:[],
        module:[],
    } as any)

    const ChangedItem=(param:any)=>{
        let newParam=param;
        newParam.list=JSON.stringify(param.list);
        newParam.menu=JSON.stringify(param.menu);
        newParam.module=JSON.stringify(param.module);
        setParam({...newParam});
    }

    const SendData = () => { 
        setLoading(true);
        param.idSearch=id
        SetUserPermission(param).then((response:any)=>{
            setLoading(false); 
            if (response.status === 'true') {
                navigate('/UserManager');
            }
        })
    }

    useEffect(() => {
        if (usersData.data !== undefined) {
            if (usersData.data.length > 0) {
                let datas = usersData.data[0];
                setTitle(datas.display_name);
            }
        }

    }, [usersData])
    
    
    useEffect(() => {
        if(permissionInfo!==undefined){
            setData(permissionInfo)
        }
     

    }, [permissionInfo])

    return <>
        <div className={`${LayoutStyle.container}`}>
            <div className={`${LayoutStyle.innerContainer}`}>
                <div className={`${LayoutStyle.flex}`}>
                    <span><Link className={`${LayoutStyle.spanName}`} to={"/UserManager"}>User Manager</Link></span>
                    <span> <ArrowForwardIosIcon className={`${LayoutStyle.fontSm}`} /></span>
                    <span>Edit User Permission ({title})</span>
                </div>
                <PermissionItems datas={data} ChangedItem={(data)=>ChangedItem(data)}/>
                <div className={` ${LayoutStyle.cover} ${LayoutStyle.mt1} ${LayoutStyle.mb1}`}>
                    <Buttons clicked={() => { SendData() }} loading={loading} title="Save Data" size='large' />
                </div>
            </div>
        </div>
    </>

}