import { ProvinceProps, SingleSelectOption } from '../../Interface';
import { useCallback, useEffect, useState } from 'react';

import { ProvinceApi } from '../../api/Api';
import SingleSelect from './SingleSelect';

export default function Province(props: ProvinceProps) {
    const { countryId, changed,provinceId } = props
    const [options, setOptions] = useState<SingleSelectOption[]>([]);
    const [province,setProvince]=useState("");
    const Change=useCallback((value:any)=>{
        setProvince(value);
        changed(value)
    },[changed])

    useEffect(() => {

        if(countryId!==""){
            ProvinceApi({"country_id":countryId??"-1"}).then((response: any) => {
                if(response.total>0){
                    const optionsItem = response.data.map((data: any, index: number) => {
                        return { value: data.id, label: data.name, selected: index === 0 ? true : false }
                    })
                    if (provinceId !== undefined) {
        
                        if (provinceId !== "" && provinceId !== null )
                            {
                                let newIndex = optionsItem.findIndex((item:any)=>item.value===(provinceId??"0"));
                                if(newIndex>=0){
                                    optionsItem[0].selected=false;
                                    optionsItem[newIndex].selected=true;
                                }
                            }
                    }
                    setOptions(optionsItem);
                 
                }else{
                    setOptions([]);
                   
                }
               
            })
        }
       
    }, [ countryId, provinceId])
    return <>
        <SingleSelect options={options} changed={(event: any) => { Change(event.value) }} />
    </>
}