import { createContext, useState } from "react";

import { PropsChildren } from "../Interface";

export const UserGalleryData = createContext<any[]>([]);
export const UserGalleryAction = createContext<(data: any) => any | undefined>((data: any) => { });

export default function UserGalleryProvider(props: PropsChildren) {

   const { children } = props
   const [userGalleryData, setUserGalleryData] = useState([]);



   return (
      <UserGalleryData.Provider value={userGalleryData}>
         <UserGalleryAction.Provider value={setUserGalleryData}>
            {children}
         </UserGalleryAction.Provider>
      </UserGalleryData.Provider>
   )

}

