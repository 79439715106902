import { ChangeToStringFy, GetValueFromMultiSelect } from '../../function/Common';
import { useContext, useEffect, useState } from 'react';

import { AddUserApi } from '../../api/Api';
import BoredCamps from '../Element/BoredCamp'
import Buttons from '../Element/ButtonLoader';
import EditInfo from '../profile/edit/EditInfo'
import LayoutStyle from '../../style/layout/Layout.module.css'
import MoreField from './MoreField';
import { UsersAction } from '../../context/Users';
import { useNavigate } from 'react-router-dom';

export default function AddUser() {
    const [info, setInfo] = useState({} as any);
    const navigate = useNavigate();
    const setUsersData = useContext(UsersAction);


    const [data] = useState({
        fname: "",
        lname: "",
        display_name: "",
        email: "",
        phone: "",
        mobile: "",
        fax: "",
        about: "",
        gender:"",
        birth_date:"",
        country:"",
        province:"",
        city:"",
        team_tag: "",
        timezone: "",
        file_url: "",
        file_name: "",
        banner_file_url:"",
        banner_file_name:"",
    });
    const [loading, setLoading] = useState(false);
    const [isAdmin, setIsAdmin] = useState("0");
    const [programPart, setProgramPart] = useState<any>("");
    const [businessType, setBusinessType] = useState<any>("");
    const [sites, setSites] = useState<any>("");

    const SendData = () => {
        setLoading(true);
        let param: any = info;
        param.fname = info.fname ?? info.fName;
        param.lname = info.lname ?? info.lName;
        param.gender = info.gender;
        param.phone = info.phone;
        param.mobile = info.mobile;
        param.fax = info.fax;
        param.birth_date = info.birth_date;
        param.display_name = info.display_name ?? info.displayName;
        param.is_admin = isAdmin
        param.site = ChangeToStringFy(GetValueFromMultiSelect(sites));
        param.business_type = ChangeToStringFy(GetValueFromMultiSelect(businessType))
        param.program_part = ChangeToStringFy(GetValueFromMultiSelect(programPart))

        AddUserApi(param).then((response: any) => {
            setLoading(false);

            if (response?.status === "true") {
                navigate("/UserManager");
            }
        })
    }

    useEffect(() => {
        setUsersData([]);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    return <>
        <div className={`${LayoutStyle.container}`}>
            <div className={`${LayoutStyle.innerContainer}`}>

                <BoredCamps Title='Add New User' linkHref='/UserManager' linkTitle='User Manager' />

                <EditInfo changed={(data) => setInfo(data)} data={data} />

                <MoreField
                    isAdmin={isAdmin}
                    setIsAdmin={setIsAdmin}
                    programPart={programPart}
                    setProgramPart={setProgramPart}
                    businessType={businessType}
                    setBusinessType={setBusinessType}
                    sites={sites}
                    setSites={setSites}
                />


                <div className={` ${LayoutStyle.cover} ${LayoutStyle.mt1} ${LayoutStyle.mb1}`}>
                    <Buttons clicked={() => { SendData() }} loading={loading} title="Save Data" size='large' />
                </div>
            </div>

        </div>
    </>
}