import { FormDatasResponse, MyFormDatas } from '../../context/FormContext';
import { useContext, useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';

import { AddFormResponseApi } from '../../api/Api';
import BoredCamps from '../Element/BoredCamp'
import Buttons from '../Element/ButtonLoader'
import LayoutStyle from '../../style/layout/Layout.module.css'
import ResponseItem from './ResponseItem';

export default function ResponseToForm() {
    const navigate = useNavigate();
    const [loading, setLoading] = useState(false);
    const [title,setTitle]=useState("");
    const [info, setInfo] = useState<any>({});
    const [data, setData] = useState<any>([]);
    const [response, setResponse] = useState<any>([]);
    const myDatas = useContext(MyFormDatas);
    const datasResponse = useContext(FormDatasResponse);
    const param=useParams();

    const program_id=param.program_id

    const changedItem=(data:any)=>{
        setInfo(data);
    }

    const SendData=()=>{
        setLoading(true);
        if(program_id!==undefined){
            info["program_id"] = program_id
        }
        AddFormResponseApi(info).then((response: any) => {
            setLoading(false);

            if (response?.status === "true") {
                navigate("-1");
            }
        })
    }

    useEffect(() => {
        if(myDatas !==undefined){
            if (myDatas.data.length > 0) {
                let datas=myDatas.data[0];
                setData(datas);
                //setInfo(datas);
                setTitle(datas.title);
            }
        }
        
    }, [myDatas])

    useEffect(()=>{
        if(datasResponse!==undefined){
            if(datasResponse.data!==undefined){
                setResponse(datasResponse.data);
            }
        }
      
    },[datasResponse])


    return <>
         <div className={`${LayoutStyle.container}`}>
            <div className={`${LayoutStyle.innerContainer}`}>
                <div className={`${LayoutStyle.backBlackLight} ${LayoutStyle.cover} ${LayoutStyle.mt1}`}>
                    {/* <BoredCamps Title={`Response to ${title}`} linkHref='/MyForms' linkTitle='Forms' /> */}

                    <ResponseItem change={changedItem} data={data.items??undefined} response={response}/>
                  
              

                    <div className={` ${LayoutStyle.cover} ${LayoutStyle.mt1} ${LayoutStyle.mb1}`}>
                        <Buttons clicked={() => { SendData() }} loading={loading} title="Save Data" size='large' />
                    </div>
                </div>
            </div>
        </div>
    </>

}