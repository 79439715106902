import * as React from 'react';

import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import DarkMode from './DarkMode';
import { DatePickInterface } from '../../Interface';
import { DateTimePicker } from '@mui/x-date-pickers/DateTimePicker';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import TextField from '@mui/material/TextField';

export default function DateTimePick(props: DatePickInterface) {
    const { DefaultValue, label, Changed, minDate } = props
    const [value, setValue] = React.useState<(data: any) => any>(DefaultValue);
    const [valueDef, setValueDef] = React.useState<(data: any) => any>();

    const change = (newValue: any) => {
       
        setValue(newValue);
        if (Changed !== undefined) {

            let date:any = new Date(newValue);
            let year = date.getFullYear();

            let Month = date.getMonth() + 1;
            Month = '' + Month;
            if (Month.length < 2) {
                Month = '0' + Month;
            }

            let Day = '' + date.getDate();
            if (Day.length < 2) {
                Day = '0' + Day;
            }
            let Hours = date.getHours();
            let Minutes = date.getMinutes();
            let Seconds = date.getSeconds();
            let time = Hours + ':' + Minutes + ':' + Seconds;
            date = year + '-' + Month + '-' + Day+' '+time;
            Changed(date)
        }
    }

    React.useEffect(()=>{
        if(DefaultValue!==undefined){
            console.log(DefaultValue,'DefaultValue====datetime')
            setValueDef(DefaultValue);
        }
      },[DefaultValue])
    return <>
        <DarkMode>

            <LocalizationProvider dateAdapter={AdapterDateFns} >
                <DateTimePicker
                    mask="____/__/__"
                    value={valueDef??value}
                    minDate={minDate}
                    label={label}
                    onChange={(newValue: any) => {
                        change(newValue);
                   
                    }}
                    renderInput={(params) => <TextField sx={{ width: "100%" }} {...params} />}
                />
            </LocalizationProvider>
        </DarkMode>
    </>;
}
