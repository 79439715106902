import { useEffect, useState } from 'react'

import Inputs from '../../Element/Input'
import LayoutStyle from '../../../style/layout/Layout.module.css'

export default function Items(props:any) {

    const {Changed,datas}=props

    const [params,setParams]=useState({name:"",href:""})

    const Change=(value="",type="")=>{
        let newParam:any=params;
        newParam[type]=value;
        setParams({...newParam});
        Changed(newParam);
    }

    useEffect(()=>{
        if(datas !==undefined)
        setParams(datas);
    },[datas])

    return <>
        <div className={`${LayoutStyle.backBlackLight} ${LayoutStyle.cover}`}>
            <div className={`${LayoutStyle.flexGrow1}`}>
                <Inputs
                    label='Name'
                    name="name"
                    id="name"
                    value={params.name}
                    changed={(event) => Change(event.target.value,"name")}
                />
            </div>
           
            <div className={`${LayoutStyle.flexGrow1}`}>
                <Inputs
                    label='Href'
                    name="href"
                    id="href"
                    value={params.href}
                    changed={(event) => Change(event.target.value,"href")}
                />
            </div>

          
        </div>
    </>
}