import { DataFromApi, UserInfo } from '../../Interface';
import { FetchFileImg, GetUserOnlineInfo, RemoveUserBanner } from '../../api/Api';
import { useContext, useEffect, useState } from 'react';

import { ApiUrl } from '../../config';
import ButtonStyle from '../../style/button/Button.module.css';
import ConfirmRejectDialog from '../Element/AgreeDialog';
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';
import { DialogAction } from '../../context/Dialog';
import LayoutStyle from '../../style/layout/Layout.module.css';
import UploadImg from '../Element/UploadImg';
import { UserInfoActionGetData } from '../../context/UserInfo';
import { red } from '@mui/material/colors';

const ProfileBanner = (props: UserInfo) => {
    const { data } = props
    const setDialogData = useContext(DialogAction);
    const [imgSrc, setImgSrc] = useState("");
    const [loading, setLoading] = useState(false);
    const [empty, setEmpty] = useState(false);
    const GetDataUserInfo = useContext(UserInfoActionGetData)

    const SendImage = (event: Event) => {
        let file = (event.target as HTMLInputElement).files;
        setLoading(true)
        FetchFileImg("user/updateMyBanner", { img: file?.[0] }).then((response: DataFromApi | any) => {
            setEmpty(true);
            setLoading(false)
            if (response.status === 'true') {
                GetUserOnlineInfo().then((response) => { data.setUserInfoData(response); });
            }
        });
    }

    const RemoveImageProfile = () => {

        setLoading(true);
        RemoveUserBanner().then((result) => {

            setLoading(false);
            setDialogData({ open: false, id: "ConfirmRemoveProfileImage" });
            setEmpty(true);
            GetUserOnlineInfo().then((response) => { data.setUserInfoData(response); });
            GetDataUserInfo();
        })
    }

    useEffect(() => {
        if (data.banner_file_url !== undefined) {
            if (data.banner_file_url !== null || data.banner_file_url !== "") {
                setImgSrc(ApiUrl + data.banner_file_url + "/thumb/" + data.banner_file_name);
            }
        }
    }, [data]);

    return <>
        <div className={`${LayoutStyle.center} ${LayoutStyle.width100}`}>
            <UploadImg
                loading={loading}
                width={loading ? '150px' : '100%'}
                height='150px'
                borderRadius='10px'
                change={(event) => { SendImage(event) }}
                imageSrc={imgSrc} empty={empty} />
            <button onClick={() => { setDialogData({ open: true, id: "ConfirmRemoveProfileImage" }) }} className={`${ButtonStyle.btnNone} `}><DeleteOutlineIcon sx={{ color: red[500] }} /></button>
        </div>

        <ConfirmRejectDialog
            title='Are you sure remove image?'
            body=""
            id="ConfirmRemoveProfileImage"
            confirmTitle='Remove'
            rejectTitle='Cancel'
            confirmFunction={() => { RemoveImageProfile() }}
            rejectFunction={() => { setDialogData({ open: false, id: "ConfirmRemoveProfileImage" }) }}
            confirmClass={ButtonStyle.danger}
            rejectClass={ButtonStyle.rejectBtnDialog}
            loading={loading}

        />

    </>
}


export default ProfileBanner;