import { useEffect, useState } from 'react';

import InputStyle from '../../style/element/input/Inputs.module.css';
import { InputType } from '../../Interface';

const TextArea = (props: InputType) => {

    const { id, name, value = "", placeholder = "&nbsp;", label, changed,cols=10 } = props
    const [valueItem,setValueItem]=useState(value);


    const change=(event:any)=>{
        setValueItem(event.currentTarget.value);
        changed(event)
    }

    useEffect(()=>{
        if(value!==undefined){
            if(value){
                setValueItem(value);
            }
        }
    },[value])

    return <>
        <div className={`${InputStyle.inputsDiv}`}>
            <label htmlFor={`${InputStyle.inputsDiv}`} >
                <textarea
                    value={valueItem}
                    id={id}
                    rows={3}
                    cols={cols}
                    name={name}
                    className={`${InputStyle.inputs}`}
                    placeholder={placeholder}
                    onChange={(event:any)=>{change(event)}} ></textarea>
                <span className={`${InputStyle.label}`}>{label}</span>
            </label>
        </div>
    </>
}

export default TextArea;