import AddIcon from '@mui/icons-material/Add';
import ButtonStyle from '../../style/button/Button.module.css';
import GridsWhite from './GridsWhite';
import LayoutStyle from '../../style/layout/Layout.module.css';
import { Link } from 'react-router-dom';

export default function WhiteBoard() {

    return <>
        <div className={`${LayoutStyle.container}`}>
            <div className={`${LayoutStyle.innerContainer}`}>
                <div className={`${LayoutStyle.backBlackLight} ${LayoutStyle.cover}`}>
                    <Link className={`${ButtonStyle.btnBlueOuterLink} `} to={"/AddWhiteBoard"}>Add New <AddIcon className={LayoutStyle.fontSm} /></Link>
                    <GridsWhite/>
                </div>
            </div>
        </div>
    </>
}