import { Link, useNavigate } from "react-router-dom";

import { AddWhiteApi } from "../../api/Api";
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import Buttons from '../Element/ButtonLoader';
import LayoutStyle from '../../style/layout/Layout.module.css';
import WhiteItem from "./WhiteItem";
import { useState } from "react";

export default function AddWhiteBoard(){
    const [loading, setLoading] = useState(false);
    const [info, setInfo] = useState({} as any);
    const navigate=useNavigate();

    const SendData=()=>{
        //setLoading(true);
        AddWhiteApi(info).then((response:any)=>{
            setLoading(false);
            if (response?.status === "true") {
                navigate("/WhiteBoard");
            }
        })
       
    }


    return<>
         <div className={`${LayoutStyle.container}`}>
            <div className={`${LayoutStyle.innerContainer}`}>
                <div className={`${LayoutStyle.flex}`}>
                    <span><Link className={`${LayoutStyle.spanName}`} to={"/WhiteBoard"}>My White Board</Link></span>
                    <span> <ArrowForwardIosIcon className={`${LayoutStyle.fontSm}`} /></span>
                    <span>Add New Item  </span>
                </div>
                <WhiteItem   changed={(data:any)=>setInfo(data)}/>
                <div className={` ${LayoutStyle.cover} ${LayoutStyle.mt1} ${LayoutStyle.mb1}`}>
                    <Buttons clicked={() => { SendData() }} loading={loading} title="Save Data" size='large' />
                </div>
            </div>
        </div>
        </>;
    
}