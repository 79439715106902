import { Link, useNavigate, useParams } from "react-router-dom";
import { useContext, useEffect, useState } from "react";

import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import Buttons from '../Element/ButtonLoader';
import LayoutStyle from '../../style/layout/Layout.module.css';
import NoteItem from "./NoteItem";
import { UpdateNotesApi } from "../../api/Api";
import { UserAllNotesData } from "../../context/Notes";

export default function EditNotes(){
    const [loading, setLoading] = useState(false);
    const [info, setInfo] = useState({} as any);
    const [data, setData] = useState<any>(undefined);
    const [title,setTitle]=useState("");
    const { id } = useParams();

    const navigate=useNavigate();
    const userAllNotes = useContext(UserAllNotesData)

    
    useEffect(() => {
        if(userAllNotes !==undefined){
            if (userAllNotes.data.length > 0) {
                let datas=userAllNotes.data[0];
                setData(datas);
                setInfo(datas);
                setTitle(datas.title);
            }
        }
        
    }, [userAllNotes])

    const SendData=()=>{
        info["idSearch"]=id
 
        setLoading(true);
        UpdateNotesApi(info).then((response:any)=>{
            setLoading(false);
            
            if (response?.status === "true") {
                navigate("/MyNote");
            }
        })
     
    }


    return<>
         <div className={`${LayoutStyle.container}`}>
            <div className={`${LayoutStyle.innerContainer}`}>
                <div className={`${LayoutStyle.flex}`}>
                    <span><Link className={`${LayoutStyle.spanName}`} to={"/MyNote"}>My Note</Link></span>
                    <span> <ArrowForwardIosIcon className={`${LayoutStyle.fontSm}`} /></span>
                    <span>Edit {title}  </span>
                </div>
                
                {data!==undefined &&<NoteItem data={data} Upload={false} changed={(data:any)=>setInfo(data)}/>}
                
                <div className={` ${LayoutStyle.cover} ${LayoutStyle.mt1} ${LayoutStyle.mb1}`}>
                    <Buttons clicked={() => { SendData() }} loading={false} title="Save Data" size='large' />
                </div>
            </div>
        </div>
        </>;
    
}