import { ReactElement, useEffect, useState } from 'react';

import ButtonStyle from '../../style/button/Button.module.css';
import LayoutStyle from '../../style/layout/Layout.module.css';
import { UserInfo } from '../../Interface';

const Links = (props: UserInfo) => {

    const { data } = props
    const [teamTag, setTeamTag] = useState<(null | string)>(null);
    let links: ReactElement[] = [];

    useEffect(() => {
        if (data.team_tag !== undefined) {
            if (data.team_tag !== null)
                setTeamTag(data.team_tag)
        }
    }, [data])
    if (teamTag !== undefined) {
        if (teamTag !== null) {
            let tags = teamTag.split(",");
            links = tags.map((item) => {
                let itemData = item.split("::");
                return (<>
                    <a target={'blank'} className={`${ButtonStyle.btnBlueOuterLink}`} href={itemData[2]}>{itemData[1]} </a>
                </>)

            })

        }
    }
    return <>
        <div className={` ${LayoutStyle.cover} ${LayoutStyle.mt1}`}>
            <div>
                <h4>Link</h4>
                <div className={`${LayoutStyle.flexWarp}`}>
                    {links}
                </div>

            </div>
        </div>
    </>
}

export default Links;