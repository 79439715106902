import { LangDataAction, LangDefaultDataAction, ParamAction } from "../../context/LanguageContext";
import { ProgramContextAction, ProgramContextData } from "../../context/ProgramContext";
import { useContext, useEffect, useState } from "react";

import { ApiUrl } from "../../config";
import { Avatar } from "@mui/material";
import ButtonStyle from '../../style/element/button/Button.module.css'
import ClosedCaptionOffIcon from '@mui/icons-material/ClosedCaptionOff';
import ConfirmRejectDialog from "../Element/AgreeDialog";
import DeleteIcon from '@mui/icons-material/Delete';
import { DialogAction } from "../../context/Dialog";
import DropDownBtn from "../Element/DropDownBtn";
import EditIcon from '@mui/icons-material/Edit';
import FactCheckIcon from '@mui/icons-material/FactCheck';
import { FormProgramDatasResponseAction } from "../../context/FormContext";
import GridServerSide from "../Element/GridServerSide";
import GridStyle from '../../style/element/grid/Grid.module.css'
import InsertPhotoIcon from '@mui/icons-material/InsertPhoto';
import LayoutStyle from '../../style/layout/Layout.module.css'
import { RemoveProgramApi } from "../../api/Api";
import SettingsIcon from '@mui/icons-material/Settings';
import { UserOnlinePermissionContextData } from "../../context/UserPermissionContext";
import { useNavigate } from "react-router-dom";

export default function MyProgram() {
    const navigate = useNavigate();
    const [permission, setPermission] = useState({ list: [], menu: [], module: [] });
    const myPermissionInfo = useContext(UserOnlinePermissionContextData)
    const GetFormDataProgramResponse=useContext(FormProgramDatasResponseAction);
    const columns = [
        {
            field: 'Action', type: "date", filterable: false, sortable: false, renderCell: (params: any) => {
                let items = [];
                let id=params.value.id;
                if (permission.module.findIndex((data: any) => data.action === 'remove' && data.menu === "programs") >= 0) {
                    items.push({ click: () => { Remove(id) }, title: <><DeleteIcon/>Remove</>, close: true })
                }
                if (permission.module.findIndex((data: any) => data.action === 'edit' && data.menu === "programs") >= 0) {
                    items.push({ click: () => { GoToEditPage(id) }, title: <><EditIcon/>Edit</>, close: true })
                }
                if (permission.module.findIndex((data: any) => data.action === 'gallery' && data.menu === "programs") >= 0) {
                    items.push({ click: () => { GoToImageGalleryPage(id) }, title: <><InsertPhotoIcon/>Image&&Gallery</>, close: true })
                }

                items.push({ click: () => { GoToLanguage(params.value) }, title: <><ClosedCaptionOffIcon/>Set Language</>, close: true })
                items.push({ click: () => { GoToChecklist(id) }, title: <><FactCheckIcon/>Checklist</>, close: true })

                return (
                    <>
                        <DropDownBtn
                            title={<><strong><SettingsIcon /></strong></>}
                            items={items}
                        />
                    </>
                );
            },
        },
        { field: 'id', type: "string", hideable: false, width: 120, },
        {
            field: 'Title', type: "string", width: 250, renderCell: (params: any) => {
                let data=params.value.split(",");
                return (<div className={`${LayoutStyle.flexWarp}`}>
                    <div><Avatar src={`${ApiUrl}${data[1]}/small/${data[2]}`}/></div> <div> {data[0]}</div>
                </div>);
     
            }
        },
        {
            field: 'Date', type: "date", width: 150, renderCell: (params: any) => {
                let dates = params.value.split("\n");
                return (<div><div>{dates[0]}</div><div>{dates[1]}</div></div>);
            }
        },
        {
            field: 'Studio', type: "string", filterable: false, width: 200, renderCell: (params: any) => {
                return (<div className={`${GridStyle.textTd}`}>{params.value}</div>);
            }
        },
        {
            field:'Publish',type:"string",filterable:false,width:100,renderCell:(params:any)=>{
                return (<div className={`${GridStyle.textTd} ${params.value==="1"?LayoutStyle.textSuccess:""}`}><strong>{params.value==='0'?"No":"Yes"}</strong></div>);
            }
        },
     
    ];
    const [rows, setRows] = useState([]);
    const programInfo = useContext(ProgramContextData)
    const GetPrograms = useContext(ProgramContextAction)
    const [id, setId] = useState(0);
    const [loading, setLoading] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [start, setStart] = useState(0);
    const [number, setNumber] = useState(25);

    const SetParams=useContext(ParamAction);
    const setLangDefaultData=useContext(LangDefaultDataAction);
    
    const GetDataLangInfo=useContext(LangDataAction);

    const setDialogData = useContext(DialogAction);
    const [rowCountState, setRowCountState] = useState(0);
    const Remove = (id: any) => { setId(id); setDialogData({ open: true, id: "InterviewBooking" }) }
    const RemoveAvailability = () => {
        setLoading(true);
        RemoveProgramApi({ idSearch: id}).then(() => {
            setLoading(false);
            setDialogData({ open: false, id: "InterviewBooking" });
            GetData();
        })
    }

    const GoToEditPage = (id: string | number) => {
        GetPrograms({ idSearch: id });
        navigate(`/EditProgram/${id}`);
    }
    
    const GoToImageGalleryPage = (id: string | number) => {
        GetPrograms({ idSearch: id });
        navigate(`/EditProgramImageGallery/${id}`);
    }
    
    const GoToLanguage = (item: any) => {
        SetParams([
            { table_name:"program",table_id:item.id,field_name:'title'},
            {table_name:"program",table_id:item.id,field_name:'tags'},
            {table_name:"program",table_id:item.id,field_name:'description'},
        ])
        setLangDefaultData(item)
        GetDataLangInfo({table_name:"program",table_id:id});
        navigate(`/language/`);
    }
    const GetData = (starts: number=start,numbers: number=number) => { GetPrograms({ start:starts, number:numbers,confirm_team:1 }); }
   
    const ChangePageSize = (param: any) => {
        setNumber(param.pageSize);
        setStart(0);
        GetData(0,param.pageSize);
    }

    const ChangePageNumber = (param: any) => {
        setStart(number * param.page);
        GetData(number * param.page);
    }
    
    const GoToChecklist=(id:any)=>{
        GetFormDataProgramResponse({idSearch:id});
        navigate(`/CheckListProgram/${id}`);
    }

    useEffect(() => {
        if (myPermissionInfo !== undefined) {
            setPermission(myPermissionInfo)
        }
    }, [myPermissionInfo])

    useEffect(() => {
        setIsLoading(true)
        if (programInfo !== undefined) {
            setIsLoading(false)
            setRowCountState(programInfo?.total);
            if (programInfo.data) {
                let newRows = programInfo.data.map((item: any) => {
                    return {
                        id: item.id,
                        Title:`${item.title},${item.file_url},${item.file_name}`,
                        Date: `${item.date_from} \n${item.date_to} `,
                        Studio: item.studio_description,
                        Publish:item.published,
                        Action: item
                    }
                })

                setRows(newRows);
            }
        }
    }, [programInfo])

    // eslint-disable-next-line react-hooks/exhaustive-deps
    useEffect(()=>{GetData()},[])
    return <>
            <div className={`${LayoutStyle.container}`}>
            <div className={`${LayoutStyle.innerContainer}`}>
                <div className={`${LayoutStyle.backBlackLight} ${LayoutStyle.cover}`}>
        <GridServerSide
            rowCounts={rowCountState}
            columns={columns}
            rows={rows}
            isLoading={isLoading}
            ChangePageNumber={ChangePageNumber}
            ChangePageSize={ChangePageSize}
        />
        <ConfirmRejectDialog
            title='Are you sure remove?'
            id="InterviewBooking"
            body="All information related to the program will be deleted! "
            confirmTitle='Remove'
            rejectTitle='Cancel'
            confirmFunction={() => { RemoveAvailability() }}
            rejectFunction={() => { setDialogData({ open: false, id: "InterviewBooking" }) }}
            confirmClass={ButtonStyle.danger}
            rejectClass={ButtonStyle.rejectBtnDialog}
            loading={loading}
        />
        </div>
        </div>
        </div>
    </>
}