import { useEffect, useState } from 'react';

import InputStyle from '../../style/element/input/Inputs.module.css';
import { InputType } from '../../Interface';

const InputNumber = (props: InputType) => {

    const { id, name, value = "", placeholder = "&nbsp;", label, changed } = props

    const [valueItem,setValueItem]=useState(value);
    const change=(event:any)=>{
        setValueItem(event.target.value);
        changed(event)
    }

    useEffect(()=>{
        if(value!==undefined){
            if(value){
                setValueItem(value);
            }
        }
    },[value])

    return <>
        <div className={`${InputStyle.inputsDiv}`}>
            <label htmlFor={`${InputStyle.inputsDiv}`} >
                <input
                    value={valueItem}
                    type="number" 
                    id={id}
                    name={name}
                    className={`${InputStyle.inputs}`}
                    placeholder={placeholder}
                    autoComplete='off'
                    onChange={(event:any)=>{change(event)}} />
                <span className={`${InputStyle.label}`}>{label}</span>
            </label>
        </div>
    </>
}

export default InputNumber;