import { WhiteActionGetData, WhitePropsData } from "../../context/WhiteBoard";
import { useContext, useEffect, useState } from "react";

import AttachFileIcon from '@mui/icons-material/AttachFile';
import ButtonStyle from '../../style/element/button/Button.module.css'
import ConfirmRejectDialog from "../Element/AgreeDialog";
import DeleteIcon from '@mui/icons-material/Delete';
import { DialogAction } from "../../context/Dialog";
import DropDownBtn from "../Element/DropDownBtn";
import EditIcon from '@mui/icons-material/Edit';
import GridServerSide from "../Element/GridServerSide";
import GridStyle from '../../style/element/grid/Grid.module.css'
import PreviewIcon from '@mui/icons-material/Preview';
import { RemoveWhiteApi } from "../../api/Api";
import SettingsIcon from '@mui/icons-material/Settings';
import { useNavigate } from "react-router-dom";

export default function GridsWhite() {
    const navigate = useNavigate();
    const GetWhiteData = useContext(WhiteActionGetData)
    const columns = [
        {
            field: 'Action', type: "date", filterable: false, sortable: false, renderCell: (params: any) => {
                let items = [];
                let values=params.value.split(",");
                let id=values[0];
                let edit=values[1];
                if (edit==='true') {
                    items.push({ click: () => { GoToEditPage(id) }, title: <><EditIcon/>Edit</>, close: true })
                    items.push({ click: () => { Remove(id) }, title: <><DeleteIcon/>Remove</>, close: true })
                    items.push({ click: () => { GoToAttachmentPage(id) }, title: <><AttachFileIcon/>Attachments</>, close: true })

                }
                items.push({ click: () => { GoToView(id) }, title: <><PreviewIcon/>ViewComment</>, close: true })
                return (
                    <>
                        <DropDownBtn
                            title={<><strong><SettingsIcon /></strong></>}
                            items={items}
                        />
                    </>
                );
            },
        },
        { field: 'id', type: "string", hideable: false, width: 120, },
        {
            field: 'Title', type: "string", width: 250, renderCell: (params: any) => {
                return (<div className={`${GridStyle.textTd}`}>{params.value}</div>);
            }
        },
        {
            field: 'Description', type: "string", filterable: false, width: 270, renderCell: (params: any) => {
                return (<div className={`${GridStyle.textTd}`}>{params.value}</div>);
            }
        },
        
        {
            field: 'TotalComment', type: "number", filterable: false, width: 170, renderCell: (params: any) => {
                return (<div className={`${GridStyle.textTd}`}>{params.value}</div>);
            }
        },

        
    ];
    const [rows, setRows] = useState([]);
    const whiteData = useContext(WhitePropsData)
    const [id, setId] = useState(0);
    const [loading, setLoading] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [start, setStart] = useState(0);
    const [number, setNumber] = useState(25);



    const setDialogData = useContext(DialogAction);
    const [rowCountState, setRowCountState] = useState(0);
    const Remove = (id: any) => { setId(id); setDialogData({ open: true, id: "WhiteBoardRemove" }) }
    const RemoveAvailability = () => {
        setLoading(true);
        RemoveWhiteApi({ idSearch: id}).then(() => {
            setLoading(false);
            setDialogData({ open: false, id: "WhiteBoardRemove" });
            GetData();
        })
    }

    const GoToAttachmentPage = (id: string | number) => {
        GetWhiteData({ idSearch: id });
        navigate(`/EditWhiteBoardAttachments/${id}`);
    }
    

    const GoToEditPage = (id: string | number) => {
        GetWhiteData({ idSearch: id });
        navigate(`/EditWhiteBoard/${id}`);
    }
    
    const GoToView = (id: string | number) => {
        
        navigate(`/WhiteBoardView/${id}`);
    }
    const GetData = (starts: number=start,numbers: number=number) => { GetWhiteData({ start:starts, number:numbers }); }
   
    const ChangePageSize = (param: any) => {
        setNumber(param.pageSize);
        setStart(0);
        GetData(0,param.pageSize);
    }

    const ChangePageNumber = (param: any) => {
        setStart(number * param.page);
        GetData(number * param.page);
    }
    
    useEffect(()=>{
        GetWhiteData({})
    // eslint-disable-next-line react-hooks/exhaustive-deps
    },[])

    useEffect(() => {
        setIsLoading(true)
        if (whiteData !== undefined) {

            setRowCountState(whiteData?.total);
            if (whiteData.data) {
                setIsLoading(false)
                let newRows = whiteData.data.map((item: any) => {
                    let edit=false;
                    if(item.creator_id===whiteData.userOnlineId){
                        edit=true;
                    }
                    
                    return {
                        id: item.id,
                        Title:`${item.title}`,
                        Description: item.description,
                        TotalComment: item.total_comment,
                        Action: `${item.id},${edit}`
                    }
                })

                setRows(newRows);
            }
        }
    }, [whiteData]);
    
    return <>
        <GridServerSide
            rowCounts={rowCountState}
            columns={columns}
            rows={rows}
            isLoading={isLoading}
            ChangePageNumber={ChangePageNumber}
            ChangePageSize={ChangePageSize}
        />
        <ConfirmRejectDialog
            title='Are you sure remove?'
            id="WhiteBoardRemove"
            body=" "
            confirmTitle='Remove'
            rejectTitle='Cancel'
            confirmFunction={() => { RemoveAvailability() }}
            rejectFunction={() => { setDialogData({ open: false, id: "WhiteBoardRemove" }) }}
            confirmClass={ButtonStyle.danger}
            rejectClass={ButtonStyle.rejectBtnDialog}
            loading={loading}
        />
    </>
}