import { ReactElement } from 'react';

export interface InputType {
  id: string;
  name: string;
  label: string;
  placeholder?: string,
  value?: string | number
  cols?:number,
  changed: (data: any) => any,
}

export interface CheckedFormItem {
  name: string;
  value: any;
}

export interface PropsChildren {
  children: React.ReactNode;
}
export interface UserInfoDatas {
  id?: string | number;
  fname: string;
  lname: string;
  display_name: string;
  email: string;
  about: string;
  gender: string;
  birth_date: string;
  country: string | "";
  province: string | "";
  city: string | "";
  phone: string;
  mobile: string;
  fax: string;
  team_tag: string | null;
  file_url: string | null;
  file_name: string | null;
  banner_file_url:string | null;
  banner_file_name:string | null;
  timezone?: string | null;
  setUserInfoData?: any
}
export interface UserInfo {
  data: UserInfoDatas
}

export interface DataFromApi {
  data: any,
  status: string,
  err: string
}

export interface UploadImgType {
  change: (event: any) => any,
  width?: string,
  height?: string,
  imageSrc?: string
  empty?: boolean
  multiple?: boolean,
  loading?: boolean,
  borderRadius?:string,
}
export interface AgreeDialog {
  title: string,
  body: any,
  confirmTitle: string,
  rejectTitle: string,
  confirmFunction: (data: any) => any,
  rejectFunction: (data: any) => any,
  confirmClass: string;
  rejectClass: string;
  loading: boolean;
  id: string
}

export interface InfoDialogProps {
  title: string,
  body: any,
  rejectTitle: string,
  rejectFunction: (data: any) => any,
  rejectClass: string;
  loading: boolean;
  id: string
}

export interface SingleSelects {
  isClearable?: boolean;
  isDisabled?: boolean;
  isLoading?: boolean;
  isMulti?: boolean;
  isRtl?: boolean;
  isSearchable?: boolean;
  changed: (data: any) => any;
  options?: SingleSelectOption[];
  changedInput?: (arg0: any) => any
  defaultValue?: any[]
}
export interface SingleSelectOption {
  label: string;
  value?: string | number | null | "";
  selected?: boolean

}
export interface ProofComponent {
  type: string;
  isMulti?: boolean;
  defaultValue: string | number | "";
  keys?:string | number,
  change: (data: any) => any,
  sendEventInChange?: true | false,
}
export interface siteComponent {

  isMulti?: boolean;
  defaultValue: string | number | "";
  change: (data: any) => any
}
export interface UserComponent {
  isMulti?: boolean;
  defaultValue: string | "";
  change: (data: any) => any,
  Availability?: true | false,
}
export interface GuestItemDatas { index: number; name: string, email: string,site:string,url_link:string }
export interface LinkItemData { index: number; type: string, title: string, link: string,site:string }
export interface LinkItem { index: number; changed: (data: any) => any, LinkItemDatas?: LinkItemData, linkType?: string }
export interface GuestItem { index: number; changed: (data: any) => any, GuestItemDatas?: GuestItemDatas }
export interface TimeZoneProps { dataUser?: UserInfoDatas | { timezone: string }, changed: (data: any) => any }
export interface EditInfoProps { changed: (data: any) => any, data: UserInfoDatas }
export interface EditLinkProps { datas?: UserInfoDatas | any, changed?: (data: LinkItemData[]) => any, linkType?: string }
export interface ChooseTeamProps { datas?: any, changed: (data: UserTeamData[]) => any }
export interface ChooseRoleProps { datas?: any}
export interface ChooseGuestProps { datas?: any, changed: (data: GuestItemDatas[]) => any }
export interface GridTable {
  columns: any[];
  rows: any[];
  rowCounts: number,
  isLoading: true | false,
  ChangePageSize: (param: any) => any
  , ChangePageNumber: (param: any) => any,
  width?: string,
  ChangeFilter?:(param:any)=>any,
}
export interface BoredCamp { linkHref: string; linkTitle: string; Title: string; }
export interface DatePickInterface { DefaultValue: any; label: any; Changed: ((arg0: any, arg1?: any) => void); id?: string, minDate?: any };
export interface TimePickInterface { DefaultValue: any; label: any; Changed: ((arg0: any, arg1?: any) => void); id?: string, minTime?: any };
export interface AddBookingItemInterface { changed: (arg0: any) => any, data?: any }
export interface UserTeamData { index: number; userId: string, edit: string }
export interface UserTeamDataItem { index: number; changed: (data: any) => any, ItemDatas?: UserTeamData }
export interface RoleCrewData { index: number; userId: string, role: string }
export interface RoleCrewDataItem { index: number; changed: (data: any) => any, ItemDatas?: RoleCrewData, isMulti: true | false }
export interface DropDownBtnPropsItem { title: string | ReactElement; click: (data: any) => any; close: boolean }
export interface DropDownBtnProps { title: ReactElement; items: DropDownBtnPropsItem[] }
export interface SidebarAdminProps { fontSize: number }
export interface CountryProps { countryId: string, changed: (param?: any) => any }
export interface ProvinceProps { countryId: string, provinceId: string, changed: (param?: any) => any }
export interface CityProps { ProvinceId: string, cityId: string, changed: (param?: any) => any }
export interface CityItemsProps { Change: (param?: any) => any, data?: { country_id: string, province_id: string, city_id: string } }
export interface CheckboxProps { Change: (param?: any) => any, label: string | ReactElement, value: any, check: true | false }
export interface RadioProps { Change: (param?: any) => any, label: string | ReactElement, defaultValue: any, option: any[], name: string }
export interface LanguageProps { table_name: string, table_id: string | number | "", field_name: string };

export interface SetParamLanguageApiProps { table_name: string, table_id: string | number | "", field_name: string, lang: string, proof_id: string | number, value: string }
export interface LangItemDatas { index: number, lang: string, proof_id: string, value: string, field_name: string }
export interface LangItem { index: number; changed: (data: any) => any, LangItemDatas?: LangItemDatas, field_name?: string }
export interface EditLangProps { datas?: LangItemDatas | any, changed: (data: LangItemDatas[]) => any, field_name?: string }
export interface UploadFileProps { multiple: true | false, accept?: "string", maxFileSize?: number, title: string, changed: (param?: any) => any }
export const ItemTypes = {
  CARD: 'card',
}
