import { useContext, useEffect, useState } from "react"

import Checkboxes from "../Element/Checkboxes"
import { FormItemDatas } from "../../context/FormContext"
import { GetFormItemDataArrayResponse } from "../booking/interviewBooking/CreateDataForEdit"
import Inputs from "../Element/Input"
import LayoutStyle from '../../style/layout/Layout.module.css'
import Radios from "../Element/Radios"
import SingleSelect from "../Element/SingleSelect"
import TextArea from "../Element/TextArea"

export default function ResponseItem(props: any) {

    const { change,response } = props
    let items: any = []
    const [info, setInfo] = useState<any>({});
    const [datas, setDatas] = useState<any>([])
    const [datasResponse, setDatasResponse] = useState<any>([])
    const [chBox, setChBox] = useState({} as any);
    const datasItem:any=useContext(FormItemDatas);

    // useEffect(() => {
    //     if (data !== undefined) {
    //         let datas = GetMyFormItemDataArray(data);
    //         setDatas(datas.data);
    //     }

    // }, [data])

    useEffect(()=>{
        if(datasItem!==undefined){
           
            let data=GetFormItemDataArrayResponse(datasItem.data)
            setDatas(data?.data!);
      
            
        }
    },[datasItem]);

    useEffect(() => {
        if (response.length !== undefined) {
            let item:any={}
            let chBox:any={};
            response.forEach((data:any) => {
                item[data.form_item_id]=data.response
                if(data.type==="checkbox"){
              
                    chBox[data.form_item_id]=data.response!==null?data.response.split(","):[]
                }
            });
       
            setDatasResponse(item);
            setInfo(item);
            setChBox(chBox);
        }

    }, [response])

    const changedItem = (value: any, types: string, json: boolean = false) => { 
        let newValue: any = info;
        newValue[types] = json ? value.toString() : value;

        setInfo({ ...newValue });
        change({...newValue});
    }
    const Changed = (checked: boolean, value: any, type: string) => {
        
        let newItems = chBox;
        if (newItems[type] ===undefined) {
            if (checked) {
                newItems[type]=[value];
            }
        } else {
            let index = newItems[type].findIndex((item: any) => item === value);
            if (index < 0) {
                if (checked) {
                    newItems[type].push(value);
                }
            } else {
                if (!checked) {
                    newItems[type].splice(index, 1);
                }
            }

        }

        setChBox({ ...newItems });
        changedItem(newItems[type],type,true)
    }
    
    items = datas.map((item: any) => {

        let element: any = <></>;
        let values=info[item.id]!==undefined?info[item.id]: datasResponse[item.id]??"";
        switch (item.type) {
            case "text": {
                element = <> <div className={`${LayoutStyle.flexGrow1}`}>
                    <Inputs value={values} name={item.title} id={item.id} label={item.title} changed={(value) => { changedItem(value.target.value, item.id) }} />
                </div>
                </>
                break;
            }
            case "p": {
                element = <> <div className={`${LayoutStyle.flexGrow1}`}>
                    <p>{item.title}</p>
                </div>
                </>
                break;
            }
            case "textarea": {
                element = <> <div className={`${LayoutStyle.flexGrow1}`}>
                    <TextArea value={values} name={item.title} id={item.id} label={item.title} changed={(value) => { changedItem(value.target.value, item.id) }} />
                </div>
                </>
                break;
            }
            case "select": {

                let options = [""];
                options = [...options,...item.value.split(",")];
                let option = [];
                
                option = options.map((dataV: any) => {
                
                    return { value: dataV, label: dataV, selected: false }
                })
         
                element = <>
                    <div className={`${LayoutStyle.flexGrow1}`}>
                        <p>{item.title}</p>
                        <SingleSelect
                            defaultValue={[option.find((itemDatas: any) => itemDatas.value === values)]}
                            options={option}
                            isMulti={false}
                            changed={(event: any) => { changedItem(event.value, item.id) }} />
                    </div>
                </>
                
                break;
            }
            case "checkbox": {

                let options = item.value.split(",");
                let option = [];
                let itemValue:any =values!==undefined?  values.split(","):[];
                option = options.map((dataV: any) => {
                    
                    return <>

                        <Checkboxes
                            check={itemValue.findIndex((data: any) => data === dataV) >= 0 ? true : false}
                            Change={(event: any) =>Changed(event.checked,event.value, item.id)}
                            value={dataV}
                            label={dataV} />

                    </>
                })

                element = <>
                    <div className={`${LayoutStyle.flexGrow1}`}>
                        <p>{item.title}</p>
                        <div className={`${LayoutStyle.flexWarp}`}>
                            {option}
                        </div>
                    </div>
                </>
                break;
            }

            case "radio": {

                let options = item.value.split(",");
                let option = [];
                option = options.map((dataV: any) => {
                    return {label:dataV,value:dataV}
                })

                element = <>
                    <div className={`${LayoutStyle.flexGrow1}`}>
                        <p>{item.title}</p>

                        <Radios
                            name={item.id}
                            option={option}
                            Change={(data: any) => changedItem(data, item.id) }
                            defaultValue={values}
                            label={item.title} />
                    </div>
                </>
                break;
            }
        }
        return <>
            {element}
        </>
    })
    return <>

        {items}
    </>
}