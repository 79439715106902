import { createContext, useState } from "react";

import { GetInterViewApi } from "../api/Api";
import { PropsChildren } from "../Interface";

export const InterViewBookingContextData = createContext<any>(undefined);
export const InterViewBookingContextAction = createContext<(param?:any) => any | undefined>(() => { });

export default function InterViewBookingContextProvider(props: PropsChildren) {

   const { children } = props
   const [interViewInfo, setInterViewInfo] = useState(undefined);

   const GetDataInterView = (param={start:0,number:25}) => {
      GetInterViewApi(param).then((response: any) => {
         setInterViewInfo(response);
      })
   }



   return (
      <InterViewBookingContextData.Provider value={interViewInfo}>
         <InterViewBookingContextAction.Provider value={GetDataInterView}>
            {children}
         </InterViewBookingContextAction.Provider>
      </InterViewBookingContextData.Provider>
   )

}

