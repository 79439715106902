import { SingleSelectOption, TimeZoneProps } from '../../Interface';
import { useEffect, useState } from 'react';

import { GetTimeZone } from '../../api/Api';
import SingleSelect from './SingleSelect';

export default function TimeZone(props: TimeZoneProps) {
    const { dataUser, changed } = props
    const [options, setOptions] = useState<SingleSelectOption[]>([]);

    const [value, setValue] = useState<any>("")

    const Change=(data:any)=>{
        console.log('datadata')
        setValue(data)
        changed(data)
    }

    useEffect(() => {
        GetTimeZone().then((response: any) => {

            const optionsItem = response.data.map((data: any, index: number) => {
                return { value: data.id, label: data.name, selected: index === 0 ? true : false }
            })
            if (dataUser !== undefined) {

                if (dataUser.timezone !== "" && dataUser.timezone !== null && dataUser.timezone !== undefined)
                    {
                            let newIndex = optionsItem.findIndex((item:any)=>item.value===(dataUser.timezone??"0"));
                            if(newIndex>=0){
                                optionsItem[0].selected=false;
                                optionsItem[newIndex].selected=true;
                            }
                        
                       
                    }
            }
            setOptions(optionsItem);
        })
    }, [dataUser])
    return <>
        <SingleSelect 
          defaultValue={[options.find((data:any)=>data.value===value)]}
          options={options} changed={(event: any) => { Change(event.value) }} />
    </>
}