import { InterViewBookingContextAction, InterViewBookingContextData } from '../../../context/InterViewBookingContext';
import { useContext, useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';

import AddBookingItem from './AddBookingItem';
import BoredCamps from '../../Element/BoredCamp';
import Buttons from '../../Element/ButtonLoader';
import LayoutStyle from '../../../style/layout/Layout.module.css';
import { UpdateBookingApi } from '../../../api/Api';

export default function EditBooking() {
    const [info, setInfo] = useState({} as any);
    const [datas, setDatas] = useState({} as any);
    const [loading, setLoading] = useState(false);
    const navigate = useNavigate();
    const GetDataInterView=useContext(InterViewBookingContextAction);
    const interViewInfo=useContext(InterViewBookingContextData);
    const { id } = useParams();
    const SubmitBooking=()=>{      
        setLoading(true)
        UpdateBookingApi({...info,...{idSearch:id}}).then((response:any)=>{
            setLoading(false);
            
            if (response?.status === "true") {
                GetDataInterView();
                navigate("/InterviewBooking");
            }
        })
    }

    useEffect(()=>{
        if(interViewInfo.total>0)
        setDatas(interViewInfo.data[0]);
    },[interViewInfo])
    return <>
        <div className={`${LayoutStyle.container}`}>

            <div className={`${LayoutStyle.innerContainer}`}>
                <div className={`${LayoutStyle.backBlackLight} ${LayoutStyle.cover}`}>
                    <BoredCamps Title='EditBooking' linkTitle='InterviewBooking' linkHref='/InterviewBooking' />
                    <AddBookingItem changed={(data)=>setInfo(data)} data={datas}/>
                    <div className={`${LayoutStyle.center}`}>
                        <Buttons title='Save Data' clicked={()=>{SubmitBooking();}} loading={loading} size='large'/>
                    </div>
                </div>
            </div>
        </div>
    </>
}