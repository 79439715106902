import * as React from 'react';

import { Link, useNavigate } from "react-router-dom";
import { SetCooke, getCookie } from '../../function/Fetching';

import AuthStyle from '../../style/auth/Auth.module.css';
import Buttons from '../Element/ButtonLoader';
import { CheckValue } from '../../function/CheckEmpty';
import { ForgetPasswordApi } from '../../api/Api';
import Inputs from '../Element/Input';
import LayoutStyle from '../../style/layout/Layout.module.css';
import { toast } from 'react-toastify';

export default function ForgetPassword() {
    const [loading, setLoading] = React.useState(false)
    const [email, setEmail] = React.useState("");
     const navigate = useNavigate();
    const handleClick = () => {
        setLoading(true);
        let checkedItem = [{ name: 'email', value: email }]
        if (!CheckValue(checkedItem)) {
            setLoading(false);
            return false;
        }
        ForgetPasswordApi({ email }).then((data: any) => {
            setLoading(false);
            if (data.status !== undefined) {
                if (data.status === 'false') {
                    toast.error(data.err);
                } else {
                    SetCooke(data.data);
                    toast.success('success');
                    navigate("/");
                }
            }


        })

    }




    return <>
        <div className={`${AuthStyle.container}`}>
            <div className={`${AuthStyle.login}`}>
                <div>
                    <h2>Forgot Password</h2>
                </div>

                <Inputs
                    id='email'
                    name='email'
                    value={email}
                    label="Email"
                    changed={(event) => { setEmail(event.target.value) }}
                />

             
                <div>
                    <Buttons
                        title='Submit'
                        size='large'
                        loading={loading}
                        clicked={() => { handleClick() }}
                    />
                </div>

                <div>
                    <Link className={`${LayoutStyle.spanName}`} to={"/Register"}>Register </Link>

                </div>
                <div>
                    <small>
                        <Link className={`${LayoutStyle.spanName}`} to={"/"}>
                            Login
                        </Link>
                    </small>

                </div>

            </div>
        </div>
    </>
}


