/* eslint-disable react-hooks/exhaustive-deps */

import { useContext, useEffect, useState } from 'react';

import AddIcon from '@mui/icons-material/Add';
import ButtonStyle from '../../style/button/Button.module.css';
import Grids from './Grids';
import LayoutStyle from '../../style/layout/Layout.module.css';
import { ProgramContextAction } from '../../context/ProgramContext';
import { UserOnlinePermissionContextData } from '../../context/UserPermissionContext';
import { useNavigate } from 'react-router-dom';

export default function Program() {
    const GetPrograms=useContext(ProgramContextAction);
    const [permission, setPermission] = useState({ list: [], menu: [], module: [] });
    const myPermissionInfo = useContext(UserOnlinePermissionContextData)
    const navigate=useNavigate();

    useEffect(()=>{
        GetPrograms();
    },[])

    useEffect(() => {
        if (myPermissionInfo !== undefined) {
            setPermission(myPermissionInfo)
        }
    }, [myPermissionInfo])
    
    const GoToAddProgram=()=>{
        navigate(`/AddProgram`);
    }

    return <>
        <div className={`${LayoutStyle.container}`}>
            <div className={`${LayoutStyle.innerContainer}`}>
                <div className={`${LayoutStyle.backBlackLight} ${LayoutStyle.cover}`}>

                {
                permission.module.findIndex((data: any) => data.action === 'add' && data.menu === "programs") >= 0 &&
                <button className={`${ButtonStyle.btnBlueOuterLink} `} onClick={()=>{GoToAddProgram()}}>Add Program <AddIcon className={LayoutStyle.fontSm} /></button>
                }

                    <Grids/>
                </div>
            </div>
        </div>
    </>
}