import { createContext, useState } from "react";

import { GetUserOnlineInfo } from "../api/Api";
import { PropsChildren } from "../Interface";

export const UserInfoData = createContext<any[]>([]);
export const UserInfoAction = createContext<(data: any) => any | undefined>((data: any) => { });
export const UserInfoActionGetData = createContext<() => any | undefined>(() => { });

export default function UserInfoProvider(props: PropsChildren) {

   const { children } = props
   const [userInfoData, setUserInfoData] = useState([]);

   const GetDataUserInfo = () => {
      GetUserOnlineInfo().then((response: any) => {
         setUserInfoData(response);
      })
   }

   return (
      <UserInfoData.Provider value={userInfoData}>
         <UserInfoAction.Provider value={setUserInfoData}>
            <UserInfoActionGetData.Provider value={GetDataUserInfo}>
               {children}
            </UserInfoActionGetData.Provider>
         </UserInfoAction.Provider>
      </UserInfoData.Provider>
   )

}

