/* eslint-disable react-hooks/exhaustive-deps */

import { FetchFileMulti, RemoveAttachApi } from '../../api/Api';
import { WhiteActionGetData, WhitePropsData } from '../../context/WhiteBoard';
import { useContext, useEffect, useState } from 'react';

import AttachList from '../Element/AttachList';
import { DataFromApi } from '../../Interface';
import LayoutStyle from '../../style/layout/Layout.module.css';
import UploadImg from '../Element/UploadImg';
import { getGalleryImageData } from '../booking/interviewBooking/CreateDataForEdit';
import { useParams } from 'react-router-dom';

export default function EditWhiteBoardAttachments(props: any) {
    const { id } = useParams();
    const GetWhiteData = useContext(WhiteActionGetData)
    const whiteData = useContext(WhitePropsData)

    const [load, setLoad] = useState(false);
    const [empty, setEmpty] = useState(false);
    const [gallery, setGallery] = useState<any[]>([]);
    const GetGallery = () => { GetWhiteData({ idSearch: id }); }


    const SendImage = (event: any) => {
        let file = (event.target as HTMLInputElement).files;
        setLoad(true);
        FetchFileMulti("UserWhiteBoard/addAttach", { img: file, idSearch: id }).then((response: DataFromApi | any) => {
            setEmpty(true);
            setLoad(false);
            if (response.status === 'true') {
                GetGallery();
            }
        });
    }

    useEffect(() => {
        if (whiteData !== undefined) {
            console.log(whiteData, '======whiteData')
            if (whiteData.data.length > 0) {
                let datas = whiteData.data[0];
                setGallery(getGalleryImageData(datas.attachments))
            }
        }
    }, [whiteData])

    return <>
        <div className={`${LayoutStyle.container}`}>
            <div className={`${LayoutStyle.innerContainer}`}>
            <div className={`${LayoutStyle.backBlackLight} ${LayoutStyle.cover} ${LayoutStyle.mt1}`}>
                <div className={LayoutStyle.flex}>
                    <h4>Attachments</h4>
                </div>
                <div>
                    <div>
                        <UploadImg loading={load} multiple={true} change={(event) => { SendImage(event) }} imageSrc={""} empty={empty} />
                    </div>
                    <div className={` ${LayoutStyle.mt1}`}>
                        <AttachList RemoveGallery={RemoveAttachApi} GetGallery={GetGallery} datas={gallery}/>
                    </div>
                </div>

            </div>
        </div>
    </div>
    </>
}

