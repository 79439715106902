import { AddFormApi } from '../../api/Api';
import BoredCamps from '../Element/BoredCamp'
import Buttons from '../Element/ButtonLoader';
import FormItem from './FormItem';
import Inputs from '../Element/Input';
import LayoutStyle from '../../style/layout/Layout.module.css'
import Proof from '../Element/Proof';
import TextArea from '../Element/TextArea';
import { useNavigate } from 'react-router-dom';
import { useState } from 'react';

export default function AddForm() {

    const [loading, setLoading] = useState(false);
    const [info, setInfo] = useState({ title: "", description: "", item: "",form_type:"0" })
    const navigate = useNavigate();

    const changedItem = (value: any, types: string, json: boolean = false) => {

        let newValue: any = info;
        newValue[types] = json ? JSON.stringify(value) : value;
        setInfo({ ...newValue });
    }

    const SendData = () => {
        setLoading(true);

        AddFormApi(info).then((response: any) => {
            setLoading(false);

            if (response?.status === "true") {
                navigate("/Forms");
            }
        })
    }
    return <>
        <div className={`${LayoutStyle.container}`}>
            <div className={`${LayoutStyle.innerContainer}`}>
                <div className={`${LayoutStyle.backBlackLight} ${LayoutStyle.cover} ${LayoutStyle.mt1}`}>
                    <BoredCamps Title='Add New Form' linkHref='/Forms' linkTitle='Forms' />

                    <div className={`${LayoutStyle.flexGrow1}`}>
                        <Inputs id={`title`} name='title' label='Title' changed={(value) => { changedItem(value.target.value, "title") }} />

                    </div>
                    <div className={`${LayoutStyle.flexGrow1}${LayoutStyle.mt1}`}>
                        <TextArea id={`description`} name='description' label='Description' changed={(value) => { changedItem(value.target.value, "description") }} />
                    </div>
                    <div className={`${LayoutStyle.mt1}`}>
                        <p>Program Form</p>
                        <Proof  type="programForm" defaultValue={""} isMulti={false} change={(event) => { changedItem(event, "form_type") }} />
                    </div>
                    <FormItem id={"undefined"} changed={(value: any) => { changedItem(value, "item", true) }} />

                    <div className={` ${LayoutStyle.cover} ${LayoutStyle.mt1} ${LayoutStyle.mb1}`}>
                        <Buttons clicked={() => { SendData() }} loading={loading} title="Save Data" size='large' />
                    </div>
                </div>
            </div>
        </div>
    </>
}