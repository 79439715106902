import { ProofComponent, SingleSelectOption } from '../../Interface';
import { useEffect, useState } from 'react';

import { GetProof } from '../../api/Api';
import LayoutStyle from '../../style/layout/Layout.module.css';
import SingleSelect from './SingleSelect';
import Sites from './Site';

export default function Proof(props: ProofComponent) {
    const { type, change, isMulti = true, defaultValue = [], sendEventInChange = false, keys } = props
    const [options, setOptions] = useState<SingleSelectOption[]>([]);
    const [defaultValues, setDefaultValues] = useState<any[]>([]);
    const [site, setSite] = useState("1");

    useEffect(() => {
        GetProof({ type, site }).then((response: any) => {
            let options: SingleSelectOption[] = [];
            response.data.forEach((d: any) => {
                options.push({ value: d.id, label: d.title, selected: false });
            });
            setOptions(options);
        })
    }, [site, type])

    useEffect(() => {

        if (defaultValue !== null) {
            let defaultValues = defaultValue.toString().split(",");
            let values: any[] = [];

            defaultValues.forEach(d => {
                if (d !== "")
                    values.push(options.find((item: any) => item.value === d));
            });
            if (values.length > 0)
                setDefaultValues(values)
        }

    }, [defaultValue, options])



    return <>
        <div className={`${LayoutStyle.flexWarp}`}>
            <div className={`${LayoutStyle.flexGrow1}`}>
                <Sites isMulti={false} change={(value) => { setSite(value.value); }} defaultValue={site} />
            </div>
            <div className={`${LayoutStyle.flexGrow1}`}>

                <SingleSelect

                    defaultValue={defaultValues}
                    options={options}
                    isMulti={isMulti}
                    changed={
                        (event: any) => {
                            if (isMulti) {
                                change(event)
                            } else {
                                change(!sendEventInChange ? event.value : event)
                            }
                        }} />
            </div>

        </div>


    </>
}