import { Link, useParams } from "react-router-dom";
import { UserAllNotesActionGetData, UserAllNotesData } from "../../context/Notes";
import { useContext, useEffect, useState } from "react"

import { ApiUrl } from "../../config";
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import AttachFileIcon from '@mui/icons-material/AttachFile';
import LayoutStyle from '../../style/layout/Layout.module.css';
import NotesUserEdit from "./NotesUserEdit";

export default function NotesView() {
    const userAllNotes = useContext(UserAllNotesData);
    const GetUserAllNotes = useContext(UserAllNotesActionGetData)
    const [data, setData] = useState({ title: "", description: "", attachments: "" });
    const { id } = useParams();

    const CreateFileLink=(props:any)=>{
        
        let {data}=props
        if(data!==undefined){
            let files=data.split(",");
            let items=files.map((item:any)=>{
                let itemData=item.split("::");
                return <><a className={`${LayoutStyle.spanName} me-2`} target="blank" href={`${ApiUrl}${itemData[1]}/${itemData[2]}`} download><AttachFileIcon/> {itemData[2]}</a></>
            })
            return <>{items}</>
        }else{
            return <></>
        }
       
    }

    useEffect(()=>{
        GetUserAllNotes({ idSearch: id });
    // eslint-disable-next-line react-hooks/exhaustive-deps
    },[])

    useEffect(() => {
        if (userAllNotes !== undefined) {

            if (userAllNotes.data) {
                setData(userAllNotes.data[0])

            }
        }
    }, [userAllNotes]);

    return <>
        <div className={`${LayoutStyle.container}`}>
            <div className={`${LayoutStyle.innerContainer}`}>
                <div className={`${LayoutStyle.flex}`}>
                    <span><Link className={`${LayoutStyle.spanName}`} to={"/MyNote"}>My Note</Link></span>
                    <span> <ArrowForwardIosIcon className={`${LayoutStyle.fontSm}`} /></span>
                    <span>Add Note  </span>
                </div>
                <div className={`${LayoutStyle.backBlackLight} ${LayoutStyle.cover} ${LayoutStyle.mt1}`}>

                    <div className={`${LayoutStyle.mt1}`}>
                        <h1>{data.title}</h1>
                    </div>
                    <div className={`${LayoutStyle.mt1}`}>
                        <p>{data.description}</p>
                    </div>
                    <div className={`${LayoutStyle.mt1}`}>
                        {data.attachments!==null?<CreateFileLink data={data.attachments}/>:""}
                    </div>
                    <div className={`${LayoutStyle.mt1}`}>
                        <NotesUserEdit />
                    </div>
                </div>
            </div>
        </div>
    </>
}