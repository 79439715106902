import * as React from 'react';

import { Link, useNavigate } from "react-router-dom";
import { SetCooke, getCookie } from '../../function/Fetching';

import AuthStyle from '../../style/auth/Auth.module.css';
import Buttons from '../Element/ButtonLoader';
import { CheckValue } from '../../function/CheckEmpty';
import Inputs from '../Element/Input';
import LayoutStyle from '../../style/layout/Layout.module.css';
import { LoginApi } from '../../api/Api';
import Password from '../Element/Password';
import { toast } from 'react-toastify';

export default function Login() {
    const [loading, setLoading] = React.useState(false)
    const [email, setEmail] = React.useState("");
    const [password, setPassword] = React.useState("");
    const navigate = useNavigate();
    const handleClick = () => {
        setLoading(true);
        let checkedItem = [{ name: 'email', value: email }, { name: "password", value: password }]
        if (!CheckValue(checkedItem)) {
            setLoading(false);
            return false;
        }
        LoginApi({ email, password }).then((data: any) => {
            setLoading(false);
            if (data.status !== undefined) {
                if (data.status === 'false') {
                    toast.error(data.err);
                } else {
                    SetCooke(data.data);
                    toast.success('success');
                    navigate("/MyProfile");
                }
            }


        })

    }

    React.useEffect(() => {
        if (getCookie() !== "") {
            navigate("/MyProfile");
        }
    })


    return <>
        <div className={`${AuthStyle.container}`}>
            <div className={`${AuthStyle.login}`}>
                <div>
                    <h2>Login to booking</h2>
                </div>

                <Inputs
                    id='email'
                    name='email'
                    value={email}
                    label="Email"
                    changed={(event) => { setEmail(event.target.value) }}
                />

                <Password
                    id='password'
                    name='password'
                    value={password}
                    label="Password"
                    changed={(event) => { setPassword(event.target.value) }}
                />
                <div>
                    <Buttons
                        title='Login'
                        size='large'
                        loading={loading}
                        clicked={() => { handleClick() }}
                    />
                </div>

                <div>
                    <Link className={`${LayoutStyle.spanName}`} to={"/Register"}>Register </Link>

                </div>
                <div>
                    <small>
                        <Link className={`${LayoutStyle.spanName}`} to={"/ForgetPassword"}>
                            <small>Forget Password?</small>
                        </Link>
                    </small>

                </div>

            </div>
        </div>
    </>
}


