import { LinkItem, LinkItemData } from '../../Interface';
import { useEffect, useState } from 'react';

import Inputs from './Input';
import LayoutStyle from '../../style/layout/Layout.module.css';
import Proof from './Proof';
import Sites from './Site';

export default function LinkItems(props: LinkItem) {

    const { index, changed, LinkItemDatas = { title: "", type: "", link: "",site:"" }, linkType = "userLink" } = props

    const [type, setType] = useState("");
    const [title, setTitle] = useState("");
    const [link, setLink] = useState("");
    const [item,setItem]=useState({type:"",title:"",link:"",site:""})
    
    useEffect(() => {
        setItem(LinkItemDatas)
        // setLink(LinkItemDatas.link);
        // setTitle(LinkItemDatas.title);
        // setType(LinkItemDatas.type);
    }, [LinkItemDatas])

    const changedItem = (value: string, types: string) => {
        
        let newItem:any=item;
        newItem[types]=value;
        setItem({...newItem});
        newItem['index']=index
        changed(newItem);

  
    }


    return <>
        <div className={`${LayoutStyle.flexWarp}`} key={`divLink`}>
       
            <div className={`${LayoutStyle.flexGrow1}`}>
                <p>Choose a type</p>
                <Proof type={linkType} defaultValue={LinkItemDatas.type} isMulti={false} change={(event: any) => { changedItem(event, "type") }} />
            </div>
            <div className={`${LayoutStyle.flexGrow1}`}>
                <p>Write a title</p>
                <Inputs
                    id={`title${index}`}
                    name='title'
                    value={LinkItemDatas.title}
                    label="."
                    changed={(event: any) => { changedItem(event.target.value, "title") }}
                />
            </div>
            <div className={`${LayoutStyle.flexGrow1}`}>
                <p>Write a link address</p>
                <Inputs
                    id={`link${index}`}
                    name='link'
                    value={LinkItemDatas.link}
                    label="."
                    changed={(event: any) => { changedItem(event.target.value, "link") }}
                />
            </div>
            <div className={`${LayoutStyle.flexGrow1}`}>
                <p>Choose which site to display</p>
                    <Sites isMulti={false} change={(value)=>{changedItem(value.value, "site")}} defaultValue={LinkItemDatas.site}/>
            </div>
        </div>
    </>
}