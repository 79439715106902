import { CountryProps, SingleSelectOption } from '../../Interface';
import { useEffect, useState } from 'react';

import { CountryApi } from '../../api/Api';
import SingleSelect from './SingleSelect';

export default function Country(props: CountryProps) {
    const { countryId, changed } = props
    const [options, setOptions] = useState<SingleSelectOption[]>([]);
    const [country, setCountry] = useState("");
    const [countryDef, setCountryDef] = useState<any[]|undefined>(undefined);
    const Change = (value: any) => {
        setCountry(value);
        changed(value)
    }

    useEffect(() => {
        if (countryId !== undefined) {
            if (countryId !== "" && countryId !== null) {
                let value=[];
                value=[options.find((data)=>data.value===countryId)]??[];
                setCountryDef(value)
            }
        }
    }, [countryId, options])

    useEffect(() => {
        CountryApi().then((response: any) => {
            const optionsItem = response.data.map((data: any, index: number) => {
                return { value: data.id, label: data.name, selected: index === 0 ? true : false }
            })
            setOptions(optionsItem);
        })
    }, []);

    return <>
        <SingleSelect defaultValue={countryDef} options={options} changed={(event: any) => { Change(event.value) }} />
    </>
}