/* eslint-disable react-hooks/exhaustive-deps */

import { useContext, useEffect, useState } from 'react';

import ChooseDocUser from './ChooseDocUser';
import Inputs from '../Element/Input';
import LayoutStyle from '../../style/layout/Layout.module.css';
import Proof from '../Element/Proof';
import TextArea from '../Element/TextArea';
import { UploadFile } from '../Element/UploadFile';
import { UserSearchParamAction } from '../../context/UserSearchParam';

export default function DocItem(props:any) {

    const {changed,Upload=true,data=undefined,site="1"}=props
    const setParams = useContext(UserSearchParamAction);

    const [info, setInfo] = useState({
        title: "",
        description: "",
        keyword:"",
        note_user: "",
        doc_user:"",
        type:"",
        img:[],
    })

    const Changed = (value: string, type: string, JsonString = false) => {

        let newValue: any = info;
        if (JsonString)
            newValue[type] = JSON.stringify(value);
        else
            newValue[type] = value;

        setInfo({ ...newValue });
         changed(newValue)
    }

    useEffect(()=>{
        if(data!==undefined){
            let sitesArray: any = [];
            if (data.user_document_user !== null&& data.user_document_user!==undefined) {
                let sites = data?.user_document_user.split(",") ?? [];
                sites.forEach((data: any) => {
                    let datas = data.split("::");
                    sitesArray.push(datas[2]);
                });
                data['doc_user']=sitesArray.toString();
            }
            setInfo(data)
        }
    },[data])

    useEffect(()=>{
        setParams({ })
    },[])

    return <>
        <div className={`${LayoutStyle.backBlackLight} ${LayoutStyle.cover} ${LayoutStyle.mt1}`}>

            <div className={`${LayoutStyle.mt1}`}>
                <Inputs
                    id='title'
                    name='title'
                    value={info.title}
                    label="Title"
                    changed={(event) => { Changed(event.target.value, "title"); }}
                />
            </div>

            <div className={`${LayoutStyle.mt1}`}>
                <p>Type:</p>
                <Proof  change={(value) => { Changed(value, "type"); }} type='userDocument' isMulti={false} defaultValue={info.type}/>
            </div>

            <div className={`${LayoutStyle.mt1}`}>
                <TextArea
                    id='description'
                    name='description'
                    value={info.description}
                    label="Details"
                    changed={(event) => { Changed(event.target.value, "description"); }}
                />
            </div>
            <div className={`${LayoutStyle.mt1}`}>
                <TextArea
                    id='keyword'
                    name='keyword'
                    value={info.keyword}
                    label="Keyword"
                    changed={(event) => { Changed(event.target.value, "keyword"); }}
                />
            </div>
            <div className={`${LayoutStyle.mt1}`}>
                <ChooseDocUser datas={info.doc_user} changed={(data:any)=>{Changed(data,"doc_user",true)}}/>
            </div>
           {Upload && <div className={`${LayoutStyle.mt1}`}>
                <UploadFile multiple={true} title="Add Files" changed={(files)=>Changed(files,'img')}/>
            </div>}
        </div>
    </>
}