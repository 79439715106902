import { Link, useNavigate } from "react-router-dom";
import { StudioActionGetData, StudioData } from "../../context/StudioContext";
import { useContext, useEffect, useState } from "react";

import AddIcon from "@mui/icons-material/Add";
import ButtonStyle from '../../style/button/Button.module.css'
import ConfirmRejectDialog from "../Element/AgreeDialog";
import DeleteIcon from '@mui/icons-material/Delete';
import { DialogAction } from "../../context/Dialog";
import DropDownBtn from "../Element/DropDownBtn";
import EditIcon from '@mui/icons-material/Edit';
import GridServerSide from "../Element/GridServerSide";
import GridStyle from '../../style/element/grid/Grid.module.css'
import LayoutStyle from '../../style/layout/Layout.module.css'
import { RemoveStudioApi } from "../../api/Api";
import SettingsIcon from '@mui/icons-material/Settings';
import { UserOnlinePermissionContextData } from "../../context/UserPermissionContext";

export default function Studio() {
    const [permission, setPermission] = useState({ list: [], menu: [], module: [] });
    const myPermissionInfo = useContext(UserOnlinePermissionContextData)
    const navigate = useNavigate()
    const columns = [
        { field: 'id', type: "string", hideable: false, width: 120, },
        {
            field: 'Title', type: "string", filterable: false, width: 250, renderCell: (params: any) => {
                return (<div className={`${GridStyle.textTd}`}>{params.value}</div>);
            }
        },
        {
            field: 'Link', type: "string", filterable: false, width:300, renderCell: (params: any) => {
                return (<div className={`${GridStyle.textTd}`}>{params.value}</div>);
            }
        },
        
        {
            field: 'Description', type: "string", filterable: false, width:400, renderCell: (params: any) => {
                return (<div className={`${GridStyle.textTd}`}>{params.value}</div>);
            }
        },
        {
            field: 'Action', type: "date", filterable: false, sortable: false, renderCell: (params: any) => {
                let items = [];
                if (permission.module.findIndex((data: any) => data.action === 'remove' && data.menu === "studio") >= 0) {
                    items.push({ click: () => { Remove(params.value) }, title: <><DeleteIcon />Remove</>, close: true })
                }
                if (permission.module.findIndex((data: any) => data.action === 'edit' && data.menu === "studio") >= 0) {
                    items.push({ click: () => { GoToEditPage(params.value) }, title: <><EditIcon />Edit</>, close: true })
                }
                return (
                    <>
                        <DropDownBtn
                            title={<><strong><SettingsIcon /></strong></>}
                            items={items}
                        />
                    </>
                );
            },
        },
    ];
    const [rows, setRows] = useState([]);
    const studioData: any = useContext(StudioData)
    const GetStudioData = useContext(StudioActionGetData)
    const [id, setId] = useState(0);
    const [loading, setLoading] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [start, setStart] = useState(0);
    const [number, setNumber] = useState(25);


    const setDialogData = useContext(DialogAction);
    const [rowCountState, setRowCountState] = useState(0);
    const Remove = (id: any) => { setId(id); setDialogData({ open: true, id: "InterviewBooking" }) }
    const RemoveAvailability = () => {
        setLoading(true);
        RemoveStudioApi({ idSearch: id }).then(() => {
            setLoading(false);
            setDialogData({ open: false, id: "InterviewBooking" });
            GetData();
        })
    }

    const GoToEditPage = (id: string | number) => {
        GetStudioData({ idSearch: id });
        navigate(`/EditStudio/${id}`);
    }

    const GetData = (starts: number = start, numbers: number = number) => { GetStudioData({ start: starts, number: numbers, order: "id", order_type: "desc" }); }

    const ChangePageSize = (param: any) => {
        setNumber(param.pageSize);
        setStart(0);
        GetData(0, param.pageSize);
    }

    const ChangePageNumber = (param: any) => {
        setStart(number * param.page);
        GetData(number * param.page);
    }

    useEffect(() => {
        if (studioData !== undefined) {
            setIsLoading(false)
            setRowCountState(studioData?.total);
            if (studioData.data) {
                let newRows = studioData.data.map((item: any) => {
                    return {
                        id: item.id,
                        Title: `${item.title}`,
                        Link: item.link,
                        Description: item.description,
                        Action: item.id
                    }
                })

                setRows(newRows);
            }
        }
    }, [studioData])

    useEffect(() => {
        GetData();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])
    useEffect(() => {
        if (myPermissionInfo !== undefined) {
            setPermission(myPermissionInfo)
        }
    }, [myPermissionInfo])
    return <>
        <div className={`${LayoutStyle.container}`}>
            <div className={`${LayoutStyle.innerContainer}`}>

                <div className={`${LayoutStyle.backBlackLight} ${LayoutStyle.cover}`}>
                {permission.module.findIndex((data: any) => data.action === 'add' && data.menu === "studio") >= 0 &&<Link className={`${ButtonStyle.btnBlueOuterLink}`} to={"/AddStudio"}>Add Studio <AddIcon className={LayoutStyle.fontSm} /></Link>}

                    <GridServerSide
                        rowCounts={rowCountState}
                        columns={columns}
                        rows={rows}
                        isLoading={isLoading}
                        ChangePageNumber={ChangePageNumber}
                        ChangePageSize={ChangePageSize}
                  
                    />
                    <ConfirmRejectDialog
                        title='Are you sure remove?'
                        id="InterviewBooking"
                        body="All information related to the program will be deleted! "
                        confirmTitle='Remove'
                        rejectTitle='Cancel'
                        confirmFunction={() => { RemoveAvailability() }}
                        rejectFunction={() => { setDialogData({ open: false, id: "InterviewBooking" }) }}
                        confirmClass={ButtonStyle.danger}
                        rejectClass={ButtonStyle.rejectBtnDialog}
                        loading={loading}
                    />
                </div>
            </div>
        </div>
    </>
}