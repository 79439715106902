import { useEffect, useState } from 'react';

import Checkboxes from '../Element/Checkboxes';
import GroupIcon from '@mui/icons-material/Group';
import LayoutStyle from '../../style/layout/Layout.module.css';
import ListAltIcon from '@mui/icons-material/ListAlt';
import LocalLibraryIcon from '@mui/icons-material/LocalLibrary';
import SettingsIcon from '@mui/icons-material/Settings';
import VideoLibraryIcon from '@mui/icons-material/VideoLibrary';

export default function PermissionItems(props: { ChangedItem: (data: any) => any, datas: any }) {
    const { ChangedItem, datas } = props;

    const [items, setItems] = useState(
        {
            list: [],
            menu: [],
            module: [],
        } as any
    );

    const Changed = (checked: boolean, value: any, type: string) => {
        let newItems = items;
        if (newItems[type].length <= 0) {
            if (checked) {
                newItems[type].push(value);
            }
        } else {
            let index = newItems[type].findIndex((item: any) => item === value);
            if (index < 0) {
                if (checked) {
                    newItems[type].push(value);
                }
            } else {
                if (!checked) {
                    newItems[type].splice(index, 1);
                }
            }

        }

        setItems({ ...newItems });
        ChangedItem(newItems)
    }

    useEffect(() => {
        let list = datas.list.map((data: any) => {
            return data.list
        })
        let menu = datas.menu.map((data: any) => {
            return data.menu
        })
        let module = datas.module.map((data: any) => {
            return data.menu + ',' + data.action
        })
        setItems({ ...{ list, menu, module } });

    }, [datas])

    return <>

        <div className={`${LayoutStyle.backBlackLight} ${LayoutStyle.cover} ${LayoutStyle.mt1}`}>
            <div className={`${LayoutStyle.flex}`}>
                <Checkboxes
                    check={items.list.findIndex((data: any) => data === "booking") >= 0 ? true : false}
                    Change={(data: any) => Changed(data.checked, data.value, "list")}
                    value="booking"
                    label={<><LocalLibraryIcon /> Booking</>} />
            </div>
            <div className={`${LayoutStyle.flexWarp}`}>
                <div>
                    <Checkboxes
                        check={items.menu.findIndex((data: any) => data === "interviewBooking") >= 0 ? true : false}
                        Change={(data: any) => Changed(data.checked, data.value, "menu")}
                        value="interviewBooking"
                        label="Interview Booking =>" />
                </div>
                <div>
                    <Checkboxes
                        check={items.module.findIndex((data: any) => data === "interviewBooking,add") >= 0 ? true : false}
                        Change={(data: any) => Changed(data.checked, data.value, "module")}
                        value="interviewBooking,add"
                        label="add" />
                    <Checkboxes
                        check={items.module.findIndex((data: any) => data === "interviewBooking,edit") >= 0 ? true : false}
                        Change={(data: any) => Changed(data.checked, data.value, "module")}
                        value="interviewBooking,edit"
                        label="edit" />
                    <Checkboxes
                        check={items.module.findIndex((data: any) => data === "interviewBooking,remove") >= 0 ? true : false}
                        Change={(data: any) => Changed(data.checked, data.value, "module")}
                        value="interviewBooking,remove"
                        label="remove" />
                </div>

            </div>


        </div>
        <hr />
        <div className={`${LayoutStyle.backBlackLight} ${LayoutStyle.cover} ${LayoutStyle.mt1}`}>


            <div className={`${LayoutStyle.flex}`}>
                <Checkboxes
                    check={items.list.findIndex((data: any) => data === "program") >= 0 ? true : false}
                    Change={(data: any) => Changed(data.checked, data.value, "list")}
                    value="program"
                    label={<><VideoLibraryIcon /> Program</>} />
            </div>
            <div className={`${LayoutStyle.flexWarp}`}>
                <div>
                    <Checkboxes
                        check={items.menu.findIndex((data: any) => data === "programs") >= 0 ? true : false}
                        Change={(data: any) => Changed(data.checked, data.value, "menu")}
                        value="programs"
                        label="Programs =>" />
                </div>
                <div>
                    <Checkboxes
                        check={items.module.findIndex((data: any) => data === "programs,add") >= 0 ? true : false}
                        Change={(data: any) => Changed(data.checked, data.value, "module")}
                        value="programs,add"
                        label="add" />
                    <Checkboxes
                        check={items.module.findIndex((data: any) => data === "programs,edit") >= 0 ? true : false}
                        Change={(data: any) => Changed(data.checked, data.value, "module")}
                        value="programs,edit"
                        label="edit" />
                    <Checkboxes
                        check={items.module.findIndex((data: any) => data === "programs,remove") >= 0 ? true : false}
                        Change={(data: any) => Changed(data.checked, data.value, "module")}
                        value="programs,remove"
                        label="remove" />
                    <Checkboxes
                        check={items.module.findIndex((data: any) => data === "programs,gallery") >= 0 ? true : false}
                        Change={(data: any) => Changed(data.checked, data.value, "module")}
                        value="programs,gallery"
                        label="gallery" />
                </div>
            </div>


        </div>
        <hr />
        <div className={`${LayoutStyle.backBlackLight} ${LayoutStyle.cover} ${LayoutStyle.mt1}`}>
            <div className={`${LayoutStyle.flex}`}>
                <Checkboxes
                    check={items.list.findIndex((data: any) => data === "user") >= 0 ? true : false}
                    Change={(data: any) => Changed(data.checked, data.value, "list")}
                    value="user"
                    label={<><GroupIcon /> User</>} />

            </div>
            <div className={`${LayoutStyle.flexWarp}`}>
                <div>
                    <Checkboxes
                        check={items.menu.findIndex((data: any) => data === "userManager") >= 0 ? true : false}
                        Change={(data: any) => Changed(data.checked, data.value, "menu")}
                        value="userManager"
                        label="User Manager =>" />
                </div>
                <div>
                    <Checkboxes
                        check={items.module.findIndex((data: any) => data === "userManager,add") >= 0 ? true : false}
                        Change={(data: any) => Changed(data.checked, data.value, "module")}
                        value="userManager,add"
                        label="add" />
                    <Checkboxes
                        check={items.module.findIndex((data: any) => data === "userManager,edit") >= 0 ? true : false}
                        Change={(data: any) => Changed(data.checked, data.value, "module")}
                        value="userManager,edit"
                        label="edit" />
                    <Checkboxes
                        check={items.module.findIndex((data: any) => data === "userManager,permission") >= 0 ? true : false}
                        Change={(data: any) => Changed(data.checked, data.value, "module")}
                        value="userManager,permission"
                        label="permission" />
                    <Checkboxes
                        check={items.module.findIndex((data: any) => data === "userManager,gallery") >= 0 ? true : false}
                        Change={(data: any) => Changed(data.checked, data.value, "module")}
                        value="userManager,gallery"
                        label="gallery" />
                    <Checkboxes
                        check={items.module.findIndex((data: any) => data === "userManager,addAvailability") >= 0 ? true : false}
                        Change={(data: any) => Changed(data.checked, data.value, "module")}
                        value="userManager,addAvailability"
                        label="Add Availability" />

                    <Checkboxes
                        check={items.module.findIndex((data: any) => data === "userManager,addBooking") >= 0 ? true : false}
                        Change={(data: any) => Changed(data.checked, data.value, "module")}
                        value="userManager,addBooking"
                        label="Add Booking" />
                </div>
            </div>

            <div className={`${LayoutStyle.flexWarp}`}>
                <div>
                    <Checkboxes
                        check={items.menu.findIndex((data: any) => data === "userDocuments") >= 0 ? true : false}
                        Change={(data: any) => Changed(data.checked, data.value, "menu")}
                        value="userDocuments"
                        label="user Documents =>" />
                </div>
                <div>
                    <Checkboxes
                        check={items.module.findIndex((data: any) => data === "userDocuments,add") >= 0 ? true : false}
                        Change={(data: any) => Changed(data.checked, data.value, "module")}
                        value="userDocuments,add"
                        label="add" />
                    <Checkboxes
                        check={items.module.findIndex((data: any) => data === "userDocuments,edit") >= 0 ? true : false}
                        Change={(data: any) => Changed(data.checked, data.value, "module")}
                        value="userDocuments,edit"
                        label="edit" />
                    <Checkboxes
                        check={items.module.findIndex((data: any) => data === "userDocuments,permission") >= 0 ? true : false}
                        Change={(data: any) => Changed(data.checked, data.value, "module")}
                        value="userDocuments,permission"
                        label="permission" />
                    <Checkboxes
                        check={items.module.findIndex((data: any) => data === "userDocuments,gallery") >= 0 ? true : false}
                        Change={(data: any) => Changed(data.checked, data.value, "module")}
                        value="userDocuments,gallery"
                        label="gallery" />
                    <Checkboxes
                        check={items.module.findIndex((data: any) => data === "userDocuments,remove") >= 0 ? true : false}
                        Change={(data: any) => Changed(data.checked, data.value, "module")}
                        value="userDocuments,remove"
                        label="Remove" />
                </div>
            </div>

        </div>
        <hr />
        <div className={`${LayoutStyle.backBlackLight} ${LayoutStyle.cover} ${LayoutStyle.mt1}`}>
            <div className={`${LayoutStyle.flex}`}>
                <Checkboxes
                    check={items.list.findIndex((data: any) => data === "setting") >= 0 ? true : false}
                    Change={(data: any) => Changed(data.checked, data.value, "list")}
                    value="setting"
                    label={<><SettingsIcon /> Setting</>} />

            </div>
            <div className={`${LayoutStyle.flexWarp}`}>
                <div>
                    <Checkboxes
                        check={items.menu.findIndex((data: any) => data === "proof") >= 0 ? true : false}
                        Change={(data: any) => Changed(data.checked, data.value, "menu")}
                        value="proof"
                        label="Proof =>" />
                </div>
                <div>
                    <Checkboxes
                        check={items.module.findIndex((data: any) => data === "proof,add") >= 0 ? true : false}
                        Change={(data: any) => Changed(data.checked, data.value, "module")}
                        value="proof,add"
                        label="add" />
                    <Checkboxes
                        check={items.module.findIndex((data: any) => data === "proof,edit") >= 0 ? true : false}
                        Change={(data: any) => Changed(data.checked, data.value, "module")}
                        value="proof,edit"
                        label="edit" />
                    <Checkboxes
                        check={items.module.findIndex((data: any) => data === "proof,remove") >= 0 ? true : false}
                        Change={(data: any) => Changed(data.checked, data.value, "module")}
                        value="proof,remove"
                        label="remove" />
                </div>
            </div>
            <div className={`${LayoutStyle.flexWarp}`}>
                <div>
                    <Checkboxes
                        check={items.menu.findIndex((data: any) => data === "site") >= 0 ? true : false}
                        Change={(data: any) => Changed(data.checked, data.value, "menu")}
                        value="site"
                        label="Site =>" />
                </div>
                <div>
                    <Checkboxes
                        check={items.module.findIndex((data: any) => data === "site,add") >= 0 ? true : false}
                        Change={(data: any) => Changed(data.checked, data.value, "module")}
                        value="site,add"
                        label="add" />
                    <Checkboxes
                        check={items.module.findIndex((data: any) => data === "site,edit") >= 0 ? true : false}
                        Change={(data: any) => Changed(data.checked, data.value, "module")}
                        value="site,edit"
                        label="edit" />
                    <Checkboxes
                        check={items.module.findIndex((data: any) => data === "site,remove") >= 0 ? true : false}
                        Change={(data: any) => Changed(data.checked, data.value, "module")}
                        value="site,remove"
                        label="remove" />
                </div>
            </div>
            <div className={`${LayoutStyle.flexWarp}`}>
                <div>
                    <Checkboxes
                        check={items.menu.findIndex((data: any) => data === "studio") >= 0 ? true : false}
                        Change={(data: any) => Changed(data.checked, data.value, "menu")}
                        value="studio"
                        label="Studio =>" />
                </div>
                <div>
                    <Checkboxes
                        check={items.module.findIndex((data: any) => data === "studio,add") >= 0 ? true : false}
                        Change={(data: any) => Changed(data.checked, data.value, "module")}
                        value="studio,add"
                        label="add" />
                    <Checkboxes
                        check={items.module.findIndex((data: any) => data === "studio,edit") >= 0 ? true : false}
                        Change={(data: any) => Changed(data.checked, data.value, "module")}
                        value="studio,edit"
                        label="edit" />
                    <Checkboxes
                        check={items.module.findIndex((data: any) => data === "studio,remove") >= 0 ? true : false}
                        Change={(data: any) => Changed(data.checked, data.value, "module")}
                        value="studio,remove"
                        label="remove" />
                </div>
            </div>


        </div>
        <hr />
        <div className={`${LayoutStyle.backBlackLight} ${LayoutStyle.cover} ${LayoutStyle.mt1}`}>
            <div className={`${LayoutStyle.flex}`}>
                <Checkboxes
                    check={items.list.findIndex((data: any) => data === "forms") >= 0 ? true : false}
                    Change={(data: any) => Changed(data.checked, data.value, "list")}
                    value="forms"
                    label={<><ListAltIcon /> Form</>} />

            </div>
            <div className={`${LayoutStyle.flexWarp}`}>
                <div>
                    <Checkboxes
                        check={items.menu.findIndex((data: any) => data === "forms") >= 0 ? true : false}
                        Change={(data: any) => Changed(data.checked, data.value, "menu")}
                        value="form"
                        label="Form =>" />
                </div>
                <div>
                    <Checkboxes
                        check={items.module.findIndex((data: any) => data === "form,add") >= 0 ? true : false}
                        Change={(data: any) => Changed(data.checked, data.value, "module")}
                        value="form,add"
                        label="add" />
                    <Checkboxes
                        check={items.module.findIndex((data: any) => data === "form,edit") >= 0 ? true : false}
                        Change={(data: any) => Changed(data.checked, data.value, "module")}
                        value="form,edit"
                        label="edit" />
                    <Checkboxes
                        check={items.module.findIndex((data: any) => data === "form,remove") >= 0 ? true : false}
                        Change={(data: any) => Changed(data.checked, data.value, "module")}
                        value="form,remove"
                        label="remove" />
                    <Checkboxes
                        check={items.module.findIndex((data: any) => data === "form,permission") >= 0 ? true : false}
                        Change={(data: any) => Changed(data.checked, data.value, "module")}
                        value="form,permission"
                        label="permission" />
                    <Checkboxes
                        check={items.module.findIndex((data: any) => data === "form,sort") >= 0 ? true : false}
                        Change={(data: any) => Changed(data.checked, data.value, "module")}
                        value="form,sort"
                        label="sort" />

                </div>
            </div>

        </div>
        <hr />
    </>
}