import {
ListItemButton,
ListItemIcon,
ListItemText,
} from "@mui/material";

import LayoutStyle from "../../style/layout/Layout.module.css";
import { Link } from "react-router-dom";
import React from "react";

export const OneSubMenu = (props:any) => {
  let href = window.location.pathname;


  const [open, setOpen] = React.useState(false);

  const handleClick = () => {
    setOpen(!open);
  };

  return (
    <>
      <div className="mb-3">
        <ListItemButton  onClick={handleClick}>
          <ListItemIcon >{props.icon}</ListItemIcon>
          <ListItemText>
            <Link className={`${LayoutStyle.link} ${href===props.href?LayoutStyle.sidebarLinkActive:""}`} to={props.href}>{props.title}</Link>
            </ListItemText>
        </ListItemButton>
  
      </div>
    </>
  );
};
