import moment from 'moment';

export const NowDate=new Date()

export const GetFirstNowDayMonth=()=>{
    return moment(
        NowDate.getFullYear() + "-" + (NowDate.getMonth() + 1) + "-" + NowDate.getDate()
      ).format("YYYY-MM-01");
}

export const GetDayMonthCustom=(Date:any)=>{
    return moment(
        Date.getFullYear() + "-" + (Date.getMonth() + 1) + "-" + Date.getDate()
      ).format("YYYY-MM-01");
}

export const GetFirstNowDayMonthLate=()=>{
    return moment(GetFirstNowDayMonth()).add(1, "M").format("YYYY-MM-01");
}

export const GetFirstNowDayMonthAgo=()=>{
    return moment(GetFirstNowDayMonth()).add(-1, "M").format("YYYY-MM-01");
}


export const GetFirstCustomMonthLate=(date:any)=>{
    let NewDate=new Date(date);
    return moment(GetDayMonthCustom(NewDate)).add(1, "M").format("YYYY-MM-01");
}

export const GetFirstCustomAddMonth=(date:any,add:number)=>{
    let NewDate=new Date(date);
    return moment(GetDayMonthCustom(NewDate)).add(add, "M").format("YYYY-MM-01");
}

export const GetFirstCustomDayMonthAgo=(date:any)=>{
    let NewDate=new Date(date);
    return moment(GetDayMonthCustom(NewDate)).add(-1, "M").format("YYYY-MM-01");
}

