import { ApiUrl, WebURL } from "../config";

const URLS = ApiUrl;
//const URLS = 'https://royasazan.com/api/';
const WEBURL = WebURL;
/// set cooke token
let Run = false;
export const SetCooke = (token, name = "token_royasazan") => {
  let d = new Date();
  d.setTime(d.getTime() + 365 * 24 * 60 * 60 * 1000);
  let expires = "expires=" + d.toUTCString();
  document.cookie = name + "=" + token + ";" + expires + ";path=/";
};

export const RemoveCooke = () => {
  document.cookie =
    "token_royasazan=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;";
};

export const getCookie = (cname = "token_royasazan") => {
  var name = cname + "=";
  var decodedCookie = decodeURIComponent(document.cookie);
  var ca = decodedCookie.split(";");
  for (var i = 0; i < ca.length; i++) {
    var c = ca[i];
    while (c.charAt(0) === " ") {
      c = c.substring(1);
    }
    if (c.indexOf(name) == 0) {
      return c.substring(name.length, c.length);
    }
  }
  return "";
};

export async function FetchingToken(url, body) {
  const datas = await GetResponse(url, body);
  return datas;
}

//// fetch data

export const Fetching = (url, body) => {

  return fetch(URLS + url, {
    method: "post",
    body: JSON.stringify(body),
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
    },
  })
    .then((res) => res.json())
    .then((datas) => {
      return datas;
    });
};
////use for upload file
export async function FetchingFile(url, body) {
  const data = await GetResponseFile(url, body);
  return data;
}

const GetResponseFile = (url, param) => {
  return new Promise((resolve) => {
    fetch(URLS + url, {
      method: "post",
      body: param,
      headers: {
        Accept: "application/json",
        Authorization: "Bearer " + getCookie(),
      },
    })
      .then((res) => res.json())
      .then((datas) => {
        if (datas.err !== "") {
          let expire = false;
          if (datas.err === "expire token") {
            expire = true;
            resolve(GetAgainDataFile(url, param));
          }
     
          if (!expire) {
            resolve(datas);
          }
        } else {
          resolve(datas);
        }
      });
  });
};

const GetResponse = (url, body) => {
  return new Promise((resolve) => {
    fetch(URLS + url, {
      method: "post",
      body: JSON.stringify(body),
      //withCredentials: true,
      //credentials: "include",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: "Bearer " + getCookie(),
      },
    })
      .then((res) => res.json())
      .then((datas) => {
        if (datas.err !== "") {
          let expire = false;
          if (datas.err === "expire token") {
            expire = true;
            GetAgainData(url, body).then((datasEnd) => {
              resolve(datasEnd);
            });
            Run = true;
          }

          if (datas.err === "invalid token") {
            GotToLogin();
          }
          if (!expire) {
            resolve(datas);
            Run = false;
          }
        } else {
          resolve(datas);
          Run = false;
        }
      });
  });
};

async function GetAgainData(url, body) {
  const newToken = await GetNewToken();
  const data = await FetchAgain(url, body, newToken);
  return data;
}

async function GetAgainDataFile(url, body) {
  const newToken = await GetNewToken();
  const data = await FetchAgainFile(url, body, newToken);
  return data;
}

const FetchAgain = (url, body, newToken) => {
  return new Promise((resolve) => {
    fetch(URLS + url, {
      method: "post",
      body: JSON.stringify(body),
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: "Bearer " + newToken,
      },
    })
      .then((res) => res.json())
      .then((data) => {
        resolve(data);
      });
  });
};

const FetchAgainFile = (url, body, newToken) => {
  return new Promise((resolve) => {
    fetch(URLS + url, {
      method: "post",
      body: body,
      headers: {
        Accept: "application/json",
        Authorization: "Bearer " + newToken,
      },
    })
      .then((res) => res.json())
      .then((data) => {
        resolve(data);
      });
  });
};

const GetNewToken = () => {
  return new Promise((resolve) => {
    if (Run === false) {
      fetch(URLS + "token/getToken", {
        method: "post",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          Authorization: "Bearer " + getCookie(),
        },
      })
        .then((res) => res.json())
        .then((datas) => {
          if (datas.err === "") {
            SetCooke(datas.data);
            resolve(datas.data);
            //Run=true;
          } else {
            if (datas.err === "invalid token") {
              GotToLogin();
            }
          }
        });
    } else {
      resolve(getCookie());
    }
  });
};

export const GotToLogin = () => {
  RemoveCooke();
  window.location.href = WEBURL;
};
