import { createContext, useState } from "react";

import { GetProofDataApi } from "../api/Api";
import { PropsChildren } from "../Interface";

export const ProofContextData = createContext<any>(undefined);
export const ProofContextAction = createContext<(param?:any) => any | undefined>(() => { });

export default function ProofContextProvider(props: PropsChildren) {

   const { children } = props
   const [proofInfo, setProofInfo] = useState(undefined);

   const GetProofs = (param={start:0,number:25}) => {
      GetProofDataApi(param).then((response: any) => {
         setProofInfo(response.data);
      })
   }



   return (
      <ProofContextData.Provider value={proofInfo}>
         <ProofContextAction.Provider value={GetProofs}>
            {children}
         </ProofContextAction.Provider>
      </ProofContextData.Provider>
   )

}

